import React, { useEffect, useMemo, useState } from "react";
import { useParams } from 'react-router';
import { MainApiProtectedVersion } from "../../../utils/axios/requests";
import { useForm, Controller } from "react-hook-form";
import { Form, Col, ListGroup } from 'react-bootstrap';





const AdminUserProfileDetail = () => {
  const { userId } = useParams();
  const [UserDetail, setUserDetail] = useState([])
  const [UserCustomDetail, setUserCustomDetail] = useState()
  const [UserDesgnation, setUserDesgnation] = useState('')





  const { handleSubmit, control, watch, register, setValue, getValues, reset, errors, formState } = useForm({ mode: 'onBlur' });



  // function Switch(label,name) {
  //   return (
  //     <Form>
  //       <Form.Check 
  //         type="switch"
  //         id="custom-switch"
  //         label
  //         name
  //         {...register(name)}
  //       />

  //     </Form>
  //   );
  // };




  useEffect(() => {

    async function UserDetail() {
      const obj = new MainApiProtectedVersion('', userId)
      const res = await obj.requstUserID().then((res) => {
        const response = res.data.data[0]
        setUserCustomDetail(response)
        if (response.developer_profile) {
          setUserDetail(response.developer_profile)
          setUserDesgnation("Developer")
          console.log('================developer_profile====================');
          // console.log(response);
        } else if (response.agent_profile) {
          console.log('==============agent_profile======================');
          setUserDetail(response.agent_profile)
          setUserDesgnation("Agent")


        } else if (response.other_profile) {
          console.log('=============other_profile=======================');
          setUserDetail(response.other_profile)
          setUserDesgnation("Other")

        } { }
        // setUserDetail(res.data.results)

      })
        .catch(error => {
          alert(error)
        })
    }

    UserDetail()



  }, [userId])





  useEffect(() => {
    if (UserCustomDetail) {
      Object.entries(UserCustomDetail).forEach(
        ([name, value]) => {
          setValue(name, value)


          // console.log('====================================');
          // console.log(name, value);
          // console.log('====================================');
        }

      );



    }

  });




  const onSubmit = async (data) => {

    const obj = new MainApiProtectedVersion('', userId)
    const res = await obj.requstUserUpdateID(data).then((res) => {
      const response = res.data.data[0]
      console.log('====================================');
      console.log(response);
      console.log('====================================');
      // setUserCustomDetail(response)

      // setUserDetail(res.data.results)

    })
      .catch(error => {
        alert(error)
      })





  }
  // useEffect(async () => {

  // //   const obj= new MainApiProtectedVersion('',userId)
  // //   const res= await obj.requstUserID()
  // //   console.log('====================================');
  // //   console.log(res);
  // //   console.log('====================================');

  // }, ])



  return (
    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="mb-6">
            <h2 className="mb-0 text-heading fs-22 lh-15">{UserDesgnation} Profile
            </h2>
           {/*  <p className="mb-1">Lorem ipsum dolor sit amet, consec tetur cing elit. Suspe ndisse suscipit</p> */}
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}
            onReset={reset}>
            <div className="row mb-6">
              <div className="col-lg-6">
                <div className="card mb-6">
                  <div className="card-body px-6 pt-6 pb-5">
                    <div className="row">
                      <div className="col-sm-4 col-xl-12 col-xxl-7 mb-6">
                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">Photo</h3>
                        <p className="card-text">Upload your profile photo.</p>
                      </div>
                      <div className="col-sm-8 col-xl-12 col-xxl-5">
                        <img src={UserDetail.profile_image} alt="My Profile" className="w-100" />
                        <div className="custom-file mt-4 h-auto">
                          <input type="file" className="custom-file-input" hidden id="customFile" name="file" />
                          <label className="btn text-white bg-blue hover-saffron" for="customFile">
                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                            profile image</label>
                        </div>
                        <p className="mb-0 mt-2">
                          *minimum 500px x 500px
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-6 mb-lg-0">
                  <div className="card-body px-6 pt-6 pb-5">
                    {/* <h3 className="card-title mb-0 text-heading fs-22 lh-15">{UserDesgnation} detail</h3>
                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                  
                    {/* <ListGroup >
                      <ListGroup.Item><Checkbox  label="Agent " name='is_agent'{...register('is_agent')}/></ListGroup.Item>
                      <ListGroup.Item><Checkbox {...register('is_custom_user')} label="Custom User " name='is_custom_user' /></ListGroup.Item>
                      <ListGroup.Item><Checkbox {...register('is_developer')} label="Developer " name='is_developer' /></ListGroup.Item>
                      <ListGroup.Item><Checkbox {...register('is_otherbusiness')} label="Other Business " name='is_otherbusiness' /></ListGroup.Item>
                      <ListGroup.Item><Checkbox {...register('is_superuser')} label="Superuser " name='is_superuser' /></ListGroup.Item>

                    </ListGroup > */}

                    <Form>
                      <Form.Check
                        type="switch"
                        label="Agent"
                        name='is_agent'
                        {...register('is_agent')}
                      />

                      <Form.Check
                        type="switch"
                        label="Custom User"
                        name='is_custom_user'
                        {...register('is_custom_user')}
                      />

                      <Form.Check
                        type="switch"
                        label="Developer"
                        name='is_developer'
                        {...register('is_developer')}
                      />

                      <Form.Check
                        type="switch"
                        label="Other User"
                        name='is_otherbusiness'
                        {...register('is_otherbusiness')}
                      />

                      <Form.Check
                        type="switch"
                        label="Super User"
                        name='is_superuser'
                        {...register('is_superuser')}
                      />




                    </Form>
                    <button className="btn bg-blue hover-saffron text-white mt-5 " onSubmit={onSubmit}>Update Profile</button>

                  </div>
                  

                </div>
              </div>
              <div className="col-lg-6">
                <div className="card mb-6">

                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">Contact information</h3>
                    {/* <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label for="firstName" className="text-heading">First name</label>
                        <input type="text" className="form-control form-control-lg border-0" id="firstName"
                          name="firsName" value={UserDetail.first_name} />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label for="lastName" className="text-heading">Last name</label>
                        <input type="text" className="form-control form-control-lg border-0" id="lastName"
                          name="lastname" value={UserDetail.last_name} />
                      </div>
                    </div>
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label for="phone" className="text-heading">Phone</label>
                        <input type="text" className="form-control form-control-lg border-0" id="phone"
                          name="phone" value={UserDetail.office_no} />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label for="mobile" className="text-heading">Mobile</label>
                        <input type="text" className="form-control form-control-lg border-0" id="mobile"
                          name="mobile" value={UserDetail.office_no} />
                      </div>
                    </div>
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4 mb-md-0">
                        <label for="email" className="text-heading">Email</label>
                        <input type="email" className="form-control form-control-lg border-0" id="email"
                          name="email" value={UserDetail.email} />
                      </div>
                      <div className="form-group col-md-6 px-4 mb-md-0">
                        <label for="skype" className="text-heading">Skype</label>
                        <input type="text" className="form-control form-control-lg border-0" id="skype"
                          name="skype" />
                      </div>
                    </div>
                  </div>



                </div>
               {/*  <div className="card">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">Package</h3>
                  
                    <div className="form-group">
                      <label for="oldPassword" className="text-heading">Old Password</label>
                      <input type="password" className="form-control form-control-lg border-0" id="oldPassword"
                        name="oldPassword" />
                    </div>
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                        <label for="newPassword" className="text-heading">New Password</label>
                        <input type="password" className="form-control form-control-lg border-0" id="newPassword"
                          name="newPassword" />
                      </div>
                      <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                        <label for="confirmNewPassword" className="text-heading">Confirm New Password</label>
                        <input type="password" className="form-control form-control-lg border-0"
                          id="confirmNewPassword" name="confirmNewPassword" />
                      </div>
                    </div>
                  </div>
                </div> */}

              </div>
            </div>
            {/* <div className="d-flex justify-content-end flex-wrap">
              <button className="btn btn-lg bg-hover-white border rounded-lg mb-3">Delete Profile</button>
              <button className="btn btn-lg btn-primary ml-4 mb-3 " onSubmit={onSubmit}>Update Profile</button>
            </div> */}
          </Form>
        </div>
      </main>
    </>
  )
}
export default AdminUserProfileDetail;