import React, { useState } from "react";
import Slider from "react-slick";
import { gql, useQuery } from '@apollo/client';
import { AnimationOnScroll } from "react-animation-on-scroll";
import BackInImage from "../../../src/images/shape-1.png";

const TestimonialQuery = () => {
    const GET_TESTIMONIAL = gql`
  
    query{
        testmonial{
          lastName
          id
          firstName
          email
          profileImageUrl
          description
          designation
          companyName
          profileImage
        }
      }
    `;
   
    const { loading, error, data } = useQuery(GET_TESTIMONIAL);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    console.log(data);
    var settings = {
        dots: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    }
    
    return (
        <>
        {data?.testmonial.length>0 ? (<>
            <section className="pt-10 pb-11 " style={{ backgroundImage: `url(${BackInImage})` }}>
                <div className="container container-xxl">
                    <div className="row heading-section-featured-TestimonialSlider mt-5">
                        <div className="col-12 text-center mb-5">
                            <h3 className="heading-testimonial text-blue ">Why do people love us?</h3>
                            <h2 className="text-heading mb-20">What Our Clients Say About Us</h2>
                        </div>
                    </div>
                    
                    <div className="slick-slider slick-dots-mt-0 custom-arrow-spacing-30 opacity-1  "
                                data-slick-options=''>
                        <Slider {...settings} className="feature-developer-site">
                            {/* first slider */}
                            {data.testmonial.map((item) => {

                                const image = `https://dollarperfoot.s3.amazonaws.com/${item.profileImage}`
                                return (
<>
                                    {/* <AnimationOnScroll animateIn="animate__fadeInUp"> */}
                                        <div class="box">
                                            <div className="card p-6 " data-animate="fadeInUp">
                                                <div className="card-body p-0 text-center">
                                                    <span className="text-blue fs-26 lh-1 mb-4 d-block">
                                                        <i className="fas fa-quote-left"></i>
                                                    </span>
                                                    <p className="card-text fs-15 lh-2 mb-4">
                                                    {item.description}
                                                    </p>
                                                    <span className="mx-auto divider mb-5"></span>
                                                    {image ==="https://dollarperfoot.s3.amazonaws.com/" ? (<>
                                                    <img src='images/favicon-icon.png' className="rounded-circle d-inline-block mb-2 fix-img"
                                                        alt="Lydia Wise" />
                                                    </>):(<>
                                                        <img src={image} className="rounded-circle d-inline-block mb-2 fix-img"
                                                        alt="Lydia Wise" />
                                                    </>)}
                                                   {/*  <img src={image} className="rounded-circle d-inline-block mb-2 fix-img"
                                                        alt="Lydia Wise" /> */}
                                                    <p className="fs-16 lh-214 text-dark font-weight-500 mb-0">{item.firstName} {item.lastName}</p>
                                                    <p className="mb-0">{item.designation}</p>
                                                    <p className="mb-0 fs-12 text-blue">{item.companyName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </>  
                                    )
                            })}
                        </Slider>
                    </div>
                </div>
            </section>
        
        </>):null}
            
        </>

    );

}


export default TestimonialQuery;