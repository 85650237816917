import React, { useEffect, useState } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Loader from "../Loader";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import Slider from 'react-rangeslider'
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import BackImage from "../../../src/images/about-shape.png";
import CurrencyFormat from "react-currency-format";
import Favicon from '../../../src/favicon.ico'
import Avatar from 'react-avatar';
import moment from "moment";
import { MainApiProtectedVersion } from "../../utils/axios/requests";
import userGetbyId from "../../utils/Api/userGetbyId";


const UserAllDetails = () => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams();
  console.log(id)
  const [UserDetail, setUserDetail] = useState([])
  console.log(UserDetail,"hello user")
  const [UserCustomDetail, setUserCustomDetail] = useState()
  const [UserDesgnation, setUserDesgnation] = useState('')
  const [propertyList, setPropertyList] = useState([])
  const filteredSale = propertyList.filter(sale => {

    return sale.labels[0].name === 'Sale'|| sale.labels[0]==='lease';
    
});

/* end */
/* lease data */
// console.log(forLeaseFilterData, 'Lease data')
const filteredLease = propertyList.filter(lease => {
    return lease.labels[0].name === 'Lease';
});

console.log(filteredSale,'filteredSale')
console.log(filteredLease,'filteredLease')


useEffect(()=>{
setPropertyForSale(filteredSale)
setPropertyForRent(filteredLease);
},[propertyList])
const [propertyForRent, setPropertyForRent] = useState([])
console.log(propertyForRent,'Lease Prop')
const [propertyForSale, setPropertyForSale] = useState([])
console.log(propertyForRent,'Sale Prop')
const InfoGet = () => {
  setLoading(true)
  const option = {
    method: 'GET', headers: {
      'access-control-allow-origin': '*',
      'content-type': 'application/json',
    },
    url: `${ApiUrl()}/auth/user/get-user-info/${id}`
  };
  axios(option)
    .then(e => {
      
      setLoading(false)
      setPropertyList(e?.data?.property_view)
     // setPropertyForRent(e?.data?.property_for_lease)
     // setPropertyForSale(e?.data?.property_for_sale)
    })
    .catch(err => {
      setLoading(false)
    })
}
useEffect(() => {
  window.scrollTo(0, 0);
 
  InfoGet()
  
}, [])

  useEffect(() => {

    async function UserDetail() {
      setLoading(true)
      const obj = new MainApiProtectedVersion('', id)
      const res = await obj.requstUserID().then((res) => {
      
        const response = res.data.data[0]
        setUserCustomDetail(response)
        if (response.developer_profile) {
          setUserDetail(response.developer_profile)
          setUserDesgnation("Developer")
          setLoading(false)
          console.log('================developer_profile====================');
          // console.log(response);
        } else if (response.agent_profile) {
          console.log('==============agent_profile======================');
          setUserDetail(response.agent_profile)
          setUserDesgnation("Agent")
          setLoading(false)

        } else if (response.other_profile) {
          console.log('=============other_profile=======================');
          setUserDetail(response.other_profile)
          setUserDesgnation("Other")
          setLoading(false)
        } { }
        // setUserDetail(res.data.results)

      })
        .catch(error => {
          alert(error)
          setLoading(false)
        })
    }

    UserDetail()



  }, [id])



  return (
   
       <main id="content ">
        <div className="bg-gray-01" style={{ backgroundImage: `url(${BackImage})` }}>
          <section className="pb-8">
            <div className="container">
              <div className="row h-100 ">
                <div className="col-lg-12 mt-20">
                  <div className="card border-0 px-6 pt-6 pb-10 add-pb-full">
                    <div className="row h-100">
                      <div className="col-sm-6 mb-6 mb-sm-0 py-5">
                        <img className="rounded-lg border card-img set-height-image-dprofilep" src={UserDetail?.profile_image}
                          alt="image" />
                      </div>
                      <div className="col-sm-6 py-5">
                        <div className="card-body p-0 ">
                          <h2 className="card-title fs-32 lh-15 mb-1 text-dark">
                            {UserDetail?.first_name} {UserDetail?.last_name} </h2>
                          <p className="card-text mb-5 text-blue">
                            {UserDetail?.designation}


                          </p>
                          <hr />

                          <ul className="list-group list-group-no-border ">
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Company :</span>
                              <span className="col-sm-7 px-1">{UserDetail?.company_name}</span>
                            </li>
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Office No :</span>
                              <a href={`tel:${UserDetail?.office_no}`} className="col-sm-7 px-1 text-heading "><CurrencyFormat displayType="text" value={UserDetail?.office_no } format="+1 (###) ###-####" mask="_" /></a>
                            </li>

                            {UserDetail?.website ?
                              <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Website :</span>
                                <span className="col-sm-7 px-1 "><a className="text-blue hover-saffron-text" target="_blank" href={UserDetail?.website}>Web Link</a></span>
                              </li> : null
                            }


                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">Address :</span>
                              <span className="col-sm-7 px-1 line-height-1-7">{UserDetail?.street_name}</span>
                            </li>


                            <hr />
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row mb-0   p-0 ">
                              
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 mt-20">
                  <div className="card border-0">
                    <div className="card-body p-6">
                      <h3 className="card-title fs-14 text-dark mb-6">
                        Contact {DeveloperProfileData?.company_name || AgentProfileData?.company_name || otherBusinessData?.company_name}

                      </h3>
                      <form>
                        <div className="form-group mb-2 position-add-validate-symbol">
                          <input type="text" className="form-control form-control-lg border-0 "
                            placeholder="Your Name" name="name" onChange={(e) => setUserLeadName(e?.target?.value)} value={userLeadName} />
                          <span className="asterisk_input"></span>
                        </div>
                        <div className="form-group mb-2 position-add-validate-symbol">
                          <input type="email" className="form-control form-control-lg border-0"
                            placeholder="Your Email" name="email" onChange={(e) => setUserLeadEmail(e?.target?.value)} value={userLeadEmail} />
                          <span className="asterisk_input"></span>
                        </div>
                        <div className="form-group mb-2">
                          <CurrencyFormat className="form-control form-control-lg border-0"
                            placeholder="Your phone" name="phone" format="+1 (###) ###-####" mask="_" onChange={(e) => setUserLeadPhoneNo(e?.target?.value)} value={userLeadPhoneNo} />
                        </div>
                        <div className="form-group mb-4">
                          <textarea className="form-control form-control-lg border-0" rows="3"
                            placeholder="Message" name="message" onChange={(e) => setUserLeadDescription(e?.target?.value)} value={userLeadDescription}></textarea>
                        </div>
                        <button type="button" className="btn bg-blue text-white hover-saffron btn-lg btn-block" onClick={SubmitLeadUser}>Send Message</button>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
  
      
          <section className="pb-11">
            <div className="container">
              <div className="collapse-tabs mb-10" id="">
                <ul className="nav nav-tabs text-uppercase  d-md-inline-flex agent-details-tabs" role="tablist">
                  <li className="nav-item">

                    <a href="#overview" className="text-blue nav-link active  fs-13 padding-r-5"
                      data-bs-toggle="tab" role="tab">
                      Overview
                    </a>
                  </li>
                  <li className="nav-item ml-0">
                    <a href="#listing" className="nav-link text-blue fs-13 padding-r-5"
                      data-bs-toggle="tab" role="tab">
                      Listings
                    </a>
                  </li>

                
              
</ul>
                <div className="tab-content shadow-none py-7 px-6 bg-white">
                  <div className="tab-pane tab-pane-parent fade show active" id="overview"
                    role="tabpanel">
                    <div className="card border-0 bg-transparent">
                      <div className="card-body p-0">
                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                          About {UserDetail?.company_name}
                        </h2>
                        <p className="card-text lh-214 mb-3">{UserDetail?.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane tab-pane-parent fade" id="listing" role="tabpanel">
                    <div className="card border-0 bg-transparent">
                      <div className="card-body p-0">
                        <h2 className="card-title fs-22 lh-15 mb-3 text-dark">
                          Our Listing
                        </h2>
                        <ul className="nav nav-pills text-capitalize nav-pills-01 mx-n5"
                          role="tablist">
                          <li className="nav-item px-3 px-sm-6 py-1 padding-r-5">
                            <a href="#all"
                              className="nav-link active shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                              data-bs-toggle="pill" role="tab">
                              All ({propertyList?.length})
                             
                            </a>
                          </li>
                          <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-5">
                            <a href="#sale"
                              className="nav-link  shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                              data-bs-toggle="pill" role="tab">
                              For Sale ({propertyForSale?.length})
                            </a>
                          </li>
                          <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-5">
                            <a href="#rent"
                              className="nav-link  shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                              data-bs-toggle="pill" role="tab">
                              For Lease ({propertyForRent?.length})
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content shadow-none pt-5 pb-0 px-0 bg-white">
                          <div className="tab-pane nested-tab-pane fade show active" id="all"
                            role="tabpanel">
                            <div className="row">

                              {
                                propertyList.map((item) => {
                                  return (
                                    <div className="col-md-6 col-lg-4 mb-7">
                                 
                                      
                                        <div className="card border-0">
                                          <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                            <img src={item?.featured_image || "images/properties-grid-35.jpg"}

                                              alt="Home in Metric Way" className="listing-property-image-dp" />
                                            <div className="card-img-overlay d-flex flex-column">
                                              <div className="mb-auto">
                                                {
                                                  item?.labels?.map((item) => {
                                                    return (
                                                      <span className="badge badge-orange mr-2">{item?.name}</span>
                                                    )
                                                  })
                                                }


                                              </div>

                                            </div>
                                          </div>
                                          <div className="card-body pt-3 px-0 pb-1">
                                            <h2 className="fs-14 mb-1"><a

                                              className="text-dark hover-saffron-text">{item?.name}</a>
                                            </h2>

                                            <p className="font-weight-500 text-gray-light mb-0">
                                              {item?.street_name}
                                            </p>

                                          </div>
                                          <div className="card-footer bg-transparent px-0 pb-0 pt-2">
                                            <ul className="list-inline mb-0">

                                              <div className="profile-property-price">
                                                {item?.labels?.find(i => i?.id)?.name === "For Lease" ? (
                                                  <>


                                                    <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                      className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                                  </>
                                                ) : (<>
                                                  <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                    className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                                </>)}
                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                    
                                     
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                          <div className="tab-pane nested-tab-pane fade" id="sale"
                            role="tabpanel">
                            <div className="row">
                             

                              {
                                propertyForSale?.map((item) => {
                                  return (
                                    <div className="col-md-6 col-lg-4 mb-7">
                                     
                                      
                                        <div className="card border-0">
                                          <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                            <img src={item?.featured_image || "images/properties-grid-35.jpg"}

                                              alt="Home in Metric Way" className="listing-property-image-dp" />
                                            <div className="card-img-overlay d-flex flex-column">
                                              <div className="mb-auto">
                                                <span className="badge badge-orange">{item?.labels?.find(i => i?.id)?.name}</span>

                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body pt-3 px-0 pb-1">
                                            <h2 className="fs-14 mb-1"><a
                                            
                                              className="text-dark hover-saffron-text">{item?.name}</a>
                                            </h2>

                                            <p className="font-weight-500 text-gray-light mb-0">
                                              {item?.street_name}
                                            </p>
                                          </div>
                                          <div className="card-footer bg-transparent px-0 pb-0 pt-2">
                                            <ul className="list-inline mb-0">

                                              <div className="profile-property-price">
                                                <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                  className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>
                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                     
                                     
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                          <div className="tab-pane nested-tab-pane fade" id="rent"
                            role="tabpanel">
                            <div className="row">
                              {
                                propertyForRent?.map((item) => {
                                  return (
                                    <div className="col-md-6 col-lg-4 mb-7">
                                     
                                    
                                        <div className="card border-0">
                                          <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                            <img src={item?.featured_image || "images/properties-grid-35.jpg"}

                                              alt="Home in Metric Way" className="listing-property-image-dp" />
                                            <div className="card-img-overlay d-flex flex-column">
                                              <div className="mb-auto">
                                                <span className="badge badge-orange">{item?.labels?.find(i => i?.id)?.name}</span>

                                              </div>

                                            </div>
                                          </div>
                                          <div className="card-body pt-3 px-0 pb-1">
                                            <h2 className="fs-14 mb-1"><a
                                             
                                              className="text-dark ">{item?.name}</a>
                                            </h2>

                                            <p className="font-weight-500 text-gray-light mb-0">
                                              {item?.street_name}
                                            </p>
                                          </div>
                                          <div className="card-footer bg-transparent px-0 pb-0 pt-2">
                                            <ul className="list-inline mb-0">

                                              <div className="profile-property-price">
                                                <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                  className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                     
                                     
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  
               



                </div>
              </div>
            </div>
          </section>
        </div>
       
      </main> 
    
  )
}
export default UserAllDetails;