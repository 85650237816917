import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { MainApiProtectedVersion } from "../../../utils/axios/requests";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import { ApiUrl, getToken } from "../../../utils/common";
import axios from "axios";
import Select from "react-select";
import { format, compareAsc } from "date-fns";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../Loader";

// interface IFormInput {
//   q: String;
//   // gender: ;
//}

export default function PropertyPage() {
  const [loading, setLoading] = useState(false);
  const [propertyType, setPropertyType] = useState("");
  console.log(propertyType);
  const [constructionStatus, setConstructionStatus] = useState("");
  const [labels, setLabels] = useState("");
  const [region, setRegion] = useState("");
  const [propertyCount, setPropertyCount] = useState("");
  console.log(propertyCount);
  const [allProperty, setAllpropertyData] = useState([]);
  console.log(allProperty, "ALL ");
  const [configData, setConfigData] = useState([]);
  const [sellingStatus, setSelligStatus] = useState("");
  const [ownerType, setOwnerType] = useState("");
  console.log(ownerType);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterDate, setFilterDate] = useState(false);
  const options = [
    { value: "0", label: "All" },
    { value: "1", label: "Developer Properties" },
    { value: "2", label: "Agent Properties" },
  ];

  useEffect(() => {
    if (filterDate) {
      setEndDate(format(new Date(filterDate[1]), "dd-MM-yyyy"));
      setStartDate(format(new Date(filterDate[0]), "dd-MM-yyyy"));
    }
  }, [filterDate]);
  const pdata = {};
  if (startDate) {
    pdata["start_date"] = startDate;
  }
  if (endDate) {
    pdata["end_date"] = endDate;
  }
  if (ownerType) {
    pdata["type"] = ownerType;
  }
  if (propertyType) {
    pdata["property_type_id"] = propertyType;
  }
  if (labels) {
    pdata["label_id"] = labels;
  }
  if (constructionStatus) {
    pdata["construction_status_id"] = constructionStatus;
  }
  if (sellingStatus) {
    pdata["selling_status_id"] = sellingStatus;
  }
  if (region) {
    pdata["loc"] = region;
  }
  console.log(pdata);
  useEffect(() => {
    if (pdata) {
      const params = new URLSearchParams(pdata);

      async function Allproperties() {
        const obj = new MainApiProtectedVersion("", "", params);
        const res = await obj
          .requstProprtyGetSearch()
          .then((res) => {
            // console.log(res);
            if (res.status === 200) {
              setAllpropertyData(res.data.data);
              setPropertyCount(res.data.count);
              // setLoading(false)
            } else if (res.status === 204) {
              setAllpropertyData();
              setPropertyCount();
            }

            // setCoupon(res.data)
          })
          .catch((error) => {
            alert(error);
            // setLoading(false)
          });
      }
      Allproperties();
    } else {
      async function Allproperties() {
        // setLoading(true)
        const obj = new MainApiProtectedVersion();
        const res = await obj
          .requstProprtyGetSearch()
          .then((res) => {
            // console.log(res);
            if (res.status === 200) {
              setAllpropertyData(res.data.data);
              setPropertyCount(res.data.count);
              // setLoading(false)
            }

            // setCoupon(res.data)
          })
          .catch((error) => {
            alert(error);
            // setLoading(false)
          });
      }
      Allproperties();
    }
  }, [
    startDate,
    region,
    labels,
    propertyType,
    constructionStatus,
    sellingStatus,
    ownerType,
  ]);

  useEffect(() => {
    const ConfigDropDownData = () => {
      const option = {
        method: "GET",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
        url: `${ApiUrl()}/api/property/config-property`,
      };
      axios(option)
        .then((e) => {
          setConfigData(e?.data);
        })
        .catch((err) => {});
    };
    ConfigDropDownData();
  }, []);
  const resetState = () => {
    if (
      filterDate ||
      region ||
      ownerType ||
      propertyType ||
      constructionStatus ||
      labels ||
      sellingStatus
    ) {
      setEndDate("");
      setStartDate("");
      setFilterDate("");
      setRegion("");
      setOwnerType("");
      setPropertyType("");
      setConstructionStatus("");
      setLabels("");
      setSelligStatus("");
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content-property" className="bg-gray-01 pb-10">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                Total Properties{" "}
                <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                  {propertyCount}
                </span>
              </h2>
            </div>
            <div className="">
              <button
                onClick={resetState}
                className="btn bg-blue hover-saffron text-white mr-1"
              >
                Refresh
              </button>
              {/*  <button className='btn bg-blue hover-saffron text-white'>Export to CSV</button> */}
              {allProperty ?(
 
              <CSVLink
                className="btn bg-blue hover-saffron text-white"
                data={allProperty}
              >
                Export to CSV
              </CSVLink>
                            
              ):(

              
                <></>
                
                   )}
           
            </div>
          </div>
          <section className="bg-blue">
            <div className="col-12">
              <div className="row">
                <div className="search-tx ">
                  <DateRangePicker
                    className="daterange"
                    minDetail="month"
                    maxDetail="year"
                    format="MM/yyy"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    onChange={setFilterDate}
                    value={filterDate}
                  />
                </div>
                <div className="search-tx">
                  <select
                    className="form-control border-0 shadow-none form-control-lg  bg-white"
                    name="Owner Type"
                    data-style="btn-lg py-2 h-52 bg-white"
                    onChange={(e) => setOwnerType(e?.target?.value)}
                    value={ownerType}
                  >
                    <option className="p-holder-color option-set">
                      Owner Type
                    </option>
                    {options?.map((item, index) => {
                      return (
                        <option className="option-set" value={item?.value}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="search-tx">
                  <select
                    className="form-control border-0 shadow-none form-control-lg  bg-white"
                    name="Region/City"
                    data-style="btn-lg py-2 h-52 bg-white"
                    onChange={(e) => setRegion(e?.target?.value)}
                    value={region}
                  >
                    <option className="p-holder-color option-set">
                      Region/City
                    </option>
                    {configData?.city?.map((item, index) => {
                      return (
                        <option className="option-set" value={item?.name}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="search-tx">
                  <select
                    className="form-control border-0 shadow-none form-control-lg  bg-white"
                    name="Property Type"
                    data-style="btn-lg py-2 h-52 bg-white"
                    onChange={(e) => setPropertyType(e?.target?.value)}
                    value={propertyType}
                  >
                    <option className="p-holder-color option-set">
                      Property Type
                    </option>
                    {configData?.propertyType?.map((item, index) => {
                      return (
                        <option className="option-set" value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="search-tx">
                  <select
                    className="form-control border-0 shadow-none form-control-lg  bg-white"
                    name="labels"
                    data-style="btn-lg py-2 h-52 bg-white"
                    onChange={(e) => setLabels(e?.target?.value)}
                    value={labels}
                  >
                    <option className="p-holder-color">For Lease/Sale</option>
                    {configData?.labels?.map((item, index) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                <div className="search-tx">
                  <select
                    className="form-control border-0 shadow-none form-control-lg  bg-white"
                    name="Selling Status"
                    data-style="btn-lg py-2 h-52 bg-white"
                    onChange={(e) => setSelligStatus(e?.target?.value)}
                    value={sellingStatus}
                  >
                    <option className="p-holder-color">Selling Status</option>
                    {configData?.SellingStatus?.map((item, index) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                <div className="search-tx">
                  <select
                    className="form-control border-0 shadow-none form-control-lg  bg-white"
                    name="Construction Status"
                    data-style="btn-lg py-2 h-52 bg-white"
                    onChange={(e) => setConstructionStatus(e?.target?.value)}
                    value={constructionStatus}
                  >
                    <option className="p-holder-color">
                      Construction Status
                    </option>
                    {configData?.ConstructionStatus?.map((item, index) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="container bg-white">
          <table class="table ">
            <thead>
              <tr>
                <th scope="col">Property Name</th>
                <th scope="col">Builder</th>
                <th scope="col">Address</th>
                <th scope="col">Area(Sq.Ft)</th>
                <th scope="col">Price(/Sq.Ft)</th>
              </tr>
            </thead>
            {allProperty ? (
              <>
                {" "}
                <tbody>
                  {allProperty?.map((item) => {
                    // console.log(item.user.id)
                    const id = item.user.id;
                    return (
                      <tr>
                        {/* <th scope="row">{item.id}</th> */}
                        <td>{item.name}</td>
                        <td>
                          <Link
                            className="text-blue hover-saffron-text"
                            to={`/admin/user/details/${id}`}
                          >
                            {item.built_by}
                          </Link>
                          {/* <Link to={`/admin/users/${id}`}>{item.built_by}</Link> */}
                        </td>
                        <td>{item.street_name}</td>
                        <td>{item.size} Sq.Ft</td>
                        <td>${item.price_per_sqft}/Sq.Ft</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <>no data found</>
            )}
          </table>
        </div>
      </main>
    </>
  );
}
