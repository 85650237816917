import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";


const AgentSidebar = () => {
  const [profileData, setProfileData] = useState();
  const navigate = useNavigate();
  const Logout = () => {
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/logout`
    };

    axios(option)
      .then(e => {
        navigate('/')
      })
      .catch(err => {

      })

  }
  // get profile api
  const GetProfile = () => {
   
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/agent-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
       
        setProfileData(e?.data?.[0])
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
       
      })
  }
  useEffect(() => {
    GetProfile();
    
  },[])
  return (
    <>
      <div className="db-sidebar bg-white sb-side">

        <nav className="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0 add-overflow-scroll">
          <div className="sticky-side sticky-area shadow-xs-1 py-3  ps-fixed">
            <div className="d-flex px-3 px-xl-6 w-100">
              <a className="navbar-brand" href="/">
                <img src="images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
              </a>
              <div className="ml-auto d-flex align-items-center ">
                <div className="d-flex align-items-center d-xl-none">
                  <div className="dropdown px-3">
                  <Link to="/agentdashboard" className=" d-flex align-items-center text-heading ">
                      <div className="w-48px">
                      <img src={profileData?.profile_image || "images/agency-list-01.jpg"}
                          alt="Ronald Hunter" className="rounded-circle" style={{width:'45px', height:'45px'}}/>
                      </div>
                    {/*   <span className="fs-13 font-weight-500 d-none d-sm-inline ml-2">
                        Ronald Hunter
                      </span> */}
                    </Link>
                   {/*  <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">My Profile</a>
                      <a className="dropdown-item" href="#">My Profile</a>
                      <a className="dropdown-item" href="#">Logout</a>
                    </div> */}
                  </div>
                 {/*  <div className="dropdown no-caret py-4 px-3 d-flex align-items-center notice mr-3">
                    <a href="#" className="dropdown-toggle text-heading fs-20 font-weight-500 lh-1"
                      data-bs-toggle="dropdown">
                      <i className="far fa-bell"></i>
                      <span className="badge badge-primary badge-circle badge-absolute font-weight-bold fs-13">1</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <button className="navbar-toggler border-0 px-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#primaryMenuSidebar"
                  aria-controls="primaryMenuSidebar" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
            <div className="collapse navbar-collapse bg-white" id="primaryMenuSidebar">
              {/* <form className="d-block d-xl-none pt-5 px-3">
                <div className="input-group">
                  <div className="input-group-prepend mr-0 bg-input">
                    <button className="btn border-0 shadow-none fs-20 text-muted pr-0" type="submit"><i
                      className="far fa-search"></i></button>
                  </div>
                  <input type="text" className="form-control border-0 form-control-lg shadow-none"
                    placeholder="Search for..." name="search" />
                </div>
              </form> */}
              <ul className="list-group list-group-flush w-100">
                <li className="list-group-item pt-6 pb-4">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Main</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg">
                    <li className="px-3 px-xl-4 py-2 bg-blue hover-saffron" style={{borderRadius:'5px',listStyle:'none'}}>
                      <Link to="/agentdashboard" className="text-white lh-1 ml-5 ">
                        {/* <span className="sidebar-item-icon d-inline-block mr-3 fs-20"> <i
                              className="fal fa-cog"></i> </span> */}
                        <span className="">Agent Dashboard</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item ml-add-35 header-r-logout">
                      <Link to="/agent-update-profile" className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-text">
                          Edit Profile
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="list-group-item pt-6 pb-4">
                  {/*  <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage Listings</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg">
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agent-add-property"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new"><use
                            xlinkHref="#icon-add-new"></use></svg></span>
                        <span className="sidebar-item-text">Add new Property</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agent-properties"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-properties"><use
                            xlinkHref="#icon-my-properties"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Properties</span>
                        {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agent-lead"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Leads</span>
                        {/*  <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agentmymembers"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Team</span>
                        {/*  <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agentprofile"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Profile</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agent-areas-serviced"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Areas Serviced</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agent-reviews"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Reviews</span>
                        {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                      </Link>
                    </li>

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agentmyfavourite"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Favourites</span>
                        {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                      </Link>
                    </li> 
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agentportfolio"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Portfolio</span>
                        {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                      </Link>
                    </li>

                  </ul>

                </li>
                <li className="list-group-item pt-6 pb-4">
                  <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3" style={{marginLeft:'28px'}}>Manage Account</h5>
                  <ul className="list-group list-group-no-border rounded-lg">
                    
                  {/*   <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agentmypackage"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Package</span>
                       
                      </Link>
                    </li> */}

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/agent-preview-invoice"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Invoices</span>
                        
                      </Link>
                    </li>

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/changepassword" className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-log-out"><use xlinkHref="#icon-log-out"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Change Password</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <a href="/" className="text-heading lh-1 sidebar-link" onClick={() => { removeUserSession(); Logout(); }}>
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-log-out"><use xlinkHref="#icon-log-out"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Log Out</span>
                      </a>
                    </li>
                  </ul>

                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
export default AgentSidebar;