import React from 'react';
/* import './mymap.css'; */
import { useState, Component } from 'react';
// import Map from './Components/Map/map'; 
import Map from './Components/Map/map';


class MyMap extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (

      <Map
        google={this.props.google}
        center={{ lat: 49.246292, lng: -123.116226 }}
        height='300px'
        width='50%'
        zoom={15}
        selectedData={(e) => this.props.selectedData(e)}
        addressMap={this.props.addressMap}
        propertyData={this.props.propertyData}
      />

    )
  }
}

export default MyMap;