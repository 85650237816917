import Header from "../../header/header";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AddId, AdminData, AgentType, ApiUrl, DeveloperType, GetAgentType, GetDeveloperType, GetOtherBusinessType, getToken, OtherBusinessType, SetAccessToken, SetRefreshToken, setUserSession } from "../../../utils/common";
import Loader from "../../Loader";
import axios from '../../../utils/axios/main'; // importing axios from customAxios
import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { auth } from '../../../_constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


const AdminLogin = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isShowPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const navigate = useNavigate()
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    let dispatch = useDispatch();



    const onSubmit = data => {
        console.log(data)
        axios.post('/superuser/', {
            email: data.email,
            password: data.password
        }).then(response => {
            if (response.data.data.is_superuser) {
                setUserSession(response.data.access)
                SetAccessToken(response.data.access)
                SetRefreshToken(response.data.refresh)
                AdminData(true);

                dispatch({
                    type: auth.SET_IS_AUTHENTICATED,
                    payload: true,
                });
                dispatch({
                    type: auth.SET_PROFILE,
                    payload: 'admin',
                });
                toast.success("successfull login Superadmin")


                navigate('/admin')
            } else {
                toast.success("Invalid Superadmin")
                navigate('/')

            }
        }
        )
            .catch(error => {
                toast.error("please check username and password")



            });



    };



    return (
        <>

            <div className="form-body">
                <div className="website-logo">
                    <Link to="/">
                        <div className="logo">
                            <img className="logo-size" src="../images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
                        </div>
                    </Link>
                </div>
                <div className="img-holder">
                    <div className="bg" style={{ backgroundImage: "url(../images/skyline-2.png)" }}></div>
                </div>
                <div className="form-holder">
                    <div className="form-content">
                        <div className="form-items">
                            <h3 className="ti-fs">Secured Login to <a href="/"><span className="pl-name">Dollar Per Foot.</span></a></h3>
                            <p>Access to the most powerfull tool in the entire Dollar Per Foot Platform.</p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-4">

                                    <label htmlFor="username" className="sr-only">Username</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend ">
                                            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18"
                                                id="inputGroup-sizing-lg">
                                                <i className="far fa-user"></i></span>
                                        </div>
                                        <input className="form-control border-0 shadow-none fs-13"

                                            placeholder="Username / Your email" {...register("email")} />
                                    </div>
                                </div>
                                <div className="form-group mb-4">

                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend ">
                                            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                                <i className="far fa-lock"></i>
                                            </span>
                                        </div>
                                        <input type={passwordShown ? "text" : "password"}
                                            className="form-control border-0 shadow-none fs-13"
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            {...register("password", { required: true })} />


                                        <div className="input-group-append">
                                            <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                                <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {errors.exampleRequired && <span>This field is required</span>}
                                <div className="Submit-button-section-login">
                                    <input type="submit" className="btn text-white bg-blue hover-saffron btn-lg btn-block" />


                                </div>

                            </form>
                            {/*  <p className="lg-bo">Don't have an account? <a href="/signup" className="text-blue hover-saffron-text"><u>Sign up</u></a></p> */}
                        </div>
                    </div>
                </div>
            </div>







        </>

    )
}
export default AdminLogin;