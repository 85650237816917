import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";


const DevHeader = () => {

  const navigate = useNavigate()
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false)

  const Logout = () => {
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/logout`
    };

    axios(option)
      .then(e => {
        navigate('/')
      })
      .catch(err => {

      })

  }


  const GetProfile = () => {
    setLoading(true)
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/developer-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
        const data = e?.data?.[0];
        setUserData(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })
  }


  useEffect(() => {
    GetProfile();
  }, [])
  return (
    <>

      {
        loading ? <Loader /> : null
      }
      <header className="main-header shadow-none shadow-lg-xs-1 bg-white position-relative d-none d-xl-block more-zindex-relative">
        <div className="container-fluid">
          <nav className="navbar navbar-light py-0 row no-gutters px-3 px-lg-0">
            <div className="col-md-4 px-0 px-md-6 order-1 order-md-0">

            </div>
            <div className="col-md-6 d-flex flex-wrap justify-content-md-end order-0 order-md-1">
              <div className="dropdown border-md-right border-0 py-3 text-right">
                <a href="#"
                  className="dropdown-toggle text-heading pr-3 pr-sm-6 d-flex align-items-center justify-content-end"
                  data-bs-toggle="dropdown">
                  <div className="mr-4 w-48px">
                    <img src={userData?.profile_image || "images/user-icon-dummy.png"} alt="Ronald Hunter" className="rounded-circle dashboard-header-image" style={{width:'45px', height:'45px'}} />
                  </div>
                  <div className="fs-13 font-weight-500 lh-1">
                    {userData?.first_name} {userData?.last_name}
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right w-100 add-left-dropdown-dashboard-header">
                  {/* <a className="dropdown-item" href="dashboard-my-profiles.html">My Profile</a> */}
                  <a href="/devmyprofile" style={{ color: 'inherit' }} className="dropdown-item">Edit Profile</a>
                  <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={() => { removeUserSession(); Logout(); }}>Logout</a>
                  {/* <Link to="/" className="dropdown-item" >Edit Profil</Link> */}
                </div>
              </div>

            </div>
          </nav>
        </div>
      </header>
    </>
  )
}
export default DevHeader;