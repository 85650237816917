import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DevSidebar from '../../Components/DevSidebar/devsidebar';
import Loader from "../../Components/Loader";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";
import DevHeader from './devheader';


const DevMyAgent = () => {
  const [show, setShow] = useState(false)
  const OnhideModal = () => {
    setShow(false);
  }
  const [deleteData, setDeleteData] = useState()
  const [loading, setLoading] = useState(false)
  const [contactUserData, setContactUserdata] = useState([]);

  const UserContactId = contactUserData?.find(i => i?.id)?.id
  const state = useLocation();
  const navigate = useNavigate();

  // get contact users api
  const GetuserData = () => {
    setLoading(true)
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/create-multiple-user-profile/${GetId()}`
    };

    axios(option)
      .then(e => {
        setLoading(false)
        const data = e?.data;
        setContactUserdata(data)
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    GetuserData();
  }, [])

  const Edit = (item) => {
    navigate('/myteam', { state: item })
  }


  // delete api done
  const Delete = (item) => {
    const option = {
      method: 'DELETE', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/user/create-multiple-user-profile/${UserContactId}`
    };
    axios(option)
      .then(e => {
        window.location.reload();
      })
      .catch(err => {

      })
  }

  const DeleteProperty = (item) => {
    setShow(true)
    setDeleteData(item)
  }


  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">

          <DevSidebar />


          <div className="page-content">
            <DevHeader />

            <div className="agent-row margin-re-top">
              <div className="row">
                {
                  contactUserData?.map((item, Index) => {
                    return (<div className="col-sm-6 col-md-4 col-lg-3 mb-6">
                      <div className="card shadow-hover-xs-4 agent-3 remove-bg height-445-myagents">

                        <div className="card-header text-center pt-6 pb-3 bg-transparent text-center">

                          {/* <img src="images/agent-1.jpg" alt="Max Kordek" /> */}
                          <img src={item?.profile_image} alt="Max Kordek" className="images-get-data" />

                          <a className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-saffron-text">{item?.first_name} {item?.last_name}</a>
                          <p className="mb-2">{item?.designation}</p>

                        </div>
                        <div className="card-body text-center pt-2  px-0">
                          <a href={`mailto:${item?.email}`} className="text-body">{item?.email}</a>
                          <a href={`tel:${item?.office_no}`} className="text-heading font-weight-600 d-block mb-3">Phone: {item?.office_no}</a>
                          {
                            item?.mobile_no?.length ? <a href={`tel:${item?.mobile_no}`} className="text-heading font-weight-600 d-block mb-3">Mobile: {item?.mobile_no}</a> : null
                          }

                        </div>
                        <div className="card-footer px-0 text-center  border-0">
                          {/* <button className="btn btn-primary edit-button-myagents" onClick={() => Edit(item)}>Edit</button> */}
                          <button className="btn btn-danger" onClick={() => DeleteProperty(item)}>Delete</button>

                        </div>
                      </div>
                    </div>)

                  })
                }


                <Modal className="modal-add-contact"
                  show={show} >
                  <Modal.Body>
                    <h3 className="submit-property-modal-heading">Are you sure to delete this Team Member?</h3>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button onClick={() => Delete()}>
                      Yes
                    </Button>

                    <Button onClick={() => OnhideModal()}>
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <Link to='/MyTeam'>
                <button type="button" className="btn bg-blue text-white hover-saffron">Add New</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DevMyAgent;
