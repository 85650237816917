import React, { useEffect, useState } from 'react'
import { MainApiProtectedVersion } from '../../../utils/axios/requests';

export default function SearchMetrics() {

  const [propHistory, setpropHistory] = useState([]);
  

  // useEffect(() => {
    async function Allproperties() {
      const obj = new MainApiProtectedVersion("");
      const res = await obj.requstProprtySearchGet().then((res) => {
          console.log(res.data);
          setpropHistory(res.data);

          // console.log(res);
          // if (res.status === 200) {
          //   setAllpropertyData(res.data.data);
          //   setPropertyCount(res.data.count);
          //   // setLoading(false)
          // } else if (res.status === 204) {
          //   setAllpropertyData();
          //   setPropertyCount();
          // }
          // setCoupon(res.data)
        })
        .catch((error) => {
          alert(error);
          // setLoading(false)
        });
    }
    Allproperties();
  // }, []);


  console.log(propHistory);
  
  return (
    <div>{propHistory}</div>
  )
}
