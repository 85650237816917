import axios from "axios";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { toast } from "react-toastify";
import { ApiUrl, getToken } from "../../../utils/common";
import Footer from "../../footer/footer";
import Header from "../../header/header";



const ForgotPassword = () => {
    const [forgotEmail, setForgotEmail] = useState('');

    const EmailValidate = () => {
        if (!forgotEmail || forgotEmail.trim() === "") {
            toast.error("Please Enter Your Registered Email")
            return false;
        }
        return true;
    }

    const EmailSubmit = () => {
        if (!EmailValidate()) return;

        // api data
        const data = {};
        data.email = forgotEmail;
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                // 'Authorization': `Bearer ${getToken()}`
            },

            data: JSON.stringify(data),
            url: `${ApiUrl()}/auth/user/send-reset-password-email`
        };

        axios(option)
        .then(e => {
            toast.success(e?.data?.success);
            const data = JSON.parse(e?.data?.data || '{}');
            toast.success(e?.data?.sucess);
            
        })
        .catch(err =>{
            toast.error(err?.response?.data?.status);
        });
    }

    // google login button   
    const ClientId = "128217884016-2rfb0n7ljilmtsb6onvroq6ikp4obklu.apps.googleusercontent.com";

    const responsegoogle = (res) => {
        
    }
    return (
        <>
            <Header />
            {/* after header part */}
            {/* <main id="content"> */}
            <section className="py-13 add-cstm-mt modal-opacity-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 mx-auto">
                            <div className="card border-0 shadow-xxs-2 login-register">
                                <div className="card-body p-6">
                                    <h2 className="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Forgot your password?</h2>

                                    <form className="form">
                                        <div className="form-group">
                                            <label htmlFor="email" className="text-heading">Enter your email address</label>
                                            <input type="email" name="mail"
                                                className="form-control form-control-lg border-0"
                                                id="email" placeholder="Enter your email address" onChange={(e) => setForgotEmail(e?.target?.value)} value={forgotEmail} />

                                        </div>
                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-lg rounded" onClick={EmailSubmit} >Get new password
                                        </button>
                                    </form>
                                    {/* <div className="divider text-center my-2">
                                        <span className="px-4 bg-white lh-17 text text-heading">
                                            or Sign Up with
                                        </span>
                                    </div>
                                    <div className="row no-gutters mx-n2">
                                        <div className="col-sm-6 px-2 mb-4 col-md-12 fgp-lg-btn-main">
                                            <GoogleLogin className="button-google-login"
                                                clientId={ClientId}
                                                buttonText="Google"
                                                onSuccess={responsegoogle}
                                                onFailure={responsegoogle}
                                                cookiePolicy={'single_host_origin'}
                                                isSignedIn={true}
                                            />

                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* </main> */}

            <Footer />
        </>
    )
}

export default ForgotPassword;