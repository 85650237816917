import React, { useEffect, useState } from "react";
import DevHeader from "./devheader";
import Devsidebar from '../../Components/DevSidebar/devsidebar';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiUrl, getToken } from "../../utils/common";
import axios from "axios";
import Loader from "../../Components/Loader";

const DevPropertyLeads = () => {

  const state = useLocation()
  const [PropertyLead, setPropertyLead] = useState([]);
  console.log(PropertyLead,'PropertyLead');
  const [loading, setLoading] = useState(false)
  
  const navigate = useNavigate()
  // property leads api
  const GetPropertyLeads = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/lead/property-lead`
    };
    axios(option)
      .then(e => {
        setPropertyLead(e?.data)
        setLoading(false)
      })
      .catch(err => [
        setLoading(false)
      ])
  }

  useEffect(() => {
    GetPropertyLeads()
  }, [])

  const PropertyReview = (item) => {
    navigate('/devpropertyleadsDetails', { state: item })
  }

  return (
    <>

      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <Devsidebar />
          <div className="page-content">
            <DevHeader />
            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Property Leads<span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{PropertyLead?.length}</span>
                    </h2>

                  </div>
                  <div>
                    <Link to="/devleads" className="btn bg-blue text-white hover-saffron btn-lg">
                      <span>Business Leads</span>
                      <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover border rounded-lg mb-6 bg-white">
                    <thead className="thead-sm thead-black">
                      <tr>
                        <th scope="col" className="col-9 border-top-0 px-6 pt-5 pb-4">Property Name</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4 header-user-icon">Leads Count</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4 header-r-logout">Leads</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        PropertyLead.map((item,key) => {
                          const propertName=item.propertData[key].name
                          const propertData=item.propertData[key]
                          const propertLeadCount=item?.property_leads
                          return (
                            <tr className="shadow-hover-xs-2 bg-hover-white">
                              <td className="align-middle p-6">
                                <div style={{ cursor: 'pointer' }} className="text-dark font-weight-500 hover-saffron-text pt-1" onClick={() => PropertyReview(propertData)}>{propertName}</div>
                              </td>
                              <td className="align-middle">{propertLeadCount}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default DevPropertyLeads;