import React, { Fragment, useEffect, useState } from 'react'
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";
import axios from "axios";

import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import { GetId, ApiUrl } from '../../../utils/common';
import Sidebar from '../Sidebar/Sidebar';
import Header from './Header';


export default function UserLayout() {

    const navigate = useNavigate();
    const [UserDesgnation, setUserDesgnation] = useState('')
    const [profileData, setProfileData] = useState();
    const [PlanData, setPlanData] = useState();
    const [subPlanData, setSubPlanData] = useState();

    const Logout = () => {
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/auth/user/logout`
        };

        axios(option)
            .then(e => {
                navigate('/')
            })
            .catch(err => {

            })

    }


    async function GetPlan() {
        const obj = new MainApiProtectedVersion()
        const res = await obj.requstSubscribeUserPlan().then((res) => {
            const plan = res.data['plan'][0]
            const subscription = res.data['subscription'][0]

            setPlanData(plan)
            setSubPlanData(subscription)

        })
          
    }

    async function GetProfile() {
        const obj = new MainApiProtectedVersion('', GetId())
        const res = await obj.requstUserID().then((res) => {
            const response = res.data.data[0]
            if (response.developer_profile) {
                setProfileData(response.developer_profile)
                setUserDesgnation("Developer")
                console.log('================developer_profile====================');
                // console.log(response);
            } else if (response.agent_profile) {
                console.log('==============agent_profile======================');
                setProfileData(response.agent_profile)
                setUserDesgnation("Agent")


            } else if (response.other_profile) {
                console.log('=============other_profile=======================');
                setProfileData(response.other_profile)
                setUserDesgnation("Other")

            } { }
            // setUserDetail(res.data.results)

        })
            .catch(error => {
                // alert(error)
            })
    }

    useEffect(() => {
        GetProfile();
        GetPlan()

    }, [])

    return (

        <Fragment>


            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
 
                    <Sidebar Desgnation={UserDesgnation} profileData={profileData}  plan={PlanData} Logout={Logout} />


                    <div className="page-content">
                        <Header profileData={profileData} Logout={Logout} />
                        <Outlet />

                    </div>

                </div>
            </div>
            {/* <AdminHeader /> */}

        </Fragment>

    )
}
