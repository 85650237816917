import Header from "../../header/header";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddId, AgentType, ApiUrl, DeveloperType, SetUserType,getAccessToken, GetAgentType, GetDeveloperType, GetOtherBusinessType, getToken, OtherBusinessType, setUserSession } from "../../../utils/common";
import Loader from "../../Loader";
import { Oval } from "react-loader-spinner";

import { useDispatch } from "react-redux";
import { auth } from '../../../_constants';

const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isShowPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const navigate = useNavigate()

    let dispatch = useDispatch();
    const Login = (e) => {
        if (!ValidateFields()) return;
        e.preventDefault()
        setButtonLoader(true);
        const data = {};
        data.email = userName;
        data.password = password;

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: JSON.stringify(data),
            // url: `${ApiUrl()}/api/token/`
            url: `${ApiUrl()}/auth/user/login`

        };
        axios(option)
            .then(e => {
                console.log(e.data);

                // eslint-disable-next-line eqeqeq
                if(e.status==200){

                    AddId(e?.data?.id);
                    DeveloperType(e?.data?.is_developer);
                    AgentType(e?.data?.is_agent);
                    OtherBusinessType(e?.data?.is_otherbusiness);
    
                    console.log(e.data.success);
                    toast.success(e.data.success)
                    LoginApi();
                if (e?.data?.is_developer==='true') {
                    toast.success(e?.data?.success)
                    SetUserType('dev')
                    dispatch({
                        type: auth.SET_PROFILE,
                        payload: 'dev',
                    });
                    toast.warning("Please Complete Your Profile")
                }
                if (e?.data?.is_agent==='true') {
                    dispatch({
                        type: auth.SET_PROFILE,
                        payload: 'agent',
                    });
                    SetUserType('agent')


                }

                if (e?.data?.is_otherbusiness==='true') {
                    dispatch({
                        type: auth.SET_PROFILE,
                        payload: 'other',
                    });
                    SetUserType('other')

                    toast.success(e?.data?.data.success)
                }
               

                }
                else{
                    
                setButtonLoader(false);
                alert('Error')

                // toast.error(e.data.a)
                }
                setButtonLoader(false);
                const data = JSON.parse(e?.data?.data || '{}');
            })
            .catch(err => {
                console.log(err);
                setButtonLoader(false);

                // toast.error(err?.response?.data?.detail)
            })
    }
    const LoginApi = () => {
        // setLoading(true)
        setButtonLoader(true);
        const data = {}
        // data.name = fullName;
        data.email = userName;
        data.password = password;
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: JSON.stringify(data),
            // url: `${ApiUrl()}/auth/user/login`
            url: `${ApiUrl()}/api/token/`

        };
        axios(option)
            .then(e => {
                console.log('====================================');
                console.log(e);
                console.log('====================================');
                // setLoading(false);
                navigate('/dashboard')

                
                getAccessToken(e?.data?.access)
                setUserSession(e?.data?.access)
                setButtonLoader(false);
                // const data = JSON.parse(e?.data?.data || '{}');
                // if (`${GetDeveloperType()}` == "true") {
                //     navigate('/developerdashboard')
                //     toast.success(e?.data?.success)
                //     toast.warning("Please Complete Your Profile")
                // }
             
            })
            .catch(err => {
                // setLoading(false)
                setButtonLoader(false);
                toast.error(err?.response?.data?.email[0] || err?.response?.data?.error)
            })
    }

    const ValidateFields = () => {
        // if (!fullName || fullName.trim() === "") {
        //   toast.error("Please Enter Your FullName")
        //   return false;
        // }

        if (!userName || userName.trim() === "") {
            toast.error("Please Enter UserName / Email")
            return false;
        }

        if (!password) {
            toast.error("Please Enter Your Password")
            return false;
        }
        return true;
    }
    return (
        <>

            {/* {
                loading ? <Loader /> : null
            } */}
            <div className="form-body">
                <div className="website-logo">
                    <Link to="/">
                        <div className="logo">
                            <img className="logo-size" src="images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
                        </div>
                    </Link>
                </div>
                <div className="img-holder">
                    <div className="bg" style={{ backgroundImage: "url(images/skyline-2.png)" }}></div>
                </div>
                <div className="form-holder">
                    <div className="form-content fixed-in-mobile">
                        <div className="form-items">
                            <h3 className="ti-fs">Secured Login to <a href="/"><span className="pl-name">Dollar Per Foot.</span></a></h3>
                            <p>Access to the most powerfull tool in the entire Dollar Per Foot Platform.</p>

                            <form onSubmit={Login}>
                                <div className="form-group mb-4">
                                    {/* username input */}
                                    <label htmlFor="username" className="sr-only">Username</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend ">
                                            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18"
                                                id="inputGroup-sizing-lg">
                                                <i className="far fa-user"></i></span>
                                        </div>
                                        <input type="text" className="form-control border-0 shadow-none fs-13"
                                            id="username" name="username"
                                            placeholder="Username / Your email" onChange={(e) => setUserName(e?.target?.value)} value={userName} />
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    {/* password input */}
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend ">
                                            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                                <i className="far fa-lock"></i>
                                            </span>
                                        </div>
                                        <input type={isShowPassword ? 'text' : "password"} className="form-control border-0 shadow-none fs-13"
                                            id="password" name="password"
                                            placeholder="Password" onChange={(e) => setPassword(e?.target?.value)} value={password} />
                                        <div className="input-group-append">
                                            <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                                <i className="far fa-eye-slash" onClick={() => setShowPassword(!isShowPassword)}></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-4">
                                    <Link to="/forgotpassword" className="d-inline-block ml-auto text-orange fs-15">
                                        Forgot Password?
                                    </Link>
                                </div>
                                <div className="Submit-button-section-login">
                                    <button type="submit" className="btn text-white bg-blue hover-saffron btn-lg btn-block">Log in</button>
                                    {
                                        buttonLoader ? <Oval
                                            height={25}
                                            width={25}
                                            color="#000"
                                            wrapperStyle={{}}
                                            wrapperClass="spinner-login-button"
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#fff"
                                            strokeWidth={5}
                                            strokeWidthSecondary={5}
                                        /> : null
                                    }

                                </div>
                            </form>
                            <p className="lg-bo">Don't have an account? <a href="/signup" className="text-blue hover-saffron-text"><u>Sign up</u></a></p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
export default Login;