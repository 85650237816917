import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiUrl, GetId, getToken, GetUserType, removeUserSession } from "../../../utils/common";

import { MainApiProtectedVersion } from "../../../utils/axios/requests";


const Sidebar = (props) => {
  const userType = GetUserType()
  const navigate = useNavigate();
  const profileData = props.profileData
  const UserDesgnation = props.Desgnation
  const Logout = props.Logout

  // const PlanData = props.plan
  // console.log('====================================');
  // console.log(PlanData.listings_per_year);
  // console.log('====================================');


  return (
    <>

      <div className="db-sidebar bg-carbon sb-side">

        <nav className="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0 add-overflow-scroll">
          <div className=" bg-carbon sticky-side sticky-area shadow-xs-1 py-3  ps-fixed">
            <div className="w-100 text-align-center bg-carbon py-2">
              {/*  <a className="navbar-brand" href="/">
                <img src="/images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
              </a> */}

              <Link className="navbar-brand" to="/profile">
                <img className="profile-img border-rd-50 px-2 py-2" src={profileData?.profile_image || "images/agency-list-01.jpg"} alt="HomeID" style={{ width: '150px' }} />
              </Link>
              <div className="">
                <span className="text-muted text-uppercase">welcome</span>
                <h2 className="text-white fs-22 lh-15"> {profileData?.first_name} {profileData?.last_name}</h2>
                <span className="text-saffron text-uppercase">{profileData?.designation}</span>
              </div>
              <div className="ml-auto d-flex align-items-center fd-reverse ">
                <div className="d-flex align-items-center d-xl-none px-3">
                 {/*  <div className="dropdown px-3">
                    <Link to="/dashboard" className=" d-flex align-items-center text-heading">
                      <div className="w-48px ">
                        <img src={profileData?.profile_image || "images/agency-list-01.jpg"}
                          alt="Ronald Hunter" className="rounded-circle" style={{ width: '45px', height: '45px' }} />
                      </div>
                     
                    </Link>
                  </div> */}
                  {/* <div className="dropdown no-caret py-4 px-3 d-flex align-items-center notice mr-3">
                    <a href="#" className="dropdown-toggle text-heading fs-20 font-weight-500 lh-1"
                      data-bs-toggle="dropdown">
                      <i className="far fa-bell"></i>
                      <span className="badge badge-primary badge-circle badge-absolute font-weight-bold fs-13">1</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <button className="navbar-toggler border-0 px-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#primaryMenuSidebar"
                  aria-controls="primaryMenuSidebar" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon">
                    <span class="text-white fs-24 font-size-50"><i class="fal fa-bars"></i></span>
                  </span>
                </button>
              </div>
            </div>
            <div className="collapse navbar-collapse bg-white" id="primaryMenuSidebar">
              {/* <form className="d-block d-xl-none pt-5 px-3">
                <div className="input-group">
                  <div className="input-group-prepend mr-0 bg-input">
                    <button className="btn border-0 shadow-none fs-20 text-muted pr-0" type="submit"><i
                      className="far fa-search"></i></button>
                  </div>
                  <input type="text" className="form-control border-0 form-control-lg shadow-none"
                    placeholder="Search for..." name="search" />
                </div>
              </form> */}

              {userType === "other" ? (<>

                <ul className="  list-group list-group-flush w-100">
                  <li className="list-group-item pt-6 pb-4">
                    {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Main</h5> */}
                    <ul className=" list-group list-group-no-border rounded-lg">
                      <li className="px-3 px-xl-4 py-2 ml-add-35 bg-blue hover-saffron" style={{ borderRadius: '5px', listStyle: 'none' }}>
                        <Link to="/dashboard" className="text-white lh-1 ">
                          <span className="sidebar-item-text text-white">
                            Other Dashboard
                          </span>
                        </Link>
                      </li>
                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item ml-add-35 header-r-logout">
                        <Link to="/profile/edit" className="text-heading lh-1 sidebar-link">
                          <span className="sidebar-item-text text-white">
                            Edit Profile
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="list-group-item pt-6 pb-4">
                    {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage Listings</h5> */}
                    <ul className="list-group list-group-no-border rounded-lg">

                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to="/lead/business/"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-heart"><use xlinkHref="#icon-heart"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Leads</span>

                        </Link>
                      </li>
                      {/*  <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <a to="/my-save-properties"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-save-search"><use xlinkHref="#icon-save-search"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">Save Search</span>
                        <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">5</span>
                      </a>
                    </li> */}


                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to='/profile'>
                          <a
                            className="text-heading lh-1 sidebar-link d-flex align-items-center">
                            <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                              <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                            </span>
                            <span className="sidebar-item-text text-white">My Profile</span>
                            {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                          </a>
                        </Link>
                      </li>

                      {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/floorplan'>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Floor Plan</span>
                          
                        </a>
                      </Link>
                    </li> */}




                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to="/review/profile"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Reviews</span>

                        </Link>
                      </li>

                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to="/services/areas"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Areas Serviced</span>

                        </Link>
                      </li>

                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to="/portfolio"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Portfolio</span>

                        </Link>
                      </li>



                    </ul>

                  </li>
                  <li className="list-group-item pt-6 pb-4">
                    <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3" style={{ marginLeft: '28px' }}>Manage Account</h5>
                    <ul className="list-group list-group-no-border rounded-lg">

                      <div className="collapse" id="invoice_collapse">
                        <div className="card card-body border-0 bg-transparent py-0 pl-6">
                          <ul className="list-group list-group-flush list-group-no-border">
                            <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                              <a to="/listing-invoice" className="text-heading lh-1 sidebar-link"
                              >Listing Invoice</a>
                            </li>
                            <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                              <a to="/add-new-invoice" className="text-heading lh-1 sidebar-link"
                              >Add New Invoice</a>
                            </li>
                            <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                              <a to="/edit-invoice" className="text-heading lh-1 sidebar-link" href="dashboard-edit-invoice.html">Edit
                                Invoice</a>
                            </li>
                            <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                              <a to="/preview-invoice" className="text-heading lh-1 sidebar-link"
                                href="dashboard-preview-invoice.html">Preview Invoice</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/*   <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmypackage"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">My Package</span>

                      </Link>
                    </li>
 */}
                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to="/user/invoice"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Invoices</span>

                        </Link>
                      </li>


                      {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmyprofile"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">My Profile</span>
                      </Link>
                    </li> */}

                      {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/MyTeam"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">Add Team</span>
                      </Link>
                    </li> */}

                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <Link to="/changepassword"
                          className="text-heading lh-1 sidebar-link">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Change Password</span>
                        </Link>
                      </li>
                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                        <a href="#" className="text-heading lh-1 sidebar-link" onClick={() => { removeUserSession(); Logout(); }}>
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-log-out"><use xlinkHref="#icon-log-out"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Log Out</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </>) : (<>



                <ul className="list-group list-group-flush w-100">
                  <li className="list-group-item pt-6 pb-4 bg-carbon">
                    {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Main</h5> */}
                    <ul className=" text-align-center list-group list-group-no-border rounded-lg">
                      <li className="px-3 px-xl-4 py-2  bg-blue hover-saffron" style={{ textAlign: "center", borderRadius: '5px', listStyle: 'none' }}>
                        <Link to="/dashboard" className="text-white lh-1 ">
                          <span className="text-white">
                            {UserDesgnation} Dashboard
                          </span>
                        </Link>
                      </li>
                      <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item  header-r-logout">
                        <Link to="/profile/edit" className="text-heading lh-1 sidebar-link">
                          <span className="text-black">
                            Edit Profile
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="list-group-item pt-6 pb-4 bg-carbon">
                    {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage Listings</h5> */}
                    <ul className="list-group list-group-no-border rounded-lg bg-carbon">
                      <li className="list-group-item px-3 px-xl-4 py-2  bg-carbon">
                        <Link to="/property/create/"
                          className="text-heading lh-1 sidebar-link">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                            <svg className="icon icon-add-new"><use
                              xlinkHref="#icon-add-new"></use></svg></span>
                          <span className="sidebar-item-text text-white text-white">Add Property</span>
                        </Link>
                      </li>
                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon ">
                        <Link to="/property"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-my-properties"><use
                              xlinkHref="#icon-my-properties"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Properties</span>

                        </Link>
                      </li>
                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to="/lead/business/"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-heart"><use xlinkHref="#icon-heart"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Leads</span>

                        </Link>
                      </li>
                      {/*  <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <a to="/my-save-properties"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-save-search"><use xlinkHref="#icon-save-search"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">Save Search</span>
                        <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">5</span>
                      </a>
                    </li> */}
                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to='/team'>
                          <a
                            className="text-heading lh-1 sidebar-link d-flex align-items-center">
                            <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                              <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                            </span>
                            <span className="sidebar-item-text text-white">My Team</span>
                            {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                          </a>
                        </Link>
                      </li>

                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to='/profile'>
                          <a
                            className="text-heading lh-1 sidebar-link d-flex align-items-center">
                            <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                              <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                            </span>
                            <span className="sidebar-item-text text-white">My Profile</span>
                            {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                          </a>
                        </Link>
                      </li>

                      {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/floorplan'>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Floor Plan</span>
                          
                        </a>
                      </Link>
                    </li> */}




                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon ">
                        <Link to="/review/profile"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Reviews</span>

                        </Link>
                      </li>

                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to="/favourite"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Favourites</span>

                        </Link>
                      </li>

                      <li className="list-group-item px-3 px-xl-4 py-2  text-white bg-carbon">
                        <Link to="/portfolio"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Portfolio</span>

                        </Link>
                      </li>



                    </ul>

                  </li>
                  <li className="list-group-item pt-6 pb-4 bg-carbon">
                    <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3" style={{ marginLeft: '28px' }}>Manage Account</h5>
                    <ul className="list-group list-group-no-border rounded-lg">

                      <div className="collapse" id="invoice_collapse">
                        <div className="card card-body border-0 bg-transparent py-0 pl-6">
                          <ul className="list-group list-group-flush list-group-no-border bg-carbon">
                            <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                              <a to="/listing-invoice" className="text-heading lh-1 sidebar-link"
                              >Listing Invoice</a>
                            </li>
                            <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                              <a to="/add-new-invoice" className="text-heading lh-1 sidebar-link"
                              >Add New Invoice</a>
                            </li>
                            <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                              <a to="/edit-invoice" className="text-heading lh-1 sidebar-link" href="dashboard-edit-invoice.html">Edit
                                Invoice</a>
                            </li>
                            <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                              <a to="/preview-invoice" className="text-heading lh-1 sidebar-link"
                                href="dashboard-preview-invoice.html">Preview Invoice</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/*   <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmypackage"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">My Package</span>

                      </Link>
                    </li>
 */}
                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to="/user/invoice"
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">My Invoices</span>

                        </Link>
                      </li>


                      {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmyprofile"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">My Profile</span>
                      </Link>
                    </li> */}

                      {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/MyTeam"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text text-white">Add Team</span>
                      </Link>
                    </li> */}

                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to="/changepassword"
                          className="text-heading lh-1 sidebar-link">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white">Change Password</span>
                        </Link>
                      </li>
                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <a href="#" className="text-heading lh-1 sidebar-link" onClick={() => { removeUserSession(); Logout(); }}>
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-log-out"><use xlinkHref="#icon-log-out"></use></svg>
                          </span>
                          <span className="sidebar-item-text text-white text-white">Log Out</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </>)}


            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
export default Sidebar; 