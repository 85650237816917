import React, { useState, useEffect, useRef } from "react";
import { BsFillLightbulbOffFill } from "react-icons/bs";
import AutoComplete from "react-google-autocomplete";

// import { AddressObject } from "../../utils/common";

const PropertySearchBar = ({ Onchange, PassingAddress }) => {
    const [place, setPlace] = useState();
    return (
        <>
            <AutoComplete
                apiKey={'AIzaSyAqVBbzZmrRV7LzReXe_bvQ4I-PuzP1GQ0'}
                onPlaceSelected={(place) => { setPlace(place); Onchange(place); PassingAddress(place) }}
                className="mt-fourteen form-control form-control-lg border-0 radius-left-tb shadow-none pr-8 bg-white placeholder-muted commercial-input"
                placeholder="Enter Location..."
                onKeyDown={(event) => {
                    if (event?.keyCode === 13) {
                      event?.preventDefault();
                    }
                  }}
                options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: ["us", "ca"] },
                }}
            />
        </>
    );
}

export default PropertySearchBar;