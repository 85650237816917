import React, { useEffect, useState } from "react";
import { BsInstagram,BsLinkedin, BsSkype, BsTwitter } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiUrl } from "../../utils/common";
import axios from "axios";



const Footer = ({searchAddress}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [featuredPropertyData, setFeaturedPropertyData] = useState([]);
    const [preLaunchPropertyData, setPreLaunchPropertyData] = useState([]);
    const FeaturedPropertyRedirect = () => {
        navigate('/propertylist', { state: { Data: featuredPropertyData, type: "Featured" } })
    }
    const PreLaunchProperties = () => {
        navigate('/propertylist', { state: { Data: preLaunchPropertyData, type: "Commercial" } })
    }



     // search api
     const Search = () => {
        setLoading(true)
        const data = {}
        // data.lat = Latitude;
        // data.lng = Longitude;
        //  data.location = searchAddress?.formatted_address;
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            // data: data,
            url: `${ApiUrl()}/api/property/advance-filter-property`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                // setHomeSearchData(e?.data)
                // console.log(homeSearchData,'homeSearchData237');
                Redirect(e?.data)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const Redirect = (e) => {
        // console.log(homeSearchData,'homeSearchData245');
        navigate('/propertylist', {state: {footerType:"AllProperty"}})
    }


    // featured properties
    const FeaturedProperty = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/property/filter-property?is_featured=true`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                setFeaturedPropertyData(e?.data);
            })
            .catch(err => {
                setLoading(false);
            })
    }
    // prelaunch property
    const PreLaunchProperty = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/property/filter-property?selling_status__name=Pre-Launch`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                setPreLaunchPropertyData(e?.data);
            })
            .catch(err => {
                setLoading(false)
            })
    }
    useEffect(() => {
       
        FeaturedProperty();
        PreLaunchProperty();
    }, [])

    return (
        <>
            <footer className="bg-dark pt-8 pb-6 footer text-muted">
                <div className="container container-xxl">
                    <div className="mb-6 mb-md-0 footer-icon">
                            <a className="d-block mb-2 logo-footer-add-other" href="#">
                                <img src="../../images/dpf-logo.png" alt="HomeID" className="logo-footer" />
                            </a>
                            <div className="lh-26 font-weight-500">
                                {/* <p className="mb-0">58 Howard Street #2 San Francisco</p>
                                <a className="d-block text-muted hover-white" href="mailto:contact@homeid.com">contact@homeid.com</a>
                                <a className="d-block text-lighter font-weight-bold fs-15 hover-white"
                                    href="tel:(+68)122109876">(+68)1221
                                    09876</a> */}
                                {/* <a className="d-block text-muted hover-white" href=".">www.test.com</a> */}
                            </div>
                        </div>
                    <div className="row footer-grid">
                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Property Types</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a className="text-muted lh-26 font-weight-500 hover-white" onClick={() => Search()}>Office</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" onClick={() => Search()}>Retail</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" onClick={() => Search()}>Restaurant</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" onClick={() => Search()}>Land For Developments</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" onClick={() => Search()}>Industrial</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Quick links</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/about-us" className="text-muted lh-26 font-weight-500 hover-white">About</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/contact-us" className="text-muted lh-26 font-weight-500 hover-white">Contact</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/" className="text-muted lh-26 font-weight-500 hover-white">Privacy Policy</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/blog-list" className="text-muted lh-26 hover-white font-weight-500">Blog</a>
                                </li>
                               {/*  <li className="list-group-item bg-transparent p-0">
                                    <Link to="#" className="text-muted lh-26 hover-white font-weight-500">FAQs</Link>
                                </li> */}
                            </ul>
                        </div>

                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Our Projects</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" onClick={() => Search()}>New Development Projects</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" onClick={() => FeaturedPropertyRedirect()}>Featured Projects</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a className="text-muted lh-26 font-weight-500 hover-white" onClick={() => PreLaunchProperties()} >Pre-Launch Projects</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 hover-white font-weight-500" onClick={() => PreLaunchProperties()}>Launching Soon</a>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                       
                        
                              <h4 className="text-white fs-16 my-4 font-weight-500">Get in touch for upcoming projects</h4>
                            <p className="font-weight-500 text-muted lh-184">Never miss a commercial project deal. Maximize your return with Dollar Per Foot.</p>
                        {/*   <form>
                                <div className="input-group input-group-lg mb-6">
                                    <input type="email" name="email" className="form-control bg-white shadow-none border-0 z-index-1" placeholder="Your email" />
                                    <div className="input-group-append">
                                        <button className="btn text-white bg-blue hover-saffron" type="submit">Subscribe</button>
                                    </div>
                                </div>
                            </form> */}
                            
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsTwitter />
                                    </a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <FaFacebookF /></a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsInstagram /></a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsLinkedin /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-0 mt-md-10 row">
                        <ul className="list-inline mb-0 col-md-6 mr-auto">
                            <li className="list-inline-item mr-6">
                                <a href="#" className="text-muted lh-26 font-weight-500 hover-white">Terms of Use</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#" className="text-muted lh-26 font-weight-500 hover-white">Privacy Policy</a>
                            </li>
                        </ul>
                        <p className="col-md-auto mb-0 text-muted">
                            © 2022 DollarPerFoot. All Rights Reserved
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;