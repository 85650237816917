import React, { useState, useEffect } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format, compareAsc } from 'date-fns';
import Select from "react-select";
import { ApiUrl, getToken } from '../../../utils/common';
import axios from 'axios';


const AllReviews = () => {
    const [configData, setConfigData] = useState([]);
    const [region, setRegion] = useState('')
    const options = [
        { value: '0', label: 'Total Leads' },
        { value: '1', label: 'Property Leads' },
        { value: '2', label: 'User Leads' },
    ]
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterDate, setFilterDate] = useState(false);
    useEffect(() => {
        if (filterDate) {
            setEndDate(format(new Date(filterDate[1]), 'dd-MM-yyyy'))
            setStartDate(format(new Date(filterDate[0]), 'dd-MM-yyyy'))
        }
    }, [filterDate])
    
    /* city api */
    
    useEffect(() => {

        const ConfigDropDownData = () => {
          const option = {
            method: 'GET', headers: {
              'access-control-allow-origin': '*',
              'content-type': 'multipart/form-data',
              'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/config-property`
          };
          axios(option)
            .then(e => {
              setConfigData(e?.data)
            })
            .catch(err => {
    
            })
        }
        ConfigDropDownData()
      }, [])
    return (
        <main id="content-review" className='bg-gray-01 pb-10 '>
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                    <div className="mr-0 mr-md-auto">
                        <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">All Reviews
                            <span
                                className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">10</span></h2>
                    </div>
                </div>
                <section className='bg-blue'>
                    <div className="col-12">
                        <div className="row">
                            <div className="search-tr ">
                                <DateRangePicker className="daterange" minDetail="month" maxDetail="year" format="MM/yyy" yearPlaceholder="yyyy" monthPlaceholder="mm" onChange={setFilterDate} value={filterDate} />
                            </div>
                            <div className="search-tr ">
                                <Select placeholder="Select Lead Type" className="form-select inner-svg" aria-label="Default select example"
                                    options={options}

                                />

                            </div>
                            <div className="search-tr   ">
                                <Select placeholder="Region/City"
                                    className="form-select inner-svg" aria-label="Region/City"
                                    options={configData?.city}
                                    // isMulti
                                    title="Region/City"
                                    data-style=""
                                    onChange={(e) => {
                                        setRegion(e)

                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={region}

                                />
                            </div>
                            <div className="reload">
                                <a  className='btn advanced-search bg-saffron text-white hover-grey width-re-10' style={{ marginTop: '15px', padding: '12px 24px' }}>
                                    <img src="../images/reload.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}
export default AllReviews;