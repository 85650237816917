import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";



const DevSidebar = () => {

  const navigate = useNavigate();
  
  const [profileData, setProfileData] = useState();
  const Logout = () => {
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/logout`
    };

    axios(option)
      .then(e => {
        navigate('/')
      })
      .catch(err => {

      })

  }
   // get profile api
   const GetProfile = () => {
   
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/developer-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
       
        setProfileData(e?.data?.[0])
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
       
      })
  }
  useEffect(() => {
    GetProfile();
    
  },[])
  return (
    <>
   
      <div className="db-sidebar bg-white sb-side">

        <nav className="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0 add-overflow-scroll">
          <div className="sticky-side sticky-area shadow-xs-1 py-3  ps-fixed">
            <div className="d-flex px-3 px-xl-6 w-100">
              <a className="navbar-brand" href="/">
                <img src="images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
              </a>
              <div className="ml-auto d-flex align-items-center ">
                <div className="d-flex align-items-center d-xl-none">
                  <div className="dropdown px-3">
                    <Link to="/developerdashboard" className=" d-flex align-items-center text-heading">
                      <div className="w-48px ">
                        <img src={profileData?.profile_image || "images/agency-list-01.jpg"}
                          alt="Ronald Hunter" className="rounded-circle" style={{width:'45px', height:'45px'}}/>
                      </div>
                      {/* <span className="fs-13 font-weight-500 d-none d-sm-inline ml-2">
                        Ronald Hunter hello
                      </span> */}
                    </Link>
                  </div>
                  {/* <div className="dropdown no-caret py-4 px-3 d-flex align-items-center notice mr-3">
                    <a href="#" className="dropdown-toggle text-heading fs-20 font-weight-500 lh-1"
                      data-bs-toggle="dropdown">
                      <i className="far fa-bell"></i>
                      <span className="badge badge-primary badge-circle badge-absolute font-weight-bold fs-13">1</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <button className="navbar-toggler border-0 px-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#primaryMenuSidebar"
                  aria-controls="primaryMenuSidebar" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
            <div className="collapse navbar-collapse bg-white" id="primaryMenuSidebar">
              {/* <form className="d-block d-xl-none pt-5 px-3">
                <div className="input-group">
                  <div className="input-group-prepend mr-0 bg-input">
                    <button className="btn border-0 shadow-none fs-20 text-muted pr-0" type="submit"><i
                      className="far fa-search"></i></button>
                  </div>
                  <input type="text" className="form-control border-0 form-control-lg shadow-none"
                    placeholder="Search for..." name="search" />
                </div>
              </form> */}
              <ul className="list-group list-group-flush w-100">
                <li className="list-group-item pt-6 pb-4">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Main</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg">
                    <li className="px-3 px-xl-4 py-2 ml-add-35 bg-blue hover-saffron" style={{borderRadius:'5px',listStyle:'none'}}>
                      <Link to="/developerdashboard" className="text-white lh-1 ">
                        <span className="sidebar-item-text">
                          Developer Dashboard
                        </span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item ml-add-35 header-r-logout">
                      <Link to="/devmyprofile" className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-text">
                          Edit Profile
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="list-group-item pt-6 pb-4">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage Listings</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg">
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devaddproperty"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new"><use
                            xlinkHref="#icon-add-new"></use></svg></span>
                        <span className="sidebar-item-text">Add new Property</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmyproperties"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-properties"><use
                            xlinkHref="#icon-my-properties"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Properties</span>

                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devleads"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-heart"><use xlinkHref="#icon-heart"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Leads</span>

                      </Link>
                    </li>
                    {/*  <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <a to="/my-save-properties"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-save-search"><use xlinkHref="#icon-save-search"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Save Search</span>
                        <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">5</span>
                      </a>
                    </li> */}
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/devmyagents'>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text">My Team</span>
                          {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                        </a>
                      </Link>
                    </li>

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/developerprofile'>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text">My Profile</span>
                          {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                        </a>
                      </Link>
                    </li>

                    {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/floorplan'>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text">Floor Plan</span>
                          
                        </a>
                      </Link>
                    </li> */}




                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devreviews"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Reviews</span>

                      </Link>
                    </li>

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmyfavourite"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Favourites</span>

                      </Link>
                    </li>

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devportfolio"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Portfolio</span>

                      </Link>
                    </li>



                  </ul>

                </li>
                <li className="list-group-item pt-6 pb-4">
                  <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3" style={{ marginLeft: '28px' }}>Manage Account</h5>
                  <ul className="list-group list-group-no-border rounded-lg">

                    <div className="collapse" id="invoice_collapse">
                      <div className="card card-body border-0 bg-transparent py-0 pl-6">
                        <ul className="list-group list-group-flush list-group-no-border">
                          <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                            <a to="/listing-invoice" className="text-heading lh-1 sidebar-link"
                            >Listing Invoice</a>
                          </li>
                          <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                            <a to="/add-new-invoice" className="text-heading lh-1 sidebar-link"
                            >Add New Invoice</a>
                          </li>
                          <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                            <a to="/edit-invoice" className="text-heading lh-1 sidebar-link" href="dashboard-edit-invoice.html">Edit
                              Invoice</a>
                          </li>
                          <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                            <a to="/preview-invoice" className="text-heading lh-1 sidebar-link"
                              href="dashboard-preview-invoice.html">Preview Invoice</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/*   <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmypackage"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Package</span>

                      </Link>
                    </li>
 */}
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devinvoicepreview"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package"><use xlinkHref="#icon-my-package"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Invoices</span>

                      </Link>
                    </li>


                    {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/devmyprofile"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">My Profile</span>
                      </Link>
                    </li> */}

                    {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/MyTeam"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Add Team</span>
                      </Link>
                    </li> */}

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/changepassword"
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Change Password</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <a href="#" className="text-heading lh-1 sidebar-link" onClick={() => { removeUserSession(); Logout(); }}>
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-log-out"><use xlinkHref="#icon-log-out"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Log Out</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
export default DevSidebar;