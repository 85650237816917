import React, { } from "react";
import { PlanId } from "../utils/common";
import { useNavigate } from "react-router-dom";


function Planlist(props) {


  const data = props.data
  const SelectedPlan = props.SelectedPlan
  const setCheckType = props.setCheckType
  const CustomUser = props.CustomUser  || null;

  return (
    <>
      <div className="row px-2">

        {
          data?.map((item) => {
            return (
              <>
                {CustomUser ? (<>


                  {item.plan_type === "Custom" ? (<>


                    <div className="col-sm-4 pack-list mb-5">
                      <h4 className=" text-uppercase fs-18 mt-8 fw-600  sub-title-color mb-5">{item.plan_type}</h4>
                      <h2 className="fs-28 fw-600">${item.monthly_price}<span className="fs-14">/mo</span></h2>
                      <p className="fw-600 color-black mb-0">billed annually</p>
                      {item.coupon ? (<>
                        <div class="discount">
                          <del>
                            <span class="amount">${item.yearly_price}/yr</span>
                          </del>
                        </div></>) : null}

                      {item.coupon ? (<>
                        <p className="fw-600 color-black mb-0">Discount<span className="fs-14"> {item.coupon.percent_off}%</span></p>
                      </>) : (<>
                        <p className="fw-600 color-black mb-0">0% Discount</p>
                      </>)}


                      <p className="sub-title-color mb-0">Listings Per Year 12</p>
                      {/*   {item.coupon ? (<> */}
                      <p className="fs-16 fw-600 color-black">CAD{item.yearly_price_discount}<span className="fs-14">/yr</span> + Tax</p>
                      {/*  </>) : <> */}
                      {/*   <p className="fs-16 fw-600 color-black">CAD {item.price}<span className="fs-14">/yr</span> + Tax</p>
                  </>} */}


                      <button className="btn bg-blue text-white hover-saffron sub-b p-btn" onClick={() => { SelectedPlan(item); setCheckType(item); PlanId(item?.id) }}>Subscribe<span><img src="images/procced-arrows.png" style={{ width: '16px' }} /></span></button>
                      <h4 className="fs-16 mt-8">Compare Plans</h4>
                      <ul className="mt-2">

                        <li className="fs-14">

                          {/* featured_listing_on_homepage */}
                          {item?.featured_listing_on_homepage ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Professional In Search Result</li>
                            : <>
                            </>}

                          {/* Placement in search result */}
                          {item?.featured_professional_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Professional In Search Result</li>
                            : <>
                            </>}

                          {/* homepage_banner_ad*/}
                          {item?.homepage_banner_ad ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Homepage Banner Ad</li>
                            : <>
                            </>}

                          {/* multi_city_in_search_results */}
                          {item?.multi_city_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Multi City in search result</li>
                            : <>
                            </>}

                          {/* Placement in search result */}
                          {item?.placement_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Placement in search result </li>
                            : <>
                            </>}

                          {/* notifications_on_matched_user_searches */}
                          {item?.notifications_on_matched_user_searches ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Notifications on Matched User Searches </li>
                            : <>
                            </>}

                          {/* featured_developer_on_homepage */}

                          {item?.featured_developer_on_homepage ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Developer on Homepage </li>
                            : <>
                            </>}

                          {/* featured_developer_on_homepage */}

                          {item?.featured_developer_on_homepage ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Business in search Result </li>
                            : <>
                            </>}


                          {/* dashboard_for_leads_management */}
                          {item?.dashboard_for_leads_management ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Dashboard for Leads Management </li>
                            : <>
                            </>}

                          {/* featured_business_in_search_results */}
                          {item?.featured_business_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Business in search Result </li>
                            : <>
                            </>}
                        </li>
                        {/* <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Dashboard for Leads Management </li> */}
                      </ul>
                    </div>
                  </>) : (<>



                  </>)}

                </>) : (<>

                  {item.plan_type === "Custom" ? (<>


                  </>) : (<>

                    <div className="col-sm-4 pack-list mb-5">
                      <h4 className=" text-uppercase fs-18 mt-8 fw-600  sub-title-color mb-5">{item.plan_type}</h4>
                      <h2 className="fs-28 fw-600">${item.monthly_price}<span className="fs-14">/mo</span></h2>
                      <p className="fw-600 color-black mb-0">billed annually</p>
                      {item.coupon ? (<>
                        <div class="discount">
                          <del>
                            <span class="amount">${item.yearly_price}/yr</span>
                          </del>
                        </div></>) : null}

                      {item.coupon ? (<>
                        <p className="fw-600 color-black mb-0">Discount<span className="fs-14"> {item.coupon.percent_off}%</span></p>
                      </>) : (<>
                        <p className="fw-600 color-black mb-0">0% Discount</p>
                      </>)}


                      <p className="sub-title-color mb-0">Listings Per Year 12</p>
                      {/*   {item.coupon ? (<> */}
                      <p className="fs-16 fw-600 color-black">CAD {item.yearly_price_discount}<span className="fs-14">/yr</span> + Tax</p>
                      {/*  </>) : <> */}
                      {/*   <p className="fs-16 fw-600 color-black">CAD {item.price}<span className="fs-14">/yr</span> + Tax</p>
</>} */}


                      <button className="btn bg-blue text-white hover-saffron sub-b p-btn" onClick={() => { SelectedPlan(item); setCheckType(item); PlanId(item?.id) }}>Subscribe<span><img src="images/procced-arrows.png" style={{ width: '16px' }} /></span></button>
                      <h4 className="fs-16 mt-8">Compare Plans</h4>
                      <ul className="mt-2">

                        <li className="fs-14">

                          {/* featured_listing_on_homepage */}
                          {item?.featured_listing_on_homepage ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Professional In Search Result</li>
                            : <>
                            </>}

                          {/* Placement in search result */}
                          {item?.featured_professional_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Professional In Search Result</li>
                            : <>
                            </>}

                          {/* homepage_banner_ad*/}
                          {item?.homepage_banner_ad ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Homepage Banner Ad</li>
                            : <>
                            </>}

                          {/* multi_city_in_search_results */}
                          {item?.multi_city_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Multi City in search result</li>
                            : <>
                            </>}

                          {/* Placement in search result */}
                          {item?.placement_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Placement in search result </li>
                            : <>
                            </>}

                          {/* notifications_on_matched_user_searches */}
                          {item?.notifications_on_matched_user_searches ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Notifications on Matched User Searches </li>
                            : <>
                            </>}

                          {/* featured_developer_on_homepage */}

                          {item?.featured_developer_on_homepage ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Developer on Homepage </li>
                            : <>
                            </>}

                          {/* featured_developer_on_homepage */}

                          {item?.featured_developer_on_homepage ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Business in search Result </li>
                            : <>
                            </>}


                          {/* dashboard_for_leads_management */}
                          {item?.dashboard_for_leads_management ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Dashboard for Leads Management </li>
                            : <>
                            </>}

                          {/* featured_business_in_search_results */}
                          {item?.featured_business_in_search_results ?
                            <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Featured Business in search Result </li>
                            : <>
                            </>}
                        </li>
                        {/* <li className="fs-14"><img src="images/check.png" alt="check" className="sub-ul-li" />Dashboard for Leads Management </li> */}
                      </ul>
                    </div>


                  </>)}
                </>)}
              </>)



          })
        }


      </div>


    </>
  )

}


export default Planlist;