import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiUrl } from "../../utils/common";

const BlogSidebar = () => {
const [loading,setLoading] = useState();
const[landingBlogList, setLandingBlogList] = useState([])
console.log( landingBlogList,'hello blog list')
    const GetAllBlogList = () => {
        setLoading(true)
        const option = {
          method: 'GET', headers: {
            'access-control-allow-origin': '*',
            'content-type': 'application/json',
          },
          url: `${ApiUrl()}/api/blog/posts/`
        };
        axios(option)
          .then(e => {
            setLoading(false);
            setLandingBlogList(e?.data?.data);
          })
          .catch(err => {
            setLoading(false);
          })
      }
      useEffect(() => {
        GetAllBlogList();
      }, [loading])
    return (
        <>
         <div className="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky mt-8" id="sidebar">
              <div className="primary-sidebar-inner">

                <div className="card mb-4">
                  <div className="card-body px-6 pt-5 pb-6">
                    <h4 className="card-title fs-16 lh-2 text-dark mb-3">Latest Posts</h4>
                    <ul className="list-group list-group-flush">
                    {landingBlogList?.map((item)=>{
                    return(
                        <li className="list-group-item px-0 pt-0 pb-3 mb-4">
                        <div className="media">
                          <div className="position-relative mr-3">
                            
                              <img className="d-block w-100px rounded  bg-img-cover-center" src={item?.featured_image_url ||"../images/post-11.jpg"}
                alt={item?.title} />
                           

                          </div>
                          <div className="media-body">
                            <h4 className="fs-14 lh-186 mb-1">
                              <Link to={`/blogs/${item.id}`}
                                className="text-dark hover-saffron-text">
                                {item?.title}
                              </Link>
                            </h4>
                            <div className="text-gray-light">
                            {moment(item?.created_on).format('YYYY-MMM-DD')}
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                    })}
                      
                     </ul>
                  </div>
                </div>


              </div>
            </div>
        </>
    )
}
export default BlogSidebar;