import React from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";

import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
/* import DemoMap from "../../Components/Map/DemoMap"; */
Geocode.setApiKey("AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo");
Geocode.enableDebug();

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.addressMap?.address || '',
      city: this.props.addressMap?.city || '',
      area: this.props.addressMap?.area || '',
      state: this.props.addressMap?.state || '',
      country: this.props.addressMap?.country || '',
      postal_code: this.props.addressMap?.postal_code || '',
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      }
    }
  }
  componentDidMount() {
    if(this.props.propertyData?.id){
      this.getMapAddress();
    } else {
      setTimeout(() => {
        this.setState({ 
          address: this.props.addressMap?.address || '',
          city: this.props.addressMap?.city || '',
          area: this.props.addressMap?.area || '',
          state: this.props.addressMap?.state || '',
          country: this.props.addressMap?.country || '',
          postal_code: this.props.addressMap?.postal_code || '',
        });
        this.getMapAddress();
      },2000)
    }
  }

  getMapAddress = () => {
    const newLat = this.props.propertyData?.lat;
    const newLng = this.props.propertyData?.lng;
    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          country = this.getCountry(addressArray),
          postal_code = this.getpostalcode(addressArray);
          console.log(addressArray,'addressArray');
        this.setState({
          address: (address) ? address : '',
          area: (area) ? area : '',
          city: (city) ? city : '',
          state: (state) ? state : '',
          country: (country) ? country : '',
          postal_code: (postal_code) ? postal_code : ''
        })
      },
      error => {
        console.error(error);
      }
    );
  }
  /**
    * Get the current address from the default map position and set those values in the state
    */
  /* componentDidMount() {
   Geocode.fromLatLng( this.state.mapPosition.lat , this.state.mapPosition.lng ).then(
    response => {
     const address = response.results[0].formatted_address,
      addressArray =  response.results[0].address_components,
      city = this.getCity( addressArray ),
      area = this.getArea( addressArray ),
      state = this.getState( addressArray ),
      postal_code = this.getpostalcode( addressArray );
   
     console.log( 'city', city, area, state );
   
     this.setState( {
      address: ( address ) ? address : '',
      area: ( area ) ? area : '',
      city: ( city ) ? city : '',
      state: ( state ) ? state : '',
      postal_code: ( postal_code ) ? postal_code : ''
     } )
    },
    error => {
     console.error(error);
    }
   );
  }; */
  /**
    * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
    *
    * @param nextProps
    * @param nextState
    * @return {boolean}
    */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state ||
      this.state.postal_code !== nextState.postal_code ||
      this.state.country !== nextState.counrty
    ) {
      return true
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false
    }
  }
  /**
    * Get the city and set the city input value to the one selected
    *
    * @param addressArray
    * @return {string}
    */
  getCountry = (addressArray) => {
    let country = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };
  getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };/**
  * Get the city and set the city input value to the one selected
  *
  * @param addressArray
  * @return {string}
  */
  getpostalcode = (addressArray) => {
    console.log(addressArray, 'addressArray');
    let postal_code = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
        postal_code = addressArray[i].long_name;
        return postal_code;
      }
    }
  };
  /**
    * Get the area and set the area input value to the one selected
    *
    * @param addressArray
    * @return {string}
    */
  getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
    * Get the address and set the address input value to the one selected
    *
    * @param addressArray
    * @return {string}
    */
  getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
    * And function for city,state and address input
    * @param event
    */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
    * This Event triggers when the marker window is closed
    *
    * @param event
    */
  onInfoWindowClose = (event) => {
  };
  /**
    * When the user types an address in the search box
    * @param place
    */
  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      country = this.getCountry(addressArray),
      postal_code = this.getpostalcode(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    console.log(lngValue, 'log');
    // Set these values in the state.
    this.setState({
      address: (address) ? address : '',
      area: (area) ? area : '',
      city: (city) ? city : '',
      state: (state) ? state : '',
      country: (country) ? country : '',
      postal_code: (postal_code) ? postal_code : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      },
    })
    this.props.selectedData({
      address: (address) ? address : '',
      area: (area) ? area : '',
      city: (city) ? city : '',
      state: (state) ? state : '',
      country: (country) ? country : '',
      postal_code: (postal_code) ? postal_code : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      },
    })
  };
  /**
    * When the marker is dragged you get the lat and long using the functions available from event object.
    * Use geocode to get the address, city, area and state from the lat and lng positions.
    * And then set those values in the state.
    *
    * @param event
    */
  onMarkerDragEnd = (event) => {
    console.log('event', event);
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng(),

      addressArray = [];
    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          country = this.getCountry(addressArray),
          postal_code = this.getpostalcode(addressArray);
        this.setState({
          address: (address) ? address : '',
          area: (area) ? area : '',
          city: (city) ? city : '',
          state: (state) ? state : '',
          country: (country) ? country : '',
          postal_code: (postal_code) ? postal_code : ''
        })
      },
      error => {
        console.error(error);
      }
    );
  };
  render() {
    console.log(this.props.propertyData?.lat,'this.props.propertyData?.lat');
    console.log(this.props.propertyData?.lng,'this.props.propertyData?.lng');
    const AsyncMap = withScriptjs(
      withGoogleMap(
        props => (
          <GoogleMap google={this.props.google}
            defaultZoom={this.props.zoom}
            defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
          >
            {/* For Auto complete Search Box */}
            {/*  <DemoMap /> */}
            <Autocomplete
              style={{
                width: '100%',
                height: '50px',
                paddingLeft: '16px',
                marginTop: '20px',
              }}
              onPlaceSelected={this.onPlaceSelected}
              //  types={["address"]}
              // options={{
              //   types: ["(regions)"],
              //   // componentRestrictions: { country: "ru" },
              // }}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: ["ca","us"] }
              }}
              
              className="map-auto"
            />
            {/*Marker*/}
            <Marker google={this.props.google}
              name={'Dolores park'}
              draggable={true}
              onDragEnd={this.onMarkerDragEnd}
              position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
            />
            <Marker />
            {/* InfoWindow on top of marker */}
            <InfoWindow
              onClose={this.onInfoWindowClose}
              position={{ lat: (this.state.markerPosition.lat + 0.0018), lng: this.state.markerPosition.lng }}
            >
              <div>
                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
              </div>
            </InfoWindow>
          </GoogleMap>
        )
      )
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = <div>

        <AsyncMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo&libraries=places"
          loadingElement={
            <div style={{ height: `100%` }} />
          }
          containerElement={
            <div style={{ height: this.props.height }} />
          }
          mapElement={
            <div style={{ height: `100%` }} />
          }
        />

        <div className='mt-80'>
          <div className="form-group">
            <label htmlFor="">Address</label>
            <input type="text" name="address" className="form-control form-in-control" onChange={this.onChange} defaultValue={this.state.address} />
          </div>
          <div className="form-group">
            <label htmlFor="">Area</label>
            <input type="text" name="city" className="form-control form-in-control" onChange={this.onChange} value={this.state.city} />
          </div>
          <div className="form-group">
            <label htmlFor="">City</label>
            <input type="text" name="area" className="form-control form-in-control" onChange={this.onChange} value={this.state.area} />
          </div>
          <div className="form-group">
            <label htmlFor="">State/Province</label>
            <input type="text" name="state" className="form-control form-in-control" onChange={this.onChange} value={this.state.state} />
          </div>
          <div className="form-group">
            <label htmlFor="">Country</label>
            <input type="text" name="country" className="form-control form-in-control" onChange={this.onChange} value={this.state.country} />
          </div>
          <div className="form-group">
            <label htmlFor="">Postal/Zip Code</label>
            <input type="text" name="postal_code" className="form-control form-in-control" onChange={this.onChange} value={this.state.postal_code} />
          </div>
        </div>


      </div>

    } else {
      map =
        <div style={{ height: this.props.height }} />

    }
    return (map)
  }
}
export default Map;