import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap';
import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import { FormControlLabel, IconButton } from "@material-ui/core";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function BlogList() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blogList, setBlogList] = useState([])
  console.log(blogList?.length,'lol')
  const navigate = useNavigate();
/* delete plan */
   
   const MatDel = ({ item }) => {
    async function handleDelClick() {
        setShow(false);
        const obj = new MainApiProtectedVersion('', item)
        const res = await obj.requstBlogDelete().then((res) => {
        console.log(res.item)
            setShow(true);
            toast.success("Blog Deleted Successfully")
        })
            .catch(error => {
                alert(error)
            })
    };

    return (
        <FormControlLabel
            control={
                <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={handleDelClick}
                >
                    <DeleteIcon style={{ color: blue[500] }} />
                </IconButton>
            }
        />
    );
};


/* Edit list */


const MatEdit = ({ index }) => {
    const handleEditClick = () => {
       console.log(index);
       navigate(`edit/${index.id}`, { state: {index} })
    };

    return (
        <FormControlLabel
            control={
                <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={handleEditClick}
                >
                    <EditIcon style={{ color: blue[500] }} />
                </IconButton>
            }
        />
    );
};

useEffect(() => {
     setLoading(true)
    async function requstBlogGet(data) {
      const obj = new MainApiProtectedVersion()
      const res = await obj.requstBlogGet().then((res) => {
      
        console.log(res.data.data)
        setBlogList(res.data.data)
     
               
            })
            .catch(err => {
                
            })
    }
    requstBlogGet()
}, [loading,show]);
  
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'title', headerName: 'Blog Title', width: 580 },
    { field: 'created_on', headerName: 'Post Date', width: 250 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 50,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return (
              <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
              >
                  <MatEdit index={params.row} />
              </div>
          );
      }
  },
  {
      field: "del",
      headerName: "Delete",
      sortable: false,
      width: 50,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return (
              <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
              >
                  <MatDel item={params.row.id} />
              </div>
          );
      }
  }
  ]
  return (
    <>
    
      <div class="row">
                    <div style={{ height: 450, width: '100%' }}>
                    {blogList ? (<>
                        <DataGrid
                            rows={blogList}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            experimentalFeatures={{ newEditingApi: false }}
                        />
                    
                    </>): blogList==="Data Not Found"  (<> 
                    no blog
                    </>)}
                        
                        
                    </div>
                </div>
    </>
  )
}
