import React, { useEffect, useState } from "react";
import { Editor, OriginalTools } from 'react-bootstrap-editor';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Form, Col } from 'react-bootstrap';
import { MainApiProtectedVersion } from "../../../utils/axios/requests";
import { toast } from "react-toastify";


const CreateBlog = () => {



    const { Id } = useParams();
    const [blog, setBlog] = useState([]);
    const isAddMode = Id;
    const navigate = useNavigate();
    const [blogContent, setBlogContent] = useState();
    const { handleSubmit, control, watch, register, setValue, getValues, reset, errors, formState } = useForm({});



    /* upload featured image */
    const [imgFile, setImageFile] = useState();
    const [isMyProfile, setIsMyProfile] = useState('');
    const PorfileImgHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);

        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
        setImageFile(e?.target?.files[0]);
    }
    // sending image to base64
    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImageFile(reader?.result)
        };
    }

    /* end */
    useEffect(() => {
        if (isAddMode) {
            async function BlogGetID() {
                const obj = new MainApiProtectedVersion('', Id)
                const res = await obj.requstBlogGetById().then((res) => {
                    setBlog(res.data.data[0])
                    reset(res.data.data[0]);
                    setValue('content', res.data.data[0]['content'])

                })
                    .catch(error => {
                        alert(error)
                    })
            }

            BlogGetID()
        }

    }, [Id, reset, isAddMode, setValue]);



    async function BlogCreate(data, urlBase,) {
        const obj = new MainApiProtectedVersion()
        const res = await obj.requstBlogCreate(data).then((res) => {
            if (res.status == 200) {
                toast.success("Blog Submitted Successfully!")
                navigate('/admin/blog')
            } else {
                toast.error("Some Technical Error")
            }

        })
            .catch(error => {
                alert(error)
            })
    }


    async function BlogUpdate(id, data) {
        const obj = new MainApiProtectedVersion('', id)
        const res = await obj.requstBlogUpdate(data).then((res) => {
            if (res.status == 200) {
                toast.success("Blog Updated Successfully!")
                navigate('/admin/blog')
            } else {
                toast.error("Some Technical Error")
            }

        })
            .catch(error => {

                navigate('/admin/')

            })
    }

    /* blog Submit api */
    async function SubmitBlog(formData) {
        formData['content'] = blogContent
        const inputFile = document.getElementById(
            "customFile",
        );

        formData['featured_image'] = inputFile?.files?.item(0)
       
        return isAddMode ? BlogUpdate(Id, formData) : BlogCreate(formData);
    }
    /* end */

    return (<>

        <main id="content " className='bg-gray-01 margin-re-top'>
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                    <div className="mr-0 mr-md-auto">
                        <h1 className="mt-5 mb-0 text-heading fs-22 lh-15">
                            {isAddMode ? (<> Blog edit</>) : (<>Create Blog</>)}
                        </h1>
                    </div>
                    <div>
                        <Link to="/admin/blog" className="btn bg-blue text-white hover-saffron btn-lg">
                            <span>Blog List</span>
                            <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                        </Link>
                    </div>
                </div>
                <Form onSubmit={handleSubmit(SubmitBlog)} >
                    <div className="card border-0 mb-4 header-user-icon">
                        <div className="card-body p-0 p-sm-8 p-4">
                            <div className="card mb-6">
                                <div className="card-body p-6">
                                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">Blog Detail</h3>
                                    <div className="col-lg-12 col-md-6 col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="title" className="text-heading margin-b-0">Blog Title <span className="star-mandatoryfield">*</span></label>
                                            <input type="text"
                                                className="form-control form-control-lg border-0"
                                                id="title" name="title" placeholder="Enter Blog Title" {...register('title', { required: true })} />
                                        </div>
                                        <div class="form-group">
                                            <label for="blog content">Blog Content</label>
                                            <Controller
                                                name="content"
                                                control={control}
                                                render={({ value, onChange }) => (
                                                    <Editor onChange={e => setBlogContent(e)} />
                                                )}
                                            />
                                        </div>
                                        <div className="col-lg-6 img-uploader-cnt-inf">
                                            {
                                                imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                            }
                                            <div className="custom-file mt-4 h-auto">
                                                <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" onChange={PorfileImgHandler} />
                                                <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                    <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                    profile image</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                        <button type="submit" className="btn text-white hover-saffron bg-blue">Submit</button>
                    </div>
                </Form>
            </div>
        </main>

    </>)
}
export default CreateBlog;