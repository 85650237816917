import React, { useState } from "react";
import CurrencyFormat from 'react-currency-format';


const CurrencyFormatType = () => {
    const [value,setValue] = useState()
    console.log(value,'value7');
    return (
        <>
        <h1>currency</h1>
        {/* <CurrencyFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
        {/* <CurrencyFormat thousandSeparator={true} value={value} prefix={'$'} onChange={(e) => setValue(e?.target?.value)} /> */}

        <CurrencyFormat decimalScale={12}
         format="##/##" placeholder="MM/YY" mask={['M', 'M', 'Y', 'Y']}/>
        </>
    )
}
export default CurrencyFormatType;