import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";


const MultiContacts = () => {
    const Data = [
        { Country: 'India', id: 1 },
        { Country: 'France', id: 2 },
        { Country: 'Germany', id: 3 },
        { Country: 'USA', id: 4 },
        { Country: 'England', id: 5 },
        { Country: 'Russia', id: 6 },
    ]

    const [options] = useState(Data)
    return (
        <>
            <section>
                <div className="container">
                    <div className="col-md-6">
                        <p>Multi Contacts</p>

                        <Multiselect options={options} displayValue={"Country"} />

                    </div>
                </div>
            </section>

        </>
    )
}
export default MultiContacts;