import axios from 'axios';
import { getAccessToken } from '../common';

// const axios = require('axios');
function getLocalAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken;
}
function getLocalRefreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    return refreshToken;
}
/* const instance = axios.create({
    baseURL: "http://api.dollarperfoot.in",
    headers: {
        "Content-Type": "application/json",
    },
}); */




const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});
instance.interceptors.request.use(
    (config) => {

        const token = getLocalAccessToken()

        if (token) {
            config.headers["Bearer"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {

                    const accessToken = await getLocalRefreshToken();

                    window.localStorage.setItem("accessToken", accessToken);
                    instance.defaults.headers.common["x-access-token"] = accessToken;
                    return instance(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }
        return Promise.reject(err);
    }
);


export default instance;
