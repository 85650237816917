import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
// import { ApiUrl, getToken, removeUserSession } from "../../../utils/common";
import { Slide } from 'react-slideshow-image';
import Loader from "../../Components/Loader";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
// import Loader from "../../../Components/Loader";



const SinglePageGallery = () => {
    const { state } = useLocation()
    const PropertyId = state?.id
    const navigate = useNavigate()

    const [loading, setloading] = useState(false);
    const [imageData, setImageData] = useState([]);
    console.log(imageData,'imageData');

    const [imageGet,setImageGet] = useState([])
    const [videoGet,setVideoGet] = useState([])

    // get multimedia image api

    const MultiMedia = () => {
        const Data = {}
        setloading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                // 'Authorization': `Bearer ${getToken()}`
            },

            url: `${ApiUrl()}/api/property/property-media/${PropertyId}`
        }

        axios(option)
            .then(e => {
                setloading(false)
                setImageGet(e?.data?.[1])
                setVideoGet(e?.data?.[2])
                setImageData(e?.data)
            })
            .catch(err => {
                setloading(false)
                // if (err?.request?.status === 401) {
                //     removeUserSession();
                //     navigate('/')
                //   }
            })

    }

    useEffect(() => {
        MultiMedia()
    }, [])

    const ExitButton = () => {
        navigate('/singlepage', { state: state } )
    }

    return (
        <>
            {
                loading ? <Loader /> : null
            }

            <div className="main-section-all-page">
                <div style={{ position: 'relative' }}>
                    {/* <Link to="/"> */}
                        <button type="button" className="btn btn-primary button-exit-gallery" onClick={ExitButton}>Exit</button>
                    {/* </Link> */}
                </div>

                {
                    imageGet ?  <h3>Image Section</h3> : null
                }
               
                <div className="row" style={{ marginTop: '60px' }}>
                    {
                        imageGet?.map((item) => {
                            return (
                                <div className="col-md-3">
                                    <img src={item?.media_path} className="gallery-section-image"></img>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    videoGet ?  <h3>Video Section</h3> : null
                }

                   
                <div className="row">
                    {
                        videoGet?.map((item) => {
                            return (
                                <div className="col-md-3">
                                <iframe src={item?.media_path} className="gallery-section-image"></iframe>
                            </div>  
                            )
                        })
                    }
                </div>

            </div>

        </>
    )
}
export default SinglePageGallery;