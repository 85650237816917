import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { MainApiProtectedVersion } from "../../../utils/axios/requests";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Select from "react-select";
import { format, compareAsc } from "date-fns";
import { ApiUrl, getToken } from "../../../utils/common";
import axios from "axios";
import { TurnedIn } from "@mui/icons-material";
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';

import { useForm } from "react-hook-form"
const SubscriberList = () => {


  const options = [
    { value: "0", label: "Total Subscriber" },
    { value: "1", label: "Active Subscriber" },
    { value: "2", label: "Inactive Subscriber" },
  ];
  const planOption = [
    { value: "1", label: "Silver" },
    { value: "2", label: "Gold" },
    { value: "3", label: "Platinum" },
  ];
  const usertype = [
    { value: "1", label: "Developer/Builder" },
    { value: "2", label: "Agent/Broker" },
    { value: "3", label: "Other" },
  ];
  const [region, setRegion] = useState("");
  const [configData, setConfigData] = useState([]);
  const [statusSubscriber, setStatusSubscriber] = useState("");
  const [subscriberList, setSubscriberList] = useState([]);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [subscriberTotalCount, setSubscriberTotalCount] = useState(0);

  const [subscribedUser, setSubscribedUser] = useState([]);
  /* filter user types */
  // const devSubscriber = subscribedUser.filter(user => {

  //     return user

  // });

  /* end */
  //console.log(devSubscriber,'HELLO USERs')
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterDate, setFilterDate] = useState(false);
  const [planStatus, setPlanStatus] = useState("");
  const [userType, setUserType] = useState("");
  const resetState = () => {
    if (
      filterDate ||
      region ||
      statusSubscriber ||
      loading ||
      planStatus ||
      userType
    ) {
      setEndDate("");
      setStartDate("");
      setFilterDate("");
      setStatusSubscriber("");
      setRegion("");
      setLoading(false);
      setPlanStatus("");
      setUserType("");
    }    console.log(watch())

  };


  const { register, handleSubmit,watch, errors, reset } = useForm()
  const onSubmit = data => {
    console.log(watch)
    reset()
  }

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  useEffect(() => {
    if (filterDate) {
      setEndDate(format(new Date(filterDate[1]), "dd-MM-yyyy"));
      setStartDate(format(new Date(filterDate[0]), "dd-MM-yyyy"));
    }
  }, [filterDate]);
  useEffect(() => {
    setLoading(true);
    async function getSubscriberList(data) {
      const obj = new MainApiProtectedVersion();
      const res = await obj
        .requestSubscribedData()
        .then((res) => {
          setSubscriberList(res.data.data);
          setSubscriberCount(res.data.data);
          setSubscriberTotalCount(res.data.count);

          setSubscribedUser(res.data.data.data);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
    getSubscriberList();
  }, [loading]);

  useEffect(() => {
    const ConfigDropDownData = () => {
      const option = {
        method: "GET",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
        url: `${ApiUrl()}/api/property/config-property`,
      };
      axios(option)
        .then((e) => {
          setConfigData(e?.data);
        })
        .catch((err) => {});
    };
    ConfigDropDownData();
  }, []);
  return (
    <main id="content-sub" className="bg-gray-01 pb-10">
      <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
        <div className="d-flex flex-wrap flex-md-nowrap mb-6">
          <div className="mr-0 mr-md-auto">
            {/* <h2 className="mb-0 text-heading fs-22 lh-15">Total Subscriber<span
                                className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">12</span></h2> */}
          </div>
          <div className="">
            <button
              onClick={resetState}
              className="btn bg-blue hover-saffron text-white mr-1"
            >
              Refresh
            </button>
            <button className="btn bg-blue hover-saffron text-white">
              Export to CSV
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-xxl-3 mb-6">
            {/*  <Link className='text-title' to="properties"> */}
            <div className="card">
              <div className="card-body row align-items-center px-6 py-7">
                <div className="col-5">
                  <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                    <img
                      style={{ width: "50px" }}
                      src="../images/totaluser.png"
                      alt="property-icon"
                    />
                  </span>
                </div>
                <div className="col-7 text-center">
                  <p
                    className="fs-42 lh-12 mb-0 counterup"
                    data-start="0"
                    data-end="29"
                    data-decimals="0"
                    data-duration="0"
                    data-separator=""
                  >
                    {subscriberTotalCount}
                  </p>
                  <p>
                    Total Subscribed 
                    Users
                  </p>
                </div>
              </div>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-sm-3 col-xxl-3 mb-6">
            <div className="card">
              <div className="card-body row align-items-center px-6 py-7">
                <div className="col-5">
                  <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-green badge-circle">
                    <img
                      src="../images/totaluser.png"
                      alt="property-rating"
                      style={{ width: "56px", height: "56px" }}
                    />
                  </span>
                </div>
                <div className="col-7 text-center">
                  <p
                    className="fs-42 lh-12 mb-0 counterup"
                    data-start="0"
                    data-end="1730"
                    data-decimals="0"
                    data-duration="0"
                    data-separator=""
                  >
                    {subscriberList.dev}
                  </p>
                  <p>Total Developer Subscribers</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xxl-3 mb-6">
            <div className="card">
              <div className="card-body row align-items-center px-6 py-7">
                <div className="col-4">
                  <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-yellow badge-circle">
                    <img
                      src="../images/totaluser.png"
                      alt="property-rating"
                      style={{ width: "56px", height: "56px" }}
                    />
                  </span>
                </div>
                <div className="col-8 text-center">
                  <p
                    className="fs-42 lh-12 mb-0 counterup"
                    data-start="0"
                    data-end="329"
                    data-decimals="0"
                    data-duration="0"
                    data-separator=""
                  >
                    {subscriberList.agent}
                  </p>
                  <p>
                    Total <br />
                    Agents Subscribers
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xxl-3 mb-6">
            {/* <Link className="text-title"to="lead"> */}
            <div className="card">
              <div className="card-body row align-items-center px-6 py-7">
                <div className="col-5">
                  <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                    <img
                      src="../images/totaluser.png"
                      alt="property-leads"
                      style={{ width: "56px", height: "56px" }}
                    />
                  </span>
                </div>
                <div className="col-7 text-center">
                  <p
                    className="fs-42 lh-12 mb-0 counterup"
                    data-start="0"
                    data-end="914"
                    data-decimals="0"
                    data-duration="0"
                    data-separator=""
                  >
                    {subscriberList.other}
                  </p>
                  <p>
                    Total <br /> Other Subscribers
                  </p>
                </div>
              </div>
            </div>
            {/*  </Link> */}
          </div>
        </div>
        <section className="bg-blue">
          <div className="col-12">
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
              <div className="search-ty ">
                <DateRangePicker
                  className="daterange"
                  minDetail="month"
                  maxDetail="year"
                  format="MM/yyy"
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  onChange={setFilterDate}
                  name='dateFilter'
                  {...register('dateFilter')}
                  value={filterDate}
                />
              </div>
              <div className="search-ty ">
                <Select
                  placeholder="User Status"
                  className="form-select inner-svg"
                  aria-label="Default select example"
                  options={options}
                  onChange={(e) => {
                    setStatusSubscriber(e);
                  }}
                  value={statusSubscriber}
                />
              </div>
              <div className="search-ty   ">
                <Select
                  placeholder="Region/City"
                  className="form-select inner-svg"
                  aria-label="Region/City"
                  options={configData?.city}
                  // isMulti
                  title="Region/City"
                  data-style=""
                  onChange={(e) => {
                    setRegion(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={region}
                />
              </div>
              <div className="search-ty   ">
                <Select
                  placeholder="Plan Type"
                  className="form-select inner-svg"
                  aria-label="Plan Status"
                  options={planOption}
                  // isMulti
                  title="Plan Status"
                  data-style=""
                  onChange={(e) => {
                    setPlanStatus(e);
                  }}
                  value={planStatus}
                />
              </div>
              <div className="search-ty   ">
                <Select
                  placeholder="User Type"
                  className="form-select inner-svg"
                  aria-label="User Status"
                  options={usertype}
                  // isMulticonsole

                  title="User Type"
                  data-style=""
                  onChange={(e) => {
                    setUserType(e);
                  }}
                  value={userType}
                />
              </div>
              {/* <div className="reload">
                                    <a onClick={resetState} className='btn advanced-search bg-saffron text-white hover-grey width-re-10' style={{ marginTop: '15px', padding: '12px 24px' }}>
                                        <img src="../images/reload.png" />
                                    </a>
                                </div> */}
            </div>
            </form>
          </div>
        </section>
      </div>
      <div className="container bg-white">
        <table class="table ">
          <thead>
            <tr>
              <th scope="col">User Name</th>
              <th scope="col">Email</th>
              <th scope="col">Profile</th>
              <th scope="col">Plan Status</th>
            </tr>
          </thead>
          {console.log(subscribedUser)}

          {subscribedUser?.map((item,key) => {
            // console.log(item.user.id)
            // const id = item.
            const dev = item.user.is_developer;
            const agent = item.user.is_agent;
            const other = item.user.is_otherbusiness;

            return (
              <>
                <tbody>
                  <td>{item.user.name}</td>
                  <td>{item.user.email}</td>

                  {item?.user.is_agent === true ? (
                    <td>Agent</td>
                  ) : item?.user.is_developer === true ? (
                    <td>Developer</td>
                  ) : item?.user.is_otherbusiness === true ? (
                    <td>Other Business</td>
                  ) : item?.user.is_custom_user === true ? (
                    <td>Custom User</td>
                  ) : (
                    <></>
                  )}

                  
                  <Accordion defaultActiveKey={key} flush>
                  <Accordion.Item eventKey={key}>
                    <Accordion.Header><td>{item.plan.plan_type}</td></Accordion.Header>
                    <Accordion.Body>
                    <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li">Plan : {item.plan.subscription_plan}</ListGroup.Item>
                    <ListGroup.Item as="li">Duration:{item.plan.duration}</ListGroup.Item>
                  </ListGroup>


                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
                </tbody>
              </>
            );
          })}
        </table>
      </div>
    </main>
  );
};
export default SubscriberList;
