import React, { useCallback, useEffect, useState } from "react";
import AgentHeader from "./agentheader";
import AgentSidebar from "../../Components/AgentSidebar/agentsidebar";
import axios from "axios";
import { ApiUrl, GetId, getToken } from "../../utils/common";
import _debounce from 'lodash/debounce';
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import Loader from "../../Components/Loader";
import Avatar from 'react-avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
const AgentReviews = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState()
  const GetUserId = userData?.user_id
  const [reviewDataGet, setReviewDataGet] = useState([])
  console.log(reviewDataGet, 'reviewDataGet');
  // const DateGet = reviewDataGet?.find(i => i?.id)?.created_at?.split('T')?.[0];
  // const TimeGet = reviewDataGet?.find(i => i?.id)?.created_at?.split('T')?.[1]?.split('.')?.[0];
  // const LeadDate = moment(DateGet).format("DD-MM-YYYY");
  const UserProfileData = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/user/agent-profile-get/${GetId()}`
    };
    axios(option)
      .then(e => {
        setUserData(e?.data?.[0])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const ReviewData = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/user-rating-review/${GetUserId}`
    };
    axios(option)
      .then(e => {
        setReviewDataGet(e?.data);
        setLoading(false)
      })
      .catch(err => {
        console.log(err, 'err');
        setLoading(false)
      })
  }

  useEffect(() => {
    UserProfileData()
  }, [])

  useEffect(() => {
    if (userData?.user_id) {
      ReviewData()
    }
  }, [userData])
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">

          <AgentSidebar />


          <div className="page-content">
            <AgentHeader />

            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">My Reviews<span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{reviewDataGet?.length}</span>
                    </h2>

                  </div>
                  <div>
                    <Link to="/agentpropreviews" className="btn bg-blue text-white hover-saffron btn-lg">
                      <span className="fs-18">Property Reviews</span>
                      <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                    </Link>
                  </div>
                </div>
                <div className="card border-0 mb-4 header-r-logout">

                  {
                    reviewDataGet?.map((item, key) => {
                      return (
                        <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"

                          >
                            <Typography sx={{ width: '25%', flexShrink: 0 }}>
                              <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                            </Typography>
                            <Typography sx={{ marginTop: '15px', width: '45%', color: 'text.secondary' }}>{item?.name}</Typography>
                            <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>{moment(item?.date_published).format('MMM-YYYY')}</Typography>


                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="">
                              <span className="fw-bold fs-18">Comment: </span>{item?.other_comment}<br />
                              <span className="fw-bold fs-18">Email: </span><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a><br />
                            
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })
                  }

                </div>
                <div className="card border-0 mb-4 header-user-icon">
                  <div className="card-body p-0 p-sm-8 p-4">
                    {
                      reviewDataGet?.map((item, key) => {
                        return (
                          <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"

                            >
                              <Typography sx={{ width: '10%', flexShrink: 0 }}>
                                <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                              </Typography>
                              <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{item?.name}</Typography>
                              <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a></Typography>
                              <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{moment(item?.date_published).format('DD-MMM-YYYY')}</Typography>
                              <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> <ReactStars value={item?.rating} edit={false} size={26}></ReactStars></Typography>
                              
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography className="mx-10">
                                {item?.other_comment}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default AgentReviews;