import React, { useEffect, useState } from 'react'
import axios from '../../../utils/axios/main'; // importing axios from customAxios
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Select from "react-select";
import { format, compareAsc } from 'date-fns'
import { ApiUrl, getToken } from '../../../utils/common';



export default function Userlist() {

  const [counter, setCounter] = useState(1)
  const handleClick1 = () => {
    // Counter state is incremented
    setCounter(counter + 1)
  }
  const handleClick2 = () => {
    // Counter state is decremented
    if (counter > 1) {
      setCounter(counter - 1)
    }

  }
  const resetState = () => {
    if (filterDate || region || userType || loading) {
      setEndDate('')
      setStartDate('')
      setFilterDate('')
      setRegion('')
      setPlanStatus('')
      setLoading(false)
      setUserType('')
    }
  }
  const usertype = [
    { value: '1', label: 'Developer/Builder' },
    { value: '2', label: 'Agent/Broker' },
    { value: '3', label: 'Other' },
  ]
  const [userType, setUserType] = useState('');
  const [configData, setConfigData] = useState([]);
  const [startDate, setStartDate] = useState('');
  console.log(startDate)
  const [endDate, setEndDate] = useState('');
  console.log(endDate)
  const [filterDate, setFilterDate] = useState(false);
  const [region, setRegion] = useState('')
  console.log(filterDate)
  const [planStatus, setPlanStatus] = useState('');

  useEffect(() => {
    if (filterDate) {
      setEndDate(format(new Date(filterDate[1]), 'dd-MM-yyyy'))
      setStartDate(format(new Date(filterDate[0]), 'dd-MM-yyyy'))
    }
  }, [filterDate])
  const navigate = useNavigate();
  const state = useLocation() || "";
  const assignedPlanId = state?.state?.item?.id;
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectPlanId, setselectPlanId] = useState()
  /* assign custom plan */
  useEffect(() => {
    if (assignedPlanId) {
      setselectPlanId(assignedPlanId);
    }
  }, [assignedPlanId])
  useEffect(() => {

    const ConfigDropDownData = () => {
      const option = {
        method: 'GET', headers: {
          'access-control-allow-origin': '*',
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${getToken()}`
        },
        url: `${ApiUrl()}/api/property/config-property`
      };
      axios(option)
        .then(e => {
          setConfigData(e?.data)
        })
        .catch(err => {

        })
    }
    ConfigDropDownData()
  }, [])
  const AssignCustomPlan = () => {
    if (selectPlanId && selectPlanId !== "null" && selectPlanId !== "undefined") {
      const data = {};
      data.is_custom_user = true;
      data.custom_plan = selectPlanId;
      console.log(data)

      if (selectedUser.length > 0) {
        selectedUser.forEach(async (index) => {
          const obj = new MainApiProtectedVersion('', index)
          const res = await obj.requstUserUpdateID(data).then((res) => {
            const response = res.data
            toast.success(" Selected Plan Assign Successfully")
          })
            .catch(error => {
              alert(error)
              console.log(error);

            })
          console.log(index)
        });
      } else {
        toast.warning(" Select user to assign Plan")
      }

    }
    else {
      toast.warning(" Select Plan to Assign")
    }
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,

      width: 180,
      valueGetter: (params) =>
        `${params.row.name || ''}`,
    },


    {
      field: 'profile',
      headerName: 'Profile',

      width: 110,
      editable: true,
      valueGetter: (params) =>
        `${params.row.is_developer === true && 'Developer' || params.row.is_agent === true && 'Agent' || params.row.is_otherbusiness === true && 'Other' || params.row.is_superuser === true && 'Admin'}`,
    },

    {
      field: 'email',
      headerName: 'Email',

      width: 200,
      editable: true,
    },



  ];
  const [User, setUserlist] = useState([])
  console.log(User, 'USER')

  useEffect(() => {

    const params = {
      "page": counter,
    }
    console.log(params, 'USER')

    async function UserList() {

      const obj = new MainApiProtectedVersion('', '', params)
      const res = await obj.requstUserList().then((res) => {
        setLoading(false)
        setUserlist(res.data.results)

      })
    }

    UserList()

  }, [counter]);

  return (
    <div>
      {
        loading ? <Loader /> : null
      }
      <main id="content-lead" className="bg-gray-01 pb-10">

        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">

          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              {/*  <h2 className="mb-0 text-heading fs-22 lh-15">Total Users<span
                className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{User?.length}</span></h2> */}
            </div>
            <div className=''>
              {assignedPlanId ? (<>
                <button className='btn bg-blue text-white hover-saffron mr-1' type="submit" onClick={() => AssignCustomPlan()} >Assign Plan</button>
              </>) : (<></>)}
              {/* <CSVLink className='btn bg-blue hover-saffron text-white' data={csvData}>Export to CSV</CSVLink> */}
              <button className='btn bg-blue hover-saffron text-white'>Export to CSV</button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-xxl-3 mb-6">
              {/*  <Link className='text-title' to="properties"> */}
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                      <img style={{ width: '50px' }} src="../images/totaluser.png" alt="property-icon" />
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="29" data-decimals="0"
                      data-duration="0" data-separator="">29</p>
                    <p>Total <br /> User</p>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-sm-3 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-green badge-circle">
                      <img src="../images/totaluser.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="1730" data-decimals="0"
                      data-duration="0" data-separator="">1730</p>
                    <p>Total Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-4">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-yellow badge-circle">
                      <img src="../images/totaluser.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                    </span>
                  </div>
                  <div className="col-8 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="329" data-decimals="0"
                      data-duration="0" data-separator="">329</p>
                    <p>Total <br />Agents</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xxl-3 mb-6">
              <Link className="text-title" to="lead">
                <div className="card">
                  <div className="card-body row align-items-center px-6 py-7">
                    <div className="col-5">
                      <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                        <img src='../images/totaluser.png' alt="property-leads" style={{ width: "56px", height: "56px" }} />
                      </span>
                    </div>
                    <div className="col-7 text-center">
                      <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                        data-end="914" data-decimals="0"
                        data-duration="0" data-separator="">914</p>
                      <p>Total Other User</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <section className='bg-blue'>
            <div className="col-12">
              <div className="row">
                <div className="search-tx ">
                  <DateRangePicker className="daterange" minDetail="month" maxDetail="year" format="MM/yyy" yearPlaceholder="yyyy" monthPlaceholder="mm" onChange={setFilterDate} value={filterDate} />
                </div>
                <div className="search-tx   ">
                  <Select placeholder="User Type"
                    className="form-select inner-svg" aria-label="User Status"
                    options={usertype}
                    // isMulti
                    title="User Type"
                    data-style=""
                    onChange={(e) => {
                      setUserType(e)

                    }}

                    value={userType}

                  />
                </div>
                <div className="search-tx   ">
                  <Select placeholder="Region/City"
                    className="form-select inner-svg" aria-label="Region/City"
                    options={configData?.city}
                    // isMulti
                    title="Region/City"
                    data-style=""
                    onChange={(e) => {
                      setRegion(e)

                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={region}

                  />
                </div>
                <div className="reload">
                  <a onClick={resetState} className='btn advanced-search bg-saffron text-white hover-grey width-re-10' style={{ marginTop: '15px', padding: '12px 24px' }}>
                    <img src="../images/reload.png" />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/*  <Box sx={{ height: 550, width: '100%' }}>
            <DataGrid className='grid-header'
              rows={User}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: false }}
              onSelectionModelChange={(ids) => { setSelectedUser(ids) }}
              onRowDoubleClick={(ids => {
                navigate(`${ids.id}`)
              })}
            />
          </Box> */}


        </div>
        <div className="container bg-white">
          <table class="table ">
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">Business</th>
                <th scope="col">View Profile</th>
              </tr>
            </thead>
            <tbody>
              {User?.map((item) => {
                // console.log(item.user.id)
                const id = item.id
                return (
                  <tr>
                    {/* <th scope="row">{item.id}</th> */}

                    <td>{item.name}{/* <Link to={`/admin/users/${id}`}>{item.built_by}</Link> */}</td>

                    <td>{item.email}</td>
                    {item?.is_agent === true
                      ?
                      <td>Agent</td> :
                      item?.is_developer === true ?
                        <td>Developer</td> :
                        item?.is_otherbusiness === true ?
                          <td>Other Business</td> :
                          item?.is_custom_user === true ?
                            <td>Custom User</td> : <></>
                    }
                    <td><Link className="text-blue hover-saffron-text" to={`/admin/user/details/${id}`}>Click here</Link></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='d-flex flex-wrap flex-md-nowrap mb-6'>
            <div className='wd-100'>
              <div className='f-right'>
                <button className='btn bg-blue text-white hover-saffron' onClick={handleClick2}>Prev</button>
                <div className='page-index'>{counter}</div>
                <button className='btn bg-blue text-white hover-saffron' onClick={handleClick1}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
