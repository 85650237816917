import React, { useEffect, useState } from "react";
import DevHeader from "./devheader";
import DevSidebar from "../../Components/DevSidebar/devsidebar";
import DatePicker from 'react-datepicker';
// import MonthPicker from 'month-year-picker';
// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker
// } from "@material-ui/pickers";
// import "date-fns";
import { toast } from "react-toastify";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import axios from "axios";
import Loader from "../../Components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import MyMap from "../../display";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import SearchProp from "../../Components/Map/searchprop";
import debounce from 'lodash/debounce'
import { cloneDeep } from "lodash";
//import Datetime from "react-datetime";
//import 'react-datetime/css/react-datetime.css';
import YearPicker from "react-year-picker";
import { ImCross } from 'react-icons/im';
// import AsyncSelect from 'react-select/async';
import { Button, Tab, Tabs } from "react-bootstrap";
import SearchDropdown from "../../Components/SearchDropdown";
import CurrencyFormat from "react-currency-format";
import { BsCurrencyDollar } from "react-icons/bs";
// import CurrencyFormat from 'react-currency-format';
const DevAddProp = () => {

    const [currentTab, setCurrentTab] = useState(0);
    const [value, setValue] = useState(2);
    const navigate = useNavigate();
    const State = useLocation();
    console.log(State, 'State46');
    const EditPropertyId = State?.state?.item?.id;
    const [floorPlanData, setFloorPlanData] = useState();

    // useEffect(() => {
    //     if (floorPlanData?.building_id?.length) {
    //         setCurrentTab(3);
    //     }

    // }, [floorPlanData])

    useEffect(() => {
        if (State?.state?.previousData?.id) {
            setCurrentTab(2)
        }
    }, [State])

    const PropertyPreviewData = State?.state?.profilePreview;
    const PreviousPropertyId = State?.state?.previousData?.id || EditPropertyId;
    const PropertyData = State?.state?.item;
    const CheckId = State?.state?.item?.id;
    const GetId = PropertyData?.city?.id
    const AllOptions = State?.state?.getDropwonData;
    const PreviousOptionsData = State?.state?.allOptions;

    const [showEditImageDelete, setShowEditImageDelete] = useState(false)
    const [editMediaId, setEditMediaId] = useState('')

    const [loading, setLoading] = useState(false);
    const [imgFile, setImageFile] = useState();
    const [sitePlanImage, setSitePlanImage] = useState();
    const [isMyProfile, setIsMyProfile] = useState('');
    const [isSitePlanApproved, setIsSitePlanApproved] = useState(false);
    const [isSiteProfile, setIsSiteProfile] = useState()
    const [planNotApproved, setPlanNotApproved] = useState(false);
    const [latitudeProperty, setLatitudeProperty] = useState();
    const [longitudeProperty, setLongitudeProperty] = useState();
    const [unitPricePlan, setUnitPricePlan] = useState();
    const [unitPlanImage, setUnitPlanImage] = useState();
    const [projectBrochure, setProjectBrochure] = useState()
    const [projectBrochureSubmit, setProjectBrochureSubmit] = useState()
    const [selectPlace, setSelectPlace] = useState();
    const [searchLoc, setSearchLoc] = useState(null);
    const [searchData, setSearchData] = useState([]);
    const [addressMap, setAddressMap] = useState();
    const [permittedUsedPDF, setPermittedUsedPDF] = useState();
    const [permittedUses, setPermittedUses] = useState();
    const [sitePlanPending, setSitePlanPending] = useState(false);
    const [sitePlanNotSubmit, setSitePlanNotSubmit] = useState(false);
    const [leaseRate, setLeaseRate] = useState();
    const [sitePlanCheck, setSitePlanCheck] = useState(false);

    useEffect(() => {
        if (planNotApproved) {
            toast.error("Please get site plan approved for your property listing to be added");
        }
    }, [planNotApproved])

    const CheckNotApprovedPlan = () => {
        setPlanNotApproved(!planNotApproved);

    }

    const ApprovedPlan = () => {
        setIsSitePlanApproved(!isSitePlanApproved);
    }

    // permitted uses uploader
    const PermittedUses = (e) => {
        const size = parseFloat(e?.target?.files?.[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please Select PDF size less than 2 MB")
            return;
        }
        setPermittedUsedPDF(URL.createObjectURL(e?.target?.files[0]));
        setPermittedUses(e?.target?.files[0]);
    }
    const PorfileImgHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
        setImageFile(e?.target?.files[0]);
    }

    // site pl  an image uploader
    const SitePlanImage = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setIsSiteProfile(URL.createObjectURL(e?.target?.files[0]));
        setSitePlanImage(e?.target?.files[0]);

    }

    // unit price plan uploader
    const UnitPricePlan = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setUnitPricePlan(URL.createObjectURL(e?.target?.files[0]));
        setUnitPlanImage(e?.target?.files[0]);
    }

    // project brochure
    const ProjectBrochure = (e) => {
        setProjectBrochure(URL.createObjectURL(e?.target?.files[0]));
        setProjectBrochureSubmit(e?.target?.files[0])
    }

    // append array in building type start
    const [inputList, setInputList] = useState([])
    const [numberBuilding, setNumberBuilding] = useState();
    const [dataCheckInput, setDataCheckInput] = useState();
    const HandleInputChange = (e, index) => {
        setDataCheckInput((e?.target?.value))
        const { name, value } = e?.target
        let list = Array.from(inputList);
        list[index] = {
            ...list[index],
            [name]: value
        };
        setInputList(list);
    }
    const HandleSelect = (e) => {
        setNumberBuilding(e?.target?.value)
        const finalArray = Array(Number(e?.target?.value)).fill({ name: '', no_of_units: '', no_of_floors: '' })
        setInputList(finalArray)
    }
    // append array in building type end

    // states define
    const [startDate1, setStartDate1] = useState(new Date())
    const [startDate2, setStartDate2] = useState(new Date())
    const [startDate3, setStartDate3] = useState(new Date())
    const [otherShow, setOtherShow] = useState(false)

    const [PropertyTitle, setPropertyTitle] = useState();
    const [website, setWebsite] = useState();
    const [virtuallink, setVirtualLink] = useState();
    const [description, setDescription] = useState();
    const [propertyType, setPropertyType] = useState();
    const [propertySize, setPropertySize] = useState();
    const [floor, setFloors] = useState();
    // const [label, setLabel] = useState();
    const [Price, setPrice] = useState();

    const [priceTo, setPriceTo] = useState()
    const [pricePer, setPricePer] = useState();
    const [maintenanceFee, setMaintenanceFee] = useState();
    const [propertyStatus, setPropertyStatus] = useState();
    const [sellingStatus, setSellingStatus] = useState();
    // const [propertyImg, setPropertyImg] = useState();
    const [address, setAddress] = useState(null);
    const [country, setCountry] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [zipCode, setZipCode] = useState()
    const [currency, setCurrency] = useState();
    const [builtBy, setBuiltBy] = useState()
    const [buildEmail, setBuiltEmail] = useState()
    const [Ammenities, setAmmenities] = useState([])
    const [nounits, setNoUnits] = useState()
    const [builtYear, setBuiltByyear] = useState();
    const [permittedUsers, setPermittedUsers] = useState();
    const [propertyLabels, setPropertyLabels] = useState();
    const [searchValue, setSearchValue] = useState();
    // get dropdown api field
    const [getDropwonData, setGetDropdownData] = useState();
    const EstdCompletion = moment(startDate3).format('YYYY-MM-DD')
    const ConstructionStartDate = moment(startDate2).format('YYYY-MM-DD')
    // update property fields
    const PropertyLabels = propertyLabels?.map(i => ({ id: i?.id }))
    const Labels = JSON.stringify(PropertyLabels)
    const property_types = propertyType?.map(i => ({ id: i?.id }))
    const propertytype = JSON.stringify(property_types)
    const amenities_value = Ammenities?.map(i => ({ id: i?.id }))
    const amenities = JSON.stringify(amenities_value);
    const permitted_uses = permittedUsers?.map(i => ({ id: i?.id }))
    const permitted = JSON.stringify(permitted_uses);
    // create floor plans states
    const [buildingId, setBuildingId] = useState()
    const [numberBuildingFloor, setNumberBuildingFloor] = useState();
    const [inputListFloor, setInputListFloor] = useState()
    useEffect(() => {

        if (PropertyData?.id) {
            setPropertyTitle(PropertyData?.name)
            setWebsite(PropertyData?.website) 
            setVirtualLink(PropertyData?.virtuallink ||'')
            setBuiltBy(PropertyData?.built_by)
            setBuiltEmail(PropertyData?.built_by_email)
            setBuiltByyear(PropertyData?.year_built)
            setDescription(PropertyData?.description)
            setPrice(PropertyData?.price_from)
            setPriceTo(PropertyData?.price_to)
            setPricePer(PropertyData?.price_per_sqft)
            setMaintenanceFee(PropertyData?.maintenance_fee)
            setCurrency(AllOptions?.currency?.find(i => i?.id === PropertyData?.currency?.id)?.id)
            setSellingStatus(AllOptions?.SellingStatus?.find(i => i?.id == PropertyData?.selling_status?.id)?.id)
            setPropertyStatus(AllOptions?.ConstructionStatus?.find(i => i?.id == PropertyData?.construction_status?.id)?.id)
            // setSellingStatus(PreviousOptionsData?.SellingStatus?.find(i => i?.id == PropertyPreviewData?.sellingStatus)?.id)
            // setPropertyStatus(PreviousOptionsData?.propertyType?.find(i => i?.id == PropertyPreviewData?.propertyStatus)?.id)


            setPropertyType(AllOptions?.propertyType?.filter(i => PropertyData?.property_types?.find(item => i?.id === item?.id)))
            setPermittedUsers(AllOptions?.permitted_use?.filter(i => PropertyData?.permitted_uses?.find(item => i?.id === item?.id)))
            setPropertyLabels(AllOptions?.labels?.filter(i => PropertyData?.labels?.find(item => i?.id === item?.id)))
            setPropertySize(PropertyData?.size)
            setFloors(PropertyData?.no_of_stories)
            setNoUnits(PropertyData?.no_of_units)
            setStartDate1(new Date(PropertyData?.sale_start))
            setStartDate2(new Date(PropertyData?.construction_start_date))
            setIsMyProfile(PropertyData?.featured_image)
            // setImageFile(PropertyData?.featured_image)
            setIsSitePlanApproved(PropertyData?.is_site_plan_approved)
            setAddress(PropertyData?.street_name)
            setCountry(AllOptions?.country?.find(i => i?.id === PropertyData?.country?.id)?.id)
            // setCountry(PropertyData?.country)
            setState(AllOptions?.state?.find(i => i?.id === PropertyData?.state?.id)?.id)
            // setState(PropertyData?.state)
            setCity(AllOptions?.city?.find(i => i?.id === PropertyData?.city?.id)?.id)
            // setCity(PropertyData?.city)
            setAmmenities(AllOptions?.Amenities?.filter(i => PropertyData?.amenities_value?.find(item => i?.id === item?.id)))
            setUnitPricePlan(PropertyData?.unit_price_plan)
            setIsSiteProfile(PropertyData?.site_plan)
            setProjectBrochure(PropertyData?.project_brochure)
            setZipCode(PropertyData?.post_code)
            setNumberBuilding(PropertyData?.no_of_buildings)
            setLatitudeProperty(PropertyData?.lat)
            setLongitudeProperty(PropertyData?.lng)
            setLeaseRate(PropertyData?.lease_rate)
            setInputList(PropertyData?.property_building)
            setPermittedUsedPDF(PropertyData?.permitted_uses_image)

        }
    }, [PropertyData?.id])

    const PropertyDropdowns = () => {
        setLoading(true)
        const data = {}

        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/property/config-property`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                setGetDropdownData(e?.data)

            })
            .catch(err => {
                setLoading(false)
            });
    }

    useEffect(() => {
        PropertyDropdowns()
    }, [])
    const UpdateProperty = () => {
        const Data = {}
        const formData = new FormData();
        formData.append('id', PropertyData?.id)
        formData.append('name', PropertyTitle);
        formData.append('selling_status', sellingStatus);
        formData.append('website', website);
        formData.append('virtuallink', virtuallink);
        formData.append('description', description);
        formData.append('sale_start', moment(startDate1).format('YYYY-MM-DD'));
        formData.append('maintenance_fee', maintenanceFee);
        formData.append('construction_start_date', moment(startDate2).format('YYYY-MM-DD'));
        formData.append('city', city);
        formData.append('state', state);
        formData.append('country', country);
        formData.append('street_name', address);
        formData.append('currency', currency);
        formData.append('price_from', Price);
        formData.append('built_by', builtBy);
        formData.append('size', propertySize);
        formData.append('price_to', priceTo);
        formData.append('post_code', zipCode);
        formData?.append('no_of_buildings', numberBuilding);
        formData.append('is_site_plan_approved', isSitePlanApproved);
        formData.append('year_built', builtYear);

        if (inputList?.length) {
            formData.append('property_building', JSON.stringify(inputList))
        }

        if (permittedUsers?.length) {
            permittedUsers.map((item) => {
                formData.append('permitted_uses', item?.id)
            })

        }
        if (propertyLabels?.length) {
            propertyLabels?.map((item) => {
                formData.append('labels', item?.id)
            })
        }
        if (Ammenities?.length) {
            Ammenities?.map((item) => {
                formData.append('amenities_value', item?.id);
            })

        }
        if (propertyType?.length) {
            propertyType?.map((item) => {
                formData.append('property_types', item?.id);
            })

        }
        if (imgFile) {
            formData.append('featured_image', imgFile);
        }

        if (sitePlanImage) {
            formData.append('site_plan', sitePlanImage);
        }
        if (unitPlanImage) {
            formData.append('unit_price_plan', unitPlanImage);
        }
        if (projectBrochureSubmit) {
            formData.append('project_brochure', projectBrochureSubmit)
        }
        const option = {
            method: 'PUT', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/api/property/create-property/${CheckId}`
        }
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    setCurrentTab(2, { state: e?.data })

                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.post_code?.[0]);
            })
    }


    // validations of input fields
    const ValidateFields = () => {
        const isLease = propertyLabels?.find(i => i?.id === 1);
        const isSale = propertyLabels?.find(i => i?.id === 2);
        if (!propertyType) {
            toast.error("Please Select Property Type")
            return false;
        }
        // if (!permittedUsers) {
        //     toast.error("Please Select Permitted Users")
        //     return false;
        // }
        if (!propertyLabels?.length) {
            toast.error("Please Select Property Labels")
            return false;
        }
        if (!Price && isSale) {
            toast.error("Please Enter Price From")
            return false;
        }
        if (Price === priceTo && isSale) {
            toast.error("Price From and Price To Should not be same")
            return false;
        }
        if (Price > priceTo && isSale) {
            toast.error("Please Enter Property Price From Less Than Price To")
            return false;
        }
        if (!priceTo && isSale) {
            toast.error("Please Enter Price To")
            return false;
        }
        if (!leaseRate && isLease) {
            toast.error("Please Enter Lease Price")
            return false;
        }
        if (!currency) {
            toast.error("Please Select Currency")
            return false;
        }
        if (!propertyStatus) {
            toast.error("Please Select Property Status")
            return false;
        }
        if (!sellingStatus) {
            toast.error("Please Select Selling Status")
            return false;
        }
        if (!propertySize) {
            toast.error("Please Select Property Size")
            return false;
        }
        if (!numberBuilding) {
            toast.error("Please Select No. Of Buildings")
            return false;
        }
        // if (!dataCheckInput || dataCheckInput?.trim === '') {
        //     toast.error("Please Enter Building Detail")
        //     return false;
        // }
        // if (!address) {
        //   toast.error("Please Enter Address")
        //   return false;
        // }
        // if (!country) {
        //   toast.error("Please Enter Country")
        //   return false;
        // }
        // if (!state) {
        //   toast.error("Please Enter State")
        //   return false;
        // }
        // if (!city) {
        //   toast.error("Please Enter City")
        //   return false;
        // }
        // if (!zipCode) {
        //   toast.error("Please Enter Postal Code")
        //   return false;
        // }
        return true;
    }

    const onAmenities = (item) => {
        let arr = [...Ammenities]
        const findIndex = Ammenities?.findIndex(i => i?.id === item?.id)
        const findItem = Ammenities?.find(i => i?.id === item?.id)
        if (findItem) {
            if (findIndex > -1) {
                arr?.splice(findIndex, 1)
                setAmmenities(arr)
            }
        } else {
            setAmmenities([...Ammenities, item])
        }

    }
    const Data = {
        PropertyTitle: PropertyTitle,
        website: website,
        virtuallink: virtuallink,
        description, description,
        propertyType: propertyType,
        propertySize: propertySize,
        floor: floor,
        Price: Price,
        pricePer: pricePer,
        maintenanceFee: maintenanceFee,
        maintenanceFee: maintenanceFee,
        propertyStatus: propertyStatus,
        sellingStatus: sellingStatus,
        address: address || searchValue,
        country: country,
        state: state,
        city: city,
        zipCode: zipCode,
        currency: currency,
        builtBy: builtBy,
        buildEmail: buildEmail,
        Ammenities: Ammenities,
        nounits: nounits,
        builtYear: builtYear,
        startDate1: startDate1,
        startDate2: startDate2,
        startDate3: startDate3,
        imgFile: imgFile,
        isSitePlanApproved: isSitePlanApproved,
        sitePlanImage: sitePlanImage,
        isMyProfile: isMyProfile,
        isSiteProfile: isSiteProfile,
        permittedUses: permittedUses,
        permittedUsedPDF: permittedUsedPDF,
        propertyLabels: propertyLabels,
        projectBrochure: projectBrochure,
        unitPricePlan: unitPricePlan,
        unitPlanImage: unitPlanImage,
        projectBrochureSubmit: projectBrochureSubmit,
        priceTo: priceTo,
        EstdCompletion: EstdCompletion,
        ConstructionStartDate: ConstructionStartDate,
        inputList: inputList,
        numberBuilding: numberBuilding,
        latitudeProperty: latitudeProperty,
        longitudeProperty: longitudeProperty,
        addressMap: addressMap,
        sitePlanPending: sitePlanPending,
        sitePlanNotSubmit: sitePlanNotSubmit,
        leaseRate: leaseRate,
    }


    const DetailNext = () => {
        if (!ValidateFields()) return
        navigate('/devpropertypreview', { state: { Data: Data, getDropwonData: getDropwonData } })
        // setCurrentTab((prev) => prev + 1)
    }

    // const Preview = () => {
    //     if (!ValidateFields()) return;
    //     navigate('/devpropertypreview', { state: { Data: Data, getDropwonData: getDropwonData } })
    // }
    const searchAddress = [selectPlace?.address];
    useEffect(() => {
        if (selectPlace?.length) {
            const country = getDropwonData?.country?.find(item => item?.name === searchAddress[0]?.country)
            const state = getDropwonData?.state?.find(item => item?.name === searchAddress[0]?.state)
            const city = getDropwonData?.city?.find(item => item?.name === searchAddress[0]?.state)
            setCountry(country)
            setState(state)
            setCity(city)
            setZipCode(searchAddress[0]?.postcode)
            setAddress(searchAddress[0]?.road || searchAddress[0]?.leisure)
            setAddress(searchAddress[0]?.road)
            setLatitudeProperty(selectPlace?.lat)
            setLongitudeProperty(selectPlace?.lat)
        }

    }, [searchAddress])
    var debounce_fun = debounce(function () {
        if (searchValue?.length) {
            Getlocation()
        }

    }, 1000);

    const onAddressChange = (e) => {
        setSearchValue(e?.target?.value)
        setAddress(e?.target?.value)
        debounce_fun();
    }
    const Getlocation = () => {
        const requestOption = {
            method: "GET", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `https://nominatim.openstreetmap.org/search?q=1+${searchValue}&format=json&polygon_geojson=1&addressdetails=1`
        };
        axios(requestOption)
            .then(e => {
                setSearchData(e?.data)
            })
            .catch(err => {
            })
    }
    /* multiimage uploader */
    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    // const { state } = useLocation()
    const PropertyId = state?.item?.id;
    const [isOpen, setIsOpen] = useState(false);
    // const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState([]);
    console.log(selectedImage, 'selectedImage');
    const [imageData, setImageData] = useState([])
    const [selectedVideo, SetSelectedVideo] = useState('');

    const [videoPreview, setVideoPreview] = useState()
    const ImageHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        if (e?.target?.files) {
            const fileArray = Array.from(e?.target?.files).map((file) => URL.createObjectURL(file))
            const fileImage = Array.from(e?.target?.files).map((file) => file)


            if (fileArray?.length > 20) {
                toast.error("You Can Maximum 20 Images Send")
                return;
            }

            setSelectedImage((prevImages) => prevImages.concat(fileArray))
            setImageData((prevImages) => prevImages.concat(fileImage))
            Array.from(e?.target?.files).map(
                (file) => URL.revokeObjectURL(file)

            )
        }
    }
    const VideoHandler = (e) => {
        const videoSize = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(20);
        if (videoSize > 20) {
            toast.error("Please select Video size less than 20 MB")
            return;
        }
        SetSelectedVideo(URL.createObjectURL(e?.target?.files[0]))
        setVideoPreview(e?.target?.files[0])
    }
    const renderPhotos = (source) => {
        return source.map((photo) => {
            return (
                <>
                    <div className="previewimgmuploader">
                        <img src={photo} key={photo} />

                        <span className="services-add-preview"
                            onClick={() => onRemove(photo)}><ImCross className="cross-icon-service" /></span>
                    </div>
                </>
            )
        })
    }

    const renderEditPhotos = (source) => {
        return mediaData?.map((photo) => {
            return (
                <div className="previewimgmuploader">
                    <img src={photo?.media_path} key={photo} />

                    <span className="services-add-preview"
                        onClick={() => onRemoveEdit(photo)}><ImCross className="cross-icon-service" /></span>
                </div>
            )
        })
    }

    const onRemove = (val) => {
        let arr = [...selectedImage]

        const Index = arr.indexOf(val);
        if (Index > -1) {
            arr.splice(Index, 1);
        }
        setSelectedImage(arr)
    }


    const onRemoveEdit = (photo) => {
        setShowEditImageDelete(true)
        setEditMediaId(photo?.id)

        // let arr = [...mediaData]

        // const filterArray = arr.filter(i => i?.id !== val?.id);
        // if (Index > -1) {
        //     arr.splice(Index, 1);
        // }
        // setMediaData(filterArray)
    }



    const OnVideoRemove = (val) => {
        SetSelectedVideo()
    }

    // images post API
    const ImageSubmit = (PreviousPropertyId) => {

        imageData?.map((item) => {
            const data = {}
            const formData = new FormData()
            formData.append('media_type', 1)

            // formData.append('media_path', fileArray)
            formData.append('property_id', PreviousPropertyId);
            if (selectedImage) {
                formData.append('media_path', item)
            }
            const option = {
                method: 'POST', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${getToken()}`
                },
                data: formData,
                url: `${ApiUrl()}/api/property/property-media/${PreviousPropertyId}`
            };
            axios(option)
                .then(e => {
                    toast.success("Images Added Sucessfully")
                })
                .catch(err => {
                    if (err?.request?.status === 401) {
                        removeUserSession();
                        navigate('/')
                    }
                })
            // setCurrentTab((prev) => prev + 1)
            setCurrentTab(3)
            // navigate('/developersingleproperty', { state: `${state?.item?.id}` })
            // navigate('/devmyproperties')
        })
    }


    const VideoSubmit = () => {
        const formData = new FormData();
        formData.append('media_type', 2);
        formData.append('property_id', PreviousPropertyId);
        if (selectedVideo) {
            formData.append('media_path', videoPreview);
        }

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/api/property/property-media/${PreviousPropertyId}`
        };
        axios(option)
            .then(e => {
                toast.success("Video Added Succesfully")
            })
            .catch(err => {
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            })
        setCurrentTab(3)

        // navigate('/developersingleproperty', { state: `${state?.item?.id}` })
        // navigate('/devmyproperties')
        /* end multiimage uploader */
    }

    // delete media api

    const DeleteMedia = () => {
        const option = {
            method: 'DELETE', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/property-media/${editMediaId}`
        };
        axios(option)
            .then(e => {
                setShowEditImageDelete(false)
                GetMedia()
                // console.log(e,'e807');
            })
            .catch(err => {
                console.log(err, 'err810');
            })
    }


    const DescriptionNext = () => {

        if (!PropertyTitle || PropertyTitle.trim() === "") {
            toast.error("Please Enter Property Name");
            return;
        }
        if (!isSitePlanApproved && !sitePlanPending && !sitePlanNotSubmit) {
            toast.error("Please Select Site Plan")
            return false;
        }
        // if (!isSiteProfile ) {
        //     toast.error("Upload Site Plan Image")
        //     return;
        // }
        // if (!imgFile) {
        //     toast.error("Please Upload Featured Image")
        //     return;
        // }
        window?.scrollTo(0, 0);
        setCurrentTab((prev) => prev + 1)

    }

    const ValidateFieldsFloorPlan = () => {
        if (!numberBuildingFloor) {
            toast.error("Please Select Building")
            return false;
        }
        if (!inputListFloor?.[0]?.floorNo) {
            toast.error("Please Add Floor Number")
            return false;
        }
        if (!imageFloorPlan) {
            toast.error("Please Select Image")
            return false;
        }
        return true;
    }

    const [imageFloorPlan, setImageFloorPlan] = useState()
    const [floorImageBlob, setFloorImageBlob] = useState()
    const PorfileImgHandlerFloor = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setImageFloorPlan(URL.createObjectURL(e?.target?.files[0]));
        setFloorImageBlob(e?.target?.files[0])
    }
    const HandleInputChangeFloor = (e, index, file) => {
        // setBuildingName(e?.target?.value)
        // setFloorNo(e?.target?.value)
        // setDescription(e?.target?.value)
        // setDataCheckInput((e?.target?.value))
        console.log(e, 'e');
        const { name, value } = e?.target
        let imageFile
        if (file) {
            imageFile = e?.target?.files?.[0]
        }
        let list = Array.from(inputListFloor);
        list[index] = {
            ...list[index],
            [name]: file ? imageFile : value
        };
        setInputListFloor(list);
    }

    const HandleSelectFloorPlan = (e) => {
        setNumberBuildingFloor(e?.target?.value)
        setBuildingId(e?.target?.value)
        // setFloorNo(e?.target?.value)
        // setDescription(e?.target?.value)
        const finalArray = Array(Number(1)).fill({ floorNo: '', description: '', file: '' })
        setInputListFloor(finalArray)

    }


    const SubmitFloorPlan = () => {
        if (!ValidateFieldsFloorPlan()) return;
        // setLoading(true)
        const data = {}
        const formData = new FormData()
        formData.append('description', inputListFloor?.[0]?.description)
        formData.append('floor', inputListFloor?.[0]?.floorNo)
        formData.append('property_id', PreviousPropertyId)
        formData.append('building_id', buildingId)
        if (floorImageBlob) {
            formData.append('media_path', floorImageBlob)
        }
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/api/property/property-floor-plan/${PreviousPropertyId}`
        };
        axios(option)
            .then(e => {

                // toast.success("Floor plan added. Go to My Properties-> Add Floor Plan to add more floor plans after you submit the listing.");
                toast.success("Floor plans submitted successfully")
                setNumberBuildingFloor([])
                setBuildingId('')
                setInputListFloor([])
                setImageFloorPlan('')
                setFloorImageBlob('')

            })
            .catch(err => {

            })
    }

    // multi contacts states
    const [builder, setBuilder] = useState([]);
    const [architect, setArchitect] = useState([])
    const [marketingCompany, setMarketingCompany] = useState([])
    const [salesCompany, setSalesCompany] = useState([])
    const [interiorDesigner, setInteriorDesigner] = useState([])

    const [options, setOptions] = useState([]);
    const [builderOptions, setBuilderOptions] = useState([]);
    const [agent, setAgent] = useState([]);
    const [agentSearchdata, setAgentSearchData] = useState([]);


    const [typeModal, setTypeModal] = useState('');
    const [isAddNew, setIsAddNew] = useState(false);
    const HandleClose = () => setIsAddNew(false);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [designation, setDesignation] = useState();
    const allDropDownData = [...builder, ...architect, ...marketingCompany, ...salesCompany, ...interiorDesigner, ...agent]

    const CreateUserValidate = () => {
        if (!name || name.trim() === "") {
            toast.error("Please Enter Name")
            return false
        }
        if (!designation || designation?.trim() === "") {
            toast.error("Please Enter Designation")
            return false
        }

        if (!email) {
            toast.error("Please Enter Email")
            return false
        }
        return true
    }
    const ContactProperty = () => {
        // if (!builder?.length && !architect?.length && !marketingCompany?.length && !salesCompany?.length && !interiorDesigner?.length) {
        //     toast.error("Please Add Atleast One Contact To Submit");
        //     return
        // }

        // if (!ValidateFields()) return;
        const body = allDropDownData?.map(item => ({
            name: `${item?.first_name}`,
            // email: item?.email,
            // ${item?.last_name}
            email: item?.email || email,
            builder: item?.type?.includes('Builder'),
            architect: item?.type?.includes('Architect'),
            marketing_company: item?.type?.includes('Marketing'),
            sales_company: item?.type?.includes('Sales'),
            interior_designer: item?.type?.includes('interior'),
            agent_key: item?.type?.includes('agent'),
            designation: item?.designation,
            user_id: item?.user_id,
            property_id: PreviousPropertyId
        }))
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: JSON.stringify(body),
            url: `${ApiUrl()}/api/property/add-property-team`


        };
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    toast.success("Property Contact Add Successfully");
                    // GetProperty();
                    setBuilder([]);
                    setArchitect([]);
                    setMarketingCompany([]);
                    setSalesCompany([]);
                    setInteriorDesigner([]);
                    navigate('/devmyproperties')
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.[0].email?.[0])
                if (err?.response?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }


            })

    }

    const AgentSearch = (e) => {
        const data = (e != null) ? e : ""
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: { "data": data },
            url: `${ApiUrl()}/auth/user/filter-location-agent?first_name=${data}`
        };
        axios(option)
            .then(e => {
                console.log(e, 'e263');
                setAgentSearchData(e?.data)
            })
            .catch(err => {
                console.log(err, 266);
            })
    }

    const onSearch = (e) => {
        const data = (e != null) ? e : ""
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: { "data": data },
            // url: `${ApiUrl()}/auth/user/multi-contact-search?search=${e}`
            url: `${ApiUrl()}/auth/user/filter-location-other?first_name=${data}`

        };
        axios(option)
            .then(e => {
                setOptions(e?.data)
            })
            .catch(err => {

            })
    }

    const builderOnSearch = (e) => {
        const data = (e != null) ? e : ""
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: { "data": data },
            // url: `${ApiUrl()}/auth/user/multi-contact-search?search=${e}`
            url: `${ApiUrl()}/auth/user/filter-location-dev?first_name=${data}`
        };
        axios(option)
            .then(e => {
                setBuilderOptions(e?.data)
            })
            .catch(err => {

            })

    }

    const createUser = () => {
        if (!CreateUserValidate()) return;
        const data = {
            first_name: name,
            email: email,
            designation: designation,
            type: typeModal,
        }
        toast.warning("Search for the new contact just added")
        if (typeModal === 'Builder') {
            setBuilder([...builder, data]);
        }
        else if (typeModal === 'Architect') {
            setArchitect([...architect, data])
        }
        else if (typeModal === 'Marketing') {
            setMarketingCompany([...marketingCompany, data])
        }
        else if (typeModal === 'Sales') {
            setSalesCompany([...salesCompany, data])
        }
        else if (typeModal === 'interior') {
            setInteriorDesigner([...interiorDesigner, data])
        }
        else {
            setAgent([...agent, data])
        }
        // else if ()
        setIsAddNew(false)
        setName('')
        setEmail('')
        setDesignation('')
    }

    // property preview data
    useEffect(() => {
        if (State?.state?.profilePreview?.PropertyTitle) {
            setCurrentTab(1)
            setPropertyTitle(PropertyPreviewData?.PropertyTitle)
            setWebsite(PropertyPreviewData?.website)
            setVirtualLink(PropertyPreviewData?.virtuallink)
            setBuiltBy(PropertyPreviewData?.builtBy)
            setBuiltEmail(PropertyPreviewData?.buildEmail)
            setBuiltByyear(PropertyPreviewData?.builtYear)
            setDescription(PropertyPreviewData?.description)
            setPrice(PropertyPreviewData?.Price)
            setPriceTo(PropertyPreviewData?.priceTo)
            setPricePer(PropertyPreviewData?.pricePer)
            setMaintenanceFee(PropertyPreviewData?.maintenanceFee)
            setCurrency(PreviousOptionsData?.currency?.find(i => i?.id == PropertyPreviewData?.currency)?.id)
            setSellingStatus(PreviousOptionsData?.SellingStatus?.find(i => i?.id == PropertyPreviewData?.sellingStatus)?.id)
            setPropertyStatus(PreviousOptionsData?.propertyType?.find(i => i?.id == PropertyPreviewData?.propertyStatus)?.id)
            setPropertyType(PreviousOptionsData?.propertyType?.filter(i => PropertyPreviewData?.propertyType?.find(item => i?.id === item?.id)))
            setPermittedUsers(PreviousOptionsData?.permitted_use?.filter(i => PropertyPreviewData?.permittedUsers?.find(item => i?.id === item?.id)))
            setPropertyLabels(PreviousOptionsData?.labels?.filter(i => PropertyPreviewData?.propertyLabels?.find(item => i?.id === item?.id)))
            setPropertySize(PropertyPreviewData?.propertySize)
            setFloors(PropertyPreviewData?.no_of_stories)
            setNoUnits(PropertyPreviewData?.no_of_units)
            setStartDate1(new Date(PropertyPreviewData?.startDate1))
            setStartDate2(new Date(PropertyPreviewData?.startDate2))
            setIsMyProfile(PropertyPreviewData?.isMyProfile)
            setIsSitePlanApproved(PropertyPreviewData?.isSitePlanApproved)
            setAddress(PropertyPreviewData?.street_name)
            setCountry(PreviousOptionsData?.country?.find(i => i?.id === PropertyPreviewData?.country?.id)?.id)
            setState(PreviousOptionsData?.state?.find(i => i?.id === PropertyPreviewData?.state?.id)?.id)
            setCity(PreviousOptionsData?.city?.find(i => i?.id === PropertyPreviewData?.city?.id)?.id)
            setAmmenities(PreviousOptionsData?.Amenities?.filter(i => PropertyPreviewData?.Ammenities?.find(item => i?.id === item?.id)))
            setUnitPricePlan(PropertyPreviewData?.unitPricePlan)
            setIsSiteProfile(PropertyPreviewData?.isSiteProfile)
            setProjectBrochure(PropertyPreviewData?.projectBrochure)
            setZipCode(PropertyPreviewData?.post_code)
            setNumberBuilding(PropertyPreviewData?.numberBuilding)
            setLatitudeProperty(PropertyPreviewData?.lat)
            setLongitudeProperty(PropertyPreviewData?.lng)
            setInputList(PropertyPreviewData?.inputList)
            setAddressMap(PropertyPreviewData?.addressMap)
            setImageFile(PropertyPreviewData?.imgFile)
            setProjectBrochureSubmit(PropertyPreviewData?.projectBrochureSubmit)
            setSitePlanImage(PropertyPreviewData?.sitePlanImage)
            setUnitPlanImage(PropertyPreviewData?.unitPlanImage)
            setPermittedUsedPDF(PropertyPreviewData?.permittedUsedPDF)
            setSitePlanPending(PropertyPreviewData?.sitePlanPending)
            setSitePlanNotSubmit(PropertyPreviewData?.sitePlanNotSubmit)
            setLeaseRate(PropertyPreviewData?.leaseRate)
        }
    }, [State])

    const RedirectToDescription = () => {
        setCurrentTab(0)
    }


    const [mediaData, setMediaData] = useState();
    console.log(mediaData, 'mediaData');
    const GetMedia = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
            },
            url: `${ApiUrl()}/api/property/property-media/${EditPropertyId}`
        };
        axios(option)
            .then(e => {
                setMediaData(e?.data?.[1])
            })
            .catch(err => {

            })
    }

    useEffect(() => {
        if (EditPropertyId) {
            GetMedia()
        }
    }, [EditPropertyId])


    const NextFloorPlan = () => {
        setCurrentTab(4)
    }

    const RedirectToFloorPlan = () => {
        setCurrentTab(3)
    }
    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">

                    <DevSidebar />
                    <div className="page-content">
                        <DevHeader />
                        <main id="content" className="bg-gray-01">
                            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
                                <div className="mb-6 margin-re-top">
                                    <h2 className="mb-0 text-heading fs-22 lh-15">Add new property
                                    </h2>

                                </div>
                                <Tabs activeKey={currentTab} id="controlled-tab-example" className="add-prop-tabs">
                                    <Tab eventKey={0} title="Description" disabled={currentTab !== 0} className="text-blue">
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body py-4 py-md-0 px-0">
                                                <div className="card mb-6">
                                                    <div className="card-body p-6">
                                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">Property Description</h3>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="title" className="text-heading margin-b-0">Property Name <span className="star-mandatoryfield">*</span></label>
                                                                    <input type="text"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="title" name="title" onChange={(e) => setPropertyTitle(e?.target?.value)} value={PropertyTitle} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="builtby" className="text-heading">Developer Name</label>
                                                                    <input type="text"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="title" name="name" onChange={(e) => setBuiltBy(e?.target?.value)} value={builtBy} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="builtby" className="text-heading">Email</label>
                                                                    <input type="email"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="title" name="email" onChange={(e) => setBuiltEmail(e?.target?.value)} value={buildEmail} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="builtby" className="text-heading">Built By Year</label>
                                                                    <YearPicker onChange={(e) => setBuiltByyear(e)} defaultValue={builtYear} className="form-control form-control-lg border-0" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="title" className="text-heading">Website</label>
                                                                    <input type="url"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="website" name="website" placeholder="https://google.com" onChange={(e) => setWebsite(e?.target?.value)} value={website} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="title" className="text-heading">Virtual Tour Link</label>
                                                                    <input type="url"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="virtuallink" name="virtuallink" placeholder="https://google.com" onChange={(e) => setVirtualLink(e?.target?.value)} value={virtuallink} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8">
                                                                <div className="form-group">
                                                                    <label htmlFor="description-01"
                                                                        className="text-heading">Description</label>
                                                                    <textarea className="form-control border-0"
                                                                        name="description" rows="6"
                                                                        id="description-01" onChange={(e) => setDescription(e?.target?.value)} value={description}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="card mb-6">
                                                            <div className="card-body p-6">
                                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Site Plans <span className="star-mandatoryfield">*</span></h3>
                                                                <div className="form-group">


                                                                    <Form.Check
                                                                        type={'radio'}
                                                                        // id="custom-switch"
                                                                        name="group6"
                                                                        label="Is Site Plan Approved"
                                                                        id={`inline-${'radio'}-6`}
                                                                        // checked={isSitePlanApproved}
                                                                        onChange={ApprovedPlan}
                                                                        onClick={() => setSitePlanCheck(true)}
                                                                    />

                                                                    {
                                                                        sitePlanCheck ?
                                                                            <div>
                                                                                {
                                                                                    sitePlanImage || isSiteProfile ? <img src={isSiteProfile} alt="image" className="w-100 add-border-other" /> : null
                                                                                }
                                                                                <input type="file" className="custom-file-input add-pstion-abs" id="SitePlan" name="SitePlan" accept="image/*,.pdf,.doc" onChange={SitePlanImage} />
                                                                                <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="SitePlan">
                                                                                    <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Site Plan</label>
                                                                            </div> : null
                                                                    }

                                                                    <Form.Check
                                                                        type={'radio'}
                                                                        name="group6"
                                                                        label="Site Plan Pending Approval"
                                                                        id={`inline-${'radio'}-6`}
                                                                        onChange={() => setSitePlanPending(!sitePlanPending)}
                                                                        onClick={() => setSitePlanCheck(false)}
                                                                    />
                                                                    <Form.Check
                                                                        type={'radio'}
                                                                        name="group6"
                                                                        label="Site Plan Not Submited"
                                                                        id={`inline-${'radio'}-6`}
                                                                        onChange={() => setSitePlanNotSubmit(!sitePlanNotSubmit)}
                                                                        onClick={() => setSitePlanCheck(false)}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-6">
                                                            <div className="card-body p-6">
                                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Upload Property Featured Image <span className="star-mandatoryfield">*</span></h3>
                                                                <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">
                                                                    {
                                                                        imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                                                    }

                                                                    <div className="custom-file mt-4 h-auto margin-top-bottom">
                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="featuredImage" name="featuredImage" accept="image/*" onChange={PorfileImgHandler} />
                                                                        <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="featuredImage">
                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                                            featured image</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">

                                                                    <div className="custom-file mt-4 h-auto margin-top-bottom">
                                                                        {
                                                                            projectBrochureSubmit || projectBrochure ? <iframe src={projectBrochure} alt="image" className="w-100 add-border-other" style={{ marginBottom: '20px' }} /> : null
                                                                        }
                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="ProjectBrochure" name="ProjectBrochure" accept="application/pdf" onChange={ProjectBrochure} />
                                                                        <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="ProjectBrochure">
                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Project Brochure (PDF)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="card mb-6">
                                                            <div className="card-body p-6">
                                                                <h3 className="card-title mb-6 text-heading fs-22 lh-15">Enter Property Location <span className="star-mandatoryfield">*</span></h3>

                                                                <div className="main-wrapper">
                                                                    <MyMap selectedData={(e) => setAddressMap(e)} addressMap={addressMap} propertyData={PropertyData} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap">
                                                    {/* <a href="#"
                                                            className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                                            <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                                className="fal fa-long-arrow-left" disabled={currentTab === 0}
                                                                onClick={() => setCurrentTab((prev) => prev - 1)}></i></span>Prev step
                                                        </a> */}
                                                    <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 padding-r-4" disabled={currentTab === 2}
                                                        onClick={() => DescriptionNext()}>Next step
                                                        <span className="d-inline-block ml-2 fs-16"><i
                                                            className="fal fa-long-arrow-right"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={1} title="Detail" disabled={currentTab !== 1} className="text-blue">
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body py-4 py-md-0 px-0">
                                                <div className="card mb-6">
                                                    <div className="card-body p-6">
                                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">Listing Detail</h3>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category"
                                                                        className="text-heading margin-b-0">Property Type <span className="star-mandatoryfield">*</span></label>
                                                                    <Select
                                                                        className="form-control border-0 shadow-none form-control-lg selectpicker multiple-picker-height-auto"
                                                                        options={getDropwonData?.propertyType}
                                                                        isMulti
                                                                        title="Select"
                                                                        data-style="btn-lg py-2 h-52"
                                                                        onChange={(e) => setPropertyType(e)}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                        value={propertyType}
                                                                        classNamePrefix='select-inner'
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category"
                                                                        className="text-heading">Permitted Uses<span className="star-mandatoryfield">*</span></label>
                                                                    <Select
                                                                        className="form-control border-0 shadow-none form-control-lg selectpicker multiple-picker-height-auto"
                                                                        options={getDropwonData?.permitted_use}
                                                                        isMulti
                                                                        title="Select"
                                                                        data-style="btn-lg py-2 h-52"
                                                                        onChange={(e) => setPermittedUsers(e)}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                        value={permittedUsers}
                                                                        classNamePrefix='select-inner'
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category"
                                                                        className="text-heading margin-b-0">Property Labels<span className="star-mandatoryfield">*</span></label>
                                                                    <Select
                                                                        className="form-control border-0 shadow-none form-control-lg selectpicker multiple-picker-height-auto"
                                                                        options={getDropwonData?.labels}
                                                                        isMulti
                                                                        title="Select"
                                                                        data-style="btn-lg py-2 h-52"
                                                                        onChange={(e) => setPropertyLabels(e)}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                        value={propertyLabels}
                                                                        classNamePrefix='select-inner'
                                                                    />
                                                                </div>
                                                            </div>



                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="price" className="text-heading margin-b-0">Sale Price From
                                                                        <span className="star-mandatoryfield">*</span></label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <CurrencyFormat className='form-control form-control-lg border-0' placeholder="Enter Price From" thousandSeparator={true} value={Price}
                                                                            onValueChange={(e) => setPrice(e?.value)}

                                                                        />
                                                                        {
                                                                            Price ? <BsCurrencyDollar style={{ position: 'absolute', top: '18px', left: '2px', fontSize: '0.875rem', color: 'rgb(66 65 65)' }} /> : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">

                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="price" className="text-heading margin-b-0">Sale Price To <span className="star-mandatoryfield">*</span></label>
                                                                    {/* <input type="number"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="price" name="price" placeholder="Price To" onChange={(e) => setPriceTo(e?.target?.value)} value={priceTo} /> */}
                                                                    <div style={{ position: 'relative' }}>
                                                                        <CurrencyFormat className='form-control form-control-lg border-0' placeholder="Enter Price To" thousandSeparator={true} value={priceTo} onValueChange={(e) => setPriceTo(e?.value)} />
                                                                        {
                                                                            priceTo ? <BsCurrencyDollar style={{ position: 'absolute', top: '18px', left: '2px', fontSize: '0.875rem', color: 'rgb(66 65 65)' }} /> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="tax"
                                                                        className="text-heading">Price Per Square Feet</label>
                                                                    {/* <input type="number" name="Pricepersqfeet"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="Pricepersqfeet" placeholder="Price Per Sq.Ft" onChange={(e) => setPricePer(e?.target?.value)} value={pricePer} /> */}
                                                                    <div style={{ position: 'relative' }}>

                                                                        <CurrencyFormat className='form-control form-control-lg border-0' Placeholder="Price (SFT)" thousandSeparator={true} value={pricePer} onValueChange={(e) => setPricePer(e?.value)} />
                                                                        {
                                                                            pricePer?.length ? <BsCurrencyDollar style={{ position: 'absolute', top: '18px', left: '2px', fontSize: '0.875rem', color: 'rgb(66 65 65)' }} /> : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="fee" className="text-heading">Maintenance Fee
                                                                    </label>
                                                                    {/* <input type="number"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="fee" name="fee" placeholder="Price in Numbers" onChange={(e) => setMaintenanceFee(e?.target?.value)} value={maintenanceFee} /> */}
                                                                    <div style={{ position: 'relative' }}>
                                                                        <CurrencyFormat className='form-control form-control-lg border-0' Placeholder="Starting Price per Sq. ft" thousandSeparator={true} value={maintenanceFee} onValueChange={(e) => setMaintenanceFee(e?.value)} />
                                                                        {
                                                                            maintenanceFee?.length ? <BsCurrencyDollar style={{ position: 'absolute', top: '18px', left: '2px', fontSize: '0.875rem', color: 'rgb(66 65 65)' }} /> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="row">

                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="fee" className="text-heading margin-b-0">Lease Rate <span className="star-mandatoryfield">*</span></label>
                                                                    {/* <input type="number"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="fee" name="fee" placeholder="Price in Numbers" onChange={(e) => setMaintenanceFee(e?.target?.value)} value={maintenanceFee} /> */}
                                                                    <div style={{ position: 'relative' }}>
                                                                        <CurrencyFormat className='form-control form-control-lg border-0' Placeholder="Starting Price per Sq. ft" thousandSeparator={true} value={leaseRate} onValueChange={(e) => setLeaseRate(e?.value)} />
                                                                        {
                                                                            leaseRate?.length ? <BsCurrencyDollar style={{ position: 'absolute', top: '18px', left: '2px', fontSize: '0.875rem', color: 'rgb(66 65 65)' }} /> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="form-group mb-0">
                                                                    <label htmlFor="type"
                                                                        className="text-heading margin-b-0">Currency <span className="star-mandatoryfield">*</span></label>
                                                                    <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                        title="Select" data-style="btn-lg py-2 h-52"
                                                                        id="type" name="type" onChange={(e) => setCurrency(e?.target?.value)} defaultValue={currency}>
                                                                        <option>Select Currency</option>
                                                                        {
                                                                            getDropwonData?.currency?.map((item, index) => {
                                                                                return (
                                                                                    <option value={item?.id} selected={item?.id === currency ? "selected" : ''}>{item?.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="type"
                                                                        className="text-heading margin-b-0">Property Status <span className="star-mandatoryfield">*</span></label>
                                                                    <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                        title="Select" data-style="btn-lg py-2 h-52"
                                                                        id="type" name="type" onChange={(e) => setPropertyStatus(e?.target?.value)} value={propertyStatus}>
                                                                        <option>Select Property Status</option>
                                                                        {
                                                                            getDropwonData?.ConstructionStatus?.map((item, index) => {
                                                                                return (
                                                                                    <option value={item?.id} selected={item?.id === propertyStatus ? "selected" : ''}>{item?.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="type"
                                                                        className="text-heading margin-b-0">Selling Status <span className="star-mandatoryfield">*</span></label>
                                                                    <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                        title="Select" data-style="btn-lg py-2 h-52"
                                                                        id="type" name="type" onChange={(e) => setSellingStatus(e?.target?.value)} defaultValue={sellingStatus}>
                                                                        <option>Select Selling Status</option>
                                                                        {
                                                                            getDropwonData?.SellingStatus?.map((item, index) => {
                                                                                return (
                                                                                    <option value={item?.id} selected={item?.id === sellingStatus ? "selected" : ''}>{item?.name}</option>
                                                                                )
                                                                            })
                                                                        }


                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="sale-start" className="text-heading">Launch Date</label>
                                                                    <DatePicker selected={startDate1} onChange={(month) => setStartDate1(month)} value={startDate1} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="conststartdate" className="text-heading">Construction Start Date</label>
                                                                    <DatePicker selected={startDate2} dateFormat="MM/yyyy" onChange={(date) => setStartDate2(date)} showMonthYearPicker onChangeRaw={(e) => e.preventDefault()} />

                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="row">

                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="tax"
                                                                        className="text-heading">Estimated Completion</label>
                                                                    <DatePicker selected={startDate3} dateFormat="MM/yyyy" onChange={(date) => setStartDate3(date)} showMonthYearPicker onChangeRaw={(e) => e.preventDefault()} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="list-in" className="text-heading margin-b-0">Property Size (SFT) <span className="star-mandatoryfield">*</span></label>
                                                                    {/* <input type="number" name="size"
                                                                        className="form-control form-control-lg border-0"
                                                                        id="size" placeholder="Square Feet In Numbers" onChange={(e) => setPropertySize(e?.target?.value)} value={propertySize} /> */}
                                                                    <div style={{ position: 'relative' }}>
                                                                        <CurrencyFormat className='form-control form-control-lg border-0' Placeholder="Enter Sq.ft area" thousandSeparator={true} value={propertySize} onValueChange={(e) => setPropertySize(e?.value)} />
                                                                        {/*  {
                                                                            propertySize?.length ? <BsCurrencyDollar style={{ position: 'absolute', top: '18px', left: '2px', fontSize: '0.875rem', color: 'rgb(66 65 65)' }} /> : null
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category"
                                                                        className="text-heading margin-b-0">No.. Of Buildings <span className="star-mandatoryfield">*</span></label>
                                                                    <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                        title="Select" data-style="btn-lg py-2 h-52"
                                                                        id="category" name="category" onChange={HandleSelect} value={numberBuilding}>
                                                                        <option>Select Building</option>
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>6</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                        <option>9</option>
                                                                        <option>10</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {
                                                            inputList?.map((x, i) => {
                                                                return (


                                                                    <div className="row">
                                                                        <h3 className="card-title mb-2 mt-2 text-heading fs-18 lh-15">Add Details For Building {i + 1}</h3>
                                                                        <div className="col-lg-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="category"
                                                                                    className="text-heading">Building Name</label>
                                                                                <input type="text" name="name"
                                                                                    value={x?.name}
                                                                                    className="form-control form-control-lg border-0"
                                                                                    placeholder="Enter Building Name"
                                                                                    onChange={(e) => HandleInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="category"
                                                                                    className="text-heading">No. Of Units</label>
                                                                                <input type="number" name="no_of_units"
                                                                                    className="form-control form-control-lg border-0"
                                                                                    placeholder="Enter units"
                                                                                    value={x?.no_of_units}
                                                                                    onChange={(e) => HandleInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="category"
                                                                                    className="text-heading">No. Of Floors</label>
                                                                                <input type="number" name="no_of_floors"
                                                                                    className="form-control form-control-lg border-0"
                                                                                    placeholder="Enter Floors"
                                                                                    value={x?.no_of_floors}
                                                                                    onChange={(e) => HandleInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                {
                                                                    unitPricePlan || unitPlanImage ? <img src={unitPricePlan} alt="image" className="w-100 add-border-other" style={{ marginBottom: '20px' }} /> : null
                                                                }
                                                                <div className="form-group">
                                                                    <input type="file" className="custom-file-input add-pstion-abs" id="UnitPlan" name="UnitPlan" accept="image/*" onChange={UnitPricePlan} />
                                                                    <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="UnitPlan">
                                                                        <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Availability Plan</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                {
                                                                    permittedUsedPDF || permittedUses ? <iframe src={permittedUsedPDF} alt="image" className="w-100 add-border-other" style={{ marginBottom: '20px' }} /> : null
                                                                }
                                                                <div className="form-group">
                                                                    <input type="file" className="custom-file-input add-pstion-abs" id="permitteduses" name="permitteduses" accept="application/pdf" onChange={PermittedUses} />
                                                                    <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="permitteduses">
                                                                        <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Permitted Uses</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="card mb-6">
                                                    <div className="card-body p-6">
                                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">Service Type</h3>

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-lg-0">
                                                                    <ul className="list-group list-group-no-border">

                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                {
                                                                                    getDropwonData?.Amenities?.map((item, index) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <input type="checkbox" className="custom-control-input"
                                                                                                    name="features[]"
                                                                                                    id="Down" value={item?.name} checked={Ammenities?.find(i => i?.id === item?.id) ? true : false} />
                                                                                                <label className="custom-control-label add-color-with-logo"
                                                                                                    htmlFor="Down" value={item?.name} onClick={() => onAmenities(item)}>{item?.name}</label>
                                                                                            </div>
                                                                                        )

                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </li>

                                                                    </ul>
                                                                    {
                                                                        otherShow ? <div className="form-group">
                                                                            <label htmlFor="extra-details"
                                                                                className="text-heading">Extra details</label>
                                                                            <input type="text"
                                                                                className="form-control form-control-lg border-0"
                                                                                id="extra-details" name="extra-details" />
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap" style={{ position: 'relative' }}>
                                                    {
                                                        State?.state?.profilePreview?.PropertyTitle ? <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 previous-button-detailsection padding-r-4" onClick={RedirectToDescription}>Previous</button> : null
                                                    }


                                                    {
                                                        CheckId ? <button type="button" className="btn btn-lg bg-blue text-white hover-saffron mb-3 padding-r-4" style={{ position: 'absolute', right: '0px' }} onClick={UpdateProperty}>Next</button> :

                                                            <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 padding-r-4" style={{ position: 'absolute', right: '0px' }} disabled={currentTab === 2}
                                                                onClick={() => DetailNext()}>Next step
                                                                <span className="d-inline-block ml-2 fs-16"><i
                                                                    className="fal fa-long-arrow-right"></i></span>
                                                            </button>
                                                    }


                                                </div>
                                            </div>

                                        </div>
                                    </Tab>
                                    <Tab eventKey={2} title="Media" disabled={currentTab !== 2}>
                                        <div className="container">
                                            <h1 style={{ width: '100%', margin: '0 auto', textAlign: 'center', marginBottom: '20px' }}>Upload Property Images</h1>

                                            <div className="col-lg-12">
                                                <div className="card mb-6 header-r-logout">
                                                    <div className="col-sm-4">
                                                        <h2 className="upload-h2-re">upload images</h2>
                                                        <div className="edit-prfl btn-sub">Upload Images
                                                            <input type="file" className="input-file-profile" multiple accept="image/*" onChange={ImageHandler}></input></div>
                                                        <p>Photos must be JPEG or PNG format and Max Size 2MB</p>
                                                    </div>
                                                    </div><div className="card mb-6 header-r-logout">
                                                    <div className="col-sm-4 mt-2">
                                                        <h2 className="upload-h2-re">upload video</h2>
                                                        <div className="edit-prfl btn-sub">Upload Video
                                                            <input type="file" name="name" className="input-file-profile" accept="video/*" onChange={VideoHandler} onClick={togglePopup}></input></div>

                                                        <p>Video must be mp4 format and Max Size
                                                            20MB</p>
                                                    </div>
                                                </div>
                                                <div className="card mb-6 header-user-icon">
                                                    <div className="card-body p-6">
                                                        <div className="row">
                                                            <div class="col-sm-6">

                                                                <div class="card mb-6">
                                                                    <div class="card-body p-6">
                                                                        <h3 class="card-title mb-0 text-heading fs-22 lh-15">Upload Images
                                                                            for your property</h3>
                                                                        <div class="dropzone upload-file text-center py-5">
                                                                            <div class="dz-default dz-message">
                                                                                <span class="upload-icon lh-1 d-inline-block mb-4"><i class="fal fa-cloud-upload-alt"></i></span>
                                                                                <p class="text-heading fs-22 lh-15 mb-4">Browse Property Gallery Images</p>
                                                                                <div className="edit-prfl btn-sub">Upload Images
                                                                                    <input type="file" className="input-file-profile" multiple accept="image/*" onChange={ImageHandler}></input></div>
                                                                                <p>Photos must be JPEG or PNG format and Max Size 2MB</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="card mb-6">
                                                                    <div class="card-body p-6">
                                                                        <h3 class="card-title mb-0 text-heading fs-22 lh-15">Upload Video
                                                                            for your property</h3>
                                                                        {/* <p class="card-text mb-5">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit</p> */}
                                                                        <div class="dropzone upload-file text-center py-5">
                                                                            <div class="dz-default dz-message">


                                                                                <span class="upload-icon lh-1 d-inline-block mb-4"><i
                                                                                    class="fal fa-cloud-upload-alt"></i></span>
                                                                                <p class="text-heading fs-22 lh-15 mb-4">Browse Your Video Content
                                                                                </p>
                                                                                <div className="edit-prfl btn-sub">Upload Video
                                                                                    <input type="file" name="name" className="input-file-profile" accept="video/*" onChange={VideoHandler} onClick={togglePopup}></input></div>

                                                                                <p>Video must be mp4 format and Max Size
                                                                                    20MB</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="finalresult">

                                                {

                                                    selectedImage ?


                                                        renderPhotos(selectedImage)
                                                        : null
                                                }

                                                {
                                                    mediaData ?
                                                        renderEditPhotos(mediaData)
                                                        : null
                                                }

                                            </div>
                                            <div className="finalresult">

                                                {
                                                    selectedVideo ?
                                                        <div className="previewimgmuploader">
                                                            <iframe src={selectedVideo} style={{ width: '250px', height: '200px' }}></iframe>
                                                            <span className="services-add-preview" onClick={() => OnVideoRemove(selectedVideo)}><ImCross className="cross-icon-service" /></span>
                                                        </div>
                                                        : null
                                                }

                                            </div>

                                            {/* <button type="button" className="btn btn-primary btn-sub" onClick={() => { ImageSubmit(PropertyId); VideoSubmit() }}>Submit</button> */}
                                            <div className="d-flex flex-wrap justify-cntnt-spc-btw">
                                                {/* <a href="#"
                                                    className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button" disabled={currentTab === 0}
                                                    onClick={() => setCurrentTab((prev) => prev - 1)}>
                                                    <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                        className="fal fa-long-arrow-left"></i></span>Prev step
                                                </a> */}
                                                {/* <button className="btn btn-lg btn-primary next-button mb-3" disabled={currentTab === 3}
                                                    onClick={() => setCurrentTab((prev) => prev + 1)}>Next step
                                                    <span className="d-inline-block ml-2 fs-16"><i
                                                        className="fal fa-long-arrow-right"></i></span>
                                                </button> */}
                                                <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 padding-r-4" disabled={currentTab === 3}
                                                    onClick={() => {
                                                        if (selectedImage?.length || selectedVideo?.length || mediaData?.length) {
                                                            ImageSubmit(PreviousPropertyId);
                                                            VideoSubmit();
                                                            // setCurrentTab((prev) => prev + 1)
                                                        }
                                                        else {
                                                            toast.error("Please Select Media")
                                                        }
                                                    }}>Submit
                                                    <span className="d-inline-block ml-2 fs-16"><i
                                                        className="fal fa-long-arrow-right"></i></span>
                                                </button>

                                                {
                                                    PropertyData?.id ? <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 padding-r-4" onClick={() => RedirectToFloorPlan()}>
                                                        Next
                                                    </button> : null
                                                }

                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={3} title="Floor Plan" disabled={currentTab !== 3}>
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body py-4 py-md-0 px-0">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card mb-6">
                                                            <div className="card-body p-6">
                                                                {/* <h3 className="card-title mb-6 text-heading fs-22 lh-15">Place the
                                                                    listing pin on the map</h3>

                                                                <div className="main-wrapper">
                                                                    <MyMap selectedData={(e) => setAddressMap(e)} />
                                                                </div> */}

                                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Floor Plan</h3>
                                                                {
                                                                    State?.state?.previousData?.property_building ? <div className="col-md-3 col-lg-3 col-xxl-3 px-2 mb-4 mb-md-0">
                                                                        <div className="form-group mb-0">
                                                                            <label htmlFor="category"
                                                                                className="text-heading margin-b-0">Buildings</label>
                                                                            <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                                title="Select" data-style="btn-lg py-2 h-52"
                                                                                id="category" name="category" value={numberBuildingFloor} onChange={HandleSelectFloorPlan}>
                                                                                <option>Select Building</option>
                                                                                {
                                                                                    State?.state?.previousData?.property_building?.map((item) => {
                                                                                        return (
                                                                                            <option value={item?.id} selected={numberBuildingFloor === item?.id}>{item?.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div> : null
                                                                }

                                                                {
                                                                    PropertyData?.property_building ? <div className="col-md-3 col-lg-3 col-xxl-3 px-2 mb-4 mb-md-0">
                                                                        <div className="form-group mb-0">
                                                                            <label htmlFor="category"
                                                                                className="text-heading margin-b-0">Buildings <span className="star-mandatoryfield">*</span></label>
                                                                            <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                                title="Select" data-style="btn-lg py-2 h-52"
                                                                                id="category" name="category" value={numberBuildingFloor} onChange={HandleSelectFloorPlan}>
                                                                                <option>Select Building</option>
                                                                                {
                                                                                    PropertyData?.property_building?.map((item) => {
                                                                                        return (
                                                                                            <option value={item?.id} selected={numberBuildingFloor === item?.id}>{item?.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div> : null
                                                                }


                                                                {
                                                                    inputListFloor?.map((x, i) => {
                                                                        return (
                                                                            <div className="row ml-0">

                                                                                {/* no of floors */}
                                                                                <div className="form-group mb-0 col-md-4">
                                                                                    <label htmlFor="category"
                                                                                        className="text-heading">Floors Number <span className="star-mandatoryfield">*</span></label>
                                                                                    <input type="number" name="floorNo"
                                                                                        className="form-control form-control-lg border-0"
                                                                                        placeholder="Enter Floors"
                                                                                        value={x?.floorNo}
                                                                                        onChange={(e) => HandleInputChangeFloor(e, i)} />
                                                                                </div>
                                                                                <div className="form-group mb-0 col-md-4 mt-2">
                                                                                    <label htmlFor="role" className="text-heading">Description</label>
                                                                                    <input type="text" className="form-control form-control-lg border-0" id="role"
                                                                                        name="description" onChange={(e) => HandleInputChangeFloor(e, i)} />
                                                                                </div>

                                                                                <div className="col-md-4 mb-0 img-uploader-cnt-inf">
                                                                                    {
                                                                                        imageFloorPlan || floorImageBlob ? <img src={imageFloorPlan} alt="image" className="w-100 add-border-other" /> : null
                                                                                    }

                                                                                    <div className="custom-file mt-4 h-auto">

                                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile"
                                                                                            name="file" accept="image/*" onChange={(e) => { HandleInputChangeFloor(e, i); PorfileImgHandlerFloor(e) }} />
                                                                                        <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                                                            Floor Plan</label>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <button type="submit" className="btn btn-lg btn-primary mb-3" onClick={SubmitFloorPlan}>Submit</button> */}

                                                <div className="d-flex flex-wrap justify-cntnt-spc-btw">
                                                    {/* <a href="#"
                                                        className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button" disabled={currentTab === 0}
                                                        onClick={() => setCurrentTab((prev) => prev - 1)}>
                                                        <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                            className="fal fa-long-arrow-left"></i></span>Prev step
                                                    </a> */}
                                                    <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 padding-r-4" disabled={currentTab === 2}
                                                        // onClick={() => setCurrentTab((prev) => prev + 1)}
                                                        onClick={SubmitFloorPlan}
                                                    >Submit
                                                        <span className="d-inline-block ml-2 fs-16"><i
                                                            className="fal fa-long-arrow-right"></i></span>
                                                    </button>

                                                    <button className="btn btn-lg bg-blue text-white hover-saffron next-button mb-3 padding-r-4" onClick={() => NextFloorPlan()}>
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={4} title="Contacts" disabled={currentTab !== 4}>
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body py-4 py-md-0 px-0">
                                                <div className="card mb-6">
                                                   {/*  <div className="card-body p-6"> */}
                                                        {/* <h3 className="card-title mb-0 text-heading fs-22 lh-15">Listing Detail</h3>
                                                        <p className="card-text mb-5">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit</p> */}
                                                       {/*  <div className="row"> */}
                                                            <div className="col-md-12">
                                                                <div className="card mb-6">
                                                                    <div className="card-body px-6 pt-6 pb-5">
                                                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15 pb-3">Property Contact</h3>
                                                                        <div className="row ">
                                                                            <div className="col-md-12 mb-5">
                                                                                <label htmlFor="Builder" className="text-heading">Builder</label>
                                                                                <SearchDropdown type="Builder" setData={(e) => setBuilder([...e])} onSearch={builderOnSearch} options={builderOptions} setOptions={setBuilderOptions}
                                                                                    onButtonClick={() => {
                                                                                        setTypeModal('Builder')
                                                                                        setIsAddNew(true)
                                                                                    }} value={builder} />
                                                                            </div>


                                                                            <div className="col-md-12 mb-5">
                                                                                <label htmlFor="Architect" className="text-heading">Architect</label>
                                                                                <SearchDropdown type="Architect" setData={(e) => setArchitect([...e])} options={options} onSearch={onSearch} setOptions={setOptions}
                                                                                    onButtonClick={() => {
                                                                                        setTypeModal('Architect')
                                                                                        setIsAddNew(true)
                                                                                    }} value={architect} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12 mb-5">
                                                                                <label htmlFor="Marketing Company" className="text-heading">Marketing Company</label>
                                                                                <SearchDropdown type="Marketing Company" setData={(e) => setMarketingCompany([...e])} options={options} onSearch={onSearch} setOptions={setOptions}
                                                                                    onButtonClick={() => {
                                                                                        setTypeModal('Marketing')
                                                                                        setIsAddNew(true)
                                                                                    }} value={marketingCompany} />
                                                                            </div>

                                                                            <div className="col-md-12 mb-5">
                                                                                <label htmlFor="Sales Company" className="text-heading">Sales Company</label>
                                                                                <SearchDropdown type="Sales Company" setData={(e) => setSalesCompany([...e])} options={options} onSearch={onSearch} setOptions={setOptions}
                                                                                    onButtonClick={() => {
                                                                                        setTypeModal('Sales')
                                                                                        setIsAddNew(true)
                                                                                    }} value={salesCompany} />
                                                                            </div>

                                                                            <div className="col-md-12 mb-5">
                                                                                <label htmlFor="Interior Designer" className="text-heading">Interior Designer</label>
                                                                                <SearchDropdown type="interior Designer" options={options} onSearch={onSearch} setOptions={setOptions} setData={(e) => {

                                                                                    setInteriorDesigner([...e])
                                                                                }}
                                                                                    onButtonClick={() => {
                                                                                        setTypeModal('interior')
                                                                                        setIsAddNew(true)
                                                                                    }} value={interiorDesigner} />

                                                                            </div>

                                                                            <div className="col-md-12 mb-5">
                                                                                <label htmlFor="agent" className="text-heading">Agent</label>
                                                                                <SearchDropdown type="agent" setData={(e) => setAgent([...e])} onSearch={AgentSearch} options={agentSearchdata} setOptions={setAgentSearchData}
                                                                                    onButtonClick={() => {
                                                                                        setTypeModal('agent')
                                                                                        setIsAddNew(true)
                                                                                    }} value={agent} />
                                                                            </div>


                                                                        </div>


                                                                        {
                                                                            isAddNew ?
                                                                                <Modal show={isAddNew} className="modal-add-contact">
                                                                                    <Modal.Header>
                                                                                        <ImCross onClick={HandleClose} className="button-cross-modal-add-contact" />
                                                                                    </Modal.Header>
                                                                                    <Modal.Body>
                                                                                        <div className="form-row mx-n4">
                                                                                            <div className="form-group col-md-6 px-4 mb-5">
                                                                                                <label htmlFor="firstName" className="text-heading">Name</label>
                                                                                                <input type="text" className="form-control form-control-lg border-0" id="firstName"
                                                                                                    name="firstname" placeholder="Enter Name" onChange={(e) => setName(e?.target?.value)} value={name} />
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 px-4 mb-5">
                                                                                                <label htmlFor="role" className="text-heading">Designation</label>
                                                                                                <input type="text" className="form-control form-control-lg border-0" id="role"
                                                                                                    name="role" placeholder="Enter Designation" onChange={(e) => setDesignation(e?.target?.value)} value={designation} />
                                                                                            </div>

                                                                                            <div className="form-group col-md-6  mb-5 px-4">
                                                                                                <label htmlFor="email" className="text-heading">Email</label>
                                                                                                <input type="email" className="form-control form-control-lg border-0" id="email"
                                                                                                    name="email" placeholder="Enter Email" onChange={(e) => setEmail(e?.target?.value)} value={email} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <button type="button" className="btn bg-blue text-white hover saffron padding-r-4 " onClick={() => createUser()}>Submit</button>
                                                                                    </Modal.Body>
                                                                                </Modal>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                                {/* <button type="button" className="btn btn-primary mr-bo-20" onClick={ContactProperty}>Submit</button> */}
                                                            </div>
                                                        {/* </div> */}
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex flex-wrap">
                                                    {/* <a href="#"
                                                        className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button" disabled={currentTab === 0}
                                                        onClick={() => setCurrentTab((prev) => prev - 1)}>
                                                        <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                            className="fal fa-long-arrow-left"></i></span>Prev step
                                                    </a> */}
                                                    <button className="btn btn-lg bg-blue text-white saffron mb-3 padding-r-4" type="button" onClick={ContactProperty}>Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>

                                {/* modal edit images  */}

                                <Modal className="modal-add-contact"
                                    show={showEditImageDelete} >
                                    <Modal.Body>
                                        <h3 className="submit-property-modal-heading">Are you sure to delete this Media?</h3>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button className="bg-blue text-white hover-saffron border-0" onClick={() => DeleteMedia()}>
                                            Yes
                                        </Button>

                                        <Button className="bg-blue text-white hover-saffron border-0" onClick={() => setShowEditImageDelete(false)}>
                                            No
                                        </Button>
                                    </Modal.Footer>
                                </Modal>



                                {/* <Button
                                    className="success"
                                    disabled={currentTab === 0}
                                    onClick={() => setCurrentTab((prev) => prev - 1)}
                                >
                                    Prev
                                </Button>
                                <Button
                                    className="success"
                                    disabled={currentTab === 2}
                                    onClick={() => setCurrentTab((prev) => prev + 1)}
                                >
                                    Next
                                </Button> */}
                                {/* <div className="collapse-tabs new-property-step">
                                    <ul className="nav nav-pills border py-2 px-3 mb-6 d-none d-md-flex mb-6"
                                        role="tablist">
                                        <li className="nav-item col">
                                            <a className="nav-link active bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"
                                                data-bs-toggle="pill"
                                                href="#description"
                                                role="tab"><span className="number">1.</span> Description</a>
                                        </li>
                                        <li className="nav-item col">
                                            <a className="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"

                                                data-bs-toggle="pill" data-number="2."
                                                href="#detail"
                                                role="tab"
                                            ><span className="number">2.</span> Detail</a>
                                        </li>
                                        <li className="nav-item col">
                                            <a className="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"

                                                data-bs-toggle="pill" data-number="3."
                                                href="#media"
                                                role="tab"
                                            ><span className="number">3.</span>Media</a>
                                        </li>
                                        <li className="nav-item col">
                                            <a className="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"

                                                data-bs-toggle="pill" data-number="4."
                                                href="#floorplan"
                                                role="tab"
                                            ><span className="number">4.</span>Floor Plan </a>
                                        </li>
                                        <li className="nav-item col">
                                            <a className="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"

                                                data-bs-toggle="pill" data-number="5."
                                                href="#contacts"
                                                role="tab"
                                            ><span className="number">5.</span>Contacts</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content shadow-none p-0">
                                        <div className="tab-pane tab-pane-parent fade show active px-0" id="description"
                                            role="tabpanel">
                                            <div className="card bg-transparent border-0">
                                                <div className="card-body py-4 py-md-0 px-0">
                                                    <div className="card mb-6">
                                                        <div className="card-body p-6">
                                                            <h3 className="card-title mb-0 text-heading fs-22 lh-15">Property Description</h3>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="title" className="text-heading margin-b-0">Property Name <span className="star-mandatoryfield">*</span></label>
                                                                        <input type="text"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="title" name="title" onChange={(e) => setPropertyTitle(e?.target?.value)} value={PropertyTitle} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="builtby" className="text-heading">Developer Name</label>
                                                                        <input type="text"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="title" name="name" onChange={(e) => setBuiltBy(e?.target?.value)} value={builtBy} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="builtby" className="text-heading">Email</label>
                                                                        <input type="email"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="title" name="email" onChange={(e) => setBuiltEmail(e?.target?.value)} value={buildEmail} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="builtby" className="text-heading">Built By Year</label>
                                                                        <YearPicker onChange={(e) => setBuiltByyear(e)} value={builtYear} className="form-control form-control-lg border-0" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="title" className="text-heading">Website</label>
                                                                        <input type="url"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="website" name="website" placeholder="https://google.com" onChange={(e) => setWebsite(e?.target?.value)} value={website} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="description-01"
                                                                            className="text-heading">Description</label>
                                                                        <textarea className="form-control border-0"
                                                                            name="description"
                                                                            id="description-01" onChange={(e) => setDescription(e?.target?.value)} value={description}></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="card mb-6">
                                                                <div className="card-body p-6">
                                                                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">Site Plans</h3>
                                                                    <div className="form-group">
                                                                        <Form.Check
                                                                            type={'radio'}
                                                                            // id="custom-switch"
                                                                            name="group5"
                                                                            label="Is Site Plan Approved"
                                                                            id={`inline-${'radio'}-5`}
                                                                            checked={isSitePlanApproved}
                                                                            onChange={() => setIsSitePlanApproved(!isSitePlanApproved)}
                                                                        />
                                                                        <Form.Check
                                                                            type={'radio'}
                                                                            name="group5"
                                                                            label="Is Site Plan not Approved"
                                                                            id={`inline-${'radio'}-5`}
                                                                            onChange={CheckNotApprovedPlan}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        isSitePlanApproved ?
                                                                            <div>
                                                                                {
                                                                                    sitePlanImage || isSiteProfile ? <img src={isSiteProfile} alt="image" className="w-100 add-border-other" /> : null
                                                                                }

                                                                                <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*,.pdf,.doc" onChange={SitePlanImage} />
                                                                                <label className="btn btn-secondary btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                                                    <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Site Plan</label>

                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="card mb-6">
                                                            <div className="card-body p-6">
                                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Upload Property Featured Image</h3>
                                                                <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">
                                                                    {
                                                                        imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                                                    }

                                                                    <div className="custom-file mt-4 h-auto margin-top-bottom">
                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*" onChange={PorfileImgHandler} />
                                                                        <label className="btn btn-secondary btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                                            featured image</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">

                                                                    <div className="custom-file mt-4 h-auto margin-top-bottom">
                                                                        {
                                                                            projectBrochureSubmit || projectBrochure ? <iframe src={projectBrochure} alt="image" className="w-100 add-border-other" style={{ marginBottom: '20px' }} /> : null
                                                                        }
                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="application/pdf" onChange={ProjectBrochure} />
                                                                        <label className="btn btn-secondary btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Project Brochure (PDF)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="card mb-6">
                                                                <div className="card-body p-6">
                                                                    <h3 className="card-title mb-6 text-heading fs-22 lh-15">Place the
                                                                        listing pin on the map</h3>

                                                                    <div className="main-wrapper">
                                                                        <MyMap selectedData={(e) => setAddressMap(e)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>










                                                    <div className="d-flex flex-wrap">
                                                        <a href="#"
                                                            className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                                            <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                                className="fal fa-long-arrow-left"></i></span>Prev step
                                                        </a>
                                                        <button className="btn btn-lg btn-primary next-button mb-3">Next step
                                                            <span className="d-inline-block ml-2 fs-16"><i
                                                                className="fal fa-long-arrow-right"></i></span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="tab-pane tab-pane-parent fade show  px-0" id="detail"
                                            role="tabpanel">
                                            <div className="card bg-transparent border-0">
                                                <div className="card-body py-4 py-md-0 px-0">
                                                    <div className="card mb-6">
                                                        <div className="card-body p-6">
                                                            <h3 className="card-title mb-0 text-heading fs-22 lh-15">Listing Detail</h3>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="category"
                                                                            className="text-heading margin-b-0">Property Type <span className="star-mandatoryfield">*</span></label>
                                                                        <Select
                                                                            className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            options={getDropwonData?.propertyType}
                                                                            isMulti
                                                                            title="Select"
                                                                            data-style="btn-lg py-2 h-52"
                                                                            onChange={(e) => setPropertyType(e)}
                                                                            getOptionLabel={(option) => option.name}
                                                                            getOptionValue={(option) => option.id}
                                                                            value={propertyType}
                                                                            classNamePrefix='select-inner'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="category"
                                                                            className="text-heading">Permitted Uses</label>
                                                                        <Select
                                                                            className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            options={getDropwonData?.permitted_use}
                                                                            isMulti
                                                                            title="Select"
                                                                            data-style="btn-lg py-2 h-52"
                                                                            onChange={(e) => setPermittedUsers(e)}
                                                                            getOptionLabel={(option) => option.name}
                                                                            getOptionValue={(option) => option.id}
                                                                            value={permittedUsers}
                                                                            classNamePrefix='select-inner'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="category"
                                                                            className="text-heading">Property Labels</label>
                                                                        <Select
                                                                            className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            options={getDropwonData?.labels}
                                                                            isMulti
                                                                            title="Select"
                                                                            data-style="btn-lg py-2 h-52"
                                                                            onChange={(e) => setPropertyLabels(e)}
                                                                            getOptionLabel={(option) => option.name}
                                                                            getOptionValue={(option) => option.id}
                                                                            value={propertyLabels}
                                                                            classNamePrefix='select-inner'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="price" className="text-heading margin-b-0">Property Price From <span className="star-mandatoryfield">*</span></label>
                                                                        <input type="text"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="price" name="price" placeholder="Price in Numbers" onChange={(e) => setPrice(e?.target?.value)} value={Price} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="price" className="text-heading margin-b-0">Property Price To <span className="star-mandatoryfield">*</span></label>
                                                                        <input type="text"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="price" name="price" placeholder="Price in Numbers" onChange={(e) => setPriceTo(e?.target?.value)} value={priceTo} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="tax"
                                                                            className="text-heading">Price Per Square Feet</label>
                                                                        <input type="text" name="Pricepersqfeet"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="Pricepersqfeet" placeholder="Price in Numbers" onChange={(e) => setPricePer(e?.target?.value)} value={pricePer} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="fee" className="text-heading">Maintenance Fee
                                                                        </label>
                                                                        <input type="number"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="fee" name="fee" placeholder="Price in Numbers" onChange={(e) => setMaintenanceFee(e?.target?.value)} value={maintenanceFee} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group mb-0">
                                                                        <label htmlFor="type"
                                                                            className="text-heading">Currency</label>
                                                                        <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            title="Select" data-style="btn-lg py-2 h-52"
                                                                            id="type" name="type" onChange={(e) => setCurrency(e?.target?.value)} defaultValue={currency}>
                                                                            <option>Select Currency</option>
                                                                            {
                                                                                getDropwonData?.currency?.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item?.id} selected={item?.id === currency ? "selected" : ''}>{item?.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="type"
                                                                            className="text-heading margin-b-0">Property Status <span className="star-mandatoryfield">*</span></label>
                                                                        <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            title="Select" data-style="btn-lg py-2 h-52"
                                                                            id="type" name="type" onChange={(e) => setPropertyStatus(e?.target?.value)} value={propertyStatus}>
                                                                            <option>Select Property Status</option>
                                                                            {
                                                                                getDropwonData?.ConstructionStatus?.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item?.id} selected={item?.id === propertyStatus ? "selected" : ''}>{item?.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="type"
                                                                            className="text-heading margin-b-0">Selling Status <span className="star-mandatoryfield">*</span></label>
                                                                        <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            title="Select" data-style="btn-lg py-2 h-52"
                                                                            id="type" name="type" onChange={(e) => setSellingStatus(e?.target?.value)} defaultValue={sellingStatus}>
                                                                            <option>Select Selling Status</option>
                                                                            {
                                                                                getDropwonData?.SellingStatus?.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item?.id} selected={item?.id === sellingStatus ? "selected" : ''}>{item?.name}</option>
                                                                                    )
                                                                                })
                                                                            }


                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="sale-start" className="text-heading">Sales Start</label>
                                                                        <DatePicker selected={startDate1} onChange={(date) => setStartDate1(date)} value={startDate1} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="conststartdate" className="text-heading">Construction Start Date</label>
                                                                        <DatePicker selected={startDate2} onChange={(date) => setStartDate2(date)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="tax"
                                                                            className="text-heading">Estimated Completion</label>
                                                                        <DatePicker selected={startDate3} onChange={(date) => setStartDate3(date)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="list-in" className="text-heading margin-b-0">Property Size (SFT) <span className="star-mandatoryfield">*</span></label>
                                                                        <input type="number" name="size"
                                                                            className="form-control form-control-lg border-0"
                                                                            id="size" placeholder="Square Feet In Numbers" onChange={(e) => setPropertySize(e?.target?.value)} value={propertySize} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="category"
                                                                            className="text-heading margin-b-0">No.. Of Buildings <span className="star-mandatoryfield">*</span></label>
                                                                        <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                                            title="Select" data-style="btn-lg py-2 h-52"
                                                                            id="category" name="category" onChange={HandleSelect}>
                                                                            <option>Select Building</option>
                                                                            <option>1</option>
                                                                            <option>2</option>
                                                                            <option>3</option>
                                                                            <option>4</option>
                                                                            <option>5</option>
                                                                            <option>6</option>
                                                                            <option>7</option>
                                                                            <option>8</option>
                                                                            <option>9</option>
                                                                            <option>10</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            {
                                                                inputList?.map((x, i) => {
                                                                    return (


                                                                        <div className="row">
                                                                            <h3 className="card-title mb-2 mt-2 text-heading fs-18 lh-15">Add Details For Building {i + 1}</h3>
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="category"
                                                                                        className="text-heading">Building Name</label>
                                                                                    <input type="text" name="name"
                                                                                        value={x?.name}
                                                                                        className="form-control form-control-lg border-0"
                                                                                        placeholder="Enter Building Name"
                                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="category"
                                                                                        className="text-heading">No. Of Units</label>
                                                                                    <input type="number" name="no_of_units"
                                                                                        className="form-control form-control-lg border-0"
                                                                                        placeholder="Enter units"
                                                                                        value={x?.no_of_units}
                                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="category"
                                                                                        className="text-heading">No. Of Floors</label>
                                                                                    <input type="number" name="no_of_floors"
                                                                                        className="form-control form-control-lg border-0"
                                                                                        placeholder="Enter Floors"
                                                                                        value={x?.no_of_floors}
                                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    {
                                                                        unitPricePlan || unitPlanImage ? <img src={unitPricePlan} alt="image" className="w-100 add-border-other" /> : null
                                                                    }
                                                                    <div className="form-group">
                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*" onChange={UnitPricePlan} />
                                                                        <label className="btn btn-secondary btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload Unit Plan</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="card mb-6">
                                                        <div className="card-body p-6">
                                                            <h3 className="card-title mb-0 text-heading fs-22 lh-15">Service Type</h3>

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="form-group mb-lg-0">
                                                                        <ul className="list-group list-group-no-border">

                                                                            <li className="list-group-item px-0 pt-0 pb-2">
                                                                                <div className="custom-control custom-checkbox">
                                                                                    {
                                                                                        getDropwonData?.Amenities?.map((item, index) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <input type="checkbox" className="custom-control-input"
                                                                                                        name="features[]"
                                                                                                        id="Down" value={item?.name} checked={Ammenities?.find(i => i?.id === item?.id) ? true : false} />
                                                                                                    <label className="custom-control-label"
                                                                                                        htmlFor="Down" value={item?.name} onClick={() => onAmenities(item)}>{item?.name}</label>
                                                                                                </div>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </li>

                                                                        </ul>
                                                                        {
                                                                            otherShow ? <div className="form-group">
                                                                                <label htmlFor="extra-details"
                                                                                    className="text-heading">Extra details</label>
                                                                                <input type="text"
                                                                                    className="form-control form-control-lg border-0"
                                                                                    id="extra-details" name="extra-details" />
                                                                            </div> : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        <a href="#"
                                                            className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                                            <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                                className="fal fa-long-arrow-left"></i></span>Prev step
                                                        </a>
                                                        {
                              CheckId ? <button type="button" className="btn btn-lg btn-primary mb-3" onClick={UpdateProperty}>Update Property</button> : <button className="btn btn-lg btn-primary mb-3" type="button" onClick={Preview}>Next & Preview</button>
                            }
                                                        <button className="btn btn-lg btn-primary next-button mb-3">Next step
                                                            <span className="d-inline-block ml-2 fs-16"><i
                                                                className="fal fa-long-arrow-right"></i></span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="tab-pane tab-pane-parent fade show  px-0" id="media"
                                            role="tabpanel" >
                                            <div className="container">
                                <h1 style={{ width: '100%', margin: '0 auto', textAlign: 'center', marginBottom: '20px' }}>Upload Property Images</h1>

                                <div className="col-lg-12">

                                    <div className="card mb-6">
                                        <div className="card-body p-6">
                                            <div className="row">
                                                <div class="col-lg-6">

                                                    <div class="card mb-6">
                                                        <div class="card-body p-6">
                                                            <h3 class="card-title mb-0 text-heading fs-22 lh-15">Upload Images
                                                                for your property</h3>
                                                            <div class="dropzone upload-file text-center py-5">
                                                                <div class="dz-default dz-message">
                                                                    <span class="upload-icon lh-1 d-inline-block mb-4"><i class="fal fa-cloud-upload-alt"></i></span>
                                                                    <p class="text-heading fs-22 lh-15 mb-4">Browse Property Gallery Images</p>
                                                                    <div className="edit-prfl btn-sub">Upload Images
                                                                        <input type="file" className="input-file-profile" multiple accept="image/*" onChange={ImageHandler}></input></div>
                                                                    <p>Photos must be JPEG or PNG format and Max Size 2MB</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="card mb-6">
                                                        <div class="card-body p-6">
                                                            <h3 class="card-title mb-0 text-heading fs-22 lh-15">Upload Video
                                                                for your property</h3>

                                                            <div class="dropzone upload-file text-center py-5">
                                                                <div class="dz-default dz-message">


                                                                    <span class="upload-icon lh-1 d-inline-block mb-4"><i
                                                                        class="fal fa-cloud-upload-alt"></i></span>
                                                                    <p class="text-heading fs-22 lh-15 mb-4">Browse Your Video Content
                                                                    </p>
                                                                    <div className="edit-prfl btn-sub">Upload Video
                                                                        <input type="file" name="name" className="input-file-profile" accept="video/*" onChange={VideoHandler} onClick={togglePopup}></input></div>

                                                                    <p>Video must be mp4 format and Max Size
                                                                        20MB</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="finalresult">

                                    {

                                        selectedImage ?


                                            renderPhotos(selectedImage)
                                            : null
                                    }

                                </div>
                                <div className="finalresult">

                                    {
                                        selectedVideo ?
                                            <div className="previewimgmuploader">
                                                <iframe src={selectedVideo} style={{ width: '250px', height: '200px' }}></iframe>
                                                <span className="services-add-preview" onClick={() => OnVideoRemove(selectedVideo)}><ImCross className="cross-icon-service" /></span>
                                            </div>
                                            : null
                                    }

                                </div>


                                <button type="button" className="btn btn-primary btn-sub" onClick={() => {
                                    if (selectedImage?.length || selectedVideo?.length) {
                                        ImageSubmit(PropertyId);
                                        VideoSubmit();
                                    } else {
                                        toast.error("Please Selet Media")
                                    }
                                }}>Save & Next</button>
                            </div>
                                        </div>
                                        <div className="tab-pane tab-pane-parent fade show  px-0" id="floorplan"
                                            role="tabpanel">
                                            <div className="card bg-transparent border-0">
                                                <div className="card-body py-4 py-md-0 px-0">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card mb-6">
                                                                <div className="card-body p-6">
                                                                    <h3 className="card-title mb-6 text-heading fs-22 lh-15">Place the
                                                                        listing pin on the map</h3>

                                                                    <div className="main-wrapper">
                                                                        <MyMap selectedData={(e) => setAddressMap(e)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        <a href="#"
                                                            className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                                            <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                                className="fal fa-long-arrow-left"></i></span>Prev step
                                                        </a>
                                                        <button className="btn btn-lg btn-primary next-button mb-3">Next step
                                                            <span className="d-inline-block ml-2 fs-16"><i
                                                                className="fal fa-long-arrow-right"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane tab-pane-parent fade show  px-0" id="contacts"
                                            role="tabpanel">
                                            <div className="card bg-transparent border-0">
                                                <div className="card-body py-4 py-md-0 px-0">
                                                    <div className="card mb-6">
                                                        <div className="card-body p-6">
                                                            <h3 className="card-title mb-0 text-heading fs-22 lh-15">Listing Detail</h3>
                                                            <p className="card-text mb-5">Lorem ipsum dolor sit amet, consectetur
                                                                adipiscing elit</p>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <ul className="list-group list-group-no-border">
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="attic" />
                                                                                <label className="custom-control-label"
                                                                                    for="attic">Attic</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="basketball-court" />
                                                                                <label className="custom-control-label"
                                                                                    for="basketball-court">Basketball court</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="doorman" />
                                                                                <label className="custom-control-label" for="doorman">Doorman</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="front-yard" />
                                                                                <label className="custom-control-label" for="front-yard">Front
                                                                                    yard</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="lake-view" />
                                                                                <label className="custom-control-label" for="lake-view">Lake
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="ocean-view" />
                                                                                <label className="custom-control-label" for="ocean-view">Ocean
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="private-space" />
                                                                                <label className="custom-control-label" for="private-space">Private
                                                                                    space</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="sprinklers" />
                                                                                <label className="custom-control-label" for="sprinklers">Sprinklers</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="wine-cellar" />
                                                                                <label className="custom-control-label" for="wine-cellar">Wine
                                                                                    cellar</label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <ul className="list-group list-group-no-border">
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="attic-01" />
                                                                                <label className="custom-control-label"
                                                                                    for="attic-01">Attic</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="basketball-court-01" />
                                                                                <label className="custom-control-label"
                                                                                    for="basketball-court-01">Basketball
                                                                                    court</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="doorman-01" />
                                                                                <label className="custom-control-label" for="doorman-01">Doorman</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="front-yard-01" />
                                                                                <label className="custom-control-label" for="front-yard-01">Front
                                                                                    yard</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="lake-view-01" />
                                                                                <label className="custom-control-label" for="lake-view-01">Lake
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="ocean-view-01" />
                                                                                <label className="custom-control-label" for="ocean-view-01">Ocean
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="private-space-01" />
                                                                                <label className="custom-control-label"
                                                                                    for="private-space-01">Private space</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="sprinklers-01" />
                                                                                <label className="custom-control-label" for="sprinklers-01">Sprinklers</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="wine-cellar-01" />
                                                                                <label className="custom-control-label"
                                                                                    for="wine-cellar-01">Wine cellar</label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <ul className="list-group list-group-no-border">
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="attic-02" />
                                                                                <label className="custom-control-label"
                                                                                    for="attic-02">Attic</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="basketball-court-02" />
                                                                                <label className="custom-control-label"
                                                                                    for="basketball-court-02">Basketball
                                                                                    court</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="doorman-02" />
                                                                                <label className="custom-control-label" for="doorman-02">Doorman</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="front-yard-02" />
                                                                                <label className="custom-control-label" for="front-yard-02">Front
                                                                                    yard</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="lake-view-02" />
                                                                                <label className="custom-control-label" for="lake-view-02">Lake
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="ocean-view-02" />
                                                                                <label className="custom-control-label" for="ocean-view-02">Ocean
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="private-space-02" />
                                                                                <label className="custom-control-label"
                                                                                    for="private-space-02">Private space</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="sprinklers-02" />
                                                                                <label className="custom-control-label" for="sprinklers-02">Sprinklers</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="wine-cellar-02" />
                                                                                <label className="custom-control-label"
                                                                                    for="wine-cellar-02">Wine cellar</label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <ul className="list-group list-group-no-border">
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="attic-03" />
                                                                                <label className="custom-control-label"
                                                                                    for="attic-03">Attic</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="basketball-court-03" />
                                                                                <label className="custom-control-label"
                                                                                    for="basketball-court-03">Basketball
                                                                                    court</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="doorman-03" />
                                                                                <label className="custom-control-label" for="doorman-03">Doorman</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="front-yard-03" />
                                                                                <label className="custom-control-label" for="front-yard-03">Front
                                                                                    yard</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="lake-view-03" />
                                                                                <label className="custom-control-label" for="lake-view-03">Lake
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="ocean-view-03" />
                                                                                <label className="custom-control-label" for="ocean-view-03">Ocean
                                                                                    view</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="private-space-03" />
                                                                                <label className="custom-control-label"
                                                                                    for="private-space-03">Private space</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="sprinklers-03" />
                                                                                <label className="custom-control-label" for="sprinklers-03">Sprinklers</label>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item px-0 pt-0 pb-2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input"
                                                                                    name="features[]"
                                                                                    id="wine-cellar-03" />
                                                                                <label className="custom-control-label"
                                                                                    for="wine-cellar-03">Wine cellar</label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        <a href="#"
                                                            className="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                                            <span className="d-inline-block text-blue mr-2 fs-16"><i
                                                                className="fal fa-long-arrow-left"></i></span>Prev step
                                                        </a>
                                                        <button className="btn btn-lg btn-primary mb-3" type="submit">Submit property
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </main>
                    </div>
                </div>
            </div >
        </>
    )
}
export default DevAddProp;
