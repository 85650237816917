import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { ApiUrl, getToken, removeUserSession } from '../../utils/common';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import Header from '../header/header';
import Footer from '../footer/footer';
import ImageGalleryControl from '../ImageGallery/imagegallery';
import CurrencyFormat from "react-currency-format";
import { Modal } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import ReactStars from 'react-rating-stars-component';
import Slider from 'react-rangeslider';
import Avatar from 'react-avatar';
import { MainApiProtectedVersion } from '../../utils/axios/requests';





const SinglePropertyDetails = () => {
  const { id } = useParams();
  console.log(id,'rahul');
  const { state } = useLocation();
  const usertoken = getToken();
  const propertyId =id;
  console.log(propertyId,'gopal');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [imageData, setImageData] = useState([]);
  const [PropertyData, setPropertyData] = useState();
  const [floorPlanData, setFloorPlanData] = useState([]);
  const [videoData, setVideoData] = useState();
  const [averageReviewRating, setAverageReviewRating] = useState();
  const averageViews = averageReviewRating?.round_off;
  console.log(averageViews, 'hello star');
  const [percentageReview, setPercentegeReview] = useState()
  const [getReviewData, setGetReviewData] = useState([]);
  const [reviewName, setReviewName] = useState();
  const [reviewEmail, setReviewEmail] = useState();
  const [reviewDescription, setReviewDescription] = useState()
  const [reviewStar, setReviewStar] = useState()
  // lead modules states
  const [leadName, setLeadName] = useState();
  const [leadEmail, setLeadEmail] = useState();
  const [leadPhoneNo, setLeadPhoneNo] = useState();
  const [leadMessage, setLeadMessage] = useState();

  // modal states
  const [show, setShow] = useState(false);
  const [unitplanshow, setUnitplanShow] = useState(false);
  const [siteplanShow, setSitePlanShow] = useState(false);

  const [ImagePath, setImagePath] = useState();
  const [sitePlanImagePath, setSitePlanPath] = useState();
  const [unitPricePlanPath, setUnitPricePlanPath] = useState();

  const [index, setIndex] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)

  // setformat dates
  const constructionStartDate = moment(PropertyData?.construction_start_date).format("DD-MM-YYYY");
  const SaleStartData = moment(PropertyData?.sale_start).format("DD-MM-YYYY");
  const Amenities = PropertyData?.amenities_value;

  // get multimedia image api
  const MultiMedia = (data) => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/property/property-media/${propertyId}`
    }

    axios(option)
      .then(e => {
        setLoading(false)
        // setImageData(e?.data?.[1])
        setImageData([{
          media_path: data?.featured_image,
        }, ...e?.data?.[1]])
        setVideoData(e?.data?.[2])
      })
      .catch(err => {
        setLoading(false);
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })

  }

  useEffect(() => {
    window?.scrollTo(0, 0);
    MultiMedia()
    GetProperty()
    FloorPlans()
    MultiContact()
  }, [])



    // async function GetProperty(){
    //   const response = new MainApiProtectedVersion(propertyId)
    //   const res = await response.requstGetProperty()
    //   console.log(res.data)
    //   if(res.status===200){
    //     setPropertyData(res.data.data)
    //     MultiMedia(res.data.data);}
     
      

    //   // setUsers(users);
    // };

  // get property data api
  const GetProperty = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/property/property-details/${propertyId}`
    }

    axios(option)
      .then(e => {
        setPropertyData(e?.data)
        MultiMedia(e?.data);
      })
      .catch(() => {

      })
  }

  // get floorplan api

  const FloorPlans = () => {

    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/property/property-floor-plan/${propertyId}`
    }

    axios(option)
      .then(e => {
        setFloorPlanData(e?.data)
      })
      .catch(() => {

      })

  }
  // property lead form api
  const PropertyLeadSubmit = () => {
    if (!LeadFieldsValidation()) return;
    const data = {}
    data.name = leadName
    data.email = leadEmail
    data.phone = leadPhoneNo
    data.message = leadMessage
    data.property_id = propertyId


    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/lead/property-lead`
    };
    axios(option)
      .then(e => {
        if (e?.status === 200) {
          toast.success("Your Request Submit Successfully");
          window.location.reload()
        }
      })
      .catch(err => {
        toast.error(err?.response?.data?.email?.[0])
      })
  }

  const LeadFieldsValidation = () => {
    if (!leadName || leadName.trim() === "") {
      toast.error("Please Enter Your Name")
      return false
    }

    if (!leadEmail) {
      toast.error("Please Enter Your Email")
      return false
    }
    return true
  }

  // add contact property get
  const [multiContactData, setMultiContactData] = useState([]);
  const MultiContact = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/property/add-property-team/${propertyId}`
    }
    axios(option)
      .then(e => {
        setMultiContactData(e?.data)
      })
      .catch(() => {
      })
  }

  const ImageShowBiggerFloorPlan = (item) => {
    setImagePath(item?.media_path);
    setShow(true);
  }


  const ImageShowUnitPlan = () => {
    setUnitPricePlanPath(PropertyData?.unit_price_plan)
    setUnitplanShow(true);
  }

  const SitePlanShow = () => {
    setSitePlanPath(PropertyData?.site_plan);
    setSitePlanShow(true)

  }

  // average rating api
  const AverageRating = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/property-rating-count/${propertyId}`
    };
    axios(option)
      .then(e => {
        setAverageReviewRating(e?.data)
      })
      .catch(() => {

      })
  }

  const PercenatageReview = () => {

    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/property-rating-star/${propertyId}`
    };
    axios(option)
      .then(e => {
        setPercentegeReview(e?.data)
      })
      .catch(() => {

      })
  }

  const GetReview = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/property-rating-review/${propertyId}`
    };
    axios(option)
      .then(e => {
        setGetReviewData(e?.data)
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })
  }

  useEffect(() => {
    AverageRating();
    PercenatageReview();
    GetReview();
  }, [])

  const ratingChanged = (newRating) => {
    setReviewStar(newRating)
  };

  const ReviewRating = () => {
    if (!ValidateFields()) return;
    const data = {}
    data.rating = reviewStar
    data.email = reviewEmail
    data.name = reviewName
    data.other_comment = reviewDescription
    data.property_id = propertyId

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/auth/review/property-rating-review `
    };

    axios(option)
      .then(e => {
        if (e?.status === 200) {
          toast.success("Review Submit Sucessfully")
          window.location.reload();
        }
      })
      .catch(err => {
        toast.error(err?.response?.data?.email[0])
      })
  }

  const ValidateFields = () => {
    if (!reviewStar) {
      toast.error("Please Select Review Stars")
      return false
    }
    if (!reviewName || reviewName.trim() === "") {
      toast.error("Please Enter Your name")
      return false
    }
    if (!reviewEmail) {
      toast.error("Please Enter Your Email")
      return false;
    }
    if (!reviewDescription || reviewDescription.trim() === "") {
      toast.error("Please Enter Description")
      return false
    }
    return true
  }


  const Contactuser = (item) => {
    navigate('/userdetails', { state: item })
  }

  return (
    <>

      {
        loading ? <Loader /> : null
      }

      <Header />
      <main id="content" style={{marginTop: "100px"}}>
        <section className='header-r-logout margin-re-top-60'>
          <div className="container-fluid">
            <img src={PropertyData?.featured_image} alt="feature-image" />
          </div>
        </section>
        <section className='header-user-icon'>
          {/*  <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pt-lg-0 pb-3">
                <li className="breadcrumb-item fs-12 letter-spacing-087">
                  <a href=".">Home</a>
                </li>
                <li className="breadcrumb-item fs-12 letter-spacing-087">
                  <a href="listing-grid-with-left-filter.html">Listing</a>
                </li>
                <li className="breadcrumb-item fs-12 letter-spacing-087 active">Villa on Hollywood Boulevard</li>
              </ol>
            </nav>
          </div> */}
          <div className="container-fluid">
            <div className="position-relative margin-t40-add-gallery-section" data-animate="zoomIn">

              <div className="row galleries m-n1">
                <div className="col-lg-6 p-1">
                  <div className="item item-size-4-3">
                    <div className="card p-0 hover-zoom-in" onClick={() => { setIndex(0); setIsModalVisible(true) }}>
                      <a href className="card-img  featured-image-in-property-page"
                        data-gtf-mfp="false"
                        data-gallery-id="01"

                        style={{ backgroundImage: `url('${imageData?.[0]?.media_path}')` }}>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-1">
                  <div className="row m-n1">
                    {
                      imageData?.slice(1, 5)?.map((item, index) => (
                        <div className="col-md-6 p-1">
                          <div className="item item-size-4-3">
                            <div className="card p-0 hover-zoom-in" onClick={() => { setIndex(index + 1); setIsModalVisible(true) }}>
                              <a href className="card-img"

                                style={{ backgroundImage: `url('${item?.media_path}')` }}>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))
                    }

                  </div>
                </div>
              </div>
            </div>
          </div >

          {
            isModalVisible ? <Modal className="modal-add-contact sliderFullWrap" size={"lg"}
              show={isModalVisible} onHide={() => setIsModalVisible(false)} onBackdropClick={() => setIsModalVisible(false)} >
              <Modal.Body className="modal-body-property-page-slider">
                <ImageGalleryControl imageData={imageData} startIndex={index} />
              </Modal.Body>

            </Modal> : null
          }



        </section>
        <div className="primary-content pt-8">
          <div className="container">
            <div className="row">
              <article className="col-lg-8 pr-xl-7">
                <section className="pb-7 border-bottom">
                  <ul className="list-inline d-sm-flex align-items-sm-center mb-2">
                    {
                      PropertyData?.labels?.map((item) => {
                        return (
                          <li className="list-inline-item badge badge-orange mr-3">{item?.name}</li>
                        )
                      })
                    }

                  </ul>
                  <div className="d-sm-flex justify-content-sm-between">
                    <div>
                      <h2 className="fs-35 font-weight-600 lh-15 text-heading">{PropertyData?.name}</h2>
                      <p className="mb-0"><i className="fal fa-map-marker-alt mr-2"></i>{PropertyData?.street_name}</p>

                    </div>
                    <div className="mt-2 text-lg-right">
                      {
                        PropertyData?.price_from || PropertyData?.price_to ? <p className="fs-22 text-heading font-weight-bold mb-0">From {<CurrencyFormat value={PropertyData?.price_from} displayType='text' thousandSeparator={true} prefix={'$'} />} To {<CurrencyFormat value={PropertyData?.price_to} displayType='text' thousandSeparator={true} prefix={'$'} />}</p> : <p className="fs-22 text-heading font-weight-bold mb-0">Lease Rate: {<CurrencyFormat value={PropertyData?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} />}</p>
                      }

                      <p className="mb-0"><CurrencyFormat value={PropertyData?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} />/SqFt</p>
                    </div>
                  </div>
                  <h4 className="fs-22 text-heading mt-6 mb-2">Description</h4>
                  <p className="fs-16 mb-0 lh-214 ws-3">{PropertyData?.description}</p>
                </section>
                <section className="pt-6 border-bottom">
                  <h4 className="fs-22 text-heading mb-6">Facts and Features</h4>
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/b-1.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Property Type</h5>
                          {
                            PropertyData?.property_types?.map((item) => {
                              return (
                                <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.name}</p>
                              )
                            })
                          }

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/b-2.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Property Status</h5>
                          <p className="mb-0 fs-13 font-weight-bold text-heading">2020</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <img className="sz-32" src="../../images/b-3.png" alt="property-type" />
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Selling Status</h5>
                          <p className="mb-0 fs-13 font-weight-bold text-heading">{PropertyData?.selling_status?.name}</p>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <img className="sz-32" src="../../images/b-5.png" alt="property-type" />
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Built Year</h5>
                          <p className="mb-0 fs-13 font-weight-bold text-heading">{PropertyData?.year_built}</p>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/b-4.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">SQFT</h5>
                          <p className="mb-0 fs-13 font-weight-bold text-heading">{PropertyData?.size}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="sz-32 icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/b-5.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Construction Start Date</h5>
                          <p className="mb-0 fs-13 font-weight-bold text-heading">{constructionStartDate}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="sz-32 icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/b-8.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Sale Start</h5>
                          <p className="mb-0 fs-13 font-weight-bold text-heading">{SaleStartData}</p>
                        </div>
                      </div>
                    </div>

                    {
                      PropertyData?.maintenance_fee ? <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <img className="sz-32" src="../../images/b-9.png" alt="property-type" />
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Maintenance Fee</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading"><CurrencyFormat value={PropertyData?.maintenance_fee} displayType='text' thousandSeparator={true} prefix={'$'} /></p>
                          </div>
                        </div>
                      </div> : null
                    }


                    <div className="col-lg-4 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="sz-32 icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/b-10.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <a href={PropertyData?.website} target="_blank" className='btn web-link-prop bg-blue text-white hover-saffron'>Project Website</a>
                        </div>
                      </div>
                    </div>
                    {
                      PropertyData?.virtuallink ?
                    <div className="col-lg-4 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="sz-32 icon icon-family fs-20 text-primary">
                            <img className="sz-32" src="../../images/virtual-reality.png" alt="property-type" />
                          </div>
                        </div>
                        <div className="media-body">
                          <a href={PropertyData?.virtuallink} target="_blank" className='btn web-link-prop bg-blue text-white hover-saffron'>Virtual Tour</a>
                        </div>
                      </div>
                    </div>:null
                    }


                    <div className='row'>
                      <div className="col-lg-12 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-primary">
                              <img className="sz-32" src="../../images/b-1.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">No Of Building</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">
                              {PropertyData?.no_of_buildings}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        {
                          PropertyData?.property_building?.map((item) => {
                            return (
                              <div className="media mb-4">
                                <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                  <div className="icon icon-family fs-20 text-primary">
                                    <img className="sz-32" src="../../images/b-11.png" alt="property-type" />
                                  </div>
                                </div>
                                <div className="media-body">
                                  <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Building Name</h5>
                                  <p className="mb-0 fs-13 font-weight-bold text-heading">
                                    {item?.name}
                                  </p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>



                      <div className='col-md-4'>
                        {
                          PropertyData?.property_building?.map((item) => {
                            return (
                              <div className="media mb-4">
                                <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                  <div className="icon icon-family fs-20 text-primary">
                                    <img className="sz-32" src="../../images/b-12.png" alt="property-type" />
                                  </div>
                                </div>
                                <div className="media-body">
                                  <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Floors</h5>
                                  <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.no_of_floors}</p>
                                </div>
                              </div>
                            )
                          })
                        }

                      </div>

                      <div className='col-md-4'>
                        {
                          PropertyData?.property_building?.map((item) => {
                            return (
                              <div className="media mb-4">
                                <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                  <img className="sz-32" src="../../images/b-13.png" alt="property-type" />
                                </div>
                                <div className="media-body">
                                  <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Units</h5>
                                  <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.no_of_units}</p>
                                </div>
                              </div>
                            )
                          })
                        }

                      </div>
                    </div>


                  </div>
                </section>

                <section className="pt-6 border-bottom pb-4">
                  <h4 className="fs-22 text-heading mb-4">Additional Details</h4>
                  <div className="row">
                    <div className="col-sm-6 mb-0 d-flex">
                      <ul className='fa-ul'>
                        {

                          multiContactData?.length ? <div className='fs-14 font-weight-500 text-heading'>Builder(s):</div> : null
                        }

                        {
                          multiContactData?.map(function (item) {
                            if (item.builder === true) {
                              return (

                                <li style={{ textDecoration: 'underline' }} onClick={() => Contactuser(item)}><a className='text-blue hover-saffron-text' >{item.name}</a></li>

                              )
                            }

                          })
                        }
                      </ul>
                    </div>
                    <div className="col-sm-6 mb-0 d-flex">
                      <ul className='fa-ul'>
                        {

                          multiContactData?.length ? <div className='fs-14 font-weight-500 text-heading'>Agents(s):</div> : null

                        }

                        {
                          multiContactData?.map(function (item) {
                            if (item.agent_key === true) {
                              return (

                                <li style={{ textDecoration: 'underline' }} onClick={() => Contactuser(item)}><a className='text-blue hover-saffron-text' >{item.name}</a></li>

                              )
                            }

                          })
                        }
                      </ul>
                    </div>
                    <div className="col-sm-6 mb-0 d-flex">
                      <ul className='fa-ul'>
                        {

                          multiContactData?.length ? <div className='fs-14 font-weight-500 text-heading'>Architect(s):</div> : null

                        }
                        {
                          multiContactData?.map(function (item) {
                            if (item.architect === true) {
                              return (


                                <li style={{ textDecoration: 'underline' }} onClick={() => Contactuser(item)}><a className='text-blue hover-saffron-text' >{item.name}</a></li>

                              )
                            }

                          })
                        }
                      </ul>
                    </div>
                    <div className="col-sm-6 mb-0 d-flex">
                      <ul className='fa-ul'>
                        {
                          multiContactData?.length ? <div className='fs-14 font-weight-500 text-heading'>Marketing Company(S):</div> : null
                        }
                        {
                          multiContactData?.map(function (item) {
                            if (item.marketing_company === true) {
                              return (


                                <li style={{ textDecoration: 'underline' }} onClick={() => Contactuser(item)}><a className='text-blue hover-saffron-text' >{item.name}</a></li>

                              )
                            }

                          })
                        }
                      </ul>
                    </div>
                    <div className="col-sm-6 mb-0 d-flex">
                      <ul className='fa-ul'>
                        {
                          multiContactData?.length ? <div className='fs-14 font-weight-500 text-heading'>Sales Company(S):</div> : null
                        }
                        {
                          multiContactData?.map(function (item) {
                            if (item.sales_company === true) {
                              return (


                                <li style={{ textDecoration: 'underline' }} onClick={() => Contactuser(item)}><a className='text-blue hover-saffron-text' >{item.name}</a></li>
                              )
                            }

                          })
                        }
                      </ul>
                    </div>
                    <div className="col-sm-6 mb-0 d-flex">
                      <ul className='fa-ul'>
                        {
                          multiContactData?.length ? <div className='fs-14 font-weight-500 text-heading'>Interior Designer(S):</div> : null
                        }
                        {
                          multiContactData?.map(function (item) {
                            if (item.interior_designer === true) {
                              return (


                                <li style={{ textDecoration: 'underline' }} onClick={() => Contactuser(item)}><a className='text-blue hover-saffron-text' >{item.name}</a></li>
                              )
                            }

                          })
                        }
                      </ul>
                    </div>
                  </div>
                </section>

                {/* Ammenities Part */}
                <section className="pt-6 border-bottom pb-4">
                  <h4 className="fs-22 text-heading mb-4">Amenities</h4>
                  <ul className="list-unstyled mb-0 row no-gutters">
                    {
                      Amenities?.map((item) => {
                        return (
                          <li className="col-sm-3 col-6 mb-2"><i className="far fa-check mr-2 text-blue"></i>{item?.name}</li>
                        )
                      })
                    }
                  </ul>
                </section>

                {
                  floorPlanData?.[0]?.building_floor_plan ? <section className="py-6 border-bottom">
                    <h4 className="fs-22 text-heading mb-4">Floor Plan</h4>
                    {/* floor plan one */}
                    <Accordion>
                      {
                        floorPlanData?.map((item) => {
                          return (
                            <AccordionItem aria-expanded="true">
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <div className="floor-title">
                                    <h4>{item?.name}</h4>
                                  </div>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              {
                                item?.building_floor_plan?.map((item) => {
                                  return (
                                    <AccordionItemPanel>
                                      <div className="floor-plan-sr">
                                        <div className="card border-0 shadow-xxs-2">
                                          <div id="collapse-01"
                                            className="collapse show"
                                            aria-labelledby="floor-plans-01"
                                            data-parent="#accordion-01">
                                            <div className="card-body card-body card-body-height col-sm-6 offset-sm-3 mb-3 img-prop" onClick={() => ImageShowBiggerFloorPlan(item)} style={{ cursor: 'pointer' }}>
                                              <img src={item?.media_path} className="card-img card_img_auto" alt="Floor Plans" />
                                            </div>
                                            <div className='section-getfloor-descp'>
                                              <h4>Description</h4>
                                              <p>{item?.description}</p>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </AccordionItemPanel>
                                  )
                                })
                              }

                            </AccordionItem>
                          )
                        })
                      }
                    </Accordion>
                  </section> : null
                }


                {/* unit plans */}

                {
                  PropertyData?.unit_price_plan ? <section className="py-6 border-bottom">
                    <h4 className="fs-22 text-heading mb-4">Availability Plan</h4>
                    <Accordion>
                      <AccordionItem aria-expanded="true">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="floor-title">
                              <h4>Availability Plan</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="floor-plan-sr">
                            <div className="card border-0 shadow-xxs-2">
                              <div id="collapse-01"
                                className="collapse show"
                                aria-labelledby="floor-plans-01"
                                data-parent="#accordion-01">
                                <div className="card-body card-body card-body-height col-sm-6 offset-sm-3 mb-3 img-prop" onClick={() => ImageShowUnitPlan()} style={{ cursor: 'pointer' }}>
                                  <img src={PropertyData?.unit_price_plan} className="card-img card_img_auto" alt="unit Plans" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </section> : null
                }


                {/* site plan */}
                {
                  PropertyData?.site_plan ? <section className="py-6 border-bottom">
                    <h4 className="fs-22 text-heading mb-4">Site Plan</h4>
                    <Accordion>
                      <AccordionItem aria-expanded="true">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="floor-title">
                              <h4>Site Plan</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="floor-plan-sr">
                            <div className="card border-0 shadow-xxs-2">
                              <div id="collapse-01"
                                className="collapse show"
                                aria-labelledby="floor-plans-01"
                                data-parent="#accordion-01">
                                <div className="card-body card-body card-body-height col-sm-6 offset-sm-3 mb-3 img-prop" onClick={() => SitePlanShow()} style={{ cursor: 'pointer' }}>
                                  <img src={PropertyData?.site_plan} className="card-img card_img_auto" alt="unit Plans" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </section> : null
                }

                {/* project brochure */}
                {
                  PropertyData?.project_brochure ? <section className="py-6 border-bottom">
                    <h4 className="fs-22 text-heading mb-4">Project Brochure</h4>
                    <Accordion>
                      <AccordionItem aria-expanded="true">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="floor-title">
                              <h4>Project Brochure</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="floor-plan-sr">
                            <div className="card border-0 shadow-xxs-2">
                              <div id="collapse-01"
                                className="collapse show"
                                aria-labelledby="floor-plans-01"
                                data-parent="#accordion-01">
                                <div className="card-body card-body col-sm-6 offset-sm-3 mb-3 img-prop">
                                  <iframe src={PropertyData?.project_brochure} frameBorder="0" style={{ width: '710px', height: '300px' }}></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </section> : null
                }
                {/* permitted uses section */}
                {
                  PropertyData?.permitted_uses_image?.length ? <section className="py-6 ">
                    <h4 className="fs-22 text-heading mb-4">Permitted Uses</h4>
                    <Accordion>
                      <AccordionItem aria-expanded="true">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="floor-title">
                              <h4>Permitted Uses</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="floor-plan-sr">
                            <div className="card border-0 shadow-xxs-2">
                              <div id="collapse-01"
                                className="collapse show"
                                aria-labelledby="floor-plans-01"
                                data-parent="#accordion-01">
                                <div className="card-body card-body col-sm-6 offset-sm-3 mb-3 img-prop">
                                  <iframe src={PropertyData?.permitted_uses_image} frameBorder="0" style={{ width: '710px', height: '300px' }}></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </section> : null
                }

                {/* floorplan modal */}
                <Modal show={show} className="modal-add-contact property-big-img-modal">
                  <div style={{ width: '800px' }}>
                    {/* <Modal.Header> */}
                    <div className='cross-icon-modal-close'>
                      <ImCross onClick={() => setShow(false)} className="icon-cross-modal" />
                    </div>
                    {/* </Modal.Header> */}
                    <Modal.Body>
                      <img src={ImagePath} alt="image" className='image-modal-property-page'></img>
                    </Modal.Body>
                  </div>
                </Modal>

                {/* unit plan modal */}
                <Modal show={unitplanshow} className="modal-add-contact property-big-img-modal">
                  <div style={{ width: '800px' }}>
                    {/* <Modal.Header> */}
                    <div className='cross-icon-modal-close'>
                      <ImCross onClick={() => setUnitplanShow(false)} className="icon-cross-modal" />
                    </div>
                    {/* </Modal.Header> */}
                    <Modal.Body>
                      <img src={unitPricePlanPath} alt="image" className='image-modal-property-page'></img>
                    </Modal.Body>
                  </div>
                </Modal>

                {/* site plan modal */}
                <Modal show={siteplanShow} className="modal-add-contact property-big-img-modal">
                  <div style={{ width: '800px' }}>
                    {/* <Modal.Header> */}
                    <div className='cross-icon-modal-close'>
                      <ImCross onClick={() => setSitePlanShow(false)} className="icon-cross-modal" />
                    </div>
                    {/* </Modal.Header> */}
                    <Modal.Body>
                      <img src={sitePlanImagePath} alt="image" className='image-modal-property-page'></img>
                    </Modal.Body>
                  </div>
                </Modal>

                <section className="py-6 border-bottom">
                  <h4 className="fs-22 text-heading lh-15 mb-5 pt-3">Rating & Reviews</h4>
                  <div className="card border-0 mb-4">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-sm-4 mb-6 mb-sm-0">
                          {/* average rating part */}
                          <div className="bg-gray-01 rounded-lg pt-2 px-6 pb-6">
                            <h5 className="fs-16 lh-2 text-heading mb-6">
                              Avarage User Rating
                            </h5>
                            {
                              averageReviewRating?.round_off ? <p className="fs-40 text-heading font-weight-bold mb-6 lh-1">{averageReviewRating?.round_off}<span
                                className="fs-18 text-gray-light font-weight-normal">/5</span></p> : null
                            }

                            {
                              averageReviewRating?.round_off ?
                                <ul className="list-inline">
                                  <ReactStars value={averageReviewRating?.round_off} edit={false} size={40} isHalf={true} a11y={true} />
                                </ul> : <ReactStars value="0" edit={false} size={40} />
                            }

                          </div>


                        </div>
                        <div className="col-lg-8 col-sm-4">
                          <h4 className="fs-16 lh-2 text-heading mb-2 mb-0">
                            Rating Breakdown
                          </h4>
                          {/* first rating */}
                          <div className="d-flex align-items-center mx-n1">

                            <div className="list-inline d-flex  mb-0 ">
                              <ReactStars value={5} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                            </div>

                            <div className="d-block width-manage ">

                              <Slider min={0} max={100} value={percentageReview?.five_star_avg}>

                              </Slider>

                            </div>
                            <div className="text-muted px-1">{percentageReview?.five_star_avg}%</div>
                          </div>

                          {/* second rating */}
                          <div className="d-flex align-items-center mx-n1">

                            <ul className="list-inline d-flex  mb-0">
                              <ReactStars value={4} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                            </ul>

                            <div className="d-block width-manage ">

                              <Slider min={0} max={100} value={percentageReview?.four_star_avg}>

                              </Slider>

                            </div>
                            <div className="text-muted px-1">{percentageReview?.four_star_avg}%</div>
                          </div>

                          {/* third rating */}
                          <div className="d-flex align-items-center mx-n1">

                            <ul className="list-inline d-flex  mb-0">
                              <ReactStars value={3} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                            </ul>

                            <div className="d-block width-manage ">

                              <Slider min={0} max={100} value={percentageReview?.three_star_avg}>

                              </Slider>

                            </div>
                            <div className="text-muted px-1">{percentageReview?.three_star_avg}%</div>
                          </div>

                          {/* fourth rating */}
                          <div className="d-flex align-items-center mx-n1">

                            <ul className="list-inline d-flex  mb-0">
                              <ReactStars value={2} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                            </ul>

                            <div className="d-block width-manage ">

                              <Slider min={0} max={100} value={percentageReview?.two_star_avg}>

                              </Slider>

                            </div>
                            <div className="text-muted px-1">{percentageReview?.two_star_avg}%</div>
                          </div>

                          {/* fifth rating */}
                          <div className="d-flex align-items-center mx-n1">

                            <ul className="list-inline d-flex  mb-0">
                              <ReactStars value={1} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                            </ul>

                            <div className="d-block width-manage ">


                              <Slider min={0} max={100} value={percentageReview?.one_star_avg}>

                              </Slider>

                            </div>
                            <div className="text-muted px-1">{percentageReview?.one_star_avg}%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {
                  getReviewData?.length ? <section className="py-6 ">
                    <div className="card border-0 mb-4">
                      <div className="card-body p-0">
                        <h4 className="fs-16 lh-2 text-heading mb-5 mb-0">
                          Reviews</h4>

                        {
                          getReviewData?.map((item) => {
                            return (
                              <div className="row border-bottom mb-6 pb-6 mb-6">
                                <div className="col-md-3 mb-4 mb-md-0">
                                  <div className="media">
                                    <div className=" h-70 mr-2">
                                      <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                                    </div>
                                    <div className="media-body mt-2">
                                      <p className="fs-16 font-weight-500 text-heading mb-0 lh-15 add-mleft-for-avatar">
                                        {item?.name}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-9">
                                  <div className="d-flex mb-1">
                                    <ul className="list-inline mb-2 mb-lg-0">
                                      <li className="list-inline-item fs-13 text-heading font-weight-500">
                                        <ReactStars value={item?.rating} edit={false} size={20}></ReactStars>

                                      </li>
                                    </ul>
                                    <p className="ml-auto mb-0 text-muted">

                                      {moment(item?.created_at).format('DD-MM-YYYY')}
                                    </p>
                                  </div>
                                  <p className="mb-0">{item?.other_comment}</p>
                                </div>
                              </div>
                            )
                          })
                        }


                      </div>
                    </div>
                  </section> : null
                }

                {
                  usertoken ? <section className="py-6 border-bottom">
                    <div className="card border-0">
                      <div className="card-body p-0">
                        <h3 className="fs-16 lh-2 text-heading mb-4">Write A Review</h3>
                        <form>
                          <div className="form-group mb-4 d-flex justify-content-start">
                            {/* <RatingReviews /> */}
                            <ReactStars
                              count={5}
                              onChange={ratingChanged}
                              size={36}
                              activeColor="#f6b500"
                            />
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group mb-4">
                                <input placeholder="Your Name" className="form-control form-control-lg border-0"
                                  type="text" name="name" onChange={(e) => setReviewName(e?.target?.value)} value={reviewName} />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group mb-4">
                                <input type="email" placeholder="Email" name="email"
                                  className="form-control form-control-lg border-0" onChange={(e) => setReviewEmail(e?.target?.value)} value={reviewEmail} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-6">
                            <textarea className="form-control form-control-lg border-0" placeholder="Your Review"
                              name="message" rows="5" onChange={(e) => setReviewDescription(e?.target?.value)} value={reviewDescription}></textarea>
                          </div>
                          <button type="button" className="btn btn-lg bg-blue text-white hover-saffron px-10 mb-2" onClick={ReviewRating}>Submit</button>
                        </form>
                      </div>
                    </div>
                  </section> : null
                }



              </article>
              {/* lead form */}
              <aside className="col-lg-4 pl-xl-4 primary-sidebar sidebar-sticky" id="sidebar">
                <div className="primary-sidebar-inner">
                  <div className="card border-0 widget-request-tour">
                    <ul className="nav nav-tabs d-flex  ">
                      <li className="nav-item ">
                        <a className="py-2 bg-blue text-white px-3" >I am Interested</a>
                      </li>
                    </ul>
                    <div className="card-body px-sm-6 shadow-xxs-2 pb-5 pt-0">
                      <form>
                        <div className="pt-1 pb-0 px-0 d-block">
                          <div className="pt-5">
                            {/* lead form data */}
                            <div className="form-group mb-2 position-add-validate-symbol">
                              <input type="text" className="form-control form-control-lg border-0"
                                placeholder="Your Name" onChange={(e) => setLeadName(e?.target?.value)} value={leadName} />
                              <span className="asterisk_input"></span>
                            </div>
                            <div className="form-group mb-2 position-add-validate-symbol">
                              <input type="email" className="form-control form-control-lg border-0"
                                placeholder="Your Email" onChange={(e) => setLeadEmail(e?.target?.value)} value={leadEmail} />
                              <span className="asterisk_input"></span>
                            </div>
                            <div className="form-group mb-2">
                              <CurrencyFormat className="form-control form-control-lg border-0"
                                placeholder="Your phone" format="+1 (###) ###-####" mask="_" onChange={(e) => setLeadPhoneNo(e?.target?.value)} value={leadPhoneNo} />
                            </div>
                            <div className="form-group mb-4">
                              <textarea className="form-control border-0" rows="4" placeholder='Your Message?' onChange={(e) => setLeadMessage(e?.target?.value)} value={leadMessage}> </textarea>
                            </div>
                            <button type="button" className="btn bg-blue text-white hover-saffron btn-lg btn-block rounded" onClick={PropertyLeadSubmit}>Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* video-sidebar */}
                {
                  videoData?.length ?
                    <div>
                      {
                        videoData?.map((item) => {
                          return (
                            <div className="primary-sidebar-inner mt-10">
                              <div className="card border-0 widget-request-tour">
                                <ul className="nav nav-tabs d-flex video-tabs">
                                  <li className="nav-item">
                                    <a className="py-2 bg-blue text-white px-3" >Property Video</a>
                                  </li>
                                </ul>
                                <div className="card-body px-sm-6 shadow-xxs-2 pb-5 pt-0">
                                  <iframe className="video-sidebar header-r-logout" width="250" height="250" src={item?.media_path} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  <iframe className="video-sidebar header-user-icon" width="300" height="250" src={item?.media_path} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                    : null
                }

                {/* end video */}
              </aside>
              {/* end lead form */}
            </div>
          </div>
        </div>

      </main>

      <Footer />
    </>
  )
}
export default SinglePropertyDetails;