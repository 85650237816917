import React, { useState, useEffect } from 'react'
import axios from '../../../../utils/axios/main'; // importing axios from customAxios
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';

export default function TaxList() {

  const [loading, setloading] = useState(false)
  const [Tax, setTax] = useState([])


  useEffect(() => {
    setloading(false)


    axios.get('/auth/subscription/tax/').then(response => {
      setTax(response.data)

    }
    )
      .catch(error => {
        alert(error)
      })
    

  }, [loading])


  return (
    <div>
      {Tax ? (<>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>id</th>
              <th>Display Name</th>
              <th>Description</th>
              <th>country</th>
              <th>country</th>
              <th>percentage</th>

            </tr>
          </thead>
          {Tax.map((item, key) => {


            return (<>

              <tbody>

                <tr  key={key}>
                  <td>{item.id}</td>
                  <td>{item.display_name}</td>
                  <td>{item.description}</td>
                  <td>{item.country}</td>
                  <td>{item.jurisdiction}</td>
                  <td>{item.percentage}</td>

                </tr>
              </tbody>
            </>)
          })}

        </Table>

      </>) : (<>

        not yet coupon
      </>)}


    </div>
  )
}
