import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Components/footer/footer";
import Header from "../../Components/header/header";
import axios from "axios";
import SearchMap from "../../Components/Map/searchMap";
import CurrencyFormat from 'react-currency-format';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiUrl } from "../../utils/common";
import { toast } from "react-toastify";
import PropertySearchBar from "../../Components/Map/propertySearchBar";
import SearchLocationInput from "../../Components/Map/SearchLocationInput";
import Loader from "../../Components/Loader";
import Select from "react-select";


const PropertyList = () => {
  const state = useLocation()
  console.log(state, 'state16');
  const navigate = useNavigate()
  const SearchData = state?.state?.Data;
  console.log(SearchData,'SearchData');
  const TypeCheck = state?.state?.type;
  // const FeaturedProjects = state?.state?.featuredPropertyData;
  // console.log(FeaturedProjects,'FeaturedProjects22');
  const [propertyType, setPropertyType] = useState();
  const FooterProperty = state?.state?.footerType;
  console.log(propertyType, 'propertyType');
  const [constructionStatus, setConstructionStatus] = useState();
  const [sellingStatus, setSelligStatus] = useState();
  const [labels, setLabels] = useState();
  const [priceperSf, setPricePerSf] = useState();
  const [keyWord, setKeyword] = useState();
  // config dropdown api
  const [getDropwonData, setGetDropdownData] = useState();
  const [searchAddress, setSearchAddress] = useState();

  const [PropertyShow, setPropertyShow] = useState(SearchData);
  console.log(PropertyShow,'PropertyShow');
  const [loading, setLoading] = useState(false)
  const [addressGet, setAddressGet] = useState();


  useEffect(() => {
    window?.scrollTo(0, 0);
    if (SearchData?.length) {
      setPropertyShow(SearchData)
    }
  }, [SearchData])


  // advance search property states

  const [search, setSearch] = useState(null);

  const KeywordSearch = () => {
    const data = (search != null) ? search : "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: { "data": data },
      url: `${ApiUrl()}/api/property/filter-property?search=${data}`
    };
    axios(option)
      .then(e => {
        console.log(e, 'e');
      })
      .catch(err => {

      })
  }
  useEffect(() => {
    KeywordSearch();
  }, [search])


  const PropertyDropdowns = () => {
    const data = {}
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/property/config-property`
    };
    axios(option)
      .then(e => {
        // setGetDropdownData(e?.data)
        setGetDropdownData(e?.data)
      })
      .catch(err => {
      });
  }
  useEffect(() => {
    PropertyDropdowns()
  }, [])

  const [lensSearch, setLensSearch] = useState()

  useEffect(() => {
    // const SubmitAdvanceSearch = () => {
    if (addressGet || propertyType || constructionStatus || lensSearch || sellingStatus || labels || FooterProperty) {
      setLoading(true)
      const data = {}
      data.lat = searchAddress?.geometry?.location?.lat();
      data.lng = searchAddress?.geometry?.location?.lng();
      data.location = searchAddress?.formatted_address;
      data.construction_status_id = constructionStatus;
      data.selling_status_id = sellingStatus;
      data.label_id = labels;
      data.property_type_id = propertyType;
      data.price_per_sq_feet = priceperSf;
      data.keyword = keyWord;
      const option = {
        method: 'POST', headers: {
          'access-control-allow-origin': '*',
          'content-type': 'application/json',
        },
        data: JSON.stringify(data),
        url: `${ApiUrl()}/api/property/advance-filter-property`,
      };
      axios(option)
        .then(e => {
          setLoading(false)
          setPropertyShow(e?.data)

        })
        .catch(err => {
          setLoading(false)
        })
    }

    // }
  }, [propertyType, constructionStatus, lensSearch, sellingStatus, addressGet, labels, FooterProperty])

 /*  const RedirectProperty = (item) => {
    navigate('/singlepage', { state: item })
  } */

  const ResetButton = () => {
    setLoading(true)
    window.location.reload()
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <Header />
      <main id="content">
        <section className="bg-blue h-lg-80 mt-100 list-Section-m">
          <div className="container-fluid">
            <form className="property-search d-lg-block width-incenter" /* style={{ paddingTop: '8px' }} */>
              <div className="row align-items-lg-center">
                <div className="d-md-flex add-position-input blue-spacing">
                  <div className="form-group mb-0 position-relative flex-md-3 mt-3 mt-md-0 form-align search-in">
                    <PropertySearchBar Onchange={(e) => setSearchAddress(e)} PassingAddress={(e) => setAddressGet(e)} />
                    {/* <button type="button" className="btn position-absolute pos-fixed-right-center p-0 text-heading fs-20 mr-4 shadow-none active-border-none" onClick={() => setLensSearch(!lensSearch)}>
                      <i className="far fa-search"></i>
                    </button> */}
                  </div>
                  <div className="col-md-7 add-inline-flex-prop list-grid-set">
                    <div className="search-ty">
                      <select className="form-control border-0 shadow-none form-control-lg selectpicker bg-white"
                        name="Property Type"
                        data-style="btn-lg py-2 h-52 bg-white" onChange={(e) => setPropertyType(e?.target?.value)} value={propertyType} >
                        <option className="p-holder-color option-set">Property Type</option>
                        {
                          getDropwonData?.propertyType?.map((item, index) => {
                            return (
                              <option className="option-set" value={item?.id}>{item?.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="search-ty">
                      <select className="form-control border-0 shadow-none form-control-lg selectpicker bg-white"
                        name="Construction Status"
                        data-style="btn-lg py-2 h-52 bg-white" onChange={(e) => setConstructionStatus(e?.target?.value)} value={constructionStatus} >
                        <option className="p-holder-color">Construction Status</option>
                        {
                          getDropwonData?.ConstructionStatus?.map((item, index) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="search-ty">
                      <select className="form-control border-0 shadow-none form-control-lg selectpicker bg-white"
                        name="Selling Status"
                        data-style="btn-lg py-2 h-52 bg-white" onChange={(e) => setSelligStatus(e?.target?.value)} value={sellingStatus} >
                        <option className="p-holder-color">Selling Status</option>
                        {
                          getDropwonData?.SellingStatus?.map((item, index) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="search-ty">
                      <select className="form-control border-0 shadow-none form-control-lg selectpicker bg-white"
                        name="labels"
                        data-style="btn-lg py-2 h-52 bg-white" onChange={(e) => setLabels(e?.target?.value)} value={labels}>
                        <option className="p-holder-color">For Lease/Sale</option>
                        {
                          getDropwonData?.labels?.map((item, index) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="search-ty">
                      <input type="number" className="form-control form-control-lg border-0 shadow-none   bg-white placeholder-muted" placeholder="Start Price(SFT)" onChange={(e) => setPricePerSf(e?.target?.value)} value={priceperSf} />
                    </div>

                    <div className="search-ty">
                      <input type="text" className="form-control form-control-lg border-0 shadow-none   bg-white placeholder-muted" placeholder="Keyword" onChange={(e) => setKeyword(e?.target?.value)} value={keyWord} />
                    </div>
                  </div>
                  <div className="d-flex search-reset">
                    {
                      keyWord?.length || priceperSf?.length ? <button type="button" className="btn property-search-icon bg-white text-dark hover-grey" onClick={() => setLensSearch(!lensSearch)}>
                        <span className="fs-18  rounded-0"><i
                          className="fal fa-search"></i></span>
                      </button> : null
                    }

                  <div className="ad-sr">
                    <button type="button" onClick={() => ResetButton()}
                      className="btn advanced-search btn-accent h-ch-56 h-lg-100 w-100 shadow-none text-secondary radius-left-tb fs-14 fs-sm-16 font-weight-600 text-center d-flex align-items-center button-change">
                    </button>
                  </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        <section className="position-relative">
          <div className="container-fluid px-0 property-margin">
            <div className="row no-gutters">

              <div className="col-xl-6 pt-7 pb-11 order-2 order-xl-1 px-3 px-xxl-8 propertylist-scroll">

                {
                  PropertyShow?.length ? <h2 className="text-heading fs-22 lh-15"> {TypeCheck} Properties
                    <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2 colour-change-in-listpage">{PropertyShow?.length}</span>
                  </h2> : null
                }


                {
                  PropertyShow?.length ?
                    <div className="row mx-lg-n2">
                      {
                        PropertyShow?.map((item) => {
                        console.log(item.id);
                        
                          return (
                            <div className="col-lg-4 col-sm-6 pb-6 px-lg-2" data-animate="fadeInUp">
                              <div /* onClick={() => RedirectProperty(item)} */ style={{ cursor: 'pointer' }}>
                              <Link to={`/property/details/${item.id}`}>

                                <div className="card border-0 hover-change-image">
                                  <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                    <img src={item?.featured_image}
                                      alt="Garden Gingerbread House" className="image-propertylist-page" />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between h-100">
                                      <div>
                                        {
                                          item?.labels?.map((item) => {
                                            return (
                                              <span className="badge badge-orange mr-2">{item?.name}</span>
                                            )

                                          })
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body p-0 border">
                                    <h2 className="my-0 mt-1"><a
                                      className="fs-16 text-dark lh-2 px-1">{item?.name}</a>
                                    </h2>
                                    <p className="text-gray-light font-weight-500 mb-1 px-1">{item?.street_name}</p>
                                    <div className="border-t">
                                      <ul className="list-inline d-flex mb-0 flex-wrap mr-n5 px-1">
                                        <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                          data-toggle="tooltip" title="Size">
                                          <img src="images/area.png" className="icon icon-bedroom fs-18 text-heading mr-1 width-32" />
                                          {item?.size} Sq.Ft
                                        </li>
                                      </ul>
                                      {item?.labels?.find(i => i?.id)?.name === "For Lease" ? (
                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={item?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                          className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>
                                      ) : (
                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                          className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div> : <h3>No Properties</h3>
                }

                {/* <div className="text-center mt-3">
                  <a href="#"
                    className="btn btn-lg btn-outline-accent text-heading shadow-none border-2x d-inline-flex align-items-center">View
                    more
                    <i className="fal fa-plus-circle ml-1 text-primary fs-18"></i>
                  </a>
                </div> */}
              </div>


              <div className="col-xl-6 order-1 order-xl-2 primary-map map-sticky overflow-hidden" id="map-sticky">
                <div className="primary-map-inner">
                  <div className="mapbox-gl map-grid-property-01 xl-vh-100" >
                    <SearchMap searchData={PropertyShow} addressGet={addressGet} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  )
}
export default PropertyList;