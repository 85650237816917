import React, { useEffect, useState } from "react";
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import { ApiUrl, GetPlanId, GetPubliShableKey, GetSessionId, getToken, PlanId, PubliShableKey, SessionId, setUserSession } from "../utils/common";
import axios from "axios";
import Loader from "../Components/Loader";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { BiErrorCircle } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./paymentcheckout";
import { Link } from 'react-router-dom';

// const stripePromise = loadStripe(`${GetPubliShableKey()}`);
const SubcriptionPackages = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [getPlans, setGetPlans] = useState([]);
  const DeveloperPlans = getPlans?.[0]
  const AgentPlans = getPlans?.[1]
  const OtherPlans = getPlans?.[2]

  // modal input fields
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // login states
  const [loginCheck, setLoginCheck] = useState(false);
  const [loginUserName, setLoginUserName] = useState('');
  const [loginPassWord, setLoginPassword] = useState('');
  const [loginIsShowPassword, setLoginIsShowPassword] = useState(false)

  const [fullName, setFullName] = useState();
  const [userName, setUserName] = useState();
  const [passWord, setPassWord] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [checkPassword, setCheckPassword] = useState(false)
  const [checkConfirmPassword, setCheckConfirmPassword] = useState(false)
  const [checkType, setCheckType] = useState();
  // const [signUpCheck,setSignUpCheck] = useState(false)



  // modal failed time
  const [failedShow, setFailedShow] = useState(false)
  const handleFailedShow = () => setFailedShow(true)
  // get subscription packages
  const SubScriptionPackage = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/subscription/details`
    };
    axios(option)
      .then(e => {
        setGetPlans(e?.data?.plans)
        // PubliShableKey(e?.data?.STRIPE_PUBLISHABLE_KEY)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  // const stripe = loadStripe("pk_test_51IryiUSBMcADo9jFi48pkUZ7LJ7cvNKliiHzAQmseS23jdNAYfV7LJYgKTTvO7cCSF0DTZUhalWjZY8BWGHTvPci005rRc4O1U");

  // selected subscition plans
  // const SelectedPlan = (item) => {
  //   setLoading(true)
  //   const Data = {};
  //   Data.plan_id = item?.id
  //   const option = {
  //     method: 'POST', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'application/json',
  //       'Authorization': `Bearer ${getToken()}`
  //     },
  //     data: JSON.stringify(Data),
  //     url: `${ApiUrl()}/auth/subscription/create_checkout_session`
  //   };
  //   axios(option)
  //     .then(e => {
  //       setLoading(false)
  //       if (e?.data?.session_created === false) {
  //         handleFailedShow()
  //       }

  //       else if (e?.data?.session_created === true) {
  //         window.open(e?.data?.session_url, '_blank')
  //       }

  //     })
  //     .catch(err => {
  //       setLoading(false)
  //       if (err?.response?.data?.detail) {
  //         toast.error(err?.response?.data?.detail)
  //         handleShow()
  //       }
  //     })
  // }
  const SelectedPlan = (item) => {
    setLoading(true)
    const Data = {};
    Data.plan_id = item?.id
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(Data),
      url: `${ApiUrl()}/auth/subscription/create_checkout_session`
    };
    axios(option)
      .then(e => {
        setLoading(false)
        if (e?.data?.status !== 200) {
          handleFailedShow()
        }
        else if (e?.data?.status == 200) {
          console.log(e.data.session);
          window.open(e.data.session.url, '_blank')
        }
      })
      .catch(err => {
        setLoading(false)
        if (err?.response?.data?.detail) {
          toast.error(err?.response?.data?.detail)
          handleShow()
        }
      })
  }

  const ValidateInput = () => {
    if (!fullName || fullName?.trim() === '') {
      toast.error("Please Enter Your Name")
      return false;
    }

    if (!userName || userName.trim() === '') {
      toast.error("Please Enter Username")
      return false;
    }

    if (!passWord) {
      toast.error("Please Enter Password")
      return false;
    }
    if (!confirmPassword) {
      toast.error("Please Enter Confirm PassWord")
      return false
    }
    return true;
  }

  // signup api
  const UserSignUp = () => {
    if (!ValidateInput()) return;
    const Data = {}
    Data.name = fullName;
    Data.email = userName;
    Data.password = passWord;
    Data.re_password = confirmPassword;
    Data.is_developer = Boolean(checkType?.subscription_plan === "developer_builder");
    Data.is_agent = Boolean(checkType?.subscription_plan === "broker_agent");
    Data.is_otherbusiness = Boolean(checkType?.subscription_plan === "other_business");
    Data.is_google_login = false
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(Data),
      url: `${ApiUrl()}/auth/subscription/register`
    };
    axios(option)
      .then(e => {
        toast.success(e?.data?.message);
        Login();
      })
      .catch(err => {

      })
  }


  // token generate api
  const Login = () => {
    const data = {};
    data.email = userName;
    data.password = passWord;

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/token/`

    };
    axios(option)
      .then(e => {
        setUserSession(e?.data?.access)
        LoginApi();
        const data = JSON.parse(e?.data?.data || '{}');
      })
      .catch(err => {
        toast.error(err?.response?.data?.detail)
      })
  }

  // login api
  const LoginApi = () => {
    setLoading(true)
    const data = {}
    data.email = userName;
    data.password = passWord;
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/auth/user/login`
    };
    axios(option)
      .then(e => {

        setLoading(false)
        handleClose()
        const data = JSON.parse(e?.data?.data || '{}');
        SelectedPlan()
      })
      .catch(err => {
        setLoading(false)
        toast.error(err?.response?.data?.email[0] || err?.response?.data?.error)
      })
  }

  const BackToHomapage = () => {
    navigate('/')
  };

  useEffect(() => {
    SubScriptionPackage()
  }, []);


  const ValidateLoginFields = () => {
    if (!loginUserName || loginUserName?.trim() === '') {
      toast.error("Please Enter Your UserName")
      return false;
    }

    if (!loginPassWord) {
      toast.error("Please Enter Your PassWord")
      return false;
    }
    return true
  }

  const tokenLogin = () => {
    if (!ValidateLoginFields()) return;
    const data = {};
    data.email = loginUserName;
    data.password = loginPassWord;

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/token/`
    };
    axios(option)
      .then(e => {
        setUserSession(e?.data?.access);
        LoginAPiModal();
      })
      .catch(err => {
        toast.error(err?.response?.data?.detail)
        console.log(err, '259');
      })
  }

  const LoginAPiModal = () => {

    const data = {}
    data.email = loginUserName;
    data.password = loginPassWord;
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/auth/user/login`
    };
    axios(option)
      .then(e => {
        toast.success(e?.data?.success);
        setShow(false)
      })
      .catch(err => {

      })

  }
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <Header />

      {/* <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements> */}
      <main id="content ">
        <section className="pb-4 mt-100 margin-top-bottom-50">

        </section>
        <section className="pack-table">
          <div className="container">
            <h1 className="fs-30 lh-1 mb-0 text-heading font-weight-600 mb-6 ">Subscription Packages</h1>
            <h4 className="mb-5 fs-18 lh-15 text-heading">Choose the package that’s right for your business </h4>
            <div className="ul-icons">
              <ul className="nav nav-tabs d-flex border-b" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active px-3" data-bs-toggle="tab" href="#developer" role="tab"
                    aria-selected="true">Developer</a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className="nav-link px-3" data-bs-toggle="tab" href="#agent" role="tab"
                    aria-selected="false">Agent</a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className="nav-link px-3" data-bs-toggle="tab" href="#other" role="tab"
                    aria-selected="false">Other Business</a>
                </li>
              </ul>
            </div>
            <div className="card-body px-sm-6 shadow-xxs-2 mb-5 padding-both">
              <div className="tab-content pt-1 pb-0 px-0 shadow-none">
                {/* dev */}
                <div className="tab-pane fade show active" id="developer" role="tabpanel">
                  <div className="row px-2">
                    <div className="col-5 pack-list mb-5">
                      <h4 className=" li-h py-2">
                        Compare All Plans
                      </h4>
                      <ul>
                        <li className="li-h">Dashboard for Leads Management </li>
                        <li className="li-h">Featured Business in search Result</li>
                        <li className="li-h">Featured Developer on Homepage</li>
                        <li className="li-h">Featured Listing on homepage</li>
                        <li className="li-h">Listings Per Year</li>
                        <li className="li-h">Featured Professional In Search Result</li>
                        <li className="li-h">Homepage Banner Ad</li>
                        <li className="li-h">Monthly Leads</li>
                        <li className="li-h">Monthly Price ($)</li>
                        <li className="li-h">Multi City in search result</li>
                        <li className="li-h">Notifications on Matched User Searches </li>
                        <li className="li-h">Placement in search result </li>
                        <li className="li-h">Duration</li>
                        <li className="li-h">Price</li>
                        <li className="li-h">Discount (%)</li>
                        <li className="li-h year-price-discount">Year Price Discount</li>
                      </ul>
                    </div>
                    {
                      DeveloperPlans?.developer_builder?.map((item) => {
                        return (
                          <div className="col-2 pack-list mb-5 ">
                            <h4 className="tx-center  li-h top-bar py-2">
                              {item?.plan_type}
                            </h4>
                            <ul className="">
                              <li className="tx-center li-h">{item?.dashboard_for_leads_management ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_business_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_developer_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>

                              <li className="tx-center li-h">{item?.featured_listing_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.listings_per_year}</li>
                              <li className="tx-center li-h">{item?.featured_professional_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.homepage_banner_ad ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.monthly_leads}</li>
                              <li className="tx-center li-h">${item?.monthly_price}</li>
                              <li className="tx-center li-h">{item?.multi_city_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.notifications_on_matched_user_searches ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.placement_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.duration}</li>
                              <li className="tx-center li-h">{item?.price}</li>
                              <li className="tx-center li-h">{item?.discount}</li>
                              <li className="tx-center li-h fs-32"><img src="images/dollar-icon.png" style={{ width: "33px", height: "33px" }} />{item?.yearly_price_discount}<span className="fs-16">/yr</span></li>
                            </ul>
                            <button type="button" className="sub-a btn btn-primary" onClick={() => { SelectedPlan(item); setCheckType(item); PlanId(item?.id) }}>Choose</button>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {/* agent */}
                <div className="tab-pane fade show" id="agent" role="tabpanel">
                  <div className="row px-2">
                    <div className="col-5 pack-list mb-5">
                      <h4 className=" li-h py-2">
                        Compare All Plans
                      </h4>
                      <ul>
                        <li className="li-h">Dashboard for Leads Management </li>
                        <li className="li-h">Featured Business in search Result</li>
                        <li className="li-h">Featured Developer on Homepage</li>
                        <li className="li-h">Featured Listing on homepage</li>
                        <li className="li-h">Listings Per Year</li>
                        <li className="li-h">Featured Professional In Search Result</li>
                        <li className="li-h">Homepage Banner Ad</li>
                        <li className="li-h">Monthly Leads</li>
                        <li className="li-h">Monthly Price ($)</li>
                        <li className="li-h">Multi City in search result</li>
                        <li className="li-h">Notifications on Matched User Searches </li>
                        <li className="li-h">Placement in search result </li>
                        <li className="li-h">Duration</li>
                        <li className="li-h">Price</li>
                        <li className="li-h">Discount (%)</li>
                        <li className="li-h year-price-discount">Year Price Discount</li>
                      </ul>
                    </div>
                    {
                      AgentPlans?.broker_agent?.map((item) => {
                        return (
                          <div className="col-2 pack-list mb-5 ">
                            <h4 className="tx-center  li-h top-bar py-2">
                              {item?.plan_type}
                            </h4>
                            <ul className="">
                              <li className="tx-center li-h">{item?.dashboard_for_leads_management ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_business_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_developer_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>

                              <li className="tx-center li-h">{item?.featured_listing_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.listings_per_year}</li>
                              <li className="tx-center li-h">{item?.featured_professional_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.homepage_banner_ad ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.monthly_leads}</li>
                              <li className="tx-center li-h">${item?.monthly_price}</li>
                              <li className="tx-center li-h">{item?.multi_city_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.notifications_on_matched_user_searches ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.placement_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.duration}</li>
                              <li className="tx-center li-h">{item?.price}</li>
                              <li className="tx-center li-h">{item?.discount}</li>
                              <li className="tx-center li-h fs-32"><img src="images/dollar-icon.png" style={{ width: "33px", height: "33px" }} />{item?.yearly_price_discount}<span className="fs-16">/yr</span></li>
                            </ul>
                            {/* <button type="button" className="sub-a btn btn-primary" onClick={() => { SelectedPlan(item); setGetPlans(item); PlanId(item?.id) }}>Choose</button> */}
                            <button type="button" className="sub-a btn btn-primary" onClick={() => { SelectedPlan(item); setCheckType(item); PlanId(item?.id) }}>Choose</button>
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
                {/* ob */}
                <div className="tab-pane fade show" id="other" role="tabpanel">
                  <div className="row px-2">
                    <div className="col-5 pack-list mb-5">
                      <h4 className=" li-h py-2">
                        Compare All Plans
                      </h4>
                      <ul>
                        <li className="li-h">Dashboard for Leads Management </li>
                        <li className="li-h">Featured Business in search Result</li>
                        <li className="li-h">Featured Developer on Homepage</li>
                        <li className="li-h">Featured Listing on homepage</li>
                        <li className="li-h">Listings Per Year</li>
                        <li className="li-h">Featured Professional In Search Result</li>
                        <li className="li-h">Homepage Banner Ad</li>
                        <li className="li-h">Monthly Leads</li>
                        <li className="li-h">Monthly Price ($)</li>
                        <li className="li-h">Multi City in search result</li>
                        <li className="li-h">Notifications on Matched User Searches </li>
                        <li className="li-h">Placement in search result </li>
                        <li className="li-h">Duration</li>
                        <li className="li-h">Price</li>
                        <li className="li-h">Discount (%)</li>
                        <li className="li-h year-price-discount">Year Price Discount</li>
                      </ul>
                    </div>
                    {
                      OtherPlans?.other_business?.map((item) => {
                        return (
                          <div className="col-3 pack-list mb-5 ">
                            <h4 className="tx-center  li-h top-bar py-2">
                              {item?.plan_type}
                            </h4>
                            <ul className="">
                              <li className="tx-center li-h">{item?.dashboard_for_leads_management ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_business_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_developer_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.featured_listing_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.listings_per_year}</li>
                              <li className="tx-center li-h">{item?.featured_professional_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.homepage_banner_ad ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.monthly_leads}</li>
                              <li className="tx-center li-h">${item?.monthly_price}</li>
                              <li className="tx-center li-h">{item?.multi_city_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.notifications_on_matched_user_searches ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.placement_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                              <li className="tx-center li-h">{item?.duration}</li>
                              <li className="tx-center li-h">{item?.price}</li>
                              <li className="tx-center li-h">{item?.discount}</li>
                              <li className="tx-center li-h fs-32"><img src="images/dollar-icon.png" style={{ width: "33px", height: "33px" }} />{item?.yearly_price_discount}<span className="fs-16">/yr</span></li>
                            </ul>
                            {/* <button type="button" className="sub-a btn btn-primary" onClick={() => { SelectedPlan(item); setGetPlans(item); PlanId(item?.id) }}>Choose</button> */}
                            <button type="button" className="sub-a btn btn-primary" onClick={() => { SelectedPlan(item); setCheckType(item); PlanId(item?.id) }}>Choose</button>
                          </div>
                        )
                      })
                    }


                  </div>
                </div>
                {/* end */}
                <Modal show={show} onHide={handleClose} animation={false} className="modal-add-contact">
                  <Modal.Header>
                    <ImCross onClick={handleClose} className="button-cross-modal-add-contact" />
                  </Modal.Header>
                  <Modal.Body>

                    {
                      loginCheck ? <div className="tab-content shadow-none p-0" id="myTabContent">
                        <div className="tab-pane fade show active" id="login" role="tabpanel"
                          aria-labelledby="login-tab">
                          <form className="form">
                            {/* fullname input */}


                            <div className="form-group mb-4">
                              {/* username input */}
                              <label htmlFor="username" className="sr-only">Username</label>
                              <div className="input-group input-group-lg">
                                <div className="input-group-prepend ">
                                  <span className="input-group-text bg-gray-01 border-0 text-muted fs-18"
                                    id="inputGroup-sizing-lg">
                                    <i className="far fa-user"></i></span>
                                </div>
                                <input type="text" className="form-control border-0 shadow-none fs-13"
                                  id="username" name="username"
                                  placeholder="Username / Your email" onChange={(e) => setLoginUserName(e?.target?.value)} value={loginUserName} />
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              {/* password input */}
                              <label htmlFor="password" className="sr-only">Password</label>
                              <div className="input-group input-group-lg">
                                <div className="input-group-prepend ">
                                  <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                    <i className="far fa-lock"></i>
                                  </span>
                                </div>
                                <input className="form-control border-0 shadow-none fs-13"
                                  id="password" name="password"
                                  placeholder="Password" type={loginIsShowPassword ? 'text' : 'password'} onChange={(e) => setLoginPassword(e?.target?.value)} value={loginPassWord} />
                                <div className="input-group-append">
                                  <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                    <i className="far fa-eye-slash" onClick={() => setLoginIsShowPassword(!loginIsShowPassword)}></i>
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* forgot password field */}
                            <div className="d-flex mb-4">
                              <Link to="/forgotpassword" className="d-inline-block ml-auto text-orange fs-15">
                                Forgot Password?
                              </Link>
                            </div>
                            <button type="button" className="btn btn-primary btn-lg btn-block" onClick={tokenLogin}>Log in</button>
                            {/* <a className="form-text">Create Your Account<span onClick={() => setSignUpCheck(true)} style={{cursor:'pointer', color:'#529BE8'}}>SignUp</span></a> */}
                          </form>

                        </div>
                      </div> : <div className="col-md-12">
                        {/* fullname input */}
                        <div className="form-group mb-4">
                          <label htmlFor="full-name" className="sr-only">Full name</label>
                          <div className="input-group input-group-lg">
                            <div className="input-group-prepend ">
                              <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                <i className="far fa-address-card"></i></span>
                            </div>
                            <input type="text" className="form-control border-0 shadow-none fs-13"
                              id="full-name" name="full-name"
                              placeholder="Full name" onChange={(e) => setFullName(e?.target?.value)} value={fullName} />
                          </div>
                        </div>

                        {/* username input */}
                        <div className="form-group mb-4">
                          <label htmlFor="username01" className="sr-only">Username</label>
                          <div className="input-group input-group-lg">
                            <div className="input-group-prepend ">
                              <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                <i className="far fa-user"></i></span>
                            </div>
                            <input type="text" className="form-control border-0 shadow-none fs-13"
                              id="username01" name="username01"
                              placeholder="Username / Your email" onChange={(e) => setUserName(e?.target?.value)} value={userName} />
                          </div>
                        </div>

                        {/* password inpu  */}
                        <div className="form-group mb-4">
                          <label htmlFor="password01" className="sr-only">Password</label>
                          <div className="input-group input-group-lg">
                            <div className="input-group-prepend ">
                              <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                <i className="far fa-lock"></i>
                              </span>
                            </div>
                            <input type={checkPassword ? "text" : "password"} className="form-control border-0 shadow-none fs-13"
                              id="password01" name="password01"
                              placeholder="Password" onChange={(e) => setPassWord(e?.target?.value)} value={passWord} />
                            <div className="input-group-append">
                              <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                <i className="far fa-eye-slash" onClick={() => setCheckPassword(!checkPassword)}></i>
                              </span>
                            </div>
                          </div>
                          {/* <p className="form-text">Password must be at least 8 characters in length</p> */}
                        </div>


                        {/* confirm password */}
                        <div className="form-group mb-4">
                          <label htmlFor="password02" className="sr-only">Confirm Password</label>
                          <div className="input-group input-group-lg">
                            <div className="input-group-prepend ">
                              <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                <i className="far fa-lock"></i>
                              </span>
                            </div>
                            <input type={checkConfirmPassword ? "text" : "password"} className="form-control border-0 shadow-none fs-13"
                              id="password02" name="password02"
                              placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e?.target?.value)} value={confirmPassword} />
                            <div className="input-group-append">
                              <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                <i className="far fa-eye-slash" onClick={(e) => setCheckConfirmPassword(!checkConfirmPassword)}></i>
                              </span>
                            </div>
                          </div>
                          <p className="form-text">it should be same as above password</p>
                          {/* <a className="nav-item col-sm-3 ml-0 nav-link pr-6 py-4 pl-9 active fs-18" id="login-tab"
                          data-bs-toggle="tab"
                          href="#login"
                          role="tab"
                          aria-controls="login" aria-selected="true">Login</a> */}
                        </div>

                        {/* <a id="login-tab" data-bs-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true" className="form-text">Already You have account Please Login</a> */}

                        <button type="button" className="btn btn-primary btn-lg btn-block" onClick={UserSignUp}>Sign up</button>
                        <a className="form-text">Already have an account? <span onClick={() => setLoginCheck(true)} style={{ cursor: 'pointer', color: '#3DA5B1', textDecoration: 'underline' }}>Sign In</span></a>
                      </div>
                    }

                  </Modal.Body>
                </Modal>


                {/* failed time modal */}
                <Modal show={failedShow} animation={false} className="modal-add-contact">
                  <Modal.Body>
                    <div className="col-md-12">
                      <div className="error-message-img-section"><BiErrorCircle className="error-img-stripe-page" /></div>
                      <h4 className="message-failed-time">Thanks for registering with us. Your payment cannot be completed at this time. We will get back to you shortly.</h4>
                      <button type="button" className="btn btn-primary btn-sub" onClick={BackToHomapage}>Back</button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default SubcriptionPackages;