

import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from '../../../../utils/axios/main'; // importing axios from customAxios

export const Taxform = (props) => {



    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => (


        axios.post('/auth/subscription/tax/create/', data).then(response => {
            console.log(response.data)

        }
        )
            .catch(error => console.log(error))



    )






    return (
        <>
            <div className='card'>

                <Form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Group>
                        <Form.Label >Tax Name</Form.Label>
                        <Form.Control type="text" placeholder='Name of the tax rates as it will appear to your customer invoice (e.g: VAT)'  {...register("display_name")} />
                    </Form.Group>


                    <Form.Group>

                        <Form.Check
                            type="switch"
                            className='pd-2 mx-2'
                            size="lg"
                            id="custom-switch"
                            label="Inclusive"
                            {...register("inclusive")}
                        />


                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Country Alpha-2 code</Form.Label>
                        <Form.Control type="text" placeholder='For example, “CA” for Canada'  {...register("jurisdiction")} /> </Form.Group>     <Form.Group>
                        {/* <Form.Label>state Name</Form.Label>
                         <Form.Control type="text"  {...register("state")} /> </Form.Group>
                    <Form.Group> */}
                        <Form.Label>Percent Off</Form.Label>
                        <Form.Control type="number" placeholder='This represents the tax rate percent out of 100.' {...register("percentage")} /> </Form.Group>



                    <Button type="submit">Submit</Button>

                </Form>
            </div>

        </>

    )
}

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(form)