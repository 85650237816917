import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import ImageGallery from 'react-image-gallery';



const ImageGalleryControl = ({imageData, startIndex}) => {
    const GalleryImages = imageData?.map((item) => {
        return ({
            original: item?.media_path,
            thumbnail: item?.media_path
        })
    })
    console.log(startIndex,'startIndex');
    return (
        <>
            {
                imageData ?
                    <ImageGallery items={GalleryImages} showFullscreenButton={false} startIndex={startIndex} showPlayButton={false} />
                    : null
            }

        </>
    )
}
export default ImageGalleryControl;