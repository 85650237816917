import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from 'react-avatar';
export default function CustomerRequest() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (<div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
    <div className="d-flex flex-wrap flex-md-nowrap mb-6">
      <div className="mr-0 mr-md-auto">
        <h2 className="mb-0 text-heading fs-22 lh-15">Customer Request<span
          className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">1</span>
        </h2>

      </div>

    </div>
    <div className='card border-0 mb-4'>
      <Accordion expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"

        >
          <Typography sx={{ width: '10%', flexShrink: 0 }}>
            <Avatar name="Gopal" round={true} size="50" color="#529BE8" />
          </Typography>
          <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>Name</Typography>
          <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href='#' className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">gopal@thinkdatalabs.com</a></Typography>
          <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>25-10-2022</Typography>
          <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> 123456789</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography className="mx-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div></div>
  )
}
