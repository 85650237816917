import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DevSidebar from "../../Components/DevSidebar/devsidebar";
import Loader from "../../Components/Loader";
import { ApiUrl, getToken } from "../../utils/common";
import DevHeader from "./devheader";
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from "react-bootstrap";
import { map } from "lodash";
import CurrencyFormat from "react-currency-format";


const DevMyfavProperty = () => {
  const [favouritePropertyData, setFavouritePropertyData] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [deleteData, setDeleteData] = useState()
  // modal states
  const [show, setShow] = useState(false);
  const OnhideModal = () => setShow(false)
  // get api
  const GetFavouriteProperty = () => {
    setLoading(true);
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/cached-properties`
    };
    axios(option)
      .then(e => {
        setLoading(false);
        setFavouritePropertyData(e?.data);
      })
      .catch(err => {
        setLoading(false);
      })
  }
  useEffect(() => {
    GetFavouriteProperty();
  }, [])

  // delete api


  const DeleteFavouriteProperty = () => {
    const option = {
      method: 'DELETE', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/cached-properties/${deleteData?.property?.id}`
    };
    axios(option)
      .then(e => {
        console.log(e, 'e59');
        if (e?.statusText === "No Content") {
          toast.success("Property Removed from Favourites")
          GetFavouriteProperty()
          setShow(false)
        }
      })
      .catch(err => {

      })
  }

  const Redirect = (item) => {
    navigate('/developersingleproperty', { state: { id: item?.property?.id } })
  }

  const DeleteConfirmation = (item) => {
    setShow(true);
    setDeleteData(item);
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
     <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
          <DevSidebar />
        

        <div className="page-content">
          <DevHeader />
          <main id="content" className="bg-gray-01 margin-re-top">
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
              <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                <div className="mr-0 mr-md-auto">
                  <h2 className="mb-0 text-heading fs-22 lh-15">My Favourites<span
                    className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{favouritePropertyData?.length}</span>
                  </h2>

                </div>
              </div>
              <div className="row">
                {
                  favouritePropertyData?.map((item) => {
                    return (
                      <div className="col-sm-6 col-md-4 col-lg-3 mb-6">

                        <div className="card shadow-hover-1 card-img-top">
                        <div className="card-header pt-6 pb-3 bg-transparent">
                          <div onClick={() => Redirect(item)} style={{ cursor: 'pointer' }}>
                            
                              <img src={item?.property?.featured_image}
                                alt="Home in Metric Way" className="images-favourite-property" />
                              <div className="card-img-overlay p-2 d-flex flex-column">
                                <div>
                                  {
                                    item?.property?.labels?.map((item) => {
                                      return (
                                        <span className="badge badge-orange mr-2">{item?.name}</span>
                                      )
                                    })
                                  }

                                </div>

                              </div>
                              
                            </div>
                            </div>
                            <div className="card-body pt-3">
                              <h2 className="card-title fs-16 lh-2 mb-0"><a
                                className="text-dark hover-saffron-text">{item?.property?.name}</a>
                              </h2>
                              <p className="card-text font-weight-500 text-grey mb-2">{item?.property?.street_name}</p>
                            </div>
                         


                          <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                          <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.property?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                                className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>
                           
                            <ul className="list-inline mb-0">

                              <li className="list-inline-item">
                                <button data-bs-toggle="tooltip" title="Remove"
                                  className="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-white bg-blue hover-saffron border-accent" onClick={() => DeleteConfirmation(item)}><i
                                    className="fas fa-trash"></i></button>


                              </li>


                            </ul>
                          </div>
                        </div>
                      </div>

                    )
                  })
                }

                <Modal className="modal-add-contact"
                  show={show} >
                  <Modal.Body>
                    <h3 className="submit-property-modal-heading">Are you sure you want to remove this property from Favourites?</h3>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button className="bg-blue text-white hover-saffron border-0" onClick={() => DeleteFavouriteProperty()}>
                      Yes
                    </Button>

                    <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnhideModal()}>
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>


              </div>
            </div>
          </main>
        </div>
      </div>

      </div>
    </>
  )
}
export default DevMyfavProperty;