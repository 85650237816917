import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CouponList from './couponList'
import { Couponform } from './form'

export default function CouponPage() {
  const [isParentData, setIsParentData] = useState(true);

// const [loading ,setloading]=useState(False)
// console.log(loading)

  




  return (
    <div>
      <div class="container row ">
      
      
    
      <div className='col'>
        <Couponform  toChild={isParentData} sendToParent={setIsParentData} />


      </div>

      <div className='col'>
        <CouponList toChild={isParentData} sendToParent={setIsParentData} />

      </div>
      </div>




    </div>
  )
}
