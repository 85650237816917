import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { RequstRefreshToken, ResponseRefreshToken } from "./types";
import { LocalStorage } from "./local_storage";
import { getAccessToken, getToken } from "../common";
import { toast } from "react-toastify";

export abstract class HTTPBaseService extends LocalStorage {
  protected instance: AxiosInstance;
  protected access_token?: string = "";
  protected readonly baseURL: string;
  protected params: any;

  public constructor(baseURL: string, _access_token?: string, params?: any) {
    super();
    this.baseURL = baseURL;
    this.params = params;
    this.instance = axios.create({
      baseURL,
    });
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private async setAccessAuth() {
    const access_token_body = {
      client_id: process.env.REACT_APP_CLIENT_ID as string,
      client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
    };
    await this.instance.post("/secure/auth/", access_token_body);
  }

  /** Mange localstorage */
  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use((response) => {
      if (response.config.headers) {
        response.config.headers["Content-Type"] = " application/json";
      }

      if (response.config.url === "/secure/auth/") {
        this.setAccessToken(response.data.access_token);
        this.setRefreshToken(response.data.refresh_token);
        
        
        
        this.access_token = response.data.access_token;
      } else if (response.config.url === "/secure/refreshtoken/") {
        this.setAccessToken(response.data.access_token);
        this.setRefreshToken(response.data.refresh_token);
        this.access_token = response.data.access_token;
      }
      return response;
    }, this.handleError);
  };

  private handleRequest = (config: AxiosRequestConfig) => {
    let access_token = localStorage.getItem("token") || null;
    (config.headers ??= {}).Authorization = `Bearer ${access_token}`;
    if (config.headers) {
      config.headers["Content-Type"] = "application/json";
    }
    if (config.method?.toLocaleUpperCase() === "GET") {
      // FIX IE 304 cache
      config.params = this.params;
      //   config.params = {
      //     ...config.params,
      //     _t: Date.now(),
      //   };
    }

    return config;
  };

  private async refreshToken(
    body: RequstRefreshToken
  ): Promise<ResponseRefreshToken> {
    return this.instance.post(`${this.baseURL}secure/refreshtoken/`, body);
  }

  private handleError = async (error: AxiosError) => {
    // console.log(error.response)

    const originalRequest = error.config;
    const requestURL = error.response?.config.url;
    const isGetRefreshTokenURL = requestURL?.includes("/secure/refreshtoken/");
    const refresh_token = localStorage.getItem("refreshToken");

    /**
     * If status is 403 and not refresh token.
     * If refresh token response is 403 means request access token is must.
     * Other error.
     */
    if (error.response?.status === 401 && !isGetRefreshTokenURL) {
      let refresh_token = localStorage.getItem("refreshToken");
      let access_token = getToken();

      // const refresh_token = this.getRefreshToken();
      console.log(refresh_token);
      if (refresh_token) {
        const access_token_body = {
          client_id: process.env.REACT_APP_CLIENT_ID as string,
          client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
        };
        await this.instance.post("/secure/auth/", access_token_body);
      } else {
        const refresh_token_body = {
          refresh_token: refresh_token as string,
        };
        await this.instance.post("/secure/refreshtoken/", refresh_token_body);
      }
      // let access_token = localStorage.getItem('token');

      (error.response.config.headers ??=
        {}).Authorization = `Bearer ${access_token}`;
      return this.instance(originalRequest);
    } else if (error.response?.status === 403 && isGetRefreshTokenURL) {
      const access_token_body = {
        client_id: process.env.REACT_APP_CLIENT_ID as string,
        client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
      };
      await this.instance.post("/secure/auth/", access_token_body);
    } else if (error.response?.status === 500) {
      console.log("Error is 500.");
    } else {
      toast.error("Given token not valid for any token type");
    }
  };
}
