import React, { useState, useEffect } from 'react'
import axios from '../../../utils/axios/main'; // importing axios from customAxios

import { Link, useNavigate } from 'react-router-dom';
import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { FormControlLabel, IconButton } from "@material-ui/core";



export default function PlanPages() {


    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState([]);

    const [tax, setTax] = useState([]);
    const [coupon, setCoupon] = useState([]);
    let location = useLocation();
    const navigate = useNavigate()

    const MatDel = ({ item }) => {
        async function handleDelClick() {
            setShow(false);
            const obj = new MainApiProtectedVersion('', item)
            const res = await obj.requstPlanDelete().then((res) => {
                setShow(true);
                toast.success("Plan Deleted Successfully")
            })
                .catch(error => {
                    alert(error)
                })
        };

        return (
            <FormControlLabel
                control={
                    <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        onClick={handleDelClick}
                    >
                        <DeleteIcon style={{ color: blue[500] }} />
                    </IconButton>
                }
            />
        );
    };


    /* Edit Plan */


    const MatEdit = ({ index }) => {
        const handleEditClick = () => {
            console.log(index);
            navigate(`edit/${index.id}`, { state: { index } })
        };

        return (
            <FormControlLabel
                control={
                    <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        onClick={handleEditClick}
                    >
                        <EditIcon style={{ color: blue[500] }} />
                    </IconButton>
                }
            />
        );
    };
    /* Assign Plan */


    const MatAssign = ({ item }) => {
        const handleAssignClick = () => {
            console.log(item);
            navigate('/admin/user', { state: { item } })
        };

        return (
            <FormControlLabel
                control={
                    <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        onClick={handleAssignClick}
                    >
                        <AssignmentIndIcon style={{ color: blue[500] }} />
                    </IconButton>
                }
            />
        );
    };






    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'subscription_plan', headerName: 'Profile', width: 150 },
        { field: 'plan_type', headerName: 'Plan Type', width: 110 },
        { field: 'monthly_price', headerName: 'Price(mo)$', width: 110 },
        { field: 'yearly_price', headerName: 'Price(yr)$', width: 110 },

        {
            field: 'display_name',
            headerName: 'Applied Coupon',
            valueGetter: (params) => {
                return params.getValue(params.id, "coupon").display_name;
            },
            width: 130
        },

        {
            field: 'percent_off',
            headerName: 'Percent Off',
            valueGetter: (params) => {
                return params.getValue(params.id, "coupon").percent_off;
            },
            width: 100
        },

        { field: 'yearly_price_discount', headerName: 'Total Price(yr)$', width: 130 },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            width: 50,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <MatEdit index={params.row} />
                    </div>
                );
            }
        },
        {
            field: "del",
            headerName: "Delete",
            sortable: false,
            width: 50,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <MatDel item={params.row.id} />
                    </div>
                );
            }
        },
        {
            field: "assign",
            headerName: "Assign",
            sortable: false,
            width: 60,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <MatAssign item={params.row} />
                    </div>
                );
            }
        }
    ];
    useEffect(() => {
        setLoading(true)
        function handleStatusChange() {
            axios.get('/auth/subscription/plans/')
                .then(response => {
                    console.log(response.data)
                    setPlan(response.data)
                })
                .catch(err => {
                })
        }
        handleStatusChange()
    }, [loading, show]);
    return (
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
            <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                <div className="mr-0 mr-md-auto">
                    <h2 className="mb-0 text-heading fs-22 lh-15">Plan Dashboard</h2>
                </div>
                <div>
                    <Link to="create" className="btn bg-blue text-white hover-saffron btn-lg">
                        <span>Create Plan</span>
                    </Link>
                    <Link to='coupon' className="ml-2 btn bg-blue text-white hover-saffron btn-lg">
                        <span>Create Discount Coupon</span>
                    </Link>

                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div style={{ height: 450, width: '100%' }}>
                        <DataGrid
                            rows={plan}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            experimentalFeatures={{ newEditingApi: false }}
                           
                        />

                    </div>
                </div>

            </div>
        </div>
    )
}
{/* <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Plan </th>
                                <th>Plan Type</th>
                                <th>Price(mo)</th>
                                <th>Price(yr)</th>
                                <th>Applied Coupon</th>
                                <th>Percent Off</th>
                                <th>Total Price(yr)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {plan.map((item) => {
                            return (
                                <>
                                    <tbody>
                                        <tr>
                                            <td>{item.id}</td>
                                            <td className="text-capitalize">{item.subscription_plan}</td>
                                            <td>{item.plan_type}</td>
                                            <td>${item.monthly_price}</td> 
                                            <td>${item.yearly_price}</td>
                                            {item.coupon ?(<>
                                                <td>{item.coupon.display_name}</td>
                                            </>):(<>
                                                <td>No Discount</td>
                                            </>)}
                                            {item.coupon ?(<>
                                                <td>{item.coupon.percent_off} % Off</td>
                                            </>):(<>
                                                <td>0% Off</td>
                                            </>)}
                                            <td>${item.yearly_price_discount}</td> 
                                            
                                          
                                            
                                            <Button class="btn btn-primary" role="button">
                                                <Link className="btn btn-info" to={`edit/${item.id}`}>Edit</Link>
                                            </Button>
                                            <button type="submit" onClick={() => PlanDelete(item.id)} >   delete</button>
                                            {item.plan_type==="Custom" ?(<>
                                                <button type="submit" onClick={() => setAssignPlan(item.id)} >Assign</button>
                                            </>):(<>
                                                <button>Unassign</button>
                                            </>)}
                                            
                                        </tr>
                                    </tbody>
                                </>
                            )
                        })}
                    </Table> */}