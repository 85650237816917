import React, { useEffect, useState } from "react";

import axios from "axios";

import { ApiUrl, GetAgentType, GetDeveloperType, GetId, GetOtherBusinessType, getToken, removeUserSession } from "../../../utils/common";
import { Link } from "react-router-dom";
import Userlist from "./list";
import { MainApiProtectedVersion } from "../../../utils/axios/requests";

const AdminUsers = () => {

  // public GrapghUser =() =>this.instance.post('api/graphql')
  // dat={

  //     query:{
  //       allIngredients:{
  //         name,
  //         email,
  //       }
  //     }

  // }
  const graphqlQuery = {
    "query": `query{ allIngredients {
      name
      email
      isAgent
    }}`,
    'mutuation': ''
  };


  const AssignCustomPlan = async () => {
    console.log('###################################');
    const obj = new MainApiProtectedVersion('', '')
    const res = await obj.GrapghUser(graphqlQuery).then((res) => {

      console.log(res.data);
      // const response = res.data
      // toast.success(" Selected Plan Assign Successfully")
    })
      .catch(error => {
        // alert(error)
        console.log(error);

      })
  }



  const [InvoceCount, setInvoceCount] = useState([])
  const [InvoceList, setInvoceList] = useState([])
  const [loading, setLoading] = useState(false)

  return (

    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 invoice-listing">
          <button onClick={AssignCustomPlan}>click</button>



          <Userlist />
        </div>
      </main>


    </>
  )
}
export default AdminUsers;