import React, { useEffect, useState } from 'react'
import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from "moment";
import Avatar from 'react-avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApiUrl, getToken } from '../../../utils/common';
import axios from 'axios';
import Select from "react-select";
import { format, compareAsc } from 'date-fns'
import { toast } from 'react-toastify';
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../Loader";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import { FormControlLabel, IconButton } from "@material-ui/core";

function LeadsPage() {
  const [startDate, setStartDate] = useState('');
  console.log(startDate)
  const [endDate, setEndDate] = useState('');
  console.log(endDate)
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [filterDate, setFilterDate] = useState(false);
  console.log(filterDate)

  useEffect(() => {
    if (filterDate) {
      setEndDate(format(new Date(filterDate[1]), 'dd-MM-yyyy'))
      setStartDate(format(new Date(filterDate[0]), 'dd-MM-yyyy'))
    }
  }, [filterDate])

  /* console.log(format(new Date(filterDate[1]), 'yyyy/MM/dd'))
  console.log(format(new Date(filterDate[0]), 'yyyy/MM/dd')) */
  const [show, setShow] = useState(false);
  const [userLeads, setUserLeads] = useState([]);
  console.log(userLeads, 'Unum')
  const [loading, setLoading] = useState(false);
  const [LeadTotal, setLeadTotal] = useState(0);
  const [PropertyLeads, setPropertyLeads] = useState([]);
  console.log(PropertyLeads, "hello")
  const [proLeads, setProLeads] = useState([]);
  const [propertyLeadscount, setPropertyLeadscount] = useState();
  console.log(propertyLeadscount, 'Pnum')
  const [LeadPropertTotal, setLeadPropert] = useState([]);
  const [LeadUser, setLeadUser] = useState([]);
  const [region, setRegion] = useState('')
  console.log(region)
  const [totalLeads, setTotalLeads] = useState();
  const [configData, setConfigData] = useState([]);
  const [leadvalue, setLeadValue] = useState('');

  //console.log(region)

  // console.log('=============PropertyLeads=======================');
  // console.log(PropertyLeads);
  // console.log('====================================');


  useEffect(() => {

    const ConfigDropDownData = () => {
      const option = {
        method: 'GET', headers: {
          'access-control-allow-origin': '*',
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${getToken()}`
        },
        url: `${ApiUrl()}/api/property/config-property`
      };
      axios(option)
        .then(e => {
          setConfigData(e?.data)
        })
        .catch(err => {

        })
    }
    ConfigDropDownData()
  }, [])

  useEffect(() => {

    if (startDate || region) {

      const GetUserLeads = () => {
        setLoading(true)
        const params = new URLSearchParams({ 'start_date': startDate, 'end_date': endDate, 'loc': region['name'] || '' });


        const obj = new MainApiProtectedVersion('', '', params)


        // eslint-disable-next-line no-unused-vars
        const res = obj.requstAdminUserlead().then((res) => {
          const data = res.data.leadProperty
          const usled = res.data.user_leads_count
          const prled = res.data.property_leads_count
          const toled = usled + prled
          setTotalLeads(toled)
          setLeadUser(res.data.leadUser)
          console.log(res.data.leadUser)
          setPropertyLeads(res.data.leadProperty)
          console.log(res.data.leadProperty)
          setUserLeads(res.data.user_leads_count)
          setPropertyLeadscount(res.data.property_leads_count)
          setLoading(false)
          console.log(res.data.leadProperty.length, 'p')
          console.log(res.data.leadUser.length, 'u')
          /*  if (res.data.leadUser.length === 0) {
             toast.error("No Business Leads Found")
           }
           if (res.data.leadProperty.length === 0) {
             toast.error("No Property Leads Found")
           } */
        })
          .catch(error => {
            alert(error)
            setLoading(false)

          })
      }

      GetUserLeads()
    }
    else {

      const GetUserLeads = () => {


        setLoading(true)
        const obj = new MainApiProtectedVersion()

        // eslint-disable-next-line no-unused-vars
        const res = obj.requstAdminUserlead().then((res) => {
          const data = res.data.leadProperty
          let usled = res.data.user_leads_count
          let prled = res.data.property_leads_count
          let toled = usled + prled
          setLoading(false)
          setTotalLeads(toled)
          setLeadUser(res.data.leadUser)

          setPropertyLeads(res.data.leadProperty)
          //console.log(res.data.leadProperty)
          setUserLeads(res.data.user_leads_count)
          console.log(res.data.leadProperty)
          setPropertyLeadscount(res.data.property_leads_count)

          // console.log(res.data.leadProperty.length, 'p')
          // console.log(res.data.leadUser.length, 'u')
        })
          .catch(error => {
            alert(error)
            setLoading(false)

          })

      }
      GetUserLeads()
    }
  }, [startDate, region, filterDate])

  /* delete plan */

  const MatDel = ({ item }) => {
    async function handleDelClick() {
      setShow(false);
      const obj = new MainApiProtectedVersion('', item)
      const res = await obj.requstLeadDelete().then((res) => {
        console.log(res.item)
        setShow(true);
        toast.success("Lead Deleted Successfully")
      })
        .catch(error => {
          alert(error)
        })
    };

    return (
      <button className="btn text-white bg-blue hover-saffron" onClick={handleDelClick} >Del</button>
      /*  <FormControlLabel
           control={
               <IconButton
                   color="secondary"
                   aria-label="add an alarm"
                   onClick={handleDelClick}
               >
                   <DeleteIcon style={{ color: blue[500] }} />
               </IconButton>
           }
       /> */
    );
  };


  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone', headerName: 'Phone Number', width: 200 },
    { field: 'date_published', headerName: 'Date', width: 100 },
    {
      field: "del",
      headerName: "Delete",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatDel item={params.row.id} />
          </div>
        );
      }
    }

  ]
  const options = [
    { value: '0', label: 'Total Leads' },
    { value: '1', label: 'Property Leads' },
    { value: '2', label: 'User Leads' },


  ]
  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];
  const resetState = () => {
    if (filterDate || region || leadvalue || loading) {
      setEndDate('')
      setStartDate('')
      setFilterDate('')
      setRegion('')
      setLeadValue('')
      setLoading(false)
    }
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <main id="content-lead" className="bg-gray-01 pb-10">
        <form>
          <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
            <div className="d-flex flex-wrap flex-md-nowrap mb-6">
              <div className="mr-0 mr-md-auto">
                {/*  <h2 className="mb-0 text-heading fs-22 lh-15">Total Leads<span
                  className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{totalLeads}</span></h2> */}
                {leadvalue?.value == "1"
                  ?
                  (<>
                    <h2 className="mb-0 text-heading fs-22 lh-15">Total Property Leads<span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{propertyLeadscount}</span></h2>
                  </>)
                  :
                  leadvalue?.value === "2"
                    ?
                    (<>
                      <h2 className="mb-0 text-heading fs-22 lh-15">Total Business Leads<span
                        className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{userLeads}</span></h2>
                    </>)
                    :
                    (<>
                      <h2 className="mb-0 text-heading fs-22 lh-15">Total Leads<span
                        className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{totalLeads}</span></h2>
                    </>)}
              </div>
              <div className=''>
                <CSVLink className='btn bg-blue hover-saffron text-white' data={csvData}>Export to CSV</CSVLink>
                {/*  <button className='btn bg-blue hover-saffron text-white'>Export to CSV</button> */}
              </div>
            </div>
            <section className='bg-blue'>
              <div className="col-12">
                <div className="row">
                  <div className="search-tx ">
                    <DateRangePicker className="daterange" minDetail="month" maxDetail="year" format="MM/yyy" yearPlaceholder="yyyy" monthPlaceholder="mm" onChange={setFilterDate} value={filterDate} />
                  </div>
                  <div className="search-tx ">
                    <Select placeholder="Select Lead Type" className="form-select inner-svg" aria-label="Default select example"
                      options={options}
                      onChange={(e) => {
                        setLeadValue(e)
                      }}
                      value={leadvalue}
                    />

                  </div>
                  <div className="search-tx   ">
                    <Select placeholder="Region/City"
                      className="form-select inner-svg" aria-label="Region/City"
                      options={configData?.city}
                      // isMulti
                      title="Region/City"
                      data-style=""
                      onChange={(e) => {
                        setRegion(e)

                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={region}

                    />
                  </div>
                  <div className="reload">
                    <a onClick={resetState} className='btn advanced-search bg-saffron text-white hover-grey width-re-10' style={{ marginTop: '15px', padding: '12px 24px' }}>
                      <img src="../images/reload.png" />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {leadvalue?.value == "1" ? (<>
            {/****************** * property lead  **********************/}
            <div className='container '>
              {
                PropertyLeads.map((item, key) => {
                  const lead = item.data
                  const name = item['property_detail'][0]['name']
                  const count = item.lead
                  return (
                    <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content1"
                        id="panel1bh-header1">
                        <Typography sx={{ width: '10%', flexShrink: 0 }}>
                          <Avatar name={name} round={true} size="50" color="#529BE8" />
                        </Typography>
                        <Typography sx={{ marginTop: '15px', width: '60%', color: 'text.secondary' }}>{name}</Typography>

                        <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>Total Leads</Typography>
                        <Typography sx={{ marginTop: '15px', width: '10%', color: 'text.secondary' }}> {count}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="mx-10">
                          <div className="container">
                            <div className="row">
                              <div style={{ height: 450, width: '100%' }}>
                                <DataGrid
                                  rows={lead}
                                  columns={columns}
                                  pageSize={5}
                                  rowsPerPageOptions={[5]}
                                  checkboxSelection
                                  experimentalFeatures={{ newEditingApi: false }}
                                  localeText={{
                                    toolbarDensity: 'Size',
                                    toolbarDensityLabel: 'Size',
                                    toolbarDensityCompact: 'Small',
                                    toolbarDensityStandard: 'Medium',
                                    toolbarDensityComfortable: 'Large',
                                    MuiTablePagination: {
                                      labelDisplayedRows: ({ from, to, count }) =>
                                        `${from} - ${to} of more than ${count}`,
                                    },
                                  }}
                                  components={{
                                    Toolbar: GridToolbar,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </div>
            {/****************** * property lead  **********************/}
          </>) : leadvalue?.value == "2" ? (<>


            {/****************** * user lead  **********************/}
            <div className='container '>
              {
                LeadUser.map((item, index) => {
                  const userdata = item.user[0]
                  const userleads = item.data
                  const counts = item.lead
                  const ids = item.id + index + "a"
                  return (
                    <Accordion expanded={expanded === `panel${ids}`} onChange={handleChange(`panel${ids}`)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Typography sx={{ width: '10%', flexShrink: 0 }}>
                          <Avatar name={userdata?.name} round={true} size="50" color="#529BE8" />
                        </Typography>
                        <Typography sx={{ marginTop: '15px', width: '60%', color: 'text.secondary' }}>{userdata?.name}</Typography>

                        <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>Total Leads</Typography>
                        <Typography sx={{ marginTop: '15px', width: '10%', color: 'text.secondary' }}> {counts}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="mx-10">
                          <div className="container">
                            <div className="row">
                              <div style={{ height: 450, width: '100%' }}>
                                <DataGrid
                                  rows={userleads}
                                  columns={columns}
                                  pageSize={5}
                                  rowsPerPageOptions={[5]}
                                  checkboxSelection
                                  experimentalFeatures={{ newEditingApi: false }}
                                  localeText={{
                                    toolbarDensity: 'Size',
                                    toolbarDensityLabel: 'Size',
                                    toolbarDensityCompact: 'Small',
                                    toolbarDensityStandard: 'Medium',
                                    toolbarDensityComfortable: 'Large',
                                    MuiTablePagination: {
                                      labelDisplayedRows: ({ from, to, count }) =>
                                        `${from} - ${to} of more than ${count}`,
                                    },
                                  }}
                                  components={{
                                    Toolbar: GridToolbar,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </div>
            {/****************** * user lead end **********************/}

          </>) :

            (<>
              <div className='container '>
                {/************************  total Lead ********************/}
                {
                  PropertyLeads.map((item, key) => {
                    const lead = item.data
                    const name = item['property_detail'][0]['name']
                    const count = item.lead
                    return (
                      <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content1"
                          id="panel1bh-header1">
                          <Typography sx={{ width: '10%', flexShrink: 0 }}>
                            <Avatar name={name} round={true} size="50" color="#529BE8" />
                          </Typography>
                          <Typography sx={{ marginTop: '15px', width: '60%', color: 'text.secondary' }}>{name}</Typography>

                          <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>Total Leads</Typography>
                          <Typography sx={{ marginTop: '15px', width: '10%', color: 'text.secondary' }}> {count}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="mx-10">
                            <div className="container">
                              <div className="row">
                                <div style={{ height: 450, width: '100%' }}>
                                  <DataGrid
                                    rows={lead}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    experimentalFeatures={{ newEditingApi: false }}
                                    localeText={{
                                      toolbarDensity: 'Size',
                                      toolbarDensityLabel: 'Size',
                                      toolbarDensityCompact: 'Small',
                                      toolbarDensityStandard: 'Medium',
                                      toolbarDensityComfortable: 'Large',
                                      MuiTablePagination: {
                                        labelDisplayedRows: ({ from, to, count }) =>
                                          `${from} - ${to} of more than ${count}`,
                                      },
                                    }}
                                    components={{
                                      Toolbar: GridToolbar,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })
                }

                {
                  LeadUser.map((item, index) => {
                    const userdata = item.user[0]
                    const userleads = item.data
                    const counts = item.lead
                    const ids = item.id + index + "a"
                    return (
                      <Accordion expanded={expanded === `panel${ids}`} onChange={handleChange(`panel${ids}`)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Typography sx={{ width: '10%', flexShrink: 0 }}>
                            <Avatar name={userdata?.name} round={true} size="50" color="#529BE8" />
                          </Typography>
                          <Typography sx={{ marginTop: '15px', width: '60%', color: 'text.secondary' }}>{userdata?.name}</Typography>

                          <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>Total Leads</Typography>
                          <Typography sx={{ marginTop: '15px', width: '10%', color: 'text.secondary' }}> {counts}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="mx-10">
                            <div className="container">
                              <div className="row">
                                <div style={{ height: 450, width: '100%' }}>
                                  <DataGrid
                                    rows={userleads}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    experimentalFeatures={{ newEditingApi: false }}
                                    localeText={{
                                      toolbarDensity: 'Size',
                                      toolbarDensityLabel: 'Size',
                                      toolbarDensityCompact: 'Small',
                                      toolbarDensityStandard: 'Medium',
                                      toolbarDensityComfortable: 'Large',
                                      MuiTablePagination: {
                                        labelDisplayedRows: ({ from, to, count }) =>
                                          `${from} - ${to} of more than ${count}`,
                                      },
                                    }}
                                    components={{
                                      Toolbar: GridToolbar,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })
                }
                {/****************** * total lead end **********************/}
              </div>
            </>)}

        </form>
      </main>
    </>
  )
}

export default LeadsPage






