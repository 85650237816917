import React, { useEffect, useState } from "react";
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import { useNavigate } from "react-router-dom";
import { ApiUrl, GetAgentType, GetDeveloperType, GetId, GetOtherBusinessType, getToken, removeUserSession } from "../utils/common";
import axios from "axios";
import moment from "moment";
const PaymentComplete = () => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [agentProfileData, setAgentProfileData] = useState();
  const navigate = useNavigate();

  const [invoiceDataGet,setInvoiceDataGet] = useState();
  const DateFormat = moment(invoiceDataGet?.date).format('MMMM Do YYYY')
  console.log(invoiceDataGet,'invoiceDataGet16');
  // developer profile get
  const GetProfile = () => {
    setLoading(true);
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/developer-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
        const data = e?.data?.[0];
        setUserData(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })
  }

  const RedirectDashboard = () => {
    // alert('working')
    if (`${GetDeveloperType()}` === "true") {
      navigate('/developerdashboard')
    }

    else if (`${GetAgentType()}` === "true") {
      navigate('/agentdashboard')
    }

    else if (`${GetOtherBusinessType()}` === "true") {
      navigate('/obdashboard')
    }
    // else (
    //   removeUserSession(window.location.reload())
    //   // window.location.reload()
    // )
  }

  // agent profile get
  const AgentProfileGet = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/agent-profile-get/${GetId()}`
    };
    axios(option)
      .then(e => {
        setAgentProfileData(e?.data?.[0])
      })
      .catch(err => {
      })
  }

  // get invoice data
  const Invoice = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/subscription/invoice/`
    };
    axios(option)
    .then(e => {
      setInvoiceDataGet(e?.data)
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
    })
  }
  useEffect(() => {
    GetProfile()
    AgentProfileGet()
    Invoice()
  }, [])


  return (
    <>
      <Header />
      <main id="content">
        <section className="pb-4 mt-100">
          {/* <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pt-6 pt-lg-2 lh-15 pb-5">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Payment Completed</li>
            </ol>
          </nav>
          <h1 className="fs-30 lh-1 mb-0 text-heading font-weight-600 mb-6">Payment Completed</h1>
        </div> */}
        </section>
        <section className="pt-8 pb-11">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-8 mb-6 mb-md-0">
                <h1 className="fs-30 lh-1 mb-0 text-heading font-weight-600 mb-6">{invoiceDataGet?.status}</h1>
                <h4 className="text-heading fs-18 font-weight-500 lh-15">My Order</h4>
                <ul className="list-unstyled">
                  <li className="d-flex justify-content-between lh-22">
                    <p className="text-gray-light mb-0">Order Number:</p>
                    <p className="font-weight-500 text-heading mb-0">{invoiceDataGet?.id}</p>
                  </li>
                  <li className="d-flex justify-content-between lh-22">
                    <p className="text-gray-light mb-0">Date:</p>
                    <p className="font-weight-500 text-heading mb-0">{DateFormat}</p>
                  </li>
                  <li className="d-flex justify-content-between lh-22">
                    <p className="text-gray-light mb-0">Total:</p>
                    <p className="font-weight-500 text-heading mb-0">${invoiceDataGet?.plan?.price}</p>
                  </li>
                  <li className="d-flex justify-content-between lh-22">
                    <p className="text-gray-light mb-0">Subcription Plan:</p>
                    <p className="font-weight-500 text-heading mb-0">{invoiceDataGet?.plan?.plan_type}</p>
                  </li>
                </ul>
              </div>
              <div className="col-md-7 offset-md-1">
                <h4 className="text-heading fs-22 font-weight-500 lh-15">Thank you for joining Dollar Per Foot</h4>
                <p className="mb-5">
                  Your Subcription Plan {invoiceDataGet?.plan?.plan_type} is enabled.
                </p>
                {/*   <p className="mb-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab, accusantium alias asperiores assumenda
                blanditiis cumque debitis deserunt dolores earum eos eum ex hic in inventore
              </p> */}
                <button type="button" className="btn bg-blue text-white hover-saffron px-4 py-2 lh-238" onClick={RedirectDashboard}>Go to My Dashboard</button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default PaymentComplete;