import React, { useEffect, useState } from "react";
import DevHeader from "./devheader";
import Devsidebar from '../../Components/DevSidebar/devsidebar';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiUrl, getToken } from "../../utils/common";
import axios from "axios";
import Loader from "../../Components/Loader";

const DevPropReview = () => {

  // all properties list
  const state = useLocation();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState([]);
  console.log(propertyData, 'propertyData');

  const ReviewProperties = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/user-properties-reviews`
    };
    axios(option)
      .then(e => {
        setLoading(false)
        setPropertyData(e?.data)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  useEffect(() => {
    ReviewProperties()
  }, [])
  // const AllProperties = () => {
  //   setLoading(true)
  //   const option = {
  //     method: 'GET', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'multipart/form-data',
  //       'Authorization': `Bearer ${getToken()}`
  //     },
  //     url: `${ApiUrl()}/api/property/user-properties`
  //   };
  //   axios(option)
  //     .then(e => {
  //       setLoading(false)
  //       setPropertyAll(e?.data)
  //     })
  //     .catch(err => {
  //       setLoading(false)
  //     })
  // }
  // useEffect(() => {
  //   AllProperties()
  // }, [])


  const ShowReviews = (item) => {
    navigate('/propreviewdeatils', { state: { item, Type: 'No' } })
    console.log(item, 'item43');
  }



  // const [propertyData,setPropertydata] = useState([]);
  // console.log(propertyData,'propertyData');
  // const PropertyReview = () => {
  //   const option = {
  //     method: 'GET', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'multipart/form-data',
  //       'Authorization': `Bearer ${getToken()}`
  //     },
  //     url: `${ApiUrl()}/api/property/user-propertiesDetail`
  //   };
  //   axios(option)
  //   .then(e => {
  //     setPropertydata(e?.data?.property)
  //     console.log(e,'e58');
  //   })
  //   .catch(err => {

  //   })
  // }


  // useEffect(() => {
  //   PropertyReview();
  // },[])

  return (
    <>
      {
        loading ? <Loader /> : null
      }

      {/*  {
        loading ? <Loader /> : null
      } */}
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <Devsidebar />
          <div className="page-content">
            <DevHeader />
            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Property Reviews<span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{propertyData?.length}</span>
                    </h2>

                  </div>
                  <div>
                    <Link to="/devreviews" className="btn bg-blue text-white hover-saffron btn-lg">
                      <span className="fs-18">My Reviews</span>
                      <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover border rounded-lg mb-6 bg-white">
                    <thead className="thead-sm thead-black">
                      <tr>
                        <th scope="col" className="col-9 border-top-0 px-6 pt-5 pb-4">Property Name</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4 header-user-icon">Reviews Count</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4 header-r-logout">Counts</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        propertyData?.map((item) => {
                          return (
                            <tr className="shadow-hover-xs-2 bg-hover-white" onClick={() => ShowReviews(item)}>
                              <td className="align-middle p-6">
                                <div style={{ cursor: 'pointer' }} className="text-dark font-weight-500 hover-saffron-text pt-1">{item?.property_name}</div>
                              </td>
                              <td className="align-middle">{item?.count}</td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default DevPropReview;