// import React, { useState, useEffect, useRef } from "react";
// import { BsFillLightbulbOffFill } from "react-icons/bs";
// // import { AddressObject } from "../../utils/common";

// function SearchLocationInput(props) {
//   const [query, setQuery] = useState("");
//   const autoCompleteRef = useRef(null);
//   const handlePlaceSelect = (updateQuery) => {
//     const addressObject = autoComplete.getPlace();
//     const latValue = addressObject.geometry.location.lat();
//     const lngValue = addressObject.geometry.location.lng();
//     const query = addressObject.formatted_address;
//     // console.log(query,'query');
//     props.AddressObject(addressObject)
//     updateQuery(query);

//     console.log(addressObject);
//     console.log(latValue, 'logV');
//     console.log(lngValue, 'lngV');
//   }
//   let autoComplete;

//   const loadScript = (url, callback) => {
//     let script = document.createElement("script");
//     script.type = "text/javascript";

//     if (script.readyState) {
//       script.onreadystatechange = function () {
//         if (script.readyState === "loaded" || script.readyState === "complete") {
//           script.onreadystatechange = null;
//           callback();
//         }
//       };
//     } else {
//       script.onload = () => callback();
//     }

//     script.src = url;
//     document.getElementsByTagName("head")[0].appendChild(script);
//   };

//   function handleScriptLoad(updateQuery, autoCompleteRef) {
//     autoComplete = new window.google.maps.places.Autocomplete(
//       autoCompleteRef.current,
//       { types: ["(regions)"], componentRestrictions: { country: ["us", "ca"] } }
//     );
//     autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
//     autoComplete.addListener("place_changed", () =>
//       handlePlaceSelect(updateQuery)
//     );
//   }
//   useEffect(() => {
//     loadScript(
//       `https://maps.googleapis.com/maps/api/js?key=AIzaSyAqVBbzZmrRV7LzReXe_bvQ4I-PuzP1GQ0&libraries=places`,
//       () => handleScriptLoad(setQuery, autoCompleteRef)
//     );
//   }, []);

//   return (
//     <div className="search-location-input">
//       <i class="far fa-search text-dark fs-18 position-absolute pl-4 pos-fixed-left-center"></i>
//       <input class="rounded-bottom-right-lg rounded-top-right-ra w-100 pl-8 py-4 bg-white border-0 fs-13 font-weight-500 text-gray-light rounded-0 lh-17"
//         placeholder="Find New Commercial Properties..."
//         ref={autoCompleteRef}
//         onChange={event => setQuery(event.target.value)}
//         value={query}
//       />
//     </div>
//   );
// }

// export default SearchLocationInput;

import React, { useState, useEffect, useRef } from "react";
import { BsFillLightbulbOffFill, BsSearch } from "react-icons/bs";
// import { AddressObject } from "../../utils/common";

import AutoComplete from "react-google-autocomplete";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "../../utils/common";
import axios from "axios";

function SearchLocationInput(props) {
  const [place, setPlace] = useState();
  
  const navigate = useNavigate()
  const Search = (place, e) => {
    const data = {}
    data.lat = place?.geometry?.location?.lat();
    data.lng = place?.geometry?.location?.lng();
    data.location = place?.formatted_address;
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: data,
      url: `${ApiUrl()}/api/property/advance-filter-property`
    };
    axios(option)
      .then(e => {
        Redirect(e?.data);
      })
      .catch(err => {

      })
  }

  const Redirect = (e) => {
    navigate('/propertylist', { state: {Data: e, place} })
  }

  const ref = useRef();
  return (
    <>
      {/* {
        loading ? <Loader /> : null
      } */}

      <main id="content">
        <section className="d-flex flex-column">
          <div style={{ width: '100%', display: 'block', float: 'left', }}>
            {/* <form onSubmit={Search}> */}
            <div class="d-flex px-15 padding-set-mob">
              <AutoComplete
                apiKey={'AIzaSyAqVBbzZmrRV7LzReXe_bvQ4I-PuzP1GQ0'}
                onPlaceSelected={(place) => {
                  setPlace(place); Search(place)
                }}
                className="rounded-bottom-right-lg rounded-top-right-ra w-100 pl-8 py-4   bg-white border-0 fs-13 font-weight-500 text-gray-light rounded-0 lh-17"
                placeholder="Enter Location..."
                onKeyDown={(event) => {
                  if (event?.keyCode === 13) {
                    event?.preventDefault();
                  }
                }}
                options={{
                  types: ["(regions)"],
                  componentRestrictions: { country: ["us", "ca"] },
                }}
                
              />
              <button type="button" class="btn text-white bg-blue hover-saffron fs-16 font-weight-600 rounded-left-0 rounded-lg" onClick={Search}>
                Search
              </button>
            </div>
            {/* </form> */}
          </div>
        </section>
      </main>
    </>
  );
}

export default SearchLocationInput;