import countryList from 'react-select-country-list';
import React, { useState, useMemo, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from 'axios';
import { ApiUrl, DeveloperId, GetId, getToken, GetUserType, removeUserSession } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import MyMap from "../../display";

import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';
import { MainApiProtectedVersion } from '../../utils/axios/requests';



function EditProfile() {
    const [imgFile, setImageFile] = useState();
    console.log(imgFile, 'imgFile')
    const userID = GetId()
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate()
    // sending image to base64
    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImageFile(reader?.result)
        };
    }

    const [value, setValue] = useState('');
    // const options = useMemo(() => countryList().getData(), []);
    const [show, setShow] = useState(false)

    // my profile img uploader preview
    const [isMyProfile, setIsMyProfile] = useState('');
    console.log(isMyProfile, " Hello FM");
    const PorfileImgHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
        setImageFile(e?.target?.files[0]);
    }
    // const [userType, setUserType] = useState();
    // console.log(userType?.is_otherbusiness,"hello type")
    //console.log(userData, 'userData');

    const UserGetData = userData;
    // console.log(UserGetData, 'UserGetData');
    const [loading, setLoading] = useState(false)

    const userType = GetUserType()
    console.log(userType)
    //     axios(option)
    //       .then(e => {
    //         const data = e?.data;
    //         setUserData(data)
    //         setLoading(false)
    //       })
    //       .catch(err => {
    //         setLoading(false)
    //         if (err?.request?.status === 401) {
    //           removeUserSession();
    //           navigate('/')
    //         }
    //       })
    //   }




    // states define
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    // const [designation, setDesignation] = useState();
    const [companyName, setCompanyName] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [countryName, setCountryName] = useState('');
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [mobile, setMobile] = useState();
    const [fax, setFax] = useState();
    const [postCode, setPostCode] = useState();
    const [twitterUrl, setTwitterUrl] = useState("");
    const [facebookUrl, setFaceBookUrl] = useState("");
    const [instagramUrl, setInstagramUrl] = useState("");
    const [areasServiced, setAreasServiced] = useState();
    const [businessType, setBusinessType] = useState();
    // console.log(configData,'cities')
    const AreaServiced = areasServiced?.map(i => ({ id: i?.id }))
    const Serviced = JSON.stringify(AreaServiced)
    // const [companyLicenceId, setCompanyLicenceId] = useState();
    const [website, setWebsite] = useState();
    const [designation, setDesignation] = useState()
    const [description, setDescription] = useState();
    const [alternateEmail1, setAlternateEmail1] = useState('');
    const [alternateEmail2, setAlternateEmail2] = useState('');
    const [alternateMobile1, setAlternateMobile1] = useState();
    const [alternateMobile2, setAlternateMobile2] = useState();
    const [configData, setConfigData] = useState([])
    const [addressMap, setAddressMap] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    console.log(addressMap, 'addressMap');
    useEffect(() => {
        setFirstName(userData?.first_name);
        setLastName(userData?.last_name)
        setDesignation(userData?.designation)
        setCompanyName(userData?.company_name)
        setAddress(userData?.street_name)
        setCity(userData?.city?.id)
        setState(userData?.state?.id)
        setCountryName(userData?.country?.id)
        setEmail(userData?.email)
        setPhone(userData?.office_no)
        setMobile(userData?.mobile_no)
        setFax(userData?.fax)
        setPostCode(userData?.post_code)
        setTwitterUrl(userData?.twitter_url)
        setFaceBookUrl(userData?.facebook_url)
        setInstagramUrl(userData?.instagram_url)
        setIsMyProfile(userData?.profile_image)
        setBusinessType(userData?.other_business_type?.id)
        // setCompanyLicenceId(userData?.company_license_id)
        setWebsite(userData?.website)
        setDescription(userData?.description)
        setAlternateEmail1(userData?.alternate_email1)
        setAlternateEmail2(userData?.alternate_email2)
        setAlternateMobile1(userData?.alternate_mobile_no1)
        setAlternateMobile2(userData?.alternate_mobile_no2)
        setAreasServiced(userData?.area_serviced)
        setLatitude(userData?.lat);
        setLongitude(userData?.lng);

    }, [userData])

    const ValidateFields = () => {
        if (!firstName || firstName.trim() === "") {
            toast.error("Please Enter Your Name")
            return false;
        }
        if (!designation) {
            toast.error("Please Enter Role/Designation")
            return false;
        }
        if (!email) {
            toast.error("Please Enter Your Email")
            return false;
        }
        if (!phone) {
            toast.error("Please Enter Office Phone Number")
            return false;
        }
        if (!mobile) {
            toast.error("Please Enter Mobile Number")
            return false;
        }
        if (!companyName) {
            toast.error("Please Enter Your Company Name")
            return false;
        }
        // if (!address) {
        //   toast.error("Please Enter Your Address")
        //   return false;
        // }
        // if (!countryName) {
        //   toast.error("Please Select Country")
        //   return false;
        // }
        // if (!state) {
        //   toast.error("Please Enter State")
        //   return false;
        // }
        // if (!city) {
        //   toast.error("Please Enter city")
        //   return false;
        // }
        // if (!postCode) {
        //   toast.error("Please Enter Post Code")
        //   return false;
        // }
        if(userType?.is_otherbusiness==="ture"||userType?.is_agent==="true"){
        if (!areasServiced) {
            toast.error("Please Select AreaServiced")
            return false;
          }}
          if(userType?.is_otherbusiness==="ture"){
          if (!businessType) {
            toast.error("Please Select Business Type")
            return false;
          }
        }
        return true;
    }

    async function UpdateUser(urlBase, formData) {
        const option = {
            method: 'PUT', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,


            url: urlBase,

        };
        axios(option)
            .then(_e => {
                toast.success("Profile has been successfully updated.")
                // GetProfile();
                navigate('/profile')
            })
            .catch(err => {

                if (err?.response?.data) {
                    toast.error(err?.response?.data?.facebook_url?.[0] ||
                        err?.response?.data?.instagram_url?.[0] || err?.response?.data?.twitter_url?.[0] || 'Something went wrong')
                }
            })
    }
    // console.log('===========userData=========================');
    // console.log(userData);
    // console.log('====================================');                



    useEffect(() => {
        async function GetProfile() {
            const obj = new MainApiProtectedVersion('', userID)
            const res = await obj.requstUserID().then((res) => {
                const data = res.data.data[0]
                console.log('====================================');
                console.log(data);
                console.log('====================================');
                if (data['is_developer'] === true) {

                
                    //   setProfileData(res.data.data[0]['developer_profile'])
                    setUserData(res.data.data[0]['developer_profile'])
                    setLoading(false)
                    console.log('================developer_profile====================');


                } else if (data['is_agent'] === true) {
                    // setProfileData(data['agent_profile'])
                    setUserData(res.data.data[0]['agent_profile'])
                    setLoading(false)

                    console.log('==============agent_profile======================');
                } else if (data['is_otherbusiness'] === true) {
                    // setProfileData(data['other_profile'])
                    setUserData(res.data.data[0]['other_profile'])
                    setLoading(false)

                    console.log('==============other_profile======================');

                }
            })
                .catch(error => {
                    alert(error)
                    setLoading(false)
                    if (error?.request?.status === 401) {
                        removeUserSession();
                        navigate('/')
                    }
                })
        }

        const ConfigDropDown = () => {
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                },
                url: `${ApiUrl()}/api/property/config-property`
            };
            axios(option)
                .then(e => {
                    setConfigData(e?.data)
                })
                .catch(_err => {
                })
        }
        GetProfile()

        ConfigDropDown()
    }, [userID, loading])


    console.log(userData.id)

    const SubmitData = () => {
        if (!ValidateFields()) return;
        const data = {}
        const formData = new FormData()
        formData.append('first_name', firstName)
        formData.append('last_name', lastName || "")
        formData.append('designation', designation)
        formData.append('company_name', companyName)
        // formData.append('street_name', address)
        // formData.append('state', state)
        // formData.append('city', city)
        formData.append('street_name', addressMap?.address)
        formData.append('state', addressMap?.state)
        formData.append('city', addressMap?.city)
        formData.append('email', email)
        //formData.append('country', countryName)
        formData.append('country', addressMap?.country)
        formData.append('office_no', phone)
        formData.append('mobile_no', mobile)
        formData.append('fax', fax || "")
        //formData.append('post_code', postCode)
        formData.append('post_code', addressMap?.postal_code)
        formData.append('user_id', GetId())
        formData.append('twitter_url', twitterUrl || "")
        formData.append('instagram_url', instagramUrl || "")
        formData.append('facebook_url', facebookUrl || "")
        formData.append('website', website || "")
        formData.append('description', description || "")
        formData.append('alternate_email1', alternateEmail1 || "")
        formData.append('alternate_email2', alternateEmail2 || "")
        formData.append('alternate_mobile_no1', alternateMobile1 || "")
        formData.append('alternate_mobile_no2', alternateMobile2 || "")
        formData.append('lat', addressMap?.mapPosition?.lat || latitude)
        formData.append('lng', addressMap?.mapPosition?.lng || longitude)
        formData.append('other_business_type', businessType)
        if (imgFile) {
            formData.append('profile_image', imgFile);
        }
        if (areasServiced?.length) {
            areasServiced?.map((item) => {
              formData.append('area_serviced', item?.id)
            })
      
          }



        if (userID) {
            if (userType === "dev") {
                const url = `${ApiUrl()}/auth/user/developer-profile/${userData.id}`
                UpdateUser(url, formData)
            } else if (userType === "agent") {
                const url = `${ApiUrl()}/auth/user/agent-profile/${userData.id}`
                UpdateUser(url, formData)

            } else {
                const url = `${ApiUrl()}/auth/user/otherbusiness-profile/${userData.id}`
                UpdateUser(url, formData)
            }


        } else {
            const option = {
                method: 'POST', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${getToken()}`
                },
                data: formData,
                url: `${ApiUrl()}/auth/user/developer-profile`
            };

            axios(option)
                .then(_e => {
                    toast.success("Profile has been successfully Create.")
                    // GetProfile();
                })
                .catch(err => {
                    if (err?.response?.data) {
                        toast.error(err?.response?.data?.facebook_url?.[0] ||
                            err?.response?.data?.instagram_url?.[0] || err?.response?.data?.twitter_url?.[0] || 'Something went wrong')

                    }
                })
        }


    }
    // const DeleteProfile = () => {
    //   setLoading(true)
    //   const option = {
    //     method: 'DELETE', headers: {
    //       'access-control-allow-origin': '*',
    //       'content-type': 'application/json',
    //       'Authorization': `Bearer ${getToken()}`
    //     },
    //     // data: JSON.stringify(data),

    //     url: `${ApiUrl()}/auth/user/developer-profile/${userData?.[0]?.id}`
    //   };

    //   axios(option)
    //     .then(e => {
    //       setLoading(false)
    //       GetProfile();
    //       window.location.reload();
    //     })
    //     .catch(err => {
    //       setLoading(false)
    //     })
    // }

    // config dropdown api

    return (
        <>
            <main id="content" className="bg-gray-01">

                <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                    <div className="mb-6">
                        <h2 className="mb-0 text-heading fs-22 lh-15">My Profile
                        </h2>

                    </div>
                    <form>
                        <div className="row mb-6">
                            <div className="col-lg-6">
                                <div className="card mb-6">
                                    <div className="card-body px-6 pt-6 pb-5">
                                        <div className="row">
                                            <div className="col-sm-4 col-xl-12 col-xxl-7 mb-6">
                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Photo</h3>
                                                <p className="card-text">Upload your profile photo.</p>
                                            </div>
                                            <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">
                                                {
                                                    imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                                }

                                                <div className="custom-file mt-4 h-auto">

                                                    <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*" onChange={PorfileImgHandler} />
                                                    <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                        <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                        profile image</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-6 mb-lg-0">
                                    <div className="card-body px-6 pt-6 pb-5">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">Company Details</h3>

                                        <div className="form-group">
                                            <label htmlFor="companyName" className="text-heading margin-b-0">Company Name <span className="star-mandatoryfield">*</span></label>
                                            <input type="text" className="form-control form-control-lg border-0" id="companyName"
                                                name="companyname" onChange={(e) => setCompanyName(e?.target?.value)} value={companyName} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description" className="text-heading">Company Overview</label>
                                            <textarea type="text" className="form-control form-control-lg border-0" id="description"
                                                name="description" rows={5} onChange={(e) => setDescription(e?.target?.value)} value={description} />
                                        </div>
                                        <MyMap selectedData={(e) => setAddressMap(e)} addressMap={addressMap} propertyData={userData} />
                                    </div>
                                </div>
                                {userType === 'other' || userType === 'agent' ? (<>

                                    <div className="card mb-6 mt-5">
                                        <div className="card-body px-6 pt-6 pb-5">
                                            <h3 className="card-title mb-0 text-heading fs-22 lh-15">Areas Serviced</h3>

                                            <div className="form-group">
                                                <label htmlFor="twitter" className="text-heading">Select Areas Where You Provide Services</label>
                                                <Select
                                                    className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                    options={configData?.city}
                                                    isMulti
                                                    title="Select"
                                                    data-style="btn-lg py-2 h-52"
                                                    onChange={(e) => setAreasServiced(e)}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    value={areasServiced}
                                                    classNamePrefix='select-inner'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>) : null
                                }

                            </div>
                            <div className="col-lg-6">
                                <div className="card mb-6">
                                    <div className="card-body px-6 pt-6 pb-5">

                                        <div className="form-row mx-n4">
                                            <div className="form-group col-md-6 px-4">
                                                <label htmlFor="firstName" className="text-heading margin-b-0">First name <span className="star-mandatoryfield">*</span></label>
                                                <input type="text" className="form-control form-control-lg border-0" id="firstName"
                                                    name="firstname" onChange={(e) => setFirstName(e?.target?.value)} value={firstName} />
                                            </div>
                                            <div className="form-group col-md-6 px-4">
                                                <label htmlFor="lastName" className="text-heading">Last name</label>
                                                <input type="text" className="form-control form-control-lg border-0" id="lastName"
                                                    name="lastname" onChange={(e) => setLastName(e?.target?.value)} value={lastName} />
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="role" className="text-heading margin-b-0">Role/Designation <span className="star-mandatoryfield">*</span></label>
                                            <input type="text" className="form-control form-control-lg border-0" id="role"
                                                name="role" onChange={(e) => setDesignation(e?.target?.value)} value={designation} />
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="email" className="text-heading margin-b-0">Email <span className="star-mandatoryfield">*</span></label>
                                            <input type="email" className="form-control form-control-lg border-0" id="email"
                                                name="email" onChange={(e) => setEmail(e?.target?.value)} value={email} />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="email" className="text-heading">Alternate Email 1</label>
                                            <input type="email" className="form-control form-control-lg border-0" id="email"
                                                name="email" onChange={(e) => setAlternateEmail1(e?.target?.value)} value={alternateEmail1} />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="email" className="text-heading">Alternate Email 2</label>
                                            <input type="email" className="form-control form-control-lg border-0" id="email"
                                                name="email" onChange={(e) => setAlternateEmail2(e?.target?.value)} value={alternateEmail2} />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="website" className="text-heading">Website</label>
                                            <input type="text" className="form-control form-control-lg border-0" id="website"
                                                name="website" placeholder="https://google.com" onChange={(e) => setWebsite(e?.target?.value)} value={website} />
                                        </div>



                                        <div className="form-row mx-n4">
                                            <div className="form-group col-md-6 px-4">
                                                <label htmlFor="phone" className="text-heading margin-b-0">Phone <span className="star-mandatoryfield">*</span></label>

                                                <CurrencyFormat className="form-control form-control-lg border-0" placeholder="Enter 10 Digit Phone Number" format="+1 (###) ###-####" mask="_" onChange={(e) => setPhone(e?.target?.value)} value={phone} />
                                            </div>
                                            <div className="form-group col-md-6 px-4">
                                                <label htmlFor="mobile" className="text-heading margin-b-0">Mobile <span className="star-mandatoryfield">*</span></label>

                                                <CurrencyFormat className="form-control form-control-lg border-0" id="mobile" placeholder="Enter 10 Digit Mobile Number" onChange={(e) => setMobile(e?.target?.value)} value={mobile} format="+1 (###) ###-####" mask="_" />
                                            </div>


                                            <div className="form-group col-md-6 px-4">
                                                <label htmlFor="mobile" className="text-heading">Alternate Mobile 1</label>
                                                <CurrencyFormat className="form-control form-control-lg border-0" placeholder="Enter 10 Digit Phone Number" format="+1 (###) ###-####" mask="_" onChange={(e) => setAlternateMobile1(e?.target?.value)} value={alternateMobile1} />
                                            </div>

                                            <div className="form-group col-md-6 px-4">
                                                <label htmlFor="mobile" className="text-heading">Alternate Mobile 2</label>
                                                <CurrencyFormat className="form-control form-control-lg border-0" placeholder="Enter 10 Digit Phone Number" format="+1 (###) ###-####" mask="_" onChange={(e) => setAlternateMobile2(e?.target?.value)} value={alternateMobile2} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {userType === 'other' ? (<>
                                <div class="card mb-6 mt-5">
                                    <div class="card-body px-6 pt-6 pb-5">
                                        <h3 class="card-title mb-0 text-heading fs-22 lh-15">Business Type <span className="star-mandatoryfield">*</span></h3>
                                        <div class="form-group mb-0">
                                            <label for="title" class="text-heading">Title / Posittion</label>
                                            <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                title="Select" data-style="btn-lg py-2 h-52"
                                                id="type" name="type" onChange={(e) => setBusinessType(e?.target?.value)} defaultValue={businessType}>
                                                <option>Select Business Type</option>
                                                {
                                                    configData?.other_business_types?.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} selected={item?.id === businessType ? "selected" : ''}>{item?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                    </>):null}
                                <div className="card mb-6 ">
                                    <div className="card-body px-6 pt-6 pb-5">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">Social information</h3>

                                        <div className="form-group">
                                            <label htmlFor="twitter" className="text-heading">Twitter Url</label>
                                            <input type="url" className="form-control form-control-lg border-0" id="twitter" name="twitter"
                                                onChange={(e) => setTwitterUrl(e?.target?.value)} value={twitterUrl} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="facebook" className="text-heading">Facebook Url</label>
                                            <input type="url" className="form-control form-control-lg border-0" id="facebook"
                                                name="facebook" onChange={(e) => setFaceBookUrl(e?.target?.value)} value={facebookUrl} />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="instagram" className="text-heading">Instagram Url</label>
                                            <input type="url" className="form-control form-control-lg border-0" id="instagram"
                                                name="instagram" onChange={(e) => setInstagramUrl(e?.target?.value)} value={instagramUrl} />
                                        </div>



                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="d-flex justify-content-end flex-wrap">
                            {/* <button type='button' className="btn btn-lg bg-hover-white border rounded-lg mb-3" onClick={() => DeleteProfile()}>Delete Profile</button> */}
                            <button type='button' className="btn btn-lg bg-blue text-white hover-saffron ml-4 mb-3" onClick={SubmitData}>
                                {userID ? 'Update Profile' : 'Save Profile'}</button>

                        </div>
                    </form>
                </div>
            </main>

        </>
    )
}
export default EditProfile;