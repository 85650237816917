import React from "react";
import AgentAdminDash from "./agentadmindashboard";
import AgentHeader from "./agentheader";
import AgentSidebar from '../../Components/AgentSidebar/agentsidebar';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
const AgentDashBaord =()=>{
    return(
        <>
             <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
                    
                    <AgentSidebar />  
                    

                    <div className="page-content">
                        <AgentHeader />
                        <AgentAdminDash />
                        
                    </div>
                    
                </div>
            </div>
        </>
    )
}
export default AgentDashBaord;