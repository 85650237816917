import React, { useEffect, useState, Fragment } from "react";
import Header from "../../Components/header/header";
import BannerImage from "../../../src/images/back-living.png";
//import BannerImage from "../../../src/images/backtext-alpha.png";
import BackImage from "../../../src/images/about-shape.png";
import BackInImage from "../../../src/images/shape-1.png";
import BackLineImage from "../../../src/images/bg_circle.png";
import Footer from "../../Components/footer/footer";
import Slider from "react-slick/lib/slider";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useGeolocated } from "react-geolocated";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiUrl } from "../../utils/common";
import SearchLocationInput from "../../Components/Map/SearchLocationInput";
import Loader from "../../Components/Loader";
import CountUp from 'react-countup';
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { MainApiProtectedVersion } from "../../utils/axios/requests";
import moment from "moment";
import TestimonialQuery from "../Testimonial/Query";
import { Modal } from "react-bootstrap";
import ReactPlayer from 'react-player'
import { textAlign } from "@mui/system";





const LandingPage = () => {

    const { state } = useLocation();
    const [propertyType, setPropertyType] = useState();
    const [constructionStatus, setConstructionStatus] = useState();
    const [priceperSf, setPricePerSf] = useState();
    const [keyWord, setKeyword] = useState();
    const [featuredAgentData, setFeaturedAgentData] = useState([]);
    const [featuredDeveloperData, setFeaturedDeveloperData] = useState([])
    const [searchAddress, setSearchAddress] = useState()
    const Latitude = searchAddress?.geometry?.location?.lat();
    const Longitude = searchAddress?.geometry?.location?.lng();
    // const [searchData,setSearchData] = useState([]);
    const [homeSearchData, setHomeSearchData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [preLaunchPropertyData, setPreLaunchPropertyData] = useState([]);
    const [featuredPropertyData, setFeaturedPropertyData] = useState([]);
    const [landingBlogList, setLandingBlogList] = useState([])
    const [getDropwonData, setGetDropdownData] = useState();
    const [search, setSearch] = useState(null);
    console.log(landingBlogList, "landing blog")

    /* get blog api */
    const GetAllBlogList = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/blog/posts/`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                setLandingBlogList(e?.data.data);
            })
            .catch(err => {
                setLoading(false);
            })
    }


    /* end blog api */



    // search api
    // const Search = () => {
    //     setLoading(true)
    //     const data = {}
    //     data.lat = Latitude;
    //     data.lng = Longitude;
    //     data.location = searchAddress?.formatted_address;
    //     const option = {
    //         method: 'POST', headers: {
    //             'access-control-allow-origin': '*',
    //             'content-type': 'application/json',
    //         },
    //         data: data,
    //         url: `${ApiUrl()}/api/property/advance-filter-property`
    //     };
    //     axios(option)
    //         .then(e => {
    //             setLoading(false)

    //             Redirect(e?.data)
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //         })
    // }

    // const Redirect = (e) => {

    //     navigate('/propertylist', { state: e, searchAddress })
    // }
    // search api
    const Search = () => {
        setLoading(true)
        const data = {}
        // data.lat = Latitude;
        // data.lng = Longitude;
        //  data.location = searchAddress?.formatted_address;
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            // data: data,
            url: `${ApiUrl()}/api/property/advance-filter-property`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                // setHomeSearchData(e?.data)
                // console.log(homeSearchData,'homeSearchData237');
                Redirect(e?.data)
            })
            .catch(err => {
                setLoading(false)
            })
    }





    const Redirect = (e) => {
        // console.log(homeSearchData,'homeSearchData245');
        navigate('/propertylist', { state: { footerType: "AllProperty" } })
    }
    var settings = {
        dots: true,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    }


    var Agents = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                Agents: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                Agents: {
                    slidesToShow: 1,
                    slidesToScroll: 1,


                }
            },
            {
                breakpoint: 480,
                Agents: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // infinite: false,
                }
            },
            {
                breakpoint: 320,
                Agents: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // infinite: false,
                }
            }
        ]


    }

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,

        });

    if (!isGeolocationAvailable) {


    } else if (!isGeolocationEnabled) {

    }
    const data = {};
    data.lat = coords?.latitude || 0;
    data.lng = coords?.longitude || 0;
    data.radius = "10";
    const option = {
        method: 'POST', headers: {
            'access-control-allow-origin': '*',
            'content-type': 'application/json',
        },
        data: JSON.stringify(data),
        // url: `${ApiUrl()}/api/property/near-by-properties`
    };

    axios(option)
        .then(e => {
            console.log(e, '67');
        })
        .catch(err => {

        })



    // keyword search part



    const KeywordSearch = () => {
        const data = (search != null) ? search : "";

        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },

            data: { "data": data },
            url: `${ApiUrl()}/api/property/filter-property?search=${data}`
        };
        axios(option)
            .then(e => {
                console.log(e, 'e');
            })
            .catch(err => {

            })
    }

    useEffect(() => {
        KeywordSearch();
    }, [search])

    // config dropdown api 



    const PropertyDropdowns = () => {
        const data = {}
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/property/config-property`
        };

        axios(option)
            .then(e => {
                // setGetDropdownData(e?.data)
                setGetDropdownData(e?.data)
            })
            .catch(err => {

            });
    }

    // featured agent api
    const FeaturedAgent = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/auth/user/featured-agents`
        };
        axios(option)
            .then(e => {
                setFeaturedAgentData(e?.data)
            })
            .catch(err => {
            })
    }

    // featured developer api
    const FeaturedDevelopers = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/auth/user/featured-developers`
        };
        axios(option)
            .then(e => {
                setFeaturedDeveloperData(e?.data)
            })
            .catch(err => {

            })
    }

    // featured properties
    const FeaturedProperty = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/property/filter-property?is_featured=true`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                setFeaturedPropertyData(e?.data);
            })
            .catch(err => {
                setLoading(false);
            })
    }


    // prelaunch property
    const PreLaunchProperty = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/api/property/filter-property?selling_status__name=Pre-Launch`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                setPreLaunchPropertyData(e?.data);
            })
            .catch(err => {
                setLoading(false)
            })
    }

    useEffect(() => {
        PropertyDropdowns();
        FeaturedAgent();
        FeaturedDevelopers();
        FeaturedProperty();
        PreLaunchProperty();
        GetAllBlogList();
    }, [])

    const FullInfo = (item) => {
        navigate('/userdetails', { state: item })
    }

    const FeaturedPropertyRedirect = () => {
        navigate('/propertylist', { state: { Data: featuredPropertyData, type: "Featured" } })
    }

    const ProjectLaunchingSoon = () => {
        navigate('/propertylist', { state: { Data: preLaunchPropertyData, type: "Commercial" } })
    }

    // const PropertyShowing = () => {
    //     navigate('/propertylist', {state: searchAddress})
    // }
    const RedirectPropertyPage = (item) => {
        navigate('/singlepropertydetails', { state: item })
    }
    const commercialPropertyPage = (item) => {
        navigate('/singlepropertydetails', { state: item })
    }
    const [videoShow, setVideoShow] = useState(false);
    const videoHandle = () => {
        setVideoShow(true);
    }
    const handleClose = () => setVideoShow(false);

    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <Header verifyAccount={state?.fromVarification} />
            <main id="content-home">
                {/* D:\react-dpf\src\Pages\landingPage\dollarperfoot.mp4 */}
                <section className=" h-100vh d-flex flex-column" style={{ /* backgroundImage: `url(${BannerImage})`, */backgroundColor: "#333", backgroundSize: "cover", backgroundPosition: "center" }} >
                    <video autoPlay loop muted playsInline>
                        <source src="https://dollarperfoot.s3.us-east-1.amazonaws.com/dollarperfoot.mp4" type="video/mp4" />
                    </video>
                    <div className="d-flex align-items-center custom-vh-100  mr-bm-sr " style={{ position: "absolute", width: "100%" }}>
                        <div className="col-lg-6 col-sm-3 col-md-12 margin-top-90 ">
                                        <AnimationOnScroll className="w-100" animateIn="animate__fadeInLeft "  >
                                            <div className="bg-fade op-7 px-5 py-8 round-5 add-paddings-mobile">
                                                {/* <h2 className="text-black display-2  fs-32 fw-600" >No.1 Platform for Commercial Real Estate New Development Projects</h2>
                                    <p className="fs-17 fw-600 fm-nunito">
                                        We connect buyers directly with developers and agents to book their new commercial space.
                                    </p> */}
                                                <h2 className="text-black display-2  fs-42 fw-600 text-center margin-top-30" ><img className="landing-fevicon" src="images/favicon-icon.png" /><span className="text-saffron">No.1 Platform for</span> <br /><span className="text-blue">Commercial Real Estate </span> <br />New Development Projects</h2>
                                                <p className="text-center text-black fs-17 fm-nunito">
                                                    We connect buyers directly with developers and agents to book their new commercial space
                                                </p>
                                                <form className="d-flex">
                                                    <div className="position-relative w-100">
                                                        <div className="in-fr">
                                                            <SearchLocationInput AddressObject={(e) => setSearchAddress(e)} />
                                                        </div>

                                                    </div>


                                                </form>
                                                <div id="advanced-search-filters-4" className="col-12 pt-4 pb-sm-4 order-sm-5 order-4 collapse bg-white"
                                                    data-parent="#accordion-4">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-3 pt-6">
                                                            <label className="ft-sz text-uppercase font-weight-500 letter-spacing-093 mb-1">Property Type</label>
                                                            <select className="form-control selectpicker bg-transparent border-bottom rounded-0 border-color-input height-unset"
                                                                name="bedroom"
                                                                title="All Bedrooms" data-style="p-0 h-24 lh-17 text-dark" onChange={(e) => setPropertyType(e?.target?.value)} value={propertyType} >
                                                                <option>Select Property Type</option>

                                                                {
                                                                    getDropwonData?.propertyType?.map((item, index) => {
                                                                        return (
                                                                            <option value={item?.id}>{item?.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-3 pt-6">
                                                            <label className=" ft-sz text-uppercase font-weight-500 letter-spacing-093 mb-1 set-ls">Construction Status</label>
                                                            <select className="form-control selectpicker bg-transparent border-bottom rounded-0 border-color-input height-unset"
                                                                title="All Bathrooms" data-style="p-0 h-24 lh-17 text-dark" name="bathroom" onChange={(e) => setConstructionStatus(e?.target?.value)} value={constructionStatus} >
                                                                <option>Select Construction Status</option>
                                                                {
                                                                    getDropwonData?.ConstructionStatus?.map((item, index) => {
                                                                        return (
                                                                            <option value={item?.id}>{item?.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-3 pt-6">
                                                            <label className=" ft-sz text-uppercase font-weight-500 letter-spacing-093 mb-1 set-ls">Location</label>
                                                            <input type="text" className="mr-lt form-control selectpicker bg-transparent border-bottom rounded-0 border-color-input height-unset b-none" placeholder="Location" />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-3 pt-6 slider-range slider-range-secondary">
                                                            <label htmlFor=" price-1-4" className="ft-sz text-uppercase font-weight-500 letter-spacing-093 mb-1 set-ls">Starting Price Per Square Feet</label>

                                                            {/* <Form.Label></Form.Label>
                                                        <Form.Range /> */}
                                                            <input type="text" className="mr-lt form-control selectpicker bg-transparent border-bottom rounded-0 border-color-input height-unset b-none" placeholder="Price Range" onChange={(e) => setPricePerSf(e?.target?.value)} value={priceperSf} />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-3 pt-6 slider-range slider-range-secondary">
                                                            <label htmlFor="price-1-4" className="ft-sz text-uppercase font-weight-500 letter-spacing-093 mb-1 set-ls">Keyword</label>

                                                            {/* <Form.Label></Form.Label>
                                                        <Form.Range /> */}
                                                            <input type="text" className="mr-lt form-control selectpicker bg-transparent border-bottom rounded-0 border-color-input height-unset b-none" placeholder="Keyword" onChange={(e) => setKeyword(e?.target?.value)} value={keyWord} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row pt-2">
                                                    
                                                </div> */}
                                                </div>
                                            </div>
                                        </AnimationOnScroll>
                                    </div>
                                    <div className="col-6 " >
                                        <AnimationOnScroll animateIn="animate__fadeInRight">

                                            <div className="all-center">
                                                <a className="all-round"><img src="images/play-icon.png" className="play-button-image" onClick={videoHandle} style={{ width: '100px' }} /></a>
                                            </div>

                                        </AnimationOnScroll>
                                    </div>
                                

                            

                       

                    </div>
                </section>

                {/* add counter section */}
                <section className=" pb-lg-10">

                    <div className="main-section-couter-part counter-section">
                        <div className="container-fluid py-5 px-10 padding-set-left-right">
                            <div className="row">
                                <div className="col-md-6 txa-center text-count ">
                                    <div className="row">
                                        <div className="col-3 content-algin">
                                            <img src="images/certificate.png" />
                                        </div>
                                        <div className="col-9 saparator-btw">
                                            <h1><CountUp delay={1} enableScrollSpy={true} end={15} className="h1-counter" />+</h1>
                                            <span>Years of Experience</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 txa-center text-count ">
                                    <div className="row">
                                        <div className="col-3 content-algin">
                                            <img src="images/building-icon.png" />
                                        </div>
                                        <div className="col-9 saparator-btw">
                                            <h1><CountUp delay={1} enableScrollSpy={true} end={1000} className="h1-counter" />+</h1>
                                            <span>Developers</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 txa-center text-count ">
                                    <div className="row">
                                        <div className="col-3 content-algin">
                                            <img src="images/manager.png" />
                                        </div>
                                        <div className="col-9 saparator-btw">
                                            <h1><CountUp delay={1} enableScrollSpy={true} end={2000} className="h1-counter" />+</h1>
                                            <span>Agents</span>
                                        </div>
                                    </div></div>
                                <div className="col-md-6 txa-center text-count ">
                                    <div className="row">
                                        <div className="col-3 content-algin">
                                            <img src="images/sold-out.png" />
                                        </div>
                                        <div className="col-9">
                                            <h1><CountUp delay={1} enableScrollSpy={true} end={100} className="h1-counter" />k+</h1>
                                            <span>Square Foot Sold</span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
                <section className="pt-lg-12 pb-lg-10 py-11 padding-top-bottom-50" style={{ backgroundImage: `url(${BackImage})` }} >

                    <div className="container container-xxl">
                        <div className="row flex-direction-column about-us-landing">
                            <div className="col-md-6 add-max-width-100">

                                <AnimationOnScroll animateIn="animate__fadeInLeft">
                                    <div className="about-img-wrap">
                                        <img className="about-img-one" src="images/about-us.png" />
                                        {/* <img className="about-img-two" src="images/office.png" /> */}
                                    </div>

                                </AnimationOnScroll>
                            </div>
                            <div className="col">
                                <AnimationOnScroll animateIn="animate__fadeInRight">
                                    <div className="ml-10 margin-left-0-about-us margin-left-0">
                                        <h2 className="text-heading font-size-24">About Us</h2>
                                        <span className="heading-divider divider-bottom"></span>
                                        <h2 className="text-heading fm-poppins fs-22 mb-5 margin-bottom-15">We are the only Real Estate Platform for Commercial New Developments Projects in USA & Canada</h2>
                                        <p className="fs-17 fm-nunito text-justify">Backed by a founders who have been into commercial developments projects, real estate large scale investments, property management and project management with Fortune 500 companies, Dollar Per Foot is the only platform for new commercial projects investment opportunities for Buyers, for Developers to showcase their new commercial developments, for Agents to list their exclusive new  commercial properties on our platform and for professionals who provide services in real estate.</p>
                                        <div className="row">
                                            <p className="fs-16 fw-600 fm-nunito text-justify"><img className="mr-1" style={{ width: '18px' }} src="images/check.png" />Developers list their commercial new development projects for targetted buyers and get leads</p></div>
                                        <p className="fs-16 fw-600 fm-nunito text-justify"><img className="mr-1" style={{ width: '18px' }} src="images/check.png" />Agents get leads from buyers looking for commercial new development projects bookings</p>
                                        <p className="fs-16 fw-600 fm-nunito text-justify"><img className="mr-1" style={{ width: '18px' }} src="images/check.png" />Businesses showcase their service portfolio and get leads on this platform</p>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                {featuredPropertyData?.length ?
                    <section className="pt-lg-12 pb-lg-10 py-11 padding-top-bottom-50">
                        <div className="container container-xxl">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="text-heading font-size-24">Featured Commercial Projects</h2>
                                    <span className="heading-divider divider-bottom"></span>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <button
                                        className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 mb-6 mb-lg-0"
                                        onClick={() => FeaturedPropertyRedirect()}
                                    >See all properties
                                        <i className="far fa-long-arrow-right ml-1"></i>
                                    </button>
                                </div>
                            </div>
                            <Slider {...settings}>
                                {
                                    featuredPropertyData?.slice(0, 9)?.map((item) => {
                                        return (
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Link to={`property/details/${item.id}`}>
                                                    <div /* onClick={() => RedirectPropertyPage(item)} */ >
                                                        <div className="box box pb-7 pt-2" data-animate="fadeInUp">
                                                            <div className="card shadow-hover-2">
                                                                <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                                                    <img src={item?.featured_image}
                                                                        alt="Home in Metric Way" className="prelaunch-property-image" />
                                                                    <div className="card-img-overlay p-2 d-flex flex-column">
                                                                        <div>
                                                                            {
                                                                                item?.labels?.map((item) => {
                                                                                    return (
                                                                                        <span className="badge mr-2 badge-orange">{item?.name}</span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body pt-3">
                                                                    <h2 className="card-title fs-16 lh-2 mb-0"><a href="#"
                                                                        className="text-dark">{item?.name}</a></h2>
                                                                    <p className="card-text font-weight-500 text-gray-light mb-2">{item?.street_name}</p>
                                                                    <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                                                        <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                                            data-toggle="tooltip" title="Size">
                                                                            <img src="images/area.png" className="icon icon-bedroom fs-18 text-primary mr-1 width-32" />
                                                                            {item?.size} Sq.Ft
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">


                                                                    {item?.labels?.find(i => i?.id)?.name === "For Lease" ? (
                                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={item?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                                            className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>
                                                                    ) : (
                                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                                            className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </AnimationOnScroll>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </section> : null
                }
                <section>
                    <div className="bg-gray-02 py-lg-13 pt-11 pb-6 padding-top-bottom-50">
                        <div className="container container-xxl">
                            <div className="row">
                                <div className="col-lg-4 pr-xl-13" data-animate="fadeInLeft">
                                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                                        <h2 className="text-heading lh-1625 font-size-24">Explore <br />
                                            by Property Type</h2>
                                        <span className="heading-divider divider-bottom"></span>
                                        <p className="mb-6 fm-nunito fs-17">Maximize your return on Commercial Property in New Development Projects. Never Miss a Deal !</p>
                                        <a
                                            onClick={() => Search()}
                                            className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 mb-6 mb-lg-0">Search By Property Type
                                            <i className="far fa-long-arrow-right ml-1"></i>
                                        </a>
                                    </AnimationOnScroll>
                                </div>

                                <div className="col-lg-8" data-animate="fadeInRight">
                                    <AnimationOnScroll animateIn="animate__fadeInRight">
                                        <div className="slick-slider arrow-haft-inner custom-arrow-xxl-hide mx-0 opacity-1"
                                            data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":false,"responsive":[{"breakpoint": 1200,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 3,"arrows":false,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 2,"arrows":false,"autoplay":true}}]}'>
                                            <Slider {...settings}>
                                                {/* slider first */}
                                                <div className="box px-0 py-6 wdt-193">
                                                    <a
                                                        onClick={() => Search()}
                                                        className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                                                        <img src="images/office-building.png" className="card-img-top"
                                                            alt="Apartment" />
                                                        <div className="card-body px-0 pt-5 pb-0">
                                                            <h4 className="card-title fs-16 lh-2 text-dark mb-0">Office</h4>
                                                        </div>
                                                    </a>
                                                </div>

                                                {/* slider two */}
                                                <div className="box px-0 py-6 wdt-193">
                                                    <a
                                                        onClick={() => Search()}
                                                        className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                                                        <img src="images/store.png" className="card-img-top"
                                                            alt="House" />
                                                        <div className="card-body px-0 pt-5 pb-0">
                                                            <h4 className="card-title fs-16 lh-2 text-dark mb-0">Retail</h4>
                                                        </div>
                                                    </a>
                                                </div>

                                                {/* slider three */}
                                                <div className="box px-0 py-6 wdt-193">
                                                    <a
                                                        onClick={() => Search()}
                                                        className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                                                        <img src="images/architect.png" className="card-img-top"
                                                            alt="Office" />
                                                        <div className="card-body px-0 pt-5 pb-0">
                                                            <h4 className="card-title fs-16 lh-2 text-dark mb-0">Land</h4>
                                                        </div>
                                                    </a>
                                                </div>

                                                {/* fourth slider */}
                                                <div className="box px-0 py-6 wdt-193">
                                                    <a
                                                        onClick={() => Search()}
                                                        className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                                                        <img src="images/factory.png" className="card-img-top"
                                                            alt="Villa" />
                                                        <div className="card-body px-0 pt-5 pb-0">
                                                            <h4 className="card-title fs-16 lh-2 text-dark mb-0">Industrial</h4>
                                                        </div>
                                                    </a>
                                                </div>

                                                {/* fifth slider */}
                                                <div className="box px-0 py-6 wdt-193">
                                                    <a
                                                        onClick={() => Search()}
                                                        className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                                                        <img src="images/cafe.png" className="card-img-top"
                                                            alt="Apartment" />
                                                        <div className="card-body px-0 pt-5 pb-0">
                                                            <h4 className="card-title fs-16 lh-2 text-dark mb-0">Restaurant</h4>
                                                        </div>
                                                    </a>
                                                </div>
                                                {/* sixth slider */}
                                                <div className="box px-0 py-6 wdt-193">
                                                    <a
                                                        onClick={() => Search()}
                                                        className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                                                        <img src="images/hospital.png" className="card-img-top"
                                                            alt="Apartment" />
                                                        <div className="card-body px-0 pt-5 pb-0">
                                                            <h4 className="card-title fs-16 lh-2 text-dark mb-0">Medical</h4>
                                                        </div>
                                                    </a>
                                                </div>
                                            </Slider>
                                        </div>
                                    </AnimationOnScroll>

                                    {/* slider start */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    preLaunchPropertyData?.length ? <section className="pt-lg-12 pb-lg-11 py-11 padding-top-bottom-50">
                        <div className="container container-xxl">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="text-heading font-size-24">Commercial Projects Launching Soon</h2>
                                    <span className="heading-divider divider-bottom"></span>
                                    {/* <p className="mb-6">Lorem ipsum dolor sit amet, consec tetur cing elit. Suspe ndisse suscipit</p> */}
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <a
                                        onClick={() => ProjectLaunchingSoon()}
                                        className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 mb-6 mb-lg-0">See all properties
                                        <i className="far fa-long-arrow-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="slick-slider slick-dots-mt-0 custom-arrow-spacing-30 opacity-1"
                                data-slick-options='{"slidesToShow": 4,"dots":true,"arrows":false,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'>
                                <Slider {...settings} className="commercial-set">
                                    {
                                        preLaunchPropertyData?.slice(0, 9)?.map((item) => {
                                            return (
                                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                                    <div onClick={() => commercialPropertyPage(item)}>
                                                        <div className="box box pb-7 pt-2" data-animate="fadeInUp">
                                                            <div className="card shadow-hover-2">
                                                                <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                                                    <img src={item?.featured_image || "images/favicon-icon.png"}
                                                                        alt="Villa on Hollywood Boulevard" className="prelaunch-property-image" />
                                                                    <div className="card-img-overlay p-2 d-flex flex-column">
                                                                        <div>
                                                                            {
                                                                                item?.labels?.map((item) => {
                                                                                    return (
                                                                                        <span className="badge mr-2 badge-orange">{item?.name}</span>
                                                                                    )

                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body pt-3">
                                                                    <h2 className="card-title fs-16 lh-2 mb-0"><a href="#"
                                                                        className="text-dark">{item?.name}</a></h2>
                                                                    <p className="card-text font-weight-500 text-gray-light mb-2">{item?.street_name}</p>
                                                                    <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">

                                                                        <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                                            data-toggle="tooltip" title="Size">
                                                                            <img src="images/area.png" className="icon icon-bedroom fs-18 text-heading mr-1 width-32" />
                                                                            {item?.size} Sq.Ft
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">

                                                                    {/* <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                                        className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p> */}
                                                                    {item?.labels?.find(i => i?.id)?.name === "For Lease" ? (
                                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={item?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                                            className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>
                                                                    ) : (
                                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                                            className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                                                    )}
                                                                    <ul className="list-inline mb-0">
                                                                        {/* <li className="list-inline-item">
                                                                            <a href="#"
                                                                                className="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                                                                                data-toggle="tooltip" title="Wishlist"><i
                                                                                    className="far fa-heart"></i></a>
                                                                        </li> */}

                                                                    </ul>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AnimationOnScroll>
                                            )
                                        })
                                    }

                                </Slider>
                            </div>
                        </div>
                    </section> : null
                }

                <section className="bg-fade newsletter-section" style={{ backgroundImage: `url(${BackLineImage})` }}>
                    <div className="container container-xxl">
                        <div className=" py-11 ">
                            <div className="row flex-direction-column subscribe-section">

                                <div className="col-md-6 subscribe-image">
                                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                                        <img className="round-5 height-625" src="images/about-new.png" />
                                    </AnimationOnScroll>
                                </div>

                                <div className="col-6 bg-frame round-5 subscribe-letter">
                                    <AnimationOnScroll animateIn="animate__fadeInRight">
                                        <iframe className="iframe-land" src="https://0516cd31.sibforms.com/serve/MUIEAPUc9Oc4p5WvdSJRmQoz-gubKbgJLtwwWOZ1UK4BcG4TZpu0Q_Zb4f5HgnTd8xB1FxD9242zHLLdNkHFpo4fcgt3LDX4zUKWzgFGgm4u9Bhx7UlFHF7lQIGgeRHsHVQVSao4hLA3nDoDQawAi2RkTXJLA30npd-jjXzLXPpIVxoZLvs-zWdb3T2nmY1kybfjhdtcltsDkTyq" frameborder="0" scrolling="no" allowfullscreen ></iframe>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    featuredDeveloperData?.length ? <section className="pt-lg-12 pb-lg-11 py-11 padding-top-bottom-50">
                        <div className="container container-xxl">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="text-heading font-size-24">Featured Developers</h2>
                                    <span className="heading-divider divider-bottom"></span>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <Link to="/finderservice"

                                        className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 mb-6 mb-lg-0">Find more Developers
                                        <i className="far fa-long-arrow-right ml-1"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="slick-slider slick-dots-mt-0 custom-arrow-spacing-30 opacity-1"
                                data-slick-options='{"slidesToShow": 4,"dots":true,"arrows":false,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'>
                                <Slider {...settings} className="feature-developer-site">
                                    {/* first slider */}
                                    {
                                        featuredDeveloperData?.slice(0, 10)?.map((item) => {
                                            return (
                                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                                    <div className="box" data-animate="fadeInUp">





                                                        {/* <div onClick={() => FullInfo(item)} style={{ cursor: 'pointer' }}> */}
                                                        <div className="card border-2 shadow-3 shadow-hover-3 px-4 height-308">
                                                            <Link to={`/user/details/${item.user_id}`} >
                                                                <div className="card-body text-center pt-6 pb-3 px-0">
                                                                    <a className="d-inline-block mb-2">
                                                                        <img src={item?.developer_profile?.profile_image || "images/favicon-icon.png"} alt="" className="images-data-landing-page" />
                                                                    </a>
                                                                    <a
                                                                        className="d-block fs-16 text-dark mb-0 font-weight-500 lh-15">{item?.name}</a>
                                                                    <p className="mb-0 text-black">{item?.developer_profile?.designation}</p>

                                                                </div>
                                                            </Link>
                                                            <div className="card-footer bg-white px-0 text-center pt-3 pb-1">
                                                                <ul className="list-inline mb-0">
                                                                    <li className="list-inline-item mb-2">
                                                                        <a href={item?.developer_profile?.twitter_url}
                                                                            className="w-40px h-40 rounded-circle border text-body bg hover-blue border d-flex align-items-center justify-content-center"><BsTwitter /></a>
                                                                    </li>
                                                                    <li className="list-inline-item mb-2">
                                                                        <a href={item?.developer_profile?.facebook_url}
                                                                            className="w-40px h-40 rounded-circle border text-body bg hover-blue border d-flex align-items-center justify-content-center"><FaFacebookF /></a>
                                                                    </li>
                                                                    <li className="list-inline-item mb-2">
                                                                        <a href={item?.developer_profile?.instagram_url}
                                                                            className="w-40px h-40 rounded-circle border text-body bg hover-blue border d-flex align-items-center justify-content-center">
                                                                            <BsInstagram /></a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}


                                                    </div>
                                                </AnimationOnScroll>
                                            )
                                        })
                                    }
                                </Slider>
                                <div className="col-lg-8 mt-10 col-md-12 center-set sub-text-ld" data-animate="fadeInRight">
                                    <div className="py-6 px-4  d-sm-flex align-items-sm-center position-relative  rounded-lg add-bg-color-agent" data-animate="fadeInUp">
                                        <div className="mt-md-0 mt-6">
                                            <h4 className="text-heading fs-20 font-weight-normal set-font-line-height"><span className="font-weight-600">Register as Developer/Builder </span>to get maximum reach out.</h4>
                                            {/*  <p className="mb-0">Lorem ipsum dolor sit amet, consec tetur cing elit</p> */}
                                        </div>
                                        <div className="ml-auto">
                                            <a href="/signup" className="btn btn-lg bg-blue hover-saffron rounded-lg mt-sm-0 mt-6 text-white width-100per">Register Now</a>
                                        </div>
                                        <i className="far fa-users h-64 w-64px bg-saffron d-flex justify-content-center align-items-center text-white rounded-circle fs-24 position-absolute custom-pos-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : null
                }

                {/* agent section add */}
                {
                    featuredAgentData?.length ? <section className="pt-lg-12 pb-lg-11 py-11 padding-top-bottom-50">
                        <div className="container container-xxl">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="text-heading font-size-24">Featured Agents</h2>
                                    <span className="heading-divider divider-bottom"></span>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <Link to="/finderservice"

                                        className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 mb-6 mb-lg-0">Find more Agents
                                        <i className="far fa-long-arrow-right ml-1"></i>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className="slick-slider slick-dots-mt-0 custom-arrow-spacing-30 opacity-1"
                            data-slick-options='{"slidesToShow": 4,"dots":true,"arrows":false,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'> */}
                            <Slider {...settings} className="featured-agents-slider">
                                {/* first slider */}
                                {
                                    featuredAgentData?.map((item) => {
                                        return (
                                            <AnimationOnScroll animateIn="animate__fadeInUp">
                                                <div className="box" data-animate="fadeInUp">


                                                    <div className="card border-2 shadow-3 shadow-hover-3 px-4 height-308">
                                                        <Link to={`/user/details/${item.user_id}`} >
                                                            <div className="card-body text-center pt-6 pb-3 px-0">
                                                                <a className="d-inline-block mb-2">
                                                                    <img src={item?.agent_profile?.profile_image || "images/favicon-icon.png"} alt="" className="images-data-landing-page" />
                                                                </a>
                                                                <a
                                                                    className="d-block fs-16 text-dark mb-0 font-weight-500 lh-15">{item?.name}</a>
                                                                <p className="mb-0 text-black">{item?.agent_profile?.designation}</p>

                                                            </div>
                                                        </Link>
                                                        <div className="card-footer bg-white px-0 text-center pt-3 pb-1">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item mb-2">
                                                                    <a href={item?.agent_profile?.twitter_url}
                                                                        className="w-40px h-40 rounded-circle border text-body bg hover-blue border d-flex align-items-center justify-content-center"><BsTwitter /></a>
                                                                </li>
                                                                <li className="list-inline-item mb-2">
                                                                    <a href={item?.agent_profile?.facebook_url}
                                                                        className="w-40px h-40 rounded-circle border text-body bg hover-blue border d-flex align-items-center justify-content-center"><FaFacebookF /></a>
                                                                </li>
                                                                <li className="list-inline-item mb-2">
                                                                    <a href={item?.agent_profile?.instagram_url}
                                                                        className="w-40px h-40 rounded-circle border text-body bg hover-blue border d-flex align-items-center justify-content-center">
                                                                        <BsInstagram /></a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>

                                                </div>

                                            </AnimationOnScroll>
                                        )
                                    })
                                }
                            </Slider>
                            <div className="p-6 mxw-670 pl-md-9 d-sm-flex align-items-sm-center position-relative mt-10 rounded-lg add-bg-color-agent" data-animate="fadeInUp">
                                <div className="mt-md-0 mt-6">
                                    <h4 className="text-black fs-20 font-weight-normal">Register as Agent on <span className="font-weight-600">Dollar Per Foot</span></h4>
                                    {/*  <p className="mb-0">Lorem ipsum dolor sit amet, consec tetur cing elit</p> */}
                                </div>
                                <div className="ml-auto">
                                    <a href="signup" className="btn btn-lg bg-blue hover-saffron  rounded-lg mt-sm-0 mt-6 text-white">Register Now</a>
                                </div>
                                <i className="far fa-users h-64 w-64px bg-saffron d-flex justify-content-center align-items-center text-white rounded-circle fs-24 position-absolute custom-pos-icon"></i>
                            </div>
                            {/* </div> */}
                        </div>
                    </section> : null
                }


                {/* testimonial section add */}
                <TestimonialQuery />
                <section className="py-lg-13 pt-11 pb-sm-9 pb-7 padding-top-bottom-50">
                    <div className="container">
                        <div className="row heading-section-featured-TestimonialSlider mt-5">
                            <div className="col-12 text-center mb-5">
                                <h2 className="heading-testimonial text-blue ">Blog & news</h2>
                                {/* <h2 className="text-heading mb-20">Dollar Per Foot Maximize your return on Commercial Property</h2> */}
                            </div>
                        </div>
                        <div className="row">

                            {/*   <div className="col-lg-4 mt-lg-4" data-animate="fadeInLeft">
                                <p className="text-blue font-weight-500 letter-spacing-263 text-uppercase mb-5">Blog & news</p>
                               
                                <ul className="list-unstyled mb-0">
                                    {landingBlogList?.slice(0,5).map((item) => {
                                        return (<>
                                            <li className="pb-3"> <Link to={`/blogs/${item.id}`} className="fs-15 font-weight-500 text-body set-font-12 hover-saffron-text"><i className="fas fa-long-arrow-alt-right mr-2"></i>{item?.title}</Link></li>
                                        </>)
                                    })}
                                    
                                </ul>
                            </div> */}
                            <div className="col-lg-12 mt-6 mt-lg-0" data-animate="fadeInRight">
                                <div className="row">
                                    {landingBlogList?.slice(0, 3).map((item) => {
                                        return (<>
                                            <div className="card border-0 col-sm-4 mb-sm-0 mb-7">
                                                <div className="position-relative d-flex align-items-end card-img">
                                                    <Link to={`/blogs/${item.id}`} className="hover-shine round-5">
                                                        <img src={item?.featured_image_url || "images/favicon-icon.png"}
                                                            alt="Retail banks wake up to digital lending this year" />
                                                    </Link>
                                                    {/*   <a href="/#"
                                                className="badge pos-fixed-bottom text-white bg-dark-opacity-04 fs-13 font-weight-500 bg sidebar-list-item m-2 position-absolute">
                                                rental
                                            </a> */}
                                                </div>
                                                <div className="card-body p-0 pr-xl-8">
                                                    <ul className="list-inline mt-4 text-gray-lighter">
                                                        <li className="list-inline-item mr-4"><i className="far fa-calendar mr-1"></i> {moment(item?.created_on).format('YYYY-MMM-DD')}</li>

                                                    </ul>
                                                    <h3 className="fs-18 text-heading lh-194">
                                                        <Link to={`/blogs/${item.id}`} className="text-heading">{item?.title}</Link>
                                                    </h3>
                                                    <div dangerouslySetInnerHTML={{ __html: item?.content.substring(0, 200) }} />

                                                </div>
                                                <Link style={{ marginTop: '5px', width: '50%' }} to={`/blogs/${item.id}`} className="btn text-white bg-blue hover-saffron">Read more <i className="far fa-long-arrow-right ml-1"></i></Link>
                                            </div>
                                        </>)
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal className="modal-video"
                    show={videoShow} onHide={handleClose} >
                    <div className="">
                        <a className="videoclose"><img src="./images/videoclose.png" alt="close" onClick={handleClose} /></a>
                    </div>
                    <ReactPlayer className="video-iframe" playing="true" loop="true" controls="true" url='https://www.youtube.com/watch?v=4bT9vCj-yAw' />
                </Modal>
            </main>

            <div className="position-fixed pos-fixed-bottom-right p-6 z-index-10">
                <a href="#"
                    className="gtf-back-to-top text-white bg-blue hover-saffron sidebar-list-item bg shadow p-0 w-52px h-52 rounded-circle fs-20 d-flex align-items-center justify-content-center add-opacity-cstm"
                    title="Back To Top"><i
                        className="fal fa-arrow-up"></i></a>
            </div>
            <Footer />
        </>
    )
}
export default LandingPage;