import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BlogCreate from './BlogCreate'
import BlogList from './BlogList'
import { Link } from 'react-router-dom'

const BlogPage = () => {
    return (<>
        <main id="content " className='bg-gray-01 margin-re-top'>
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                    <div className="mr-0 mr-md-auto">
                        <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Blog</h2>
                    </div>
                    <div>
                        <Link to="createblog" className="btn bg-blue text-white hover-saffron btn-lg">
                            <span>Create Blog</span>
                            <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                        </Link>
                    </div>
                </div>
                <div className="card border-0 mb-4 header-user-icon">
                    <div className="card-body p-0 p-sm-8 p-4">
                    <BlogList />
                    </div>
                </div>
            </div>
        </main>
       {/*  <main id="content " className="mt-100">
            <section>
                <div className="container container-xxl">
                    <div className='row'>
                        <div className="col-md-6">
                            <BlogList />
                        </div>
                         <div className="col-md-6">
                            <BlogCreate />
                        </div>
                    </div>
                </div>
            </section>
        </main> */}
    </>
    )
}

export default BlogPage;
