import React from 'react'
import { Link } from 'react-router-dom'
import TaxList from './List'
import { Taxform } from './form'

export default function TaxPage() {

  




  return (
    <div>
      <div class="container row ">
    
      <div className='col'>
        <Taxform />


      </div>

      <div className='col'>
        <TaxList />

      </div>
      </div>




    </div>
  )
}
