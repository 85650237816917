import React from 'react'
import { useState } from 'react';

const Pagination = ({ postPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
        pageNumbers.push(i)
    }

    const [activeIndex,setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index)
    }
    return (
        <>
            <nav>
                <ul className='pagination add-custom-style-pagination'>
                    {
                        pageNumbers?.map((item, index) => {
                            return (
                                <li className='page-item'>
                                    <a onClick={() => {paginate(item); handleClick(index)}} className={activeIndex === index ? "active-pagination" : "page-link"}>
                                        {item}
                                    </a>
                                </li>
                            )

                        })
                    }
                </ul>
            </nav>
        </>
    )
}
export default Pagination;