import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import { FormControlLabel, IconButton } from "@material-ui/core";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import { gql, useMutation, useQuery } from '@apollo/client';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Modal } from "react-bootstrap";


const AdminTestimonial = () => {
    const GET_TESTIMONIAL = gql`
    query{
        testmonial{
          lastName
          id
          firstName
          email
          profileImageUrl
          description
          designation
          companyName
          profileImage
        }
      }
    `;
    const { loading, error, data } = useQuery(GET_TESTIMONIAL);
    const DEL_TESTIMONIAL = gql`
     mutation deleteTestmonial($id: ID!){
        deleteTestmonial(id:$id){
         success
       }
     }
     `;
    const [show, setShow] = useState(false);
    // console.log(show,'RAHUL')
    // const [load, setLoading] = useState(false);
    // const [blogList, setBlogList] = useState([])
    const [testData, setTestData] = useState();
    // console.log(testData, 'testData')
    const [submissionDone, setSubmissionDone] = useState(false);
    const OnCloseModal = () => {
        setSubmissionDone(false)
    }
    const navigate = useNavigate();
    const MatDel = ({ item }) => {
        const [deleteTestmonial, success] = useMutation(DEL_TESTIMONIAL);
        const handleDelClick = () => {
            deleteTestmonial({
                variables: { id: item }
            });
            window.location.reload();
        };

        return (
            <FormControlLabel
                control={
                    <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        onClick={handleDelClick}
                    >
                        <DeleteIcon style={{ color: blue[500] }} />
                    </IconButton>
                }
            />
        );
    };
    const MatView = ({ index }) => {
        const handleRowClick = () => {
            setTestData(index);
            setSubmissionDone(true)
        };
        return (
            <FormControlLabel
                control={
                    <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        onClick={handleRowClick}
                    >
                        <VisibilityIcon style={{ color: blue[500] }} />
                    </IconButton>
                }
            />
        );
    };
    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'firstName', headerName: 'First Name', width: 180 },
        { field: 'lastName', headerName: 'Last Name', width: 180 },
        { field: 'companyName', headerName: 'Comapny Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 230 },
        {
            field: "view",
            headerName: "View",
            sortable: false,
            width: 80,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <MatView index={params.row} />
                    </div>
                );
            }
        },
        {
            field: "del",
            headerName: "Delete",
            sortable: false,
            width: 80,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <MatDel item={params.row.id} />
                    </div>
                );
            }
        }
    ]
    return (<>

        <main id="content " className='bg-gray-01 '>
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                    <div className="mr-0 mr-md-auto">
                        <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Testimonial</h2>
                    </div>
                </div>
                <div className="card border-0 mb-4 header-user-icon">
                    <div className="card-body p-0 p-sm-8 p-4">
                        <div class="row">
                            {(data?.testmonial) ? (<>
                                <div style={{ height: 450, width: '100%' }}>
                                    <DataGrid
                                        rows={data?.testmonial}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[5]}
                                        checkboxSelection
                                        experimentalFeatures={{ newEditingApi: false }}
                                    //onRowClick={handleRowClick}
                                    />
                                </div>
                            </>) : (<>
                                No data found
                            </>)}
                        </div>
                    </div>
                </div>

                {testData ? (

                    <>
                        <Modal className="col-lg-12 col-md-6  col-sm-3 modal-add-contact"
                            show={submissionDone} >
                            <Modal.Body>
                                <div className="container mt-5">
                                    <div class="row d-flex justify-content-center">
                                        <div className="col-12">
                                            <div className=" p-3 py-4">
                                                <div className="text-center">
                                                    <img src={`https://dollarperfoot.s3.amazonaws.com/${testData?.profileImage}`} width="150" className="rounded-circle" />
                                                </div>
                                                <div className="text-center mt-3">
                                                    <span className="bg-secondary p-1 px-4 rounded text-white">{testData.firatName} {testData?.lastName}</span>
                                                    <h5 className="mt-2 text-blue fs-14">{testData.email}</h5>
                                                    <span className="mt-2 mb-0">{testData.companyName}</span>
                                                    <span>{testData.designation}</span>
                                                    <div className="px-4 mt-1">
                                                        <p className="fonts">{testData.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnCloseModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>) : null}

            </div>
        </main>
    </>)
}
export default AdminTestimonial;