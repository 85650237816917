import React, { useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Form, Modal, } from "react-bootstrap";
import { AddId, ApiUrl, GetAgentType, GetDeveloperType, GetId, GetOtherBusinessType, getToken, removeUserSession, setUserSession } from "../../utils/common";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { gapi } from 'gapi-script';
import { Link, useNavigate } from "react-router-dom";
import Popup from "../popup/signinpopup/popup";
import Loader from "../Loader";

const AdminHeader = ({ verifyAccount }) => {

  const navigate = useNavigate();
  const usertoken = getToken();
  const [agentProfileData, setAgentProfileData] = useState();
  const [otherBusinessData, setOtherBusinessData] = useState();
  // logout api
  
  const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('DevType');
    localStorage.removeItem('ObType');
    localStorage.removeItem('planId');
    localStorage.removeItem('AdminType');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isSuperUser');

    localStorage.removeItem('AgeType');
    localStorage.removeItem('id');
    navigate('/')

    
  }
  // modal part
  const [show, setShow] = useState(verifyAccount || false);

  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false)
  // developer profile get
 

  // agent profile get

  // 
  
  

  return (
    <header className="main-header shadow-none shadow-lg-xs-1 bg-white position-relative d-none d-xl-block">
            <div className="container-fluid">
              <nav className="navbar navbar-light py-0 row no-gutters px-3 px-lg-0">
                <div className="col-md-4 px-0 px-md-6 order-1 order-md-0">
                  
                </div>
                <div className="col-md-6 d-flex flex-wrap justify-content-md-end order-0 order-md-1">
                  <div className="dropdown border-0 py-3 text-right">
                    <a href="#"
                       className="dropdown-toggle text-heading pr-3 pr-sm-6 d-flex align-items-center justify-content-end"
                       data-bs-toggle="dropdown">
                      <div className="mr-4 w-48px">
                        <img src="../images/dummy-user.jpg"
                                 alt="Ronald Hunter" className="rounded-circle" />
                      </div>
                      <div className="fs-13 font-weight-500 lh-1" onClick={Logout}>
                        Super Admin
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right  py-2">
                     {/*  <a className="dropdown-item" href="dashboard-my-profiles.html">My Profile</a> */}
                      <a className="dropdown-item" href="#">Logout</a>
                    </div>
                  </div>
                  
                 {/*  <div className="dropdown no-caret py-3 px-3 px-sm-6 d-flex align-items-center justify-content-end notice">
                    <a href="#" className="dropdown-bs-toggle text-heading fs-20 font-weight-500 lh-1"
                       data-toggle="dropdown">
                      <i className="far fa-bell"></i>
                      <span className="badge badge-primary badge-circle badge-absolute font-weight-bold fs-13">1</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
              </nav>
            </div>
        </header>

  )
}
export default AdminHeader;