import React from "react";
import './popup.css';
// import CloseImage from '../../../../src/close.png';



function Popup(props) {

    
    return (props.trigger) ? (
        
        <div className="popup">
            <div className="popup-inner">
                {/* <button className="close-btn" onClick={() => props.setTrigger(false)}>
                    <img src={CloseImage} alt="close-img" />
                </button> */}
                {props.children}
            </div>

        </div>
        
    ) : "";
}

export default Popup;