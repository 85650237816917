import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { Form, Col } from 'react-bootstrap';
import axios from '../../../utils/axios/main'; // importing axios from customAxios
import { useForm, Controller } from "react-hook-form";
import { ListGroup } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { MainApiProtectedVersion } from "../../../utils/axios/requests";
import { useLocation } from 'react-router-dom';


export default function PlanCreate() {
 const state = useLocation();
 

  const { Id } = useParams();
 
  const isAddMode = !Id;
  const { handleSubmit, control, watch, register, setValue, getValues, reset, errors, formState } = useForm({});
  const [loading, setloading] = useState(false)
  const [coupon, setCoupon] = useState([])
  const [Tax, setTax] = useState([])
  const [couponID, setCouponId] = useState()
  console.log(couponID);
  const [TaxId, setTaxId] = useState()

  const [plan, setPlan] = useState([])
  const [Duration, setDurationValue] = useState('')

  const [SingleTax, setSingleTax] = useState()
  const navigate = useNavigate()

  const [discountPrice, setDiscountPrice] = useState()
  const [TaxPrice, setTaxPrice] = useState()
  const [price, setPriceValue] = useState(0)
  const [Singlecoupon, setSingleCoupon] = useState()
  const [discountPriceValue, setDiscountPriceValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [durationValue, setDuration] = useState('');
  console.log(durationValue, 'duration')
  const offPrice = Singlecoupon?.percent_off;
  const yprice = price * 12;
  const mprice = price / 12;
  const [customPlan, setCustomPlan] = useState('');
  console.log(customPlan, ' value')
  
  
  
  


  /* custom plan */
  /* function onChange(e) {
    setCustomPlan(e.target.value) //update your value here
  } */

  function onChange(event) {
    setDuration(event.target.value) //update your value here
  }
  useEffect(() => {
    if (durationValue) {
      if (durationValue === 'year') {
        console.log('=============year=======================');

        const discountPriceValues = price * (offPrice / 100);
        setDiscountPriceValue(discountPriceValues)
        const total = price - price * (offPrice / 100);
        setTotalAmount(total)
      } else {
        console.log('=============month=======================');

        const discountPriceValues = yprice * (offPrice / 100);
        setDiscountPriceValue(discountPriceValues)
        const total = yprice - yprice * (offPrice / 100);
        setTotalAmount(total)
        console.log('====================================');
      }
    } else {
      console.log('errro')
    }
  });

  


  useEffect(() => {
    setloading(false)
    async function TaxGet() {
      const obj = new MainApiProtectedVersion()
      const res = await obj.requstSubscriptionTaxGet().then((res) => {
        setTax(res.data)

      })
        .catch(error => {
          alert(error)
        })
    }
    async function CouponGet() {
      const obj = new MainApiProtectedVersion()
      const res = await obj.requstSubscriptionCouponGet().then((res) => {
        setCoupon(res.data)
      })
        .catch(error => {
          alert(error)
        })
    }
    CouponGet()
    TaxGet()

  }, [loading])

  const Checkbox = ({ name }) => (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: false }}
        render={({ field }) =>
          <Form.Check
            {...field}
            type="switch"
          />
        }
      />
      <Form.Check.Label className="ml-8">{name}</Form.Check.Label>
    </>
  )
  const SelectCoupon = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <>
      <Form.Label>
        {label}
      </Form.Label>
      <Form.Select className="form-control form-control-lg border-0" aria-label="Default select example" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
        {
          coupon.map((x) =>
            <option value={x.id} >{x.display_name}</option>)
        }
      </Form.Select>
    </>
  ));
  const SelectDuration = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <>
      <Form.Label>
        {label}
      </Form.Label>
      <Form.Select className="form-control form-control-lg border-0" aria-label="Default select example" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
        <option value="">Select Duration</option>
        <option value="year">Year</option>
        <option value="month">Month</option>
      </Form.Select>
    </>
  ));
  const SelectTax = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <>
      <Form.Label>
        {label}
      </Form.Label>
      <Form.Select className="form-control form-control-lg border-0" aria-label="Default select example"
        name={name} ref={ref}
        onChange={onChange} onBlur={onBlur}>
        {
          Tax.map((x) =>
            <option value={x.id} >{x.display_name}</option>)
        }
      </Form.Select>
    </>
  ));
  const SelectPlanType = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <>
      <Form.Label>
        {label}
      </Form.Label>
      <Form.Select className="form-control form-control-lg border-0" aria-label="Default select example" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
        <option value="Silver">Silver</option>
        <option value="Gold">Gold</option>
        <option value="Platinum">Platinum</option>
        <option value="Custom">Custom Plan</option>
      </Form.Select>
    </>
  ));
  const SelectPlanFor = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <>


      <Form.Label>
        {label}
      </Form.Label>
      <Form.Select className="form-control form-control-lg border-0" aria-label="Default select example" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
        <option value="developer_builder">Developer/Builder</option>
        <option value="broker_agent">Broker / Agent</option>
        <option value="other_business">Other Business</option>
      </Form.Select>
    </>
  ));
  

  useEffect(() => {
    if (!isAddMode) {
      async function SubscriptionPlanID() {
        const obj = new MainApiProtectedVersion('', Id)
        const res = await obj.requstSubscriptionPlanID().then((res) => {
          setPlan(res.data)
          setCouponId(res.data.coupon)
          reset(res.data);
          setValue('coupon',res.data.coupon.id)
         // console.log(res.data.coupon.id)
        })
          .catch(error => {
            alert(error)
          })
      }
      
      SubscriptionPlanID()
    }
  }, [Id,reset, isAddMode, setValue]);

  function updatePlan(Id, data) {
    return axios.put(`/auth/subscription/plan/update/${Id}/`, data).then(response => {
      console.log(response)
      if (response.status == 200) {
        alert("plan update")
        navigate('/admin/plan')
      }
    }
    )
      .catch(error => console.log(error));
  }
  function CreatePlan(data) {
    return axios.post('/auth/subscription/plan/create', data).then(response => {
      console.log(response.data)
      navigate('/admin/plan')
    }
    )
      .catch(error => console.log(error));
  }
  
  useEffect(() => {
    watch(async (value, { name, type }) => {
      if (name === "tax") {
        let taxId = value.tax
        const obj = new MainApiProtectedVersion('', taxId)
        const res = await obj.requstSubscriptionTaxIDGet().then((res) => {
          setSingleTax(res.data)
          console.log(res.data)
        })
          .catch(error => {
            alert(error)
          })
      }
      setPriceValue(value.price)
      if (name === "coupon") {
        let couponId = value.coupon
        const obj = new MainApiProtectedVersion('', couponId)
        const res = await obj.requstSubscriptionCouponIDGet().then((res) => {
          setSingleCoupon(res.data)
          console.log(res.data)
        })
          .catch(error => {
            alert(error)
          })

      }
    });
  }, [watch]);

  const onSubmit = (data) => {

    if (Singlecoupon) {
      const CouponPercentage = Singlecoupon.percent_off

      if (data.duration === 'month') {
        data['monthly_price'] = price
        const yearly = price * 12
        const afterDiscount = yearly - (yearly * CouponPercentage / 100);
        console.log('############afterDiscountTax###############', afterDiscount)
        const disCount = afterDiscount.toFixed(2)
        setDiscountPrice(disCount)
        data['yearly_price_discount'] = disCount
        var yearlyPRice = price * 12
        const Yealyprice = yearlyPRice.toFixed(2)
        data['yearly_price'] = Yealyprice
        if (disCount) {
          return isAddMode ? CreatePlan(data) : updatePlan(Id, data);
        }

      } else {
        const afterDiscount = price - (price * CouponPercentage / 100);
        console.log('############afterDiscountTax###############', afterDiscount)
        setDiscountPrice(afterDiscount.toFixed(2))
        data['yearly_price_discount'] = afterDiscount.toFixed(2)
        data['yearly_price'] = `${price}.00`
        var yearlyPRice = price / 12
        data['monthly_price'] = yearlyPRice.toFixed(2)
        if (afterDiscount) {
          return isAddMode ? CreatePlan(data) : updatePlan(Id, data);
        }

      }
    }
    else {
      if (data.duration === 'month') {
        data['monthly_price'] = price
        const yearly = price * 12
        setDiscountPrice(yearly)
        data['yearly_price_discount'] = yearly.toFixed(2)
        data['yearly_price'] = yearly.toFixed(2)
        if (yearly) {
          return isAddMode ? CreatePlan(data) : updatePlan(Id, data);
        }

      } else {
        setDiscountPrice(price)
        data['yearly_price_discount'] = price
        data['yearly_price'] = `${price}.00`
        var monthly_price = price / 12
        data['monthly_price'] = monthly_price
        if (monthly_price) {
          return isAddMode ? CreatePlan(data) : updatePlan(Id, data);
        }
      }
    }
  };
  return (
    <>
      <main id="content" class="bg-gray-01">
        <div class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
          <div className="mb-6 margin-re-top">
            <h2 className="mb-0 text-heading fs-22 lh-15">{isAddMode ? 'Add Plan' : 'Edit Plan'}
            </h2>

          </div>
          <div className="card bg-transparent border-0">
            <div className="card-body py-4 py-md-0 px-0">
              <div className="card mb-6">
                <div className="card-body p-6">
                  <Form onSubmit={handleSubmit(onSubmit)}
                    onReset={reset}>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Plan Type</label>
                          <SelectPlanType  {...register("plan_type")} onChange={(e) => setCustomPlan(e?.target?.value)} value={customPlan} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Subscription Type</label>
                          <SelectPlanFor  {...register("subscription_plan")} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Coupon Type</label>
                          <SelectCoupon  {...register("coupon")} />
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      {customPlan === "Custom" ? (<>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="title" className="text-heading margin-b-0">Plan Name</label>
                            <input type="text" className="form-control form-control-lg border-0"
                              id="title" name="title"  {...register('plan_name')} />
                          </div>
                        </div>
                      </>) : null}

                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Plan Duration</label>
                          <SelectDuration  {...register("duration")} onChange={onChange} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Price</label>
                          <input type="text" className="form-control form-control-lg border-0"
                            id="title" name="title"  {...register('price')} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Monthly Lead</label>
                          <input type="text" className="form-control form-control-lg border-0"
                            id="title" name="title"  {...register('monthly_leads')} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="title" className="text-heading margin-b-0">Listing Per Yr</label>
                          <input type="text" className="form-control form-control-lg border-0"
                            id="title" name="title"  {...register('listings_per_year')} />
                        </div>
                      </div>


                    </div>
                    <div className='row'>
                      <div className="py-5 px-3">
                        <h2 className="text-heading fs-22 mb-3">Compare Plan</h2>
                        <ListGroup >
                          <ListGroup.Item><Checkbox label="Plan Type" name='dashboard_for_leads_management' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='featured_business_in_search_results' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='featured_developer_on_homepage' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='featured_professional_in_search_results' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='homepage_banner_ad' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='multi_city_in_search_results' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='notifications_on_matched_user_searches' /></ListGroup.Item>
                          <ListGroup.Item><Checkbox name='placement_in_search_results' /></ListGroup.Item>
                        </ListGroup >
                      </div>

                    </div>
                    <div className='row mb-3'>
                      {Singlecoupon ? (
                        <div class="col-sm-4 col-lg-6">
                          <h2 className="text-heading fs-22 mb-3">Discount Information</h2>
                          <div class="card">

                            <div class="card-body">
                              <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Coupon Name</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" value={Singlecoupon.display_name} readOnly /* {...monthly_price} */ />
                              </div>
                              <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Duration</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" value={Singlecoupon.duration} readOnly />
                              </div>
                              <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Percent off</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" value={Singlecoupon.percent_off} readOnly />
                              </div>
                              <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Coupon Name</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" value={Singlecoupon.display_name} readOnly />
                              </div>
                            </div>
                          </div>
                        </div>) : (<></>)}
                      {price ? (<><div class="col-sm-4 col-lg-6">
                        <h2 className="text-heading fs-22 mb-3">Total Amount Information</h2>
                        <div class="card">
                          <div class="card-body">

                            {durationValue === "month" ? (<>
                              <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Monthy Amount</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" readOnly value={price} />
                              </div>
                              <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Yearly Amount</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" readOnly value={yprice} />
                              </div>
                              {offPrice ? (<>
                                <div className="form-group">
                                  <label htmlFor="title" className="text-heading margin-b-0">Discount Amount</label>
                                  <input type="text" className="form-control form-control-lg border-0"
                                    id="title" name="title" readOnly value={discountPriceValue} />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="title" className="text-heading margin-b-0">Total Amount</label>
                                  <input type="text" className="form-control form-control-lg border-0"
                                    id="title" name="title" readOnly value={totalAmount} />
                                </div>
                              </>

                              ) : (<>
                                <div className="form-group">
                                  <label htmlFor="title" className="text-heading margin-b-0">Discount Amount</label>
                                  <input type="text" className="form-control form-control-lg border-0"
                                    id="title" name="title" readOnly value="0" />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="title" className="text-heading margin-b-0">Total Amount</label>
                                  <input type="text" className="form-control form-control-lg border-0"
                                    id="title" name="title" readOnly value={yprice} />
                                </div></>)}
                            </>) :
                              (<>   <div className="form-group">
                                <label htmlFor="title" className="text-heading margin-b-0">Monthy Amount</label>
                                <input type="text" className="form-control form-control-lg border-0"
                                  id="title" name="title" readOnly value={mprice} />
                              </div>

                                <div className="form-group">
                                  <label htmlFor="title" className="text-heading margin-b-0">Yearly Amount</label>
                                  <input type="text" className="form-control form-control-lg border-0"
                                    id="title" name="title" readOnly value={price} />
                                </div>
                                {offPrice ? (<>
                                  <div className="form-group">
                                    <label htmlFor="title" className="text-heading margin-b-0">Discount Amount</label>
                                    <input type="text" className="form-control form-control-lg border-0"
                                      id="title" name="title" readOnly value={discountPriceValue} />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="title" className="text-heading margin-b-0">Total Amount</label>
                                    <input type="text" className="form-control form-control-lg border-0"
                                      id="title" name="title" readOnly value={totalAmount} />
                                  </div>
                                </>

                                ) : (<>
                                  <div className="form-group">
                                    <label htmlFor="title" className="text-heading margin-b-0">Discount Amount</label>
                                    <input type="text" className="form-control form-control-lg border-0"
                                      id="title" name="title" readOnly value="0" />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="title" className="text-heading margin-b-0">Total Amount</label>
                                    <input type="text" className="form-control form-control-lg border-0"
                                      id="title" name="title" readOnly value={price} />
                                  </div></>)}
                              </>)}
                          </div>
                        </div></div>
                      </>) : (<></>)}
                    </div>
                    <input className="btn bg-blue text-white hover-saffron" type="submit" />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}