import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { ApiUrl } from "../utils/common";


const SearchDropdown = ({type,setData,onButtonClick,value,options,onSearch,setOptions}) => {

    const [searchValue, setSearchValue] = useState('');
    // const [options, setOptions] = useState([]);
    // console.log(options,'options11');
    const [itemValue, setItemValue] = useState([]);
    const [isdropdownOpen,setIsdropdownOpen] = useState(false);

    useEffect(() => {
        if(value?.length){
            setItemValue(value)
        }
    },[value])

    // const onSearch = (e) => {
    //     const data = (e != null) ? e : ""
    //     const option = {
    //         method: 'GET', headers: {
    //             'access-control-allow-origin': '*',
    //             'content-type': 'application/json',
    //         },
    //         data: { "data": data },
    //         // url: `${ApiUrl()}/auth/user/multi-contact-search?search=${e}`
    //         url: `${ApiUrl()}/auth/user/filter-location-other?first_name=${data}`
            
    //     };
    //     axios(option)
    //         .then(e => {
    //             setOptions(e?.data)
    //         })
    //         .catch(err => {

    //         })
    // }

    const onChange = (e) => {
        // HIT YOUR SEARCH API
        setTimeout(onSearch(e), 5000);
        setIsdropdownOpen(true)

        setSearchValue(e)

        // SET API VALUES
        const options = []
        setOptions(options)
    }

    const onItemClick = (item) => {
        // setSearchValue('')
        // const findIndex = itemValue?.findIndex(i => i?.first_name === item?.first_name)
        // if (findIndex > -1) {
        //     let arr = [...itemValue]
        //     arr.splice(findIndex, 1)
        //     setItemValue(arr)
        //     setData(arr)
        // } else {
        //     setItemValue([...itemValue, item])
        //     setData([...itemValue, {...item, type}])
        // }

    }

    const onSelectedItem = (e) => {
        setData(
            e?.map(item => ({
                ...item,
                type,
            }))
        )
    }

    const [selectedValue,setSelectedValue] = useState('')
    const [isNotFound, setIsNotFound] = useState(false)
    useEffect(() => {
        if(selectedValue?.length){
            setIsNotFound(true) 
        } else{
            setIsNotFound(false)
        }
    }, [selectedValue, itemValue])
    return (
        <>

            {/* <div className="col-md-6"> */}
            <div className="main-section-input-search-add-contact">
            <Multiselect 
            
            options={[...options, ...itemValue]} 
            displayValue={"first_name"} 
                                                    onSearch={(e) => {onChange(e);setSelectedValue(e)}} onSelect={(e) => onSelectedItem(e)} onRemove={(e) => setData(e)} />
                {/* <input className="input-search-add-contact form-control form-control-lg border-0" placeholder="Search"
                    onChange={onChange}
                    value={itemValue?.length ? itemValue?.map(item => item?.first_name)?.join(', ') : searchValue} />
                {
                    isdropdownOpen && (options?.length || searchValue?.length) ?

                        <>
                            <div onClick={() => setIsdropdownOpen(false)} style={{ position: 'fixed', width: '100%', top: '0px', left: '0px', height:'100%' }}></div>
                            <div className="dropdown-search-add-contact">
                                {
                                    options?.map((item) => (
                                        <div className="inner-part-dropdown-add-contact" onClick={() => {onItemClick(item); setSelectedValue(false)}}>
                                            <p className="inner-data-dropdown-add-contact">{item?.first_name}</p>
                                            <p className="inner-data-dropdown-add-contact">{item?.designation}</p>
                                        </div>
                                    ))
                                }

                                
                            </div>
                        </> : null
                } */}
                {
                    isNotFound && !options?.length ? <button type="button" className="button-add-new-search-dropdown" onClick={onButtonClick}>Add “{searchValue}” as a new {type}</button> : null
                }
            </div>
            {/* </div> */}
        </>
    )
}
export default SearchDropdown;