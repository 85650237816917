import { HTTPBaseService } from "./interceptor_custom_auth";
import {
  RequstAccessToken,
  RequstRefreshToken,
  QuestsionResultUpdate,
  PlanCreate,
  login,
  UserDetail,
  BlogCreate,
  TestmonailProfile,
} from "./types";

export class MainApiProtectedVersion extends HTTPBaseService {
  requstQueVoteId() {
    throw new Error("Method not implemented.");
  }

  private primary_key?: number = 0;

  public constructor(
    access_token?: string,
    primary_key?: number,
    params?: any
  ) {
    super(
      process.env.REACT_APP_BASE_API_PATH as string,
      access_token as string
    );
    this.primary_key = primary_key;
    this.params = params;
  }

  public requstAccessToken = (body: RequstAccessToken) =>
    this.instance.post("/secure/auth/", body);
  public requstRefreshToken = (body: RequstRefreshToken) =>
    this.instance.post("/secure/refreshtoken/", body);

  public requstQuestionResults = (body: QuestsionResultUpdate) =>
    this.instance.put(
      `/api/v1/front/votes/results/update/${this.primary_key}/`,
      body
    );
  public requstGetProperty = () =>
    this.instance.get(`/api/property/user-properties`);
  public requstGetPropertyId = () =>
    this.instance.get(`/api/property/property-details/${this.primary_key}/`);
  public requstAllProposal = () => this.instance.get("/auth/subscription/tax/");
  public requstConfigProperty = () =>
    this.instance.get("/api/property/config-property");

  public requstSubscriptionPlan = () =>
    this.instance.get("/auth/subscription/details/");

    // https://api.dollarperfoot.com/api/testmonialprofile/


  public requstTestmonialprofileCreate = (body: TestmonailProfile)  => this.instance.post(`/api/testmonialprofile/`,body);

  // super admin url
  // /api/blog/blog-post
  public requstBlogGetById = () =>
    this.instance.get(`/api/blog/post/${this.primary_key}/`);
  public requstBlogCreate = (body: BlogCreate) =>
    this.instance.post(`/api/blog/create/`, body);
  public requstBlogGet = (body: BlogCreate) =>
    this.instance.get(`/api/blog/posts/`);
  // public requstBlogGetID = (body:BlogCreate) => this.instance.get(`/api/blog/post`);

  public requstBlogDelete = () =>
    this.instance.delete(`/api/blog/delete/${this.primary_key}/`);
  public requstBlogUpdate = (body: BlogCreate) =>
    this.instance.put(`/api/blog/update/${this.primary_key}/`, body);

  public requstUserID = () =>
    this.instance.get(`/api/user/${this.primary_key}/`);
  public requstUserList = () => this.instance.get(`/api/user/`);
  public requstSuperuserLogin = (body: login) =>
    this.instance.post("superuser/", body);
  public requstUserUpdateID = (body: UserDetail) =>
    this.instance.post(`auth/user/plan/${this.primary_key}/assign/`, body);
  public requstCheckout = () => this.instance.get("reate_checkout_session");

  // ################################plan##########################################

  // ###################lead #################################
  public requstUserlead = () => this.instance.get("/api/lead/user-lead");
  public requstAdminUserlead = () => this.instance.get("/api/lead/all/");
  public requstPropertylead = () => this.instance.get("/api/lead/user/");
  public requstUserFeatured = () => this.instance.get("/auth/user/featured/");
 public requstGetFavouriteProperty = () => this.instance.get("/api/property/cached-properties");
 public requstLeadDelete = () =>
    this.instance.delete(`/api/lead/user-lead/delete/${this.primary_key}/`);
  // ################################plan##########################################

  public requstSubscriptionPlanCreate = (body: PlanCreate) =>
    this.instance.post("/auth/subscription/plan/create", body);
  public requstPlanList = () => this.instance.get("/auth/subscription/plans/");

  public requstSubscriptionPlanID = () =>
    this.instance.get(`/auth/subscription/plan/${this.primary_key}/`);
  public requstPlanDelete = () =>
    this.instance.delete(`/auth/subscription/plan/delete/${this.primary_key}/`);
  public requstPlanUpdate = () =>
    this.instance.put(`/auth/subscription/plan/update/${this.primary_key}/`);
  //#############################coupon############################################
  public requstSubscriptionCouponGet = () =>
    this.instance.get("/auth/subscription/coupon/");
  public requstSubscriptionCouponIDGet = () =>
    this.instance.get(`/auth/subscription/coupon/${this.primary_key}/`);
  public requstCouponUpdate = () =>
    this.instance.get(`/auth/subscription/coupon/udpate/${this.primary_key}/`);
  public requstCouponDelete = () =>
    this.instance.delete(
      `/auth/subscription/coupon/delete/${this.primary_key}/`
    );
  //#############################coupon############################################
  public requstSubscriptionTaxIDGet = () =>
    this.instance.get(`/auth/subscription/tax/${this.primary_key}/`);
  public requstSubscriptionTaxGet = () =>
    this.instance.get("/auth/subscription/tax/");
  public requstSubscribeUserPlan = () =>
    this.instance.get("/auth/subscription/current_plan/");

  //#########################################invoice###################################################
  public requstInoiceLoginUser = () =>
    this.instance.get("auth/subscription/invoice/user/");
  public requstInoice = () => this.instance.get("auth/subscription/invoice/");
  public requstInoiceAll = () =>
    this.instance.get("auth/subscription/invoice/all/");
  public requstInoiceByUser = () =>
    this.instance.get(`auth/subscription/invoice/user/${this.primary_key}/`);
  public requstInoiceById = () =>
    this.instance.get(`auth/subscription/invoice/byid/${this.primary_key}/`);
  //#########################################invoice###################################################
  // public requstSubscriptionCouponGet = () => this.instance.get('/auth/subscription/coupon/');
  // public requstSubscriptionCouponIDGet = () => this.instance.get(`/auth/subscription/coupon/${this.primary_key}/`);
  // public requstCouponUpdate= () => this.instance.get(`/auth/subscription/coupon/udpate/${this.primary_key}/`);
  // public requstCouponDelete = () => this.instance.delete(`/auth/subscription/coupon/delete/${this.primary_key}/`);
  //#############################coupon############################################
  // public requstSubscriptionCouponGet = () => this.instance.get('/auth/subscription/coupon/');
  // public requstSubscriptionCouponGet = () => this.instance.get('/auth/subscription/coupon/');

  public requstProprtyGet = () =>
    this.instance.get("api/property/allproperty/");

  public requstProprtyHistoryGetID = () =>
  this.instance.get(`api/property/admin-property-history/${this.primary_key}`);

    // #############################################?
  public requstProprtySearchGet = () =>
  this.instance.get(`api/property/admin-search-metrices`);


  public requstProprtyHistoryGet = () =>
  this.instance.post(`api/property/admin-property-history/`);

  
  public requstProprtyGetID = () =>
    this.instance.get(`api/property/property/${this.primary_key}/`);
  public requstProprtyGetSearch = () =>
    this.instance.get(`api/property/search/`);
  //#########################################dashboard counts###################################################

  //#########################################all subscription###################################################
  public requestSubscribedData = () =>
    this.instance.get("auth/subscription/allsubscription/");

  //#########################################invoice###################################################


  //#########################################Testominal###################################################
 

  //#########################################invoice###################################################

}
