import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../utils/common";
import moment from "moment";
import BlogSidebar from "./Blog-sidebar";

const BlogSinglePage = () => {
  const [loading, setLoading] = useState();
  const [blogDetails, setBlogDetails] = useState();
  console.log(blogDetails, 'hello Detials')
  const Id = useParams().id
  const GetPropertyDetails = () => {
    const options = {

      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/blog/post/${Id}/`
    }
    axios(options)
      .then(e => {
        setLoading(false);
        setBlogDetails(e.data.data);
      })
      .catch(err => {
        setLoading(false);
      })
  }
  useEffect(() => {
    GetPropertyDetails();
  }, [Id,loading])

console.log(Id);
return (
  <>
    <Header />
    <main id="content" className="mt-100">

      <section className="pt-8 pb-8">
        <div className="container">
          <div className="row ml-xl-0 mr-xl-n6">
          {blogDetails?.map((item)=>{
          return(
            <div className="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0 ">
            <h3 className="fs-md-32 text-heading lh-141 mb-2">
             {item?.title}
            </h3>
            <div className="position-relative">
              <img className="rounded-lg d-block" src={item?.featured_image_url ||"../images/post-11.jpg"}
                alt={item?.title} />

            </div>
            <ul className="list-inline mt-4 ">
            {/*   <li className="list-inline-item mr-4"><img className="mr-1"
                src="../images/author-01.jpg"
                alt="D. Warren" /> D. Warren
              </li> */}
              <li className="list-inline-item mr-4"><i className="far fa-calendar mr-1"></i> {moment(item?.created_on).format('YYYY-MMM-DD')}</li>
              {/*   <li className="list-inline-item mr-4"><i className="far fa-eye mr-1"></i> 149 views</li> */}
            </ul>


            <div className="content-font lh-214 mb-9">
            <div dangerouslySetInnerHTML={{ __html: item?.content}} />
            </div>


            {/* <h3 className="fs-22 text-heading lh-15 mb-6">Comments (3)</h3>
            <div className="media mb-6 pb-5 border-bottom">
              <div className="w-70px mr-2">
                <img src="images/testimonial-5.jpg"
                  alt="Dollie Horton" />
              </div>
              <div className="media-body">
                <p className="text-heading fs-16 font-weight-500 mb-0">Dollie Horton</p>
                <p className="mb-4">Very good and fast support during the week. Thanks for always keeping your
                  WordPress themes
                  up to date. Your level of support and dedication is second to none. Solved all my problems
                  in a pressing time! Excited to see the other themes they make!</p>
                <ul className="list-inline">
                  <li className="list-inline-item text-muted">02 Dec 2019 at 2:40pm<span
                    className="d-inline-block ml-2">|</span></li>
                 
                </ul>
              </div>
            </div>
            <div className="media mb-6 pb-5 border-bottom">
              <div className="w-70px mr-2">
                <img src="images/review-05.jpg"
                  alt="Dollie Horton" />
              </div>
              <div className="media-body">
                <p className="text-heading fs-16 font-weight-500 mb-0">Dollie Horton</p>
                <p className="mb-4">Very good and fast support during the week. Thanks for always keeping your
                  WordPress themes
                  up to date. Your level of support and dedication is second to none. Solved all my problems
                  in a pressing time! Excited to see the other themes they make!</p>
                <ul className="list-inline">
                  <li className="list-inline-item text-muted">02 Dec 2019 at 2:40pm<span
                    className="d-inline-block ml-2">|</span></li>
                  
                </ul>
              </div>
            </div>
            <div className="media mb-10 pb-5 border-bottom">
              <div className="w-70px h-70 mr-2 bg-gray-01 rounded-circle fs-18 text-muted d-flex align-items-center justify-content-center">
                DH
              </div>
              <div className="media-body">
                <p className="text-heading fs-16 font-weight-500 mb-0">Dollie Horton</p>
                <p className="mb-4">Very good and fast support during the week. Thanks for always keeping your
                  WordPress themes
                  up to date. Your level of support and dedication is second to none. Solved all my problems
                  in a pressing time! Excited to see the other themes they make!</p>
                <ul className="list-inline">
                  <li className="list-inline-item text-muted">02 Dec 2019 at 2:40pm<span
                    className="d-inline-block ml-2">|</span></li>
                  
                </ul>
              </div>
            </div>
            <h3 className="fs-22 text-heading lh-15 mb-6">Leave your thought here</h3>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <input type="text" placeholder="Your Name" name="name"
                      className="form-control form-control-lg border-0" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <input placeholder="Your Email"
                      className="form-control form-control-lg border-0"
                      type="email" name="email" />
                  </div>
                </div>
              </div>
              <div className="form-group mb-6">
                <textarea className="form-control border-0" placeholder="Message" name="message"
                  rows="5"></textarea>
              </div>
              <button type="submit" className="btn btn-lg bg-blue text-white hover-saffron px-9">Submit</button>
            </form> */}
          </div>
          )
          })}
            <BlogSidebar />
           
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </>
)
}
export default BlogSinglePage;