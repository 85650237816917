import React from 'react'
import AdminBilling from '../billing/Billing'

function InvoicePage() {

  return (
    <div>
        <AdminBilling />
    </div>
  )
}

export default InvoicePage