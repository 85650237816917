import { React, useState } from "react";
import DevSidebar from "../../Components/DevSidebar/devsidebar";
import DevHeader from "./devheader";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiUrl, getToken } from "../../utils/common";

const FloorPlan = () => {

    const { state } = useLocation();
    const StateData = state?.item
    const navigate = useNavigate();
    const [floorNo, setFloorNo] = useState(1)
    const [description, setDescription] = useState('');
    const [imgFile, setImageFile] = useState();
    const [isMyProfile, setIsMyProfile] = useState('');
    const [buildingId, setBuildingId] = useState()
    const PropertyId = state?.item?.id;

    // append array in building type start
    const [inputList, setInputList] = useState([])
    const [numberBuilding, setNumberBuilding] = useState();
    const [buildingName, setBuildingName] = useState('');
    const [dataCheckInput, setDataCheckInput] = useState();
    console.log(inputList, 'inputList');

    // append array in building type end
    const PorfileImgHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        // if (e?.target?.files.length !== 0) {
        setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
        setImageFile(e?.target?.files[0]);
        // }

    }

    const HandleInputChange = (e, index, file) => {
        // setBuildingName(e?.target?.value)
        // setFloorNo(e?.target?.value)
        // setDescription(e?.target?.value)
        // setDataCheckInput((e?.target?.value))
        console.log(e,'e');
        const { name, value } = e?.target
        let imageFile
        if (file) {
            imageFile = e?.target?.files?.[0]
        }
        let list = Array.from(inputList);
        list[index] = {
            ...list[index],
            [name]: file ? imageFile : value
        };
        console.log(list,'list59');
        setInputList(list);
    }
    const HandleSelect = (e) => {
        setNumberBuilding(e?.target?.value)
        setBuildingId(e?.target?.value)
        // setFloorNo(e?.target?.value)
        // setDescription(e?.target?.value)
        const finalArray = Array(Number(1)).fill({floorNo: '', description: '', file: '' })
        setInputList(finalArray)

    }

    // floor plan api
    const SubmitPlan = () => {
        if (!ValidateFields()) return
        const data = {}
        const formData = new FormData()
        // formData?.append('building_no', numberBuilding);
        // formData?.append('building_name', buildingName);
        formData.append('description', inputList?.[0]?.description)
        formData.append('floor', inputList?.[0]?.floorNo)
        formData.append('property_id', PropertyId)
        formData.append('building_id', buildingId)
        if (imgFile) {
            formData.append('media_path', imgFile)
        }

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/api/property/property-floor-plan/${PropertyId}`
        };

        axios(option)
            .then(e => {
                toast.success("Floor Plan Added Successfull");
                window.location.reload();
            })
            .catch(err => {
                
            })


    }

    const ValidateFields = () => {

        if (!numberBuilding) {
            toast.error("Please Select Building")
            return false;
        }
        if (!inputList?.[0]?.floorNo) {
            toast.error("Please Add Floor Number")
            return false;
        }
        if (!imgFile) {
            toast.error("Please Select Image")
            return false;
        }
        return true;
    }

    // const SubmitData = () => {
    //     navigate('/floorplanlist', { state: PropertyId })
    // }


    return (
        <>
        <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <DevSidebar />
          <div className="page-content">
            <DevHeader />
                        <main id="content" className="bg-gray-01 margin-re-top">
                            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-6">
                                            <div className="card-body px-6 pt-6 pb-5">
                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Floor Plan</h3>
                                                <div className="col-md-3 col-lg-3 col-xxl-3 px-2 mb-4 mb-md-0">
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="category"
                                                            className="text-heading margin-b-0">Buildings <span className="star-mandatoryfield">*</span></label>
                                                        <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                            title="Select" data-style="btn-lg py-2 h-52"
                                                            id="category" name="category" onChange={HandleSelect}>
                                                            <option>Select Building</option>
                                                            {
                                                                StateData?.property_building?.map((item) => {
                                                                    return (
                                                                        <option value={item?.id}>{item?.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                {
                                                    inputList?.map((x, i) => {
                                                        return (
                                                            <div className="row ml-0">
                                                                {/* building name  */}
                                                                {/* <div className="form-group mb-0 col-md-3">
                                                                    <label htmlFor="category"
                                                                        className="text-heading">Building Name</label>
                                                                    <input type="text" name="building_name"
                                                                        value={x?.buildingName}
                                                                        className="form-control form-control-lg border-0"
                                                                        placeholder="Enter Building Name"
                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                </div> */}
                                                                {/* no of floors */}
                                                                <div className="form-group mb-0 col-md-4">
                                                                    <label htmlFor="category"
                                                                        className="text-heading">Floors Number <span className="star-mandatoryfield">*</span></label>
                                                                    <input type="number" name="floorNo"
                                                                        className="form-control form-control-lg border-0"
                                                                        placeholder="Enter Floors"
                                                                        value={x?.floorNo}
                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                </div>
                                                                <div className="form-group mb-0 col-md-4 mt-2">
                                                                    <label htmlFor="role" className="text-heading">Description</label>
                                                                    <input type="text" className="form-control form-control-lg border-0" id="role"
                                                                        name="description" onChange={(e) => HandleInputChange(e, i)} />
                                                                </div>

                                                                <div className="col-md-4 mb-0 img-uploader-cnt-inf">
                                                                    {
                                                                        imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                                                    }

                                                                    <div className="custom-file mt-4 h-auto">

                                                                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile"
                                                                            name="file" accept="image/*" onChange={(e) => { HandleInputChange(e, i); PorfileImgHandler(e) }} />
                                                                        <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                                            Floor Plan</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }

                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-lg bg-blue text-white hover-saffron mb-3" onClick={SubmitPlan}>Submit</button>

                                {/* <div className="d-flex justify-content-end flex-wrap">
                                    <button className="btn btn-lg bg-hover-white border rounded-lg mb-3">Delete Profile</button> 
                                    {
                                        buttonCheck ? <button className="btn btn-lg btn-primary ml-4 mb-3" onClick={UpdateFields}>Save Profile</button> : <button className="btn btn-lg btn-primary ml-4 mb-3" onClick={SubmitData}>Update Profile</button>
                                    }
                                </div> */}
                            </div>
                        </main>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FloorPlan;