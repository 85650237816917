import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";



const DevPropertyPreview = () => {
  const { state } = useLocation()
  console.log(state, 'state14');
  const profileData = state?.Data;
  console.log(profileData, 'profileData16');
  const allOptions = state?.getDropwonData

  const Ammenities = profileData?.Ammenities;
  const propertyType = profileData?.propertyType;
  const permittedUses = profileData?.permittedUsers;
  const LabelsProperty = profileData?.propertyLabels;
  const label = allOptions?.label;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  // modal states
  const [show, setShow] = useState(false);

  const SubmitData = () => {
    setShow(true)

    const property_types = propertyType?.map(i => ({ id: i?.id }))
    const propertytype = JSON.stringify(property_types)


    const amenities_value = Ammenities?.map(i => ({ id: i?.id }))
    const amenities = JSON.stringify(amenities_value);


    const permitted_uses = permittedUses?.map(i => ({ id: i?.id }))
    const permitted = JSON.stringify(permitted_uses);

    const PropertyLabels = LabelsProperty?.map(i => ({ id: i?.id }))
    const Labels = JSON.stringify(PropertyLabels)
    const formData = new FormData();
    formData.append('name', profileData?.PropertyTitle);
    // formData.append('construction_status', );
    formData.append('selling_status', profileData?.sellingStatus);
    formData.append('website', profileData?.website);
    formData.append('description', profileData?.description);
    formData.append('sale_start', moment(profileData?.startDate1).format('YYYY-MM-DD'));
    formData.append('maintenance_fee', profileData?.maintenanceFee);
    formData.append('construction_start_date', moment(profileData?.startDate2).format('YYYY-MM-DD'));
    // formData.append('no_of_units', profileData?.nounits);
    // formData.append('no_of_stories', noStories);
    // formData.append('no_of_stories', profileData?.floor);
    formData.append('area', profileData?.addressMap?.city);
    formData.append('city', profileData?.addressMap?.area);
    // formData.append('state', profileData?.state);
    formData.append('state', profileData?.addressMap?.state);
    // formData.append('country', profileData?.country);
    formData.append('country', profileData?.addressMap?.country);
    // formData.append('street_name', profileData?.address);
    formData.append('street_name', profileData?.addressMap?.address);
    formData.append('currency', profileData?.currency);
    formData.append('price_from', profileData?.Price || '');
    formData.append('built_by', profileData?.builtBy);
    formData.append('built_by_email', profileData?.buildEmail);
    formData.append('size', profileData?.propertySize);
    formData.append('price_per_sqft', profileData?.pricePer);
    formData.append('price_to', profileData?.priceTo || '');
    //formData.append('post_code', profileData?.zipCode);
    formData.append('post_code', profileData?.addressMap?.postal_code);
    formData.append('no_of_buildings', profileData?.numberBuilding)
    formData.append('lat', profileData?.addressMap?.mapPosition?.lat || "");
    formData.append('lng', profileData?.addressMap?.mapPosition?.lng || "");

    formData.append('construction_status', profileData?.propertyStatus);
    formData.append('lease_rate', profileData?.leaseRate);
    if (profileData?.permittedUses) {
      formData.append('permitted_uses_image', profileData?.permittedUses);
    }

    if (profileData?.inputList?.length) {
      formData.append('property_buildings', JSON.stringify(profileData?.inputList))
    }
    if (profileData?.unitPlanImage) {
      formData.append('unit_price_plan', profileData?.unitPlanImage)
    }
    if (profileData?.projectBrochureSubmit) {
      formData.append('project_brochure', profileData?.projectBrochureSubmit)
    }
    // if (profileData?.permittedUses?.length) {

    // }
    if (profileData?.propertyLabels?.length) {
      profileData?.propertyLabels?.map(item => {
        formData.append('labels', item?.id)
      })
    }
    if (profileData?.Ammenities?.length) {
      profileData?.Ammenities?.map(item => {
        formData.append('amenities_value', item?.id);
      })
    }
    if (profileData?.propertyType?.length) {
      profileData?.propertyType?.map(item => {
        formData.append('property_types', item?.id);
      })
    }
    formData.append('year_built', profileData?.builtYear)
    if (profileData?.imgFile) {
      formData.append('featured_image', profileData?.imgFile);
    }
    formData.append('is_site_plan_approved', profileData?.isSitePlanApproved)
    formData.append('site_plan_pending_approval', profileData?.sitePlanPending)
    formData.append('site_plan_not_submited', profileData?.sitePlanNotSubmit)

    if (profileData?.sitePlanImage) {
      formData.append('site_plan', profileData?.sitePlanImage);
    }
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      data: formData,
      url: `${ApiUrl()}/api/property/create-property`

    };
    axios(option)
      .then(e => {
        setShow(false)
        if (e?.data?.id) {
          navigate('/devaddproperty', { state: { previousData: e?.data } })
        }
      })
      .catch(err => {
        setShow(false)
        toast.error(err?.response?.data?.website?.[0])
        // toast.error(err?.response?.data?.price[0] || err?.response?.data?.property_type[0]); 
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })


  }

  const EditPreview = () => {
    navigate('/devaddproperty', { state: { profilePreview: profileData, allOptions } })
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <main id="content">
        <section className="header-user-icon">
          <div className="container-fluid" >
            <img className="pr-pro" src="images/previewbanner.png" />
          </div>
        </section>
        <div className="primary-content pt-8 mr-200 margin-re-top-50">

          <div className="container">
            <div className="pre-header"><h2 className="font-r-size-30">Preview Property</h2></div>
            <div className="bg-fff">
              <div className="row">
                <article className="col-lg-12 pr-xl-7 bg-fff">

                  <section className="pb-7 border-bottom">
                    <ul className="list-inline d-sm-flex align-items-sm-center mb-2">
                      {/* <li className="list-inline-item badge badge-orange mr-2">Featured</li>
                      <li className="list-inline-item badge badge-primary mr-3">For Sale</li> */}
                      {/* <li className="list-inline-item mr-2 mt-2 mt-sm-0"><i className="fal fa-clock mr-1"></i>2 months ago
                </li> */}
                      {/* <li className="list-inline-item mt-2 mt-sm-0"><i className="fal fa-eye mr-1"></i>1039 views</li> */}
                    </ul>
                    <div className="d-sm-flex justify-content-sm-between">
                      <div>
                        <h2 className="fs-35 font-weight-600 lh-15 text-heading">{profileData?.PropertyTitle}</h2>
                        {/* <p className="mr-bo-20 mb-0"><i className="fal fa-map-marker-alt mr-2"></i>398 Pete Pascale Pl, New York</p> */}
                        <p className="mr-bo-20 mb-0"><i className="fal fa-map-marker-alt mr-2"></i>{profileData?.addressMap?.address}{/* , {allOptions?.state?.find(i => i?.id === Number(profileData?.state))?.name}, {allOptions?.city?.find(i => i?.id === Number(profileData?.city))?.name}, {allOptions?.country?.find(i => i?.id === Number(profileData?.country))?.name}, {profileData?.zipCode} */}</p>

                        {
                          profileData?.Price || profileData?.priceTo ? <p className="mr-bo-20 fs-22 text-heading font-weight-bold mb-0">From <CurrencyFormat value={profileData?.Price} displayType='text' thousandSeparator={true} prefix={'$'} /> - To <CurrencyFormat value={profileData?.priceTo} displayType='text' thousandSeparator={true} prefix={'$'} /></p> : <p className="mr-bo-20 fs-22 text-heading font-weight-bold mb-0">Lease Rate: <CurrencyFormat value={profileData?.leaseRate} displayType='text' thousandSeparator={true} prefix={'$'} /></p>
                        }

                        {
                          profileData?.pricePer ? <p className="mb-0"><CurrencyFormat value={profileData?.pricePer} displayType='text' thousandSeparator={true} prefix={'$'} />/SqFt</p> : null
                        }

                      </div>
                      <div className="mt-2 text-lg-right b-r10">
                        <img className="fd-img" src={profileData?.isMyProfile} alt="featured-images" />
                      </div>
                    </div>
                    <h4 className="fs-22 text-heading mt-6 mb-2">Description</h4>
                    <p className="mb-0 lh-214">{profileData?.description}</p>
                  </section>
                  <section className="pt-6 border-bottom">
                    <h4 className="fs-22 text-heading mb-6">Facts and Features</h4>
                    <div className="row">
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-1.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Property Type</h5>
                            {/* <p className="mb-0 fs-13 font-weight-bold text-heading">{allOptions?.city?.find(i => i?.id === Number(profileData?.city))?.name}</p> */}
                            {/* <p className="mb-0 fs-13 font-weight-bold text-heading">{allOptions?.propertyType?.find(i => i?.id === Number(profileData?.propertyType))?.name}</p> */}

                            {
                              profileData?.propertyType?.map((item) => {
                                return (
                                  <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.name}</p>
                                )
                              })
                            }


                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-2.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Property Status</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">{allOptions?.ConstructionStatus?.find(i => i?.id === Number(profileData?.propertyStatus))?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-3.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Selling Status</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">{allOptions?.SellingStatus?.find(i => i?.id === Number(profileData?.sellingStatus))?.name}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-4.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Property Status</h5>
                            {/* <p className="mb-0 fs-13 font-weight-bold text-heading">{allOptions?.labels?.find(i => i?.id === Number(profileData?.propertyLabels))?.name}</p> */}
                            {
                              profileData?.propertyLabels?.map((item) => {
                                return (
                                  <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.name}</p>
                                )
                              })
                            }


                          </div>
                        </div>
                      </div>


                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-5.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">SQFT</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">{profileData?.propertySize}</p>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="sz-32 icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-6.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Permitted Uses</h5>
                            

                            {
                              profileData?.permittedUsers?.map((item) => {
                                return (
                                  <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.name}</p>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div> */}

                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-7.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Currency</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">{allOptions?.currency?.find(i => i?.id === Number(profileData?.currency))?.name}</p>
                          </div>
                        </div>
                      </div>



                      {/* <div className="col-lg-3 col-sm-4 mb-6">
                        
                      </div> */}

                      {/* <div className="col-lg-3 col-sm-4 mb-6">
                        
                      </div> */}
                      {/* <div className="col-lg-3 col-sm-4 mb-6">
                        
                      </div> */}
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="sz-32 icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-8.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Construction Start Date</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">{moment(profileData?.ConstructionStartDate).format("DD-MMM-YYYY")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-blue">
                              <img className="sz-32" src="images/b-9.png" alt="property-type" />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">ESTD. Completion</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">{moment(profileData?.EstdCompletion).format("DD-MMM-YYYY")}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="col-lg-3 col-sm-4 mb-6 pl-0">
                            <div className="media">
                              <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                <div className="icon icon-family fs-20 text-blue">
                                  <img className="sz-32" src="images/b-11.png" alt="property-type" />
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">No Of Buildings</h5>
                                <p className="mb-0 fs-13 font-weight-bold text-heading">{profileData?.numberBuilding}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          {
                            profileData?.inputList?.map((item) => {
                              return (
                                <div className="media mb-4">
                                  <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                    <div className="icon icon-family fs-20 text-blue">
                                      <img className="sz-32" src="images/b-11.png" alt="property-type" />
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Building Name</h5>
                                    <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.name}</p>
                                  </div>
                                </div>
                              )
                            })
                          }

                        </div>

                        <div className="col-md-4">
                          {
                            profileData?.inputList?.map((item) => {
                              return (
                                <div className="media mb-4">
                                  <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                    <div className="icon icon-family fs-20 text-blue">
                                      <img className="sz-32" src="images/b-12.png" alt="property-type" />
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Floors</h5>
                                    <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.no_of_floors}</p>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>

                        <div className="col-md-4">
                          {
                            profileData?.inputList?.map((item) => {
                              return (
                                <div className="media mb-4">
                                  <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                    <div className="icon icon-family fs-20 text-blue">
                                      <img className="sz-32" src="images/b-13.png" alt="property-type" />
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Units</h5>
                                    <p className="mb-0 fs-13 font-weight-bold text-heading">{item?.no_of_units}</p>
                                  </div>
                                </div>
                              )
                            })
                          }

                        </div>
                      </div>
                    </div>
                    {/* </div> */}


                  </section>

                  <section className="pt-6 border-bottom pb-4">
                    <h4 className="fs-22 text-heading mb-4">Amenities</h4>
                    <ul className="list-unstyled mb-0 row no-gutters">

                      {
                        Ammenities?.map((item, index) => {
                          return (
                            <li className="col-sm-3 col-6 mb-2"><i className="far fa-check mr-2 text-blue"></i>{item?.name}</li>
                          )
                        })
                      }
                    </ul>
                  </section>
                  {/* <section className="py-6">
                    <h4 className="fs-22 text-heading mb-6">Floor Plans</h4>
                    <div className="accordion accordion-03 mb-3">
                      <div className="card border-0 shadow-xxs-2">
                        <div className="card-header bg-gray-01 border-gray border-0 p-0">
                          
                        </div>
                        <div className="collapse show">
                          <div className="card-body card-body col-sm-6 offset-sm-3 mb-3 b-r10">
                            <img src={profileData?.isSiteProfile} className="card-img" alt="Floor Plans" />


                          </div>
                        </div>
                      </div>
                    </div>
                  </section> */}
                  {
                    profileData?.unitPricePlan ? <section className="py-6">
                      <h4 className="fs-22 text-heading mb-6">Unit Plans</h4>
                      <div className="accordion accordion-03 mb-3">
                        <div className="card border-0 shadow-xxs-2">
                          <div className="card-header bg-gray-01 border-gray border-0 p-0">
                            {/* <div className="heading d-flex justify-content-between align-items-center px-6">
          <h2 className="mb-0 fs-16 text-heading font-weight-500 py-4 lh-13">First Floor</h2>
        </div> */}
                          </div>
                          <div className="collapse show">
                            <div className="card-body card-body col-sm-6 offset-sm-3 mb-3 b-r10">
                              <img src={profileData?.unitPricePlan} className="card-img" alt="Floor Plans" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> : null
                  }


                  {
                    profileData?.permittedUsedPDF ? <section className="py-6">
                      <h4 className="fs-22 text-heading mb-6">Permitted Uses</h4>
                      <div className="accordion accordion-03 mb-3">
                        <div className="card border-0 shadow-xxs-2">
                          <div className="card-header bg-gray-01 border-gray border-0 p-0">
                            {/* <div className="heading d-flex justify-content-between align-items-center px-6">
          <h2 className="mb-0 fs-16 text-heading font-weight-500 py-4 lh-13">First Floor</h2>
        </div> */}
                          </div>
                          <div className="collapse show">
                            <div className="card-body card-body col-sm-6 offset-sm-3 mb-3 b-r10">
                              <iframe src={profileData?.permittedUsedPDF} className="card-img" alt="Floor Plans" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> : null
                  }

                </article>
                <div className="btn-pr">
                  {/* <div className="btn-ed-pr">
                    <button className="btn btn-primary">Edit Property</button>
                  </div> */}
                  <div className="btn-sb-pr">
                    <button type="button" className="btn bg-blue text-white hover-saffron edit-property-button-preview padding-r-5" onClick={EditPreview}>Edit</button>
                    <button className="btn bg-blue text-white hover-saffron save-property-button-preview padding-r-5" onClick={SubmitData}>Save Property</button>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </main>

      <Modal className="modal-add-contact"
        show={show} >
        <Modal.Body>
          <h3 className="submit-property-modal-heading">Your Property is Being Saved. <span>Please Wait....</span></h3>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default DevPropertyPreview;