import React, { Fragment } from 'react'
import AdminFooter from './AdminFooter'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";

import axios from '../../utils/axios/main'; // importing axios from customAxios

export default function AdminPageLayout() {



    return (
        <Fragment>
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
                    <AdminSidebar />
                    <div className="page-content">
                        <AdminHeader />
                        <Outlet />

                    </div>
                </div>
            </div>
        </Fragment>



    )
}
