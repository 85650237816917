import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { ApiUrl, getToken, GetUserType } from '../../utils/common';
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { GetId, removeUserSession } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { MainApiProtectedVersion } from '../../utils/axios/requests';
import Avatar from 'react-avatar';


const DashBaord = () => {
  const userType = GetUserType()
  console.log(userType);
  const [userLeads, setUserLeads] = useState([]);
  console.log(userLeads,'userleads')
  const [propertyLeads, setPropertyLeads] = useState([]);
  
  const [counts, setCount] = useState()
  const [planData, setPlanData] = useState()
  const [loading, setLoading] = useState(false)
  const [profileData, setProfileData] = useState();
  const [userPropertyList, setUserPropertyList] = useState([]);
  console.log(userPropertyList, "propertylist");
  const navigate = useNavigate();
  const UserID = localStorage.getItem('id') || null;

  const CountsGet = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/dashboard/counts`
    };
    axios(option)
      .then(e => {
        setCount(e?.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  async function GetUserLead() {
    setLoading(true)
    const obj = new MainApiProtectedVersion();
    const res = await obj.requstUserlead().then((res) => {
      setUserLeads(res?.data.data)
      // setLeadTotal(res?.data.user_lead_count)
      setLoading(false)
    })
      .catch(error => {
        // alert(error)
        setLoading(false)
      })
  }
  async function GetPropertyLead() {
    setLoading(true)
    const obj = new MainApiProtectedVersion();
    const res = await obj.requstPropertylead().then((res) => {
      // console.log(res?.data.data,'hello rahul')
      setPropertyLeads(res?.data)

      // setLeadTotal(res?.data.user_lead_count)
      setLoading(false)
    })
      .catch(error => {
        // alert(error)
        setLoading(false)
      })
  }
  async function GetPlan() {
    setLoading(true)

    const obj = new MainApiProtectedVersion()
    const res = await obj.requstSubscribeUserPlan().then((res) => {


      setPlanData(res?.data['subscription'][0])

    })
      .catch(err => {
        setLoading(false);
      })
  }

  async function PropertyList() {
    setLoading(true)
    const obj = new MainApiProtectedVersion('', UserID)
    const res = await obj.requstGetFavouriteProperty().then((res) => {
      const data = res?.data;

      setUserPropertyList(data);
    })
      .catch(error => {
        setLoading(false);
      })
  }


  async function GetProfile() {
    const obj = new MainApiProtectedVersion('', UserID)
    const res = await obj.requstUserID().then((res) => {

      const data = res.data.data[0]
      console.log('====================================');
      console.log(data);
      console.log('====================================');
      if (data['developer_profile']) {
        setProfileData(res.data.data[0]['developer_profile'])

        console.log('================developer_profile====================');


      } else if (data['agent_profile']) {
        setProfileData(data['agent_profile'])

        console.log('==============agent_profile======================');
      } else {
        setProfileData(data['other_profile'])

        console.log('==============other_profile======================');

      }
    })
      .catch(error => {
        alert(error)
      })
  }



  useEffect(() => {
    CountsGet();
    GetProfile();
    GetPlan();
    PropertyList();
    GetUserLead();
    GetPropertyLead();
  }, [])
  var settings = {
    autoplay: true,
    arrows: false,
    vertical: true,
    autoplaySpeed: 4000,
    slidesToShow: 5,
    swipeToSlide: true
  };
  var topreviews = {
    autoplay: true,
    arrows: false,
    vertical: true,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    swipeToSlide: true
  };
  var propleads = {
    autoplay: true,
    arrows: false,
    vertical: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    swipeToSlide: true
  };
  return (
    <>
      <main id="content" className="bg-gray-01 margin-re-top-80">
        <div className="px-3 px-lg-5 px-xxl-13 py-5">
          {/* <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">

            </div>
            <div>
              <Link to="/devaddproperty" className="btn btn-primary btn-lg">
                <span>Add New Property</span>
                <span className="d-inline-block ml-1 fs-20 lh-1"></span>
              </Link>
            </div>
            <div>
              <Link to="/devmyprofile" className="btn btn-primary btn-lg ml-2">
                <span>Edit Profile</span>
                <span className="d-inline-block ml-1 fs-20 lh-1"></span>
              </Link>
            </div>
          </div> */}

          {userType === "other" ? (<>
            <div className='row'>
              <div className="col-sm-3 col-xxl-6 mb-6">
                <Link className='text-title' to="/review/property">
                  <div className="card">
                    <div className="card-body row align-items-center px-6 ">
                      <div className="col-4">
                        <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                          <img src="./images/customer-feedback.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                        </span>
                      </div>
                      <div className="col-8 text-center">
                        <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                          data-end="329" data-decimals="0"
                          data-duration="0" data-separator="">{counts?.user_review_count}</p>
                        <p>My Reviews</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-3 col-xxl-6 mb-6">
                <Link className="text-title" to="/lead/business">
                  <div className="card">
                    <div className="card-body row align-items-center px-6 ">
                      <div className="col-5">
                        <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-orange badge-circle">
                          <img src='./images/leadership.png' alt="property-leads" style={{ width: "56px", height: "56px" }} />
                        </span>
                      </div>
                      <div className="col-7 text-center">
                        <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                          data-end="914" data-decimals="0"
                          data-duration="0" data-separator="">{counts?.user_lead_count}</p>
                        <p>My Leads</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </>) : (<>
            <div className="row">
              <div className="col-sm-3 col-xxl-3 mb-6">
                <Link className='text-title' to="/property">
                  <div className="card">
                    <div className="card-body row align-items-center px-6 display-inline-block">
                      <div className="col-5">
                        <span className="w-83px h-83  fs-36 badge badge-orange badge-circle">
                          <img style={{ marginTop: '5px', width: "56px", height: "56px" }} src="./images/property-icon.png" alt="property-icon" />
                        </span>
                      </div>
                      <div className="col-7 text-center max-width-100">
                        <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                          data-end="29" data-decimals="0"
                          data-duration="0" data-separator="">{counts?.property_count}</p>
                        <p>My Properties</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-3 col-xxl-3 mb-6">
                <Link className='text-title' to="/team">
                  <div className="card">
                    <div className="card-body row align-items-center px-6 display-inline-block">
                      <div className="col-5 ">
                        <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                          <img src="./images/property-team.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                        </span>
                      </div>
                      <div className="col-7 text-center max-width-100">
                        <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                          data-end="1730" data-decimals="0"
                          data-duration="0" data-separator="">{counts?.user_team_count}</p>
                        <p>My Team</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-3 col-xxl-3 mb-6">
                <Link className='text-title' to="/review/property">
                  <div className="card">
                    <div className="card-body row align-items-center px-6  display-inline-block">
                      <div className="col-4">
                        <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-orange badge-circle">
                          <img src="./images/customer-feedback.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                        </span>
                      </div>
                      <div className="col-7 text-center max-width-100">
                        <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                          data-end="329" data-decimals="0"
                          data-duration="0" data-separator="">{counts?.user_review_count}</p>
                        <p>My Reviews</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-3 col-xxl-3 mb-6">
                <Link className="text-title" to="/lead/business">
                  <div className="card">
                    <div className="card-body row align-items-center px-6 display-inline-block">
                      <div className="col-5">
                        <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                          <img src='./images/leadership.png' alt="property-leads" style={{ width: "56px", height: "56px" }} />
                        </span>
                      </div>
                      <div className="col-7 text-center max-width-100">
                        <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                          data-end="914" data-decimals="0"
                          data-duration="0" data-separator="">{counts?.user_lead_count}</p>
                        <p>My Leads</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </>)}
          {
            planData?.plan_name ?

              <div className='row mb-5'>
                <div className='col-lg-12'>
                  <div className="card round-30">
                    <div className="card-body align-items-center px-7 ">
                      <div className='row'>
                        <div className='section-content-admin d-flex px-2 py-3'>
                          <div className="col-lg-1 col-sm-3">
                            <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                              <img src='images/subscribe.png' alt="property-leads" style={{ width: "56px", height: "56px" }} />
                            </span>
                          </div>
                          <div className="col-lg-10 col-sm-6">
                            <p className='mb-0'>Subscribed Plan</p>
                            <h2 className='fs-16 '>{planData.plan_name}</h2>
                            <p className='text-blue'>Valid till: {moment(planData.exp_date).format("DD-MMM-YYYY")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null
          }
          <div className='row'>
            <div className="section col-md-6">
              <div className="card">
                <div className="card-body  align-items-center px-6 py-3 ">
                  <h2 className="fs-18">Favourite Properties
                    <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{userPropertyList?.length}</span>
                  </h2>
                  <Slider {...settings}>
                    {
                      userPropertyList?.map((item) => {
                        return (
                          <div className="section-content-admin d-flex px-2 py-2 mb-1">

                            <div className='px-2 ' style={{ width: '100px' }} >
                              <img style={{ height: '55px' }} className="rounded-circle" src={item?.property?.featured_image} />
                            </div>
                            <div className='property-content px-2 mt-1 w-100'>
                              <h2 className='fs-16  '>{item?.property?.name}</h2>
                              <span className='text-blue font-weight-bold'>
                                <CurrencyFormat displayType="text" thousandSeparator={true} value={item?.property?.price_from} prefix="$" />
                              </span>
                            </div>
                            <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                              {item?.property?.labels?.find(i => i?.id)?.name}
                            </span>
                          </div>
                        )
                      })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="section col-md-6">
              <div className="card ">
                <div className="card-body row align-items-center px-6 py-3">
                  <h2 className="fs-18">Property Leads</h2>
                  <Slider {...topreviews}>
                    {propertyLeads?.map((item) => {
                      const propertName = item?.propertData[0].name
                      return (
                        <div className="section-content-admin d-flex px-1 py-2 mb-1">
                          <div className='' style={{ width: '60px' }}>
                            <Avatar name={propertName} round={true} size="35" color="#529BE8" />
                          </div>
                          <div className='property-content px-2 mt-1 w-100'>
                            <h2 className='fs-16  '>{propertName}</h2>
                          </div>
                          <span className='badge text-black mt-1 justify-content-center' style={{ height: 'fit-content' }}>
                            Score: {item.property_leads}
                          </span>
                        </div>
                      )
                    }
                    )}

                  </Slider>
                </div>
              </div>
              <div className="card mt-5 ">
                <div className="card-body row align-items-center px-6 py-5">
                  <h2 className="fs-18">Business Leads</h2>
                  <Slider {...propleads}>
                    {userLeads?.map((item) => {
                      return (
                        <div className="section-content-admin d-flex px-1 py-2 mb-1">
                          <div className='' style={{ width: '60px' }}>
                            <Avatar name={item.name} round={true} size="35" color="#529BE8" />
                          </div>
                          <div className='property-content px-2 mt-1 w-100'>
                            <h2 className='fs-16  '>{item.name}</h2>
                          </div>
                          <span className='badge text-black mt-1 justify-content-center' style={{ height: 'fit-content' }}>
                          {moment(item?.date_published).format('DD-MMM-YYYY')}
                          </span>
                        </div>
                      )
                    }
                    )}



                  </Slider>
                </div>
              </div>

            </div>
          </div>
        </div>

      </main>
    </>
  )
}
export default DashBaord;