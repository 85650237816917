import React, { Component } from 'react';
import { Map, Marker, Circle, GoogleApiWrapper, InfoWindow, Polyline } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { googleMapsApiKey } from '../../utils/common';
import MarkerIcon from '../../../src/favicon.ico'
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete 
      bounds: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 43.651070,
        lng: -79.347015
      },
      storeItem: {}
    };
  }

  handleChange = address => {
    this.setState({ address });
  };



  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng);

        // update center state
        this.setState({ mapCenter: latLng });
      })
      .catch(error => console.error('Error', error));
  };

  onMarkerClick(props, marker, e, item) {
    console.log(props, marker, e, item);
    this.setState({ storeItem: item, showingInfoWindow: true, activeMarker: marker, })

    // ..
  }


  ShowProperty = (item) => {
    console.log('working', item)
    // const navigate = useNavigate()
    // navigate('/singlepage', {state:item})
  }
  render() {
    console.log(this.props, 'item59');
    const mapCenter = this.props?.searchData?.map(item => {
      if (item?.lat && item?.lng) {
        return ({
          lat: item?.lat, lng: item?.lng
        })
      }
    })
    const triangleCoords = this.props?.searchData?.map(item => {
      if (item?.lat && item?.lng) {
        return ({
          lat: item?.lat, lng: item?.lng
        })
      }
    }).filter(element => {
      return element !== undefined;
    }) || [
        { lat: 25.774, lng: -80.190 },
        { lat: 18.466, lng: -66.118 },
        { lat: 32.321, lng: -64.757 },
        { lat: 25.774, lng: -80.190 }
      ];

    const lat = this.props.addressGet?.geometry.location.lat()
    const lng = this.props.addressGet?.geometry.location.lng()

    const maplatlong = this.props?.searchData?.filter(item => item?.lat || item?.lng) || [];
    const coords = { lat: maplatlong?.[0]?.lat || lat || 30.266666, lng: maplatlong?.[0]?.lng || lng || -97.733330 };

    return (
      <div id='googleMaps'>


        <Map zoom={9} google={this.props.google}
          center={coords} style={{ width: '100%', height: "100vh", position: 'unset' }}>

          {this.props?.searchData?.map((item) => {
            if (!item?.lat && !item?.lng) return null
            return (
              <Marker
                //   icon={{

                //     url: MarkerIcon,
                //     anchor: new this.props.google.maps.Point(50, 50),
                //     scaledSize:new this.props.google.maps.Size(70, 70)

                // }}
                position={{
                  lat: item?.lat,
                  lng: item?.lng
                }}

                defaultAnimation={2}
                onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, item)}
              >

              </Marker>
            )
          })}
          {/* <div> */}
          <InfoWindow

            marker={this.state.activeMarker} visible={this.state.showingInfoWindow} zIndex={9999}>
            <div className='section-infowindow-part' onClickCapture={() => console.log('', this.state.storeItem)} >
              {/* {this.state.storeItem?.name} */}
              <div className='main-section-marker-infowindow'>
                <img src={this.state.storeItem?.featured_image} onClick={() => console.log('clicked')} style={{ width: '100%', height: '200px', zIndex: 9999 }} />
                {
                  this.state.storeItem.labels?.map((item) => {
                    return (
                      <span className="badge badge-orange mr-2 labels-infowindow-img">{item?.name}</span>
                    )

                  })
                }
                <div className="card-body p-0">
                  <h2 className="my-0 mt-1" ><a
                    className="fs-16 text-dark  lh-2">{this.state.storeItem?.name}</a>
                  </h2>
                  <p className="text-gray-light font-weight-500 mb-1">{this.state.storeItem?.street_name}</p>
                  {/* <p className="fs-17 font-weight-bold text-heading mb-0">
                    ${this.state.storeItem?.price_from}
                  </p> */}

                  {
                    this.state.storeItem?.price_from ? <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={this.state.storeItem?.price_from} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                      className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p> :
                      this.state.storeItem?.lease_rate ? <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value={this.state.storeItem?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                        className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p> : null
                  }

                </div>

              </div>
            </div>
          </InfoWindow>

          {/* </div> */}

          {/* <div style={{width:'100px', height:'100px', backgroundColor:'#fff'}}>
            
          </div> */}
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: `${googleMapsApiKey()}`
})(MapContainer)



// new map