import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import DevSidebar from '../../Components/DevSidebar/devsidebar';
import { ApiUrl, getToken } from "../../utils/common";
import DevHeader from './devheader';
import Loader from "../../Components/Loader";

const FloorPlansList = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { state } = useLocation();
    const [isOpen, setIsopen] = useState(false);
    const [floorPlanData, setFloorPlanData] = useState([])

    const GetFloorPlans = () => {
        setLoading(true);
        const Data = {}

        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/property-floor-plan/${state}`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                setFloorPlanData(e?.data);
                
            })
            .catch(err => {
                setLoading(false)
            })
    }

    useEffect(() => {
        GetFloorPlans();
    }, [])

    const AddFloorPlan = () => {
        navigate('/floorplan', { state: {item:{id:state}} })
    }
    return (
        <>

        {
            loading ? <Loader /> : null
        }
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
                    <DevSidebar />
                    <div className="page-content">
                        <DevHeader />
                        <div className="agent-row margin-re-top">
                            <div className="row">
                                {
                                    floorPlanData?.map((item) => {
                                        return (
                                            <div className="col-sm-6 col-md-4 mb-6">
                                                <div className="card px-6">
                                                    <a className="card-img-top floor-plan-img" onClick={() => setIsopen(true)}>
                                                        <img src={item?.media_path}
                                                            alt="Eco House Company" />
                                                    </a>
                                                    <div className="card-body px-0 pt-2 pb-6 border-top">
                                                        <a className="floor-plan-img" onClick={() => setIsopen(true)}>
                                                            <h6 className="text-dark lh-213 mb-0 ">Floor Plan {item?.floor}</h6>
                                                        </a>
                                                        <p className="card-text">{item?.description}</p>
                                                    </div>
                                                    {
                                                        isOpen ? <div className="pop-img">
                                                            <span onClick={() => setIsopen(false)}>&times;</span>
                                                            <img src={item?.media_path}
                                                                alt="Eco House Company" />
                                                        </div> : null
                                                    }
                                                    <div className="card-footer px-0 text-center hover-image border-0 mr-bo-20">
                                                        <button className="btn btn-danger" >Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <button type="button" className="btn bg-blue text-white hover-saffron add-more-floor-plan-btn" onClick={AddFloorPlan}>Add Floor Plan</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FloorPlansList;