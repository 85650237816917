import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiUrl, getToken } from "../../utils/common";

const AccountVerification = () => {
    const path = window?.location?.pathname?.split('/')[2]
    // const finalToken = path?.split('/b7')[1]
    console.log(path,'path');

    const navigate = useNavigate();

    const verifyApi = () => {
        const body = {
            token: path
        }
        const option = {
          method: 'POST', headers: {
            'access-control-allow-origin': '*',
            'content-type': 'application/json',
            // 'Authorization': `Bearer ${getToken()}`
          },
          data: JSON.stringify(body),
          url: `${ApiUrl()}/auth/user/verify-user`
        };
        axios(option)
          .then(e => {
            // setUserSession(e?.data?.access)
            navigate('/login', {fromVarification: true})
            toast.success("User Has Been Verified Please Login")
    
          })
          .catch(err => {
            toast.error(err?.response?.data?.email[0])
          })
      }

      useEffect(() => {
        if(path){
            verifyApi()  
        }
        
      },[path])
    
    return (
        <div>Loading....</div>
    )
}

export default AccountVerification