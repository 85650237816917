import { combineReducers } from 'redux'

import { authReducer } from './_reducers/auth.reducer'

export const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  authReducer,
})

export type RootState = ReturnType<typeof rootReducer>
