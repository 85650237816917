import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from '../../../../utils/axios/main'; // importing axios from customAxios



export const Couponform = (props) => {


    //    const [loading, setLoading] = props.update


    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => (
        axios.post('/auth/subscription/coupon/create/', data).then(response => {
            console.log(response.data)
            { props.sendToParent(false) }

        }
        )
            .catch(error => console.log(error))
    )
    // /auth/subscription/coupon/create
    const SelectDurationType = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
        <>
            <div className='pd-2'>
                <label>{label}</label>
                <select name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
                    <option value="once">Once</option>
                    <option value="forever">Forever</option>
                    <option value="repeating">Repeating</option>
                </select>
            </div>
        </>
    ));
    return (
        <>
            <div className='card'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Duration</Form.Label>
                        <SelectDurationType  {...register("duration")} /> </Form.Group>
                    <Form.Group>
                        <Form.Label>Duration in months</Form.Label>
                        <Form.Control type="number " placeholder='Required only if duration is repeating, must be the number of months (e.g: 3)' {...register("duration_in_months")} /> </Form.Group>
                    <Form.Group>
                        <Form.Label>Coupon Name</Form.Label>
                        <Form.Control type="text" placeholder='Enter Coupon name (Small letters without Spacing)'{...register("display_name")} /> </Form.Group>
                    <Form.Group>
                        <Form.Label>Percent Off</Form.Label>
                        <Form.Control type="number" placeholder='Enter Number'  {...register("percent_off")} /> </Form.Group>
                    <Form.Group>
                        <Form.Label>Times Redeemed</Form.Label>
                        <Form.Control type="number" Placeholder="Number of times this coupon has been applied to a customer.(e.g: Positive or Zero)"  {...register("times_redeemed")} /> </Form.Group>
                    <Form.Group>
                        <Form.Label>Max Redemptions</Form.Label>
                        <Form.Control type="text" Placeholder="Maximum number of times this coupon can be redeemed" {...register("max_redemptions")} /> </Form.Group>
                    <Form.Group>
                        <Form.Label>Redeem By</Form.Label>
                        <Form.Control type="text" Placeholder="Date after which the coupon can no longer be redeemed (e.g:Unix Timestamp)"  {...register("redeem_by")} /> </Form.Group>
                    {/* <Form.Group>
                        <Form.Label>Applies to</Form.Label>
                        <Form.Control type="text"  {...register("applies_to")} />
                    </Form.Group> */}
                    <Button type="submit">Submit</Button>
                </Form>
            </div>
        </>
    )
}
// const mapStateToProps = (state) => ({})
// const mapDispatchToProps = {}
// export default connect(mapStateToProps, mapDispatchToProps)(form)