import React, { useEffect, useState } from "react";
import DevHeader from "./devheader";
import Devsidebar from '../../Components/DevSidebar/devsidebar';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import axios from "axios";
import Loader from "../../Components/Loader";
import moment from "moment";
import Avatar from "react-avatar";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactStars from "react-rating-stars-component";
const DevPropertyReviewDetails = () => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const state = useLocation();
    //console.log(state, 'state13');
    const navigate = useNavigate();
    const PropertyId = state?.state?.item?.property_id;
    const PropertyName = state?.state?.item?.property_name;
    const [loading, setLoading] = useState(false)
    const [reviewData, setReviewData] = useState([]);
    const GetReview = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/auth/review/property-rating-review/${PropertyId}`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                setReviewData(e?.data)
            })
            .catch(err => {
                setLoading(false)
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            })
    }

    useEffect(() => {
        GetReview()
    }, [])

    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
                    <Devsidebar />
                    <div className="page-content">
                        <DevHeader />
                        <main id="content" className="bg-gray-01 margin-re-top">
                            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                                    <div className="mr-0 mr-md-auto">
                                        {
                                            reviewData?.length ? <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">{PropertyName}
                                                <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{reviewData?.length}</span>
                                            </h2> : null
                                        }

                                    </div>
                                    <div>
                                        <Link to="/devpropreviews" className="btn bg-blue text-white hover-saffron btn-lg">
                                            <span>Reviewed Property</span>
                                            <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card border-0 mb-4 header-r-logout">
                                    


                                        {
                                            reviewData?.map((item, key) => {
                                                return (
                                                    <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"

                                                        >
                                                            <Typography sx={{ width: '25%', flexShrink: 0 }}>
                                                                <Avatar name={item?.name} round={true} className="avatar-reviews avatar-review-dashboards" size="50" color="#529BE8" />
                                                            </Typography>
                                                            <Typography sx={{ marginTop: '15px', width: '45%', color: 'text.secondary' }}>{item?.name}</Typography>
                                                            {/* <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a></Typography> */}
                                                            <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>{moment(item?.date_published).format('MMM-YYYY')}</Typography>
                                                            {/* <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> <ReactStars value={item?.rating} edit={false} size={26}></ReactStars></Typography> */}
                                                            {/* <Typography sx={{ marginTop: '15px', width: '5%', color: 'text.secondary' }}> <a data-bs-toggle="tooltip" title="Delete" className="d-inline-block fs-18 ml-1 text-muted hover-saffron-text "><i
                                                                className="fal fa-trash-alt"></i></a></Typography> */}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography className="">
                                                            <span className="fw-bold fs-18">Comment: </span>{item?.other_comment}<br />
                            <span className="fw-bold fs-18">Email: </span><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })
                                        }

                                    
                                    

                                </div>
                                <div className="card border-0 mb-4 header-user-icon">
                                    <div className="card-body p-0 p-sm-8 p-4">


                                        {
                                            reviewData?.map((item, key) => {
                                                return (
                                                    <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"

                                                        >
                                                            <Typography sx={{ width: '10%', flexShrink: 0 }}>
                                                                <Avatar name={item?.name} round={true} className="avatar-reviews avatar-review-dashboards" size="50" color="#529BE8" />
                                                            </Typography>
                                                            <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{item?.name}</Typography>
                                                            <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a></Typography>
                                                            <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{moment(item?.date_published).format('DD-MMM-YYYY')}</Typography>
                                                            <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> <ReactStars value={item?.rating} edit={false} size={26}></ReactStars></Typography>
                                                            {/* <Typography sx={{ marginTop: '15px', width: '5%', color: 'text.secondary' }}> <a data-bs-toggle="tooltip" title="Delete" className="d-inline-block fs-18 ml-1 text-muted hover-saffron-text "><i
                                                                className="fal fa-trash-alt"></i></a></Typography> */}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography className="mx-10">
                                                                {item?.other_comment}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })
                                        }

                                    </div>
                                    {/* lead */}

                                    {/* {
                      reviewData?.map((item) => {
                        return (
                          <div className="media border-top py-6 d-sm-flex d-block text-sm-left text-center">
                            <Avatar name={item?.name} round={true} className="avatar-reviews avatar-review-dashboards" size="80" color="#529BE8" />
                            <div className="media-body">
                              <div className="row mb-1 align-items-center">
                                <div className="col-sm-6 mb-2 mb-sm-0">
                                  <h4 className="mb-0 text-heading fs-14 cl-text">{item?.name}</h4>
                                </div>
                              </div>
                              <p className="mb-3 pr-xl-17">{item?.other_comment}</p>
                              <div className="d-flex justify-content-sm-start justify-content-center">
                               
                                <a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a>
                              </div>
                              <p className="mb-3 pr-xl-17 mt-2">{moment(item?.date_published).format('DD-MMM-YYYY')}</p>

                            </div>
                          </div>
                        )
                      })
                    } */}



                                    {/* lead end */}

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DevPropertyReviewDetails;