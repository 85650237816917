import React, { useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";

const TabsPartProperty = () => {
    const [currentTab, setCurrentTab] = useState(0);
    return (
        <>
            <h1>tabs System</h1>
            <Tabs activeKey={currentTab} id="controlled-tab-example">
                <Tab eventKey={0} title="Home" disabled={currentTab !== 0}>
                    Home
                </Tab>
                <Tab eventKey={1} title="Profile" disabled={currentTab !== 1}>
                    Profile
                </Tab>
                <Tab eventKey={2} title="Contacts" disabled={currentTab !== 2}>
                    Contacts
                </Tab>
            </Tabs>

            <Button
                className="success"
                disabled={currentTab === 0}
                onClick={() => setCurrentTab((prev) => prev - 1)}
            >
                Prev
            </Button>
            <Button
                className="success"
                disabled={currentTab === 2}
                onClick={() => setCurrentTab((prev) => prev + 1)}
            >
                Next
            </Button>
        </>
    )
}
export default TabsPartProperty;