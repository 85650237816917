import React, { useEffect, useState } from "react";
import DevSidebar from '../../Components/DevSidebar/devsidebar';
import DevHeader from './devheader';
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { ApiUrl, DeveloperId, GetId, getToken, removeUserSession } from "../../utils/common";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";

const DevProfile = () => {
  const [loading, setLoading] = useState(false)
  const [ProfileData, setProfileData] = useState();
  const GetProfile = () => {
    setLoading(true);
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/user/developer-profile`
    };

    axios(option)
      .then(e => {
        setLoading(false);
        const data = e?.data;
        setProfileData(data)
        toast.success("Please Update Your Profile First")
        DeveloperId(e?.data?.id);
      })
      .catch(err => {
        if(err?.request?.status === 401){
          removeUserSession();
          navigate('/')
        }
        setLoading(false);
      })
  }
  useEffect(() => {
    GetProfile();
  }, [])

  const navigate = useNavigate()

  return (
    <>
    {
      loading ? <Loader /> : null
    }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">

          <DevSidebar />


          <div className="page-content">
            <DevHeader />
            <section className="pb-7 shadow-xs-1 position-relative z-index-1 mt-n17">
              <div className="container pt-17">
                      <div className="row">
                        {/* <h1>{item?.company_name}</h1> */}
                        <div className="col-md-5">
                          <img src={ProfileData?.profile_image || "images/image-box-9.jpg"}  className="card-img dev-img"
                            alt="" />
                            
                        </div>
                        <div className="col-md-7">
                          <div className="pl-md-10 pr-md-8 py-7">
                            <h2 className="fs-30 text-dark font-weight-600 lh-16 mb-0">{ProfileData?.company_name}</h2>
                            <p className="fs-16 font-weight-500 lh-213 mb-4"></p>
                            {/* <p className="mb-1">Company Agent at <a href="#" className="text-heading">Modern House Real Estate</a> 
                            </p> */}
                            <p className="mb-6">{ProfileData?.description}</p>
                            <hr className="mb-4 cl-8" />
                            <div className="row">
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Developer Name:</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.first_name} {ProfileData?.last_name}</p>

                              </div>
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Role/Designation:</p> 
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.designation}</p>

                              </div>
                              <hr className="mb-4 cl-8" />
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Company Address</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl" >{ProfileData?.street_name} {ProfileData?.city}</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl" >{ProfileData?.state},{ProfileData?.country}</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl" >{ProfileData?.post_code}</p>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Phone/Mobile</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">M: {ProfileData?.mobile_no}</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">P: {ProfileData?.office_no}</p>
                              </div>

                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Email</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.email}</p>
                              </div>
                              
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Alternate Email 1</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.alternate_email1}</p>
                              </div>

                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Alternate Email 2</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.alternate_email2}</p>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Company License ID</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.company_license_id}</p>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <p className="mb-0 p-nm">Company Website</p>
                                <p className="text-heading font-weight-500 mb-0 lh-13 nm-cl">{ProfileData?.website}</p>
                              </div>
                            </div>
                            <hr className="mb-4 cl-8" />
                            <div className="row align-items-center">

                              <div className="soc-cen">
                                <div className="so-ic">
                                <ul className="list-inline text-gray-lighter m-0 p-0">
                                  <li className="list-inline-item mx-0 my-1">
                                    <a href="#"
                                      className="w-32px h-32 d-flex s-link"><img src="images/twitter-c.png" /></a>
                                  </li>
                                  <li className="list-inline-item mr-0 ml-2 my-1">
                                    <a href="#"
                                      className="w-32px h-32 d-flex s-link"><img src="images/instagram-c.png" /></a>
                                  </li>
                                  <li className="list-inline-item mr-0 ml-2 my-1">
                                    <a href="#"
                                      className="w-32px h-32  d-flex s-link"><img src="images/facebook-c.png" /></a>
                                  </li>
                                </ul>
                                </div>
                                <div className="ed-btn">
                                <button type="button" className="btn btn-primary btn-ed" onClick={() => navigate('/devmyprofile')}>Edit Profile</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 </section>
               </div>
              </div>
            </div>
    </>
  )
}
export default DevProfile;