import React, { useEffect, useState } from "react";
import { MainApiProtectedVersion } from "../../../utils/axios/requests";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
function PropertyAnalyticsPage() {
  const [propHistory, setpropHistory] = useState([]);

  useEffect(() => {
    async function Allproperties() {
      const obj = new MainApiProtectedVersion("");
      const res = await obj
        .requstProprtyHistoryGet()
        .then((res) => {
          console.log(res.data);
          setpropHistory(res.data);

          // console.log(res);
          // if (res.status === 200) {
          //   setAllpropertyData(res.data.data);
          //   setPropertyCount(res.data.count);
          //   // setLoading(false)
          // } else if (res.status === 204) {
          //   setAllpropertyData();
          //   setPropertyCount();
          // }
          // setCoupon(res.data)
        })
        .catch((error) => {
          alert(error);
          // setLoading(false)
        });
    }
    Allproperties();
  }, []);

  return (
    <div>
      <div className="row">
        
        
            {propHistory ? (

              <>
              {
                propHistory.map((item,key)=>{
                  // console.log(key);
                  // console.log(item['price']);
                  
                  
                  return (
                   <>
                   <ListGroup as="ol" className="col-6">
                   <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Subheading</div>
                        Cras justo{item['property']}

                      </div>
                      <Badge bg="primary" pill>
                        {item['price']}
                      </Badge>
                    </ListGroup.Item>
                    
                  </ListGroup>
                   </>
                )})

              }
              </>


              // <div className="row">
              //   <div className="col-md-6">
              //     <ListGroup as="ol" numbered>
              //       <ListGroup.Item
              //         as="li"
              //         className="d-flex justify-content-between align-items-start"
              //       >
              //         <div className="ms-2 me-auto">
              //           <div className="fw-bold">Subheading</div>
              //           Cras justo odio
              //         </div>
              //         <Badge bg="primary" pill>
              //           14
              //         </Badge>
              //       </ListGroup.Item>
              //       <ListGroup.Item
              //         as="li"
              //         className="d-flex justify-content-between align-items-start"
              //       >
              //         <div className="ms-2 me-auto">
              //           <div className="fw-bold">Subheading</div>
              //           Cras justo odio
              //         </div>
              //         <Badge bg="primary" pill>
              //           14
              //         </Badge>
              //       </ListGroup.Item>
              //       <ListGroup.Item
              //         as="li"
              //         className="d-flex justify-content-between align-items-start"
              //       >
              //         <div className="ms-2 me-auto">
              //           <div className="fw-bold">Subheading</div>
              //           Cras justo odio
              //         </div>
              //         <Badge bg="primary" pill>
              //           14
              //         </Badge>
              //       </ListGroup.Item>
              //     </ListGroup>
              //   </div>
              // </div>
            ) : (
              "data not found"
            )}
      </div>
    </div>
  );
}

export default PropertyAnalyticsPage;
