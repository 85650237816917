import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DevSidebar from "../../Components/DevSidebar/devsidebar";
import Loader from "../../Components/Loader";
import { ApiUrl, getToken } from "../../utils/common";
import DevHeader from "./devheader";



const DevMyPackage = () => {
  const [loading, setLoading] = useState(false)
  const [packageData, setPackageData] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const GetPackage = () => {
    setLoading(true);
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/subscription/current_plan`
    };
    axios(option)
      .then(e => {
        setLoading(false);
        setPackageData(e?.data);
      })
      .catch(err => {
        if (err?.response?.data?.message === "No Active Subscription yet") {
          setShow(true)
        }
        setLoading(false);
      })
  }

  useEffect(() => {
    GetPackage();
  }, [])
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="row">
        <div className='col-md-2 pr-0'>
          <DevSidebar />
        </div>

        <div className="col-md-10 pl-0 add-ctsm-heights">
          <DevHeader />
          <main id="content" className="bg-gray-01">
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
              <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                <div className="mr-0 mr-md-auto">
                  <h2 className="mb-0 text-heading fs-22 lh-15">My Packages
                  </h2>
                </div>
              </div>
              <div className="row px-2">
                <div className="col-5 pack-list mb-5">
                  <h4 className=" li-h py-2">
                    My Plan
                  </h4>
                  <ul>
                    <li className="li-h">Number of Listings Per Year</li>
                    <li className="li-h">Placement in search results</li>
                    <li className="li-h">Dashboard for Leads Management </li>
                    <li className="li-h">Featured Listing on homepage</li>
                    <li className="li-h">Featured Developer on Homepage</li>
                    <li className="li-h">Notifications on Matched User Searches </li>
                    <li className="li-h">Homepage Banner Ad </li>
                    {/* <li className="li-h">Ad Campaign Management</li> */}
                    <li className="li-h year-price-discount">Year Price Discount</li>
                  </ul>
                </div>

                <div className="col-4 pack-list mb-5 ">
                  <h4 className="tx-center  li-h top-bar py-2 text-transform-cap">
                    {packageData?.plan?.plan_type}
                  </h4>
                  <ul className="">
                    <li className="tx-center li-h">{packageData?.plan?.listings_per_year}</li>
                    <li className="tx-center li-h">{packageData?.plan?.placement_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                    <li className="tx-center li-h">{packageData?.plan?.placement_in_search_results ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                    <li className="tx-center li-h">{packageData?.plan?.featured_listing_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                    <li className="tx-center li-h">{packageData?.plan?.featured_developer_on_homepage ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>
                    <li className="tx-center li-h">{packageData?.plan?.notifications_on_matched_user_searches ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>

                    <li className="tx-center li-h">{packageData?.plan?.homepage_banner_ad ? <img src="images/check.png" alt="check" style={{ width: "16px", height: "16px" }} /> : <img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} />}</li>

                    {/* <li className="tx-center li-h"><img src="images/prohibition.png" alt="check" style={{ width: "16px", height: "16px" }} /></li> */}
                    <li className="tx-center li-h fs-32"><img src="images/dollar-icon.png" style={{ width: "33px", height: "33px" }} />{packageData?.plan?.yearly_price_discount}<span className="fs-16">/yr</span></li>
                  </ul>
                </div>

              </div>
              <div className="text-right">
                <a href="/subcriptionpackages" className="btn btn-lg bg-blue text-white hover-saffron">Change Package</a>
              </div>
            </div>
          </main>
        </div>
      </div>
      {
        show ? <Modal show={handleShow} className="modal-add-contact">
          <Modal.Body>
            <h4 className="heading-modal-no-plans">You don't have any active subscription plan. Please click below to Subscribe</h4>
          </Modal.Body>
          <Modal.Footer>
            <Link to='/subcriptionpackages'>
              <Button className="btn bg-blue text-white hover-saffron">
                Subscribe
              </Button>
            </Link>

          </Modal.Footer>
        </Modal> : null
      }

    </>
  )
}
export default DevMyPackage;