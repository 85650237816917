import { borderRadius } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "../../utils/common";

import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { gql, useMutation } from '@apollo/client';

const TestimonialForm = () => {

const { register, handleSubmit, watch,reset, formState: { errors } } = useForm();
   const[formData,setFormData] = useState('');
    const [PreviewImg, setPreviewImg] = useState('');
    // const [isMyProfile, setIsMyProfile] = useState('');
    // modal states
    const [show, setShow] = useState(false);
    const OnhideModal = () => setShow(false)
    const [submissionDone, setSubmissionDone] = useState(false);
    const OnCloseModal = () =>{
        setSubmissionDone(false)
        window.location.reload();
    } 
    // const CREATE_TESTMONAL = gql`
    //                 mutation createTestmonial($description:String!,$profileImageUrl:Upload!, $companyName:String!,$email:String!,$firstName:String!,$lastName:String!,$designation:String!){

    //                     createTestmonial(profileImageUrl:$profileImageUrl,description:$description, companyName:$companyName,email:$email,firstName:$firstName,lastName:$lastName,designation:$designation){
                            
    //                     testmonial{
    //                                 firstName
    //                                 lastName
    //                                 email
    //                                 profileImageUrl
    //                                 description
    //                                 companyName
    //                                 designation
    //                             }
                            
    //                 }
    //             }
    //             `;
   // const [createTestmonial] = useMutation(CREATE_TESTMONAL);
    const[imagefile,setImageFile] = useState();
    const[imageUrl,setImageUrl] = useState();
    console.log(imageUrl,'imageUrl')
    console.log(imagefile,'imagefile')
    const PopImg = (e) => {
        let image = URL.createObjectURL(e?.target?.files[0])
        console.log(image);
        setPreviewImg(image)
        setShow(true)
        setImageUrl(e?.target?.files[0])
       
    }
    const CreateTestProfile = (data) => {
        const option = {
          method: 'POST', headers: {
            'access-control-allow-origin': '*',
            'content-type': 'multipart/form-data',
          },
          data,
          url: `${ApiUrl()}/api/testmonialprofile/`
        };
        axios(option)
        .then(e => {
          const data = e?.data?.[0];
        
          console.log(e, 'e');
          setSubmissionDone(true)
        })
        .catch(err => {
          // setLoading(false)
          if (err?.request?.status === 401) {
           
          }
        })
      }
      const onSubmit = (data) => {
        console.log("====================================");
        console.log(data);
        data.images_url=data.files[0];
       data.profile_image=data.files[0];
      
        console.log("====================================");
          return  CreateTestProfile(data) ;
      };
    return (<div className="" style={{ textAlign: 'center' }} >
        <div className="mb-5 col-12">
            <form className="" onSubmit={handleSubmit(onSubmit)} style={{ /* backgroundColor: "#f8f8f8", */ borderRadius: '5px', opacity: "0.9" }}>
                <div className="pt-3 " style={{ textAlign: 'center' }}><p className="fs-18"><span style={{ color: "#1a63c6", textTransform: 'uppercase', fontWeight: "600" }}><img style={{ width: "40px" }} src="images/favicon-icon.png" />Dollar Per Foot</span><br />Submit your Feedback. </p></div>
                <div className="row px-3">
                    <div className="col-lg-6  col-sm-3 py-1 mt-3" >
                        <span className="star-mandatoryfield mend-type">*</span>
                        <input type="text" className="form-control form-control-lg border-1" placeholder="First Name" {...register("first_name", { required: true, maxLength: 80 })} />
                        {errors.first_name && <span style={{ color: "red" }}>This field is required</span>}
                    </div>
                    <div className=" col-lg-6  col-sm-3 py-1 mt-3" >
                        <span className="star-mandatoryfield mend-type"></span>
                        <input type="text" className="form-control form-control-lg border-1" placeholder="Last Name" {...register("last_name", {maxLength: 80 })} />
                    </div>
                    <div className=" col-lg-6  col-sm-3 py-1 mt-3" >
                        <span className="star-mandatoryfield mend-type">*</span>
                        <input type="text" className="form-control form-control-lg border-1" placeholder="Company Name" {...register("company_name", { required: true, maxLength: 80 })} />
                        {errors.company_name && <span style={{ color: "red" }}>This field is required</span>}
                    </div>
                    <div className=" col-lg-6  col-sm-3 py-1 mt-3" >
                        <span className="star-mandatoryfield mend-type"></span>
                        <input type="text" className="form-control form-control-lg border-1" placeholder="Designation" {...register("designation", { maxLength: 80 })} />
                    </div>
                    <div className=" col-12 py-1 mt-3 " >
                        <span className="star-mandatoryfield mend-type">*</span>
                        <input type="text" className="form-control form-control-lg border-1" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                        {errors.email && <span style={{ color: "red" }}>This field is required</span>}
                    </div>
                    <div className="col-12 py-1 mt-3" >
                    <span className="star-mandatoryfield mend-type">*</span>
                        <textarea className="form-control form-control-lg border-1" placeholder="Testimonial" {...register("description", {required: true, maxLength: 150 })} />
                        {errors.description && <span style={{ color: "red" }}>This field is required</span>}
                    </div>
                    <div className="col-lg-6  col-sm-3">
                        <div className="form-group mt-3   " style={{ textAlign: 'center' }} >
                            <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*"  {...register("files")} onChange={PopImg} />
                            <label className="btn text-white hover-saffron bg-blue" htmlFor="customFile">
                                <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                profile image</label>
                            {/* <button className="btn text-white bg-orange ">Upload Image</button> */}
                        </div>
                    </div>
                    <div className="col-lg-6  col-sm-3">
                        <div className="form-group mt-3  " style={{ textAlign: 'center' }} >
                            <input type="submit" className="btn text-white hover-saffron bg-blue "  style={{ width: '70%' }} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <Modal className="col-lg-6  col-sm-3 modal-add-contact"
            show={show} >
            <Modal.Body>
                <img src={PreviewImg} />
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnhideModal()}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal className="col-lg-6  col-sm-3 modal-add-contact"
            show={submissionDone} >
            <Modal.Body>
                <div className="text-center">
                <h2 className="mt-3">We Appreciate Your Feedback.</h2>
                 <span className="text-blue fs-18">  Thank You!</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnCloseModal()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>

    );
}
export default TestimonialForm;


