import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import { ApiUrl } from "../../utils/common";
import axios from "axios";
import PaginationDefine from "../../Components/pagination/pagination";
import { Link } from "react-router-dom";
import moment from "moment";

const BlogList = () => {

  // pagination states
  const [loading, setLoading] = useState(false);
  const [landingBlogList, setLandingBlogList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(9);
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const currentPosts = landingBlogList.slice(indexOfFirstPost, indexOfLastPost);
  
  console.log(landingBlogList, "landing blog")

  const GetAllBlogList = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/api/blog/posts/`
    };
    axios(option)
      .then(e => {
        
        setLandingBlogList(e?.data?.data);
      })
      .catch(err => {
        setLoading(false);
      })
  }
  useEffect(() => {
    GetAllBlogList();
  }, [loading])
const truncateString = (string = '', maxLength = 50) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string
    
const blogContent = truncateString("",150);
  return (
    <>
      <Header />
      <main id="content" className="mt-100">
        <section className="py-8
        
        bg-gray-01">
          <div className="container">
            <h2 className="fs-30 lh-16 mb-10 text-dark font-weight-600 text-center">Interesting articles updated daily</h2>
            <div className="row">
              {currentPosts?.map((item) => {
                return (
                  <div className="col-md-4 mb-6">
                    <div className="card border-0 shadow-xxs-3">
                      <div className="position-relative d-flex align-items-end card-img-top">
                      <Link to={`/blogs/${item.id}`} className="hover-shine">
                          <img src={item?.featured_image_url || "images/favicon-icon.png"}
                            alt={item?.title} />
                        </Link>

                      </div>
                      <div className="card-body px-5 pt-3 pb-5">
                        <p className="mb-1 fs-13">{moment(item?.created_on).format('YYYY-MMM-DD')}</p>
                        <h3 className="fs-18 text-heading lh-194 mb-1">
                        <Link to={`/blogs/${item.id}`} className="text-heading ">{item?.title}</Link>
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: item?.content.substring(0,250) }} />

                        <Link className="text-blue hover-saffron-text font-weight-500" to={`/blogs/${item.id}`}>
                        Learn more
                        </Link>
                      </div>
                    </div>
                  </div>

                )

              }
              )}


<PaginationDefine postPerPage={postPerPage} totalPosts={landingBlogList?.length} paginate={paginate}  />

            </div>
            
            {/*  <nav className="pt-4">
            <ul className="pagination rounded-active justify-content-center mb-0">
              <li className="page-item"><a className="page-link" href="#"><i className="far fa-angle-double-left"></i></a>
              </li>
              <li className="page-item"><a className="page-link" href="#">1</a></li>
              <li className="page-item active"><a className="page-link" href="#">2</a></li>
              <li className="page-item d-none d-sm-block"><a className="page-link" href="#">3</a></li>
              <li className="page-item">...</li>
              <li className="page-item"><a className="page-link" href="#">6</a></li>
              <li className="page-item"><a className="page-link" href="#"><i
                        className="far fa-angle-double-right"></i></a></li>
            </ul>
          </nav> */}
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default BlogList;