import { Grid } from "react-loader-spinner";
import React from "react";

const Loader = () => {
    return (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', position: 'fixed', left: 0, top: 0, width: "100%", height: '100%', zIndex: 99999, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <Spinner animation="border" role="status" color="white" style={{borderColor:'white',borderRightColor:'transparent', width: '50px', height: '50px'}}>
                <span className="sr-only">Loading...</span>
            </Spinner> */}

            <Grid
                height="80"
                width="80"
                color="#ee9141"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}
export default Loader;