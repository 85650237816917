import React, { useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Form, Modal, } from "react-bootstrap";
import { AddId, ApiUrl, GetAgentType, GetDeveloperType, GetId, GetOtherBusinessType, getToken, removeUserSession, setUserSession } from "../../utils/common";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { gapi } from 'gapi-script';
import { Link, useNavigate } from "react-router-dom";
import Popup from "../popup/signinpopup/popup";
import Loader from "../Loader";


const Header = ({ verifyAccount }) => {

  const navigate = useNavigate();
  const usertoken = getToken();
  const devType = GetDeveloperType();
  const agentType = GetAgentType();
  const otherType = GetOtherBusinessType();
  const [userData, setUserData] = useState();
  const [agentProfileData, setAgentProfileData] = useState();
  const [otherBusinessData, setOtherBusinessData] = useState();

  // logout api
  const Logout = () => {
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/user/logout`
    };

    axios(option)
      .then(e => {
        setLoading(true)
        removeUserSession();
        navigate('/')
      })
      .catch(err => {

      })
  }
  // modal part
  const [show, setShow] = useState(verifyAccount || false);

  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [loading, setLoading] = useState(false)
  // developer profile get
  const GetProfile = () => {
    setLoading(true);
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/developer-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
        const data = e?.data?.[0];
        setUserData(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })
  }

  // agent profile get

  const AgentProfileGet = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/agent-profile-get/${GetId()}`
    };
    axios(option)
      .then(e => {
        setAgentProfileData(e?.data?.[0])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const otherProfileGet = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/otherbusiness-profile-get/${GetId()}`
    };
    axios(option)
      .then(e => {
        console.log(e, 'e106');
        setOtherBusinessData(e?.data?.[0]);
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (usertoken) {
      GetProfile();
      AgentProfileGet();
      otherProfileGet();
    }
  }, [usertoken])

  // dashboard redirect function
  const RedirectDashboard = () => {
    // alert('working')
    if (`${GetDeveloperType()}` === "true") {
      navigate('/developerdashboard')
    }

    else if (`${GetAgentType()}` === "true") {
      navigate('/agentdashboard')
    }

    else if (`${GetOtherBusinessType()}` === "true") {
      navigate('/obdashboard')
    }
    else (
      removeUserSession(window.location.reload())


    )
  }

  const AddListingRedirect = () => {
    if (`${GetDeveloperType()}` === "true") {
      navigate('/property/create')
    }

    else if (`${GetAgentType()}` === "true") {
      navigate('/property/create')
    }
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <header className="main-header  fixed-top m-0 navbar-dark header-sticky header-sticky-smart header-mobile-xl blog-main">
        <div className="sticky-area stick-area">
          <div className="container-fluid px-10">
            <div className="d-flex align-items-center pos-relative">
              <nav className="navbar navbar-expand-xl bg-transparent px-0 w-100 w-xl-auto wdth-100per">

                <Link className="navbar-brand mr-7 mr-none"
                  to="/" data-toggle="dropdown" >
                  <img src="../../images/dpf-logo.png" alt="HomeID" className="normal-logo main-logo-header" />
                  <img src="../../images/dpf-logo.png" alt="HomeID"
                    className="sticky-logo" />
                </Link>
                {/* <a className="d-block d-xl-none ml-auto mr-4 position-relative text-white p-2" href="#">
                  <i className="fal fa-heart fs-large-4"></i>
                  <span className="badge badge-primary badge-circle badge-absolute">1</span>
                </a> */}
                <button className="navbar-toggler border-0 px-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#primaryMenu02"
                  aria-controls="primaryMenu02" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="text-white fs-24 font-size-50"><i className="fal fa-bars"></i></span>
                </button>
                <div className="collapse navbar-collapse mt-3 mt-xl-0" id="primaryMenu02">
                  <ul className="navbar-nav hover-menu main-menu px-0 mx-xl-n4">
                    <li id="navbar-item-home" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-3 px-1200-1">
                      <Link className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/" data-toggle="dropdown" >
                        Home
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <li id="navbar-item-listing" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-3 px-1200-1">
                      <Link className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/about-us" data-toggle="dropdown" >
                        About Us
                        <span className="caret"></span>
                      </Link>

                    </li>

                    <li id="navbar-item-dashboard" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-3 px-1200-1">
                      <Link className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/finderservice" data-toggle="dropdown" >
                        Business Finder
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <li id="navbar-item-dashboard" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-3 px-1200-1">
                      <Link className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/blogs" data-toggle="dropdown" >
                        Blog
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <li id="navbar-item-dashboard" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-3 px-1200-1">
                      <Link className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/feedback" data-toggle="dropdown" >
                        Testimonial
                        <span className="caret"></span>
                      </Link>
                    </li>

                    <li id="navbar-item-pages" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-3 px-1200-1">
                      <Link className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/contact-us" data-toggle="dropdown" >
                        Contact
                        <span className="caret"></span>
                      </Link>
                    </li>

                  </ul>


                  {/* <div className="ml-auto d-none d-xl-block"> */}
                  <ul className="d-r-inline navbar-nav main-menu hover-menu flex-row ml-auto align-items-center justify-content-lg-end flex-wrap  add-positioning-styles">
                    {
                      usertoken ? <li id="navbar-item-pages" aria-haspopup="true" aria-expanded="false"
                        className="nav-item dropdown nav-list-item px-0 px-xl-3 px-1200-1">
                        {devType === 'true' || agentType === "true" || otherType === "true" ? (<>
                         

                          <Link  className="nav-link dropdown-toggle p-0 nav-bar-link dashboard-link-header-mob"  to={"/dashboard"}>                            Dashboard
                          </Link>
                        </>) : (<>
                        
                          <a className="nav-link pl-3 pr-3 add-mr-15 nav-bar-link round-5 border-1 auth-tab add-color-border" onClick={() => { removeUserSession(); Logout(); window.location.reload() }}>Logout</a>
                        </>)}

                      </li> : <li className="nav-item ">
                        <Link to='/login' className="nav-link pl-3 pr-3 add-mr-15 nav-bar-link round-5 border-1 auth-tab add-color-border">
                          Sign In
                        </Link>

                      </li>
                    }
                    <li className="nav-item  header-user-icon">
                      {
                        devType === 'true' || agentType === "true" || otherType === "true" ? <div className="dropdown border-0  text-right">
                          <a href="#"
                            className="dropdown-toggle text-heading  d-flex align-items-center justify-content-end add-icon-clr"
                            data-bs-toggle="dropdown">


                            <div className="mr-4 w-48px ">
                              <img src={userData?.profile_image || agentProfileData?.profile_image || otherBusinessData?.profile_image || "images/user-icon-dummy.png"}
                                alt="Ronald Hunter" className="rounded-circle set-height-40" style={{ width: '45px', height: '45px' }} />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right w-100 ">
                            <a className="dropdown-item" href="/">Home Page</a>
                            <Link to="/profile/edit"style={{ color: 'inherit' }} className="dropdown-item">Edit Profile</Link>
                            <a className="dropdown-item" onClick={() => { removeUserSession(); Logout(); window.location.reload() }}>Logout</a>
                          </div>
                        </div> : null
                      }

                    </li>
                    {
                      devType === 'true' || agentType === "true" || otherType === "true" ? (
                        <li className="nav-item header-r-logout">
                          <a className=" nav-link pl-3 pr-3 add-mr-15 nav-bar-link round-5 border-1 auth-tab add-color-border" onClick={() => { removeUserSession(); Logout(); window.location.reload() }}>Logout</a>
                        </li>) : null
                    }
                    {devType === 'true' || agentType === "true" ? (
                      <li className="add-listing-button add-margin-right ">
                        <a href onClick={() => AddListingRedirect()} style={{ cursor: 'pointer' }}>
                          Add Listing
                        </a>
                      </li>) : null}


                    <li className="subscibe-button-main-header">
                      <a classname="py-2 text-white" href="/subpackages">
                        Plans
                      </a>
                    </li>
                  {/*   <li className="ml-2">
                      <Link classname="py-2" to="/feedback">
                        <img style={{ width: '45px' }} src="/images/testimonial.png" />
                      </Link>
                    </li> */}

                  </ul>
                  {/* </div> */}
                </div>
              </nav>
              <div className="ml-auto d-none d-xl-block">
                {/* <ul className="navbar-nav main-menu hover-menu flex-row ml-auto align-items-center justify-content-lg-end flex-wrap py-2">
                  {
                    usertoken ? <li id="navbar-item-pages" aria-haspopup="true" aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-4">
                      <a className="nav-link dropdown-toggle p-0 nav-bar-link" style={{ cursor: 'pointer' }} onClick={() => RedirectDashboard()}>
                        Dashboard
                      </a>
                    </li> : <li className="nav-item ">
                      <Link to='/login' className="nav-link pl-3 pr-3 add-mr-15 nav-bar-link round-5 border-1 auth-tab">
                        Sign In
                      </Link>

                    </li>
                  }
                  <li className="nav-item">
                    {
                      userData?.id || agentProfileData?.id || otherBusinessData?.id ? <div className="dropdown border-0 py-3 text-right">
                        <a href="#"
                          className="dropdown-toggle text-heading pr-3 pr-sm-6 d-flex align-items-center justify-content-end add-icon-clr"
                          data-bs-toggle="dropdown">

                          <div className="mr-4 w-48px">
                            <img src={userData?.profile_image || agentProfileData?.profile_image || otherBusinessData?.profile_image || "images/user-icon-dummy.png"}
                              alt="Ronald Hunter" className="rounded-circle set-height-40" />
                          </div>

                        </a>
                        <div className="dropdown-menu dropdown-menu-right w-100">
                          <a className="dropdown-item" href="/">Home Page</a>
                          <a className="dropdown-item" href="/" onClick={() => { removeUserSession(); Logout(); window.location.reload() }}>Logout</a>
                        </div>
                      </div> : null
                    }
                  </li>

                  {
                    agentProfileData?.id || userData?.id ? <li className="add-listing-button add-margin-right">
                      <a href onClick={() => AddListingRedirect()} style={{ cursor: 'pointer' }}>
                        Add Listing
                      </a>
                    </li> : null
                  }


                  <li className="subscibe-button-main-header">
                    <a classname="py-2 text-white" href="/subpackages">
                      Plans
                    </a>
                  </li>

                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* modal part  */}
      <div className="modal fade login-register login-register-modal" id="login-register-modal" tabIndex="-1" role="dialog"
        aria-labelledby="login-register-modal" aria-hidden="true" onClick={handleShow}>
        <div className="modal-dialog modal-dialog-centered mxw-571" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 p-0">
              <div className="nav nav-tabs row w-100 no-gutters" id="myTab" role="tablist">
                <a className="nav-item col-sm-3 ml-0 nav-link pr-6 py-4 pl-9 active fs-18" id="login-tab"
                  data-bs-toggle="tab"
                  href="#login"
                  role="tab"
                  aria-controls="login" aria-selected="true">Login</a>
                <a className="nav-item col-sm-3 ml-0 nav-link py-4 px-6 fs-18" id="register-tab" data-bs-toggle="tab"
                  href="#register"
                  role="tab"
                  aria-controls="register" aria-selected="false">Register</a>
                <div className="nav-item col-sm-6 ml-0 d-flex align-items-center justify-content-end">
                  <button type="button" className="close m-0 fs-23" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body p-4 py-sm-7 px-sm-8">
              <div className="tab-content shadow-none p-0" id="myTabContent">

                {/* register data */}
                <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                  <div className="divider text-center my-2">
                    <span className="px-4 bg-white lh-17 text">
                      or continue with
                    </span>
                  </div>
                  <div className="col-12 px-2 mb-4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default Header;