import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DevSidebar from "../../Components/DevSidebar/devsidebar";
import Loader from "../../Components/Loader";
import SearchDropdown from "../../Components/SearchDropdown";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";


const AddContact = () => {
    const [isAddNew, setIsAddNew] = useState(false)
    const HandleClose = () => setIsAddNew(false)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [designation, setDesignation] = useState();
    const [options, setOptions] = useState([]);
    const [builderOptions, setBuilderOptions] = useState([]);
    const navigate = useNavigate()
    //  get api data
    const [getDataContact, setGetDataContact] = useState([]);
    const [builder, setBuilder] = useState([]);
    const [architect, setArchitect] = useState([]);
    const [marketingCompany, setMarketingCompany] = useState([]);
    const [salesCompany, setSalesCompany] = useState([]);
    const [interiorDesigner, setInteriorDesigner] = useState([]);
    const [agent, setAgent] = useState([]);
    const [agentSearchdata,setAgentSearchData] = useState([]);

    const [typeModal, setTypeModal] = useState('');
    const state = useLocation();
    const PropertyId = state?.state?.item?.id
    console.log(interiorDesigner, 'interiorDesigner');
    const allDropDownData = [...builder, ...architect, ...marketingCompany, ...salesCompany, ...interiorDesigner, ...agent]
    console.log(allDropDownData, 'allDropDownData');
    // add property Contact api
    const ContactProperty = () => {
        if (!builder?.length && !architect?.length && !marketingCompany?.length && !salesCompany?.length && !interiorDesigner?.length && !agent?.length) {
            toast.error("Please Add Atleast One Contact To Submit");
            return
        }

        // if (!ValidateFields()) return;
        const body = allDropDownData?.map(item => ({
            name: `${item?.first_name}`,
            // email: item?.email,
            // ${item?.last_name}
            email: item?.email || email,
            builder: item?.type?.includes('Builder'),
            architect: item?.type?.includes('Architect'),
            marketing_company: item?.type?.includes('Marketing'),
            sales_company: item?.type?.includes('Sales'),
            interior_designer: item?.type?.includes('interior'),
            agent_key: item?.type?.includes('agent'),
            designation: item?.designation,
            user_id:item?.user_id,
            property_id: PropertyId
        }))
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: JSON.stringify(body),
            url: `${ApiUrl()}/api/property/add-property-team`


        };
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    toast.success("Property Contact Add Successfully");
                    GetProperty();
                    setBuilder([]);
                    setArchitect([]);
                    setMarketingCompany([]);
                    setSalesCompany([]);
                    setInteriorDesigner([]);
                    window.location.reload()
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.[0].email?.[0])
                if (err?.response?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }


            })

    }
    // const ValidateFields = () => {
    //     if (!name || name.trim() === "") {
    //         toast.error('Please Enter Your Name')
    //         return false
    //     }
    //     if (!designation || designation.trim() === "") {
    //         toast.error('Please Enter Your Designation')
    //         return false
    //     }
    //     return true;
    // }

    // get property contact api
    const GetProperty = () => {
        setLoading(true)

        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/add-property-team/${PropertyId}`
        }

        axios(option)
            .then(e => {
                setLoading(false)
                setGetDataContact(e?.data)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    useEffect(() => {
        GetProperty()
    }, [])


    // delete property contact api
    const DeleteProperty = (id) => {
        const option = {
            method: 'DELETE', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/add-property-team/${id}`
        }

        axios(option)
            .then(e => {
                if (e?.data === "") {
                    toast.success("Contact Delete Successfully");
                    GetProperty()
                }
            })
            .catch(err => {
            })
    }

    const ValidateFields = () => {
        if (!name || name.trim() === "") {
            toast.error("Please Enter Name")
            return false
        }
        if (!designation || designation?.trim() === "") {
            toast.error("Please Enter Designation")
            return false
        }

        if (!email) {
            toast.error("Please Enter Email")
            return false
        }
        return true

    }

    const createUser = () => {

        if (!ValidateFields()) return;
        const data = {
            first_name: name,
            email: email,
            designation: designation,
            type: typeModal,
        }
        toast.warning("Search for the new contact just added")
        if (typeModal === 'Builder') {
            setBuilder([...builder, data]);
        }
        else if (typeModal === 'Architect') {
            setArchitect([...architect, data])
        }
        else if (typeModal === 'Marketing') {
            setMarketingCompany([...marketingCompany, data])
        }
        else if (typeModal === 'Sales') {
            setSalesCompany([...salesCompany, data])
        }
        else if (typeModal === 'interior') {
            setInteriorDesigner([...interiorDesigner, data])
        }
        else {
            setAgent([...agent, data])
        }
        // else if ()
        setIsAddNew(false)
        setName('')
        setEmail('')
        setDesignation('')
    }

    const onSearch = (e) => {
        const data = (e != null) ? e : ""
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: { "data": data },
            // url: `${ApiUrl()}/auth/user/multi-contact-search?search=${e}`
            url: `${ApiUrl()}/auth/user/filter-location-other?first_name=${data}`

        };
        axios(option)
            .then(e => {
                setOptions(e?.data)
            })
            .catch(err => {

            })
    }

    const builderOnSearch = (e) => {
        const data = (e != null) ? e : ""
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: { "data": data },
            // url: `${ApiUrl()}/auth/user/multi-contact-search?search=${e}`
            url: `${ApiUrl()}/auth/user/filter-location-dev?first_name=${data}`
        };
        axios(option)
            .then(e => {
                setBuilderOptions(e?.data)
            })
            .catch(err => {

            })

    }

    const AgentSearch = (e) => {
        const data = (e != null) ? e : ""
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
            },
            data: { "data": data },
            url: `${ApiUrl()}/auth/user/filter-location-agent?first_name=${data}`
        };
        axios(option)
            .then(e => {
                setAgentSearchData(e?.data)
            })
            .catch(err => {

            })
    }

    return (
        <>

            {
                loading ? <Loader /> : null
            }
                                   <div className="agent-row margin-re-top">

{/*  <div className="row"> */}
<div className="col-md-12">
    <div className="card mb-6">
        <div className="card-body px-6 pt-6 pb-5">
            <h3 className="card-title mb-0 text-heading fs-22 lh-15 pb-3">Property Contact</h3>
            <div className="row ">
                <div className="col-md-12 mb-5">
                    <label htmlFor="Builder" className="text-heading">Builder</label>
                    <SearchDropdown type="Builder" setData={(e) => setBuilder([...e])} onSearch={builderOnSearch} options={builderOptions} setOptions={setBuilderOptions}
                        onButtonClick={() => {
                            setTypeModal('Builder')
                            setIsAddNew(true)
                        }} value={builder} />
                </div>


                <div className="col-md-12 mb-5">
                    <label htmlFor="Architect" className="text-heading">Architect</label>
                    <SearchDropdown type="Architect" setData={(e) => setArchitect([...e])} options={options} onSearch={onSearch} setOptions={setOptions}
                        onButtonClick={() => {
                            setTypeModal('Architect')
                            setIsAddNew(true)
                        }} value={architect} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-5">
                    <label htmlFor="Marketing Company" className="text-heading">Marketing Company</label>
                    <SearchDropdown type="Marketing Company" setData={(e) => setMarketingCompany([...e])} options={options} onSearch={onSearch} setOptions={setOptions}
                        onButtonClick={() => {
                            setTypeModal('Marketing')
                            setIsAddNew(true)
                        }} value={marketingCompany} />
                </div>

                <div className="col-md-12 mb-5">
                    <label htmlFor="Sales Company" className="text-heading">Sales Company</label>
                    <SearchDropdown type="Sales Company" setData={(e) => setSalesCompany([...e])} options={options} onSearch={onSearch} setOptions={setOptions}
                        onButtonClick={() => {
                            setTypeModal('Sales')
                            setIsAddNew(true)
                        }} value={salesCompany} />
                </div>

                <div className="col-md-12 mb-5">
                    <label htmlFor="Interior Designer" className="text-heading">Interior Designer</label>
                    <SearchDropdown type="interior Designer" options={options} onSearch={onSearch} setOptions={setOptions} setData={(e) => {

                        setInteriorDesigner([...e])
                    }}
                        onButtonClick={() => {
                            setTypeModal('interior')
                            setIsAddNew(true)
                        }} value={interiorDesigner} />

                </div>

                <div className="col-md-12 mb-5">
                    <label htmlFor="agent" className="text-heading">Agent</label>
                    <SearchDropdown type="agent" setData={(e) => setAgent([...e])} onSearch={AgentSearch} options={agentSearchdata} setOptions={setAgentSearchData}
                        onButtonClick={() => {
                            setTypeModal('agent')
                            setIsAddNew(true)
                        }} value={agent} />
                </div>

                


            </div>


            {
                isAddNew ?
                    <Modal show={isAddNew} className="modal-add-contact">
                        <Modal.Header>
                            <ImCross onClick={HandleClose} className="button-cross-modal-add-contact" />
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row mx-n4">
                                <div className="form-group col-md-6 px-4 mb-5">
                                    <label htmlFor="firstName" className="text-heading">Name</label>
                                    <input type="text" className="form-control form-control-lg border-0" id="firstName"
                                        name="firstname" placeholder="Enter Name" onChange={(e) => setName(e?.target?.value)} value={name} />
                                </div>

                                <div className="form-group col-md-6 px-4 mb-5">
                                    <label htmlFor="role" className="text-heading">Designation</label>
                                    <input type="text" className="form-control form-control-lg border-0" id="role"
                                        name="role" placeholder="Enter Designation" onChange={(e) => setDesignation(e?.target?.value)} value={designation} />
                                </div>

                                <div className="form-group col-md-6  mb-5 px-4">
                                    <label htmlFor="email" className="text-heading">Email</label>
                                    <input type="email" className="form-control form-control-lg border-0" id="email"
                                        name="email" placeholder="Enter Email" onChange={(e) => setEmail(e?.target?.value)} value={email} />
                                </div>
                            </div>
                            <button type="button" className="btn bg-blue text-white hover-saffron" onClick={() => createUser()}>Submit</button>
                        </Modal.Body>
                    </Modal>
                    : null
            }




            {/* <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">
                    {
                        imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                    }

                    <div className="custom-file mt-4 h-auto">

                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*" onChange={PorfileImgHandler} />
                        <label className="btn btn-secondary btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                            profile image</label>
                    </div>
                </div> */}
        </div>
    </div>
    <button type="button" className="btn bg-blue text-white hover-saffron mr-bo-20" onClick={ContactProperty}>Submit</button>
</div>

{/*  </div> */}


{/* get Data  */}
<div className="row">
    {
        getDataContact?.map((item) => {
            return (
                <div className="col-sm-6 col-md-4 col-lg-3 mb-6">

                    <div className="card shadow-hover-xs-4 agent-3 remove-bg min-height-240">

                        <div className="card-header text-center pt-6 pb-3 bg-transparent text-center">

                            {/* <img src="images/agent-1.jpg" alt="Max Kordek" /> */}
                            {/* <img src={item?.profile_image} alt="Max Kordek" className="images-get-data" /> */}

                            <a className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-primary">{item?.name}</a>
                            <p className="mb-2">{item?.designation}</p>
                            {item.agent_key===true ?
                            (
                                <p className="mb-2"> Agent</p>):null
                            }
                            {item.builder===true ?
                            (
                                <p className="mb-2"> Builder</p>):null
                            }
                            {item.architect===true ?
                            (
                                <p className="mb-2"> Architect</p>):null
                            }
                            {item.interior_designer===true ?
                            (
                                <p className="mb-2"> Interior Designer</p>):null
                            }
                            {item.marketing_company===true ?
                            (
                                <p className="mb-2"> Mrketing Executive</p>):null
                            }
                            {item.sales_company===true ?
                            (
                                <p className="mb-2"> Sales Executive</p>):null
                            }
                            


                        </div>
                        <div className="card-body text-center pt-2  px-0">
                            <a href="" className="text-body">{item?.email}</a>

                        </div>
                        <div className="card-footer px-0 text-center hover-image border-0">
                            <button className="btn btn-danger" onClick={() => DeleteProperty(item?.id)} >Delete</button>
                        </div>
                    </div>

                </div>
            )
        })
    }

</div>

</div>



        </>
    )
}
export default AddContact;