import React from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import SinglePropertyPage from "../../Components/SingleProperty/singlepropertypage";
const DevSinglePropertyPage =(props)=>{

    
    console.log('====================================');
    let id = props

    console.log(id);
    console.log('====================================');
    
    return(
        <>
            <Header />
            <SinglePropertyPage />
            <Footer />
        </>
    )
}
export default DevSinglePropertyPage;